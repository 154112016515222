import React, {useState, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../hooks/useMountedState.js";
import usePrevious from "../hooks/usePrevious.js";

// Components
import CheckBox from "./CheckBox.js";

// Style
import {animateBackground, fadeIn, rotate} from "../style/components/animations.js";
import {border, colors, pad, radius} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {
  TitleCell,
  Text,
  TriangleDown,
  TriangleUp,
  Inline,
  Small,
  Abbr,
  scrollbar
} from "../style/components/general.js";

const BaseTable = ({
  loading,
  headings,
  data,
  orderBy,
  setOrderBy,
  groupBy,
  setGroupBy,
  items,
  setItems,
  editMenu,
  showOptions,
  alignRows,
  dataMissing,
  minHeight,
  maxHeight,
  cellFullWidth,
  cellOverflow
}) => {
  const isMounted = useMountedState();

  const prev = usePrevious(showOptions);

  const [checkAll, setCheckAll] = useState(null);

  const sort = e => {
    if (orderBy && groupBy) {
      let element = e.target;
      let currentGroupBy = null;
      while (!currentGroupBy && element) {
        currentGroupBy = element.getAttribute("data-key");
        element = element.parentElement;
      }

      if (currentGroupBy) setGroupBy(currentGroupBy);

      if (orderBy === "asc") setOrderBy("desc");

      if (orderBy === "desc") setOrderBy("asc");
    }
  };

  const getIdentifier = target => target.publicId || target.id || target.name;

  const setCheckMark = (checked, item) => {
    setCheckAll(null);
    if (checked) setItems(edit => [...edit, item]);
    else {
      const edit = [...items];
      const updated = edit.filter(target => getIdentifier(target) !== getIdentifier(item));
      setItems(updated);
    }
  };

  // Check whether all checkboxes have been manually checked
  const allChecked = useMemo(() => items?.length === data?.length, [data, items]);

  const selectAll = () => {
    setCheckAll(!allChecked);
    setItems(!allChecked ? data?.filter(item => item.canAccess ?? true) : []);
  };

  useEffect(() => {
    if (isMounted() && prev !== showOptions) {
      setItems([]);
      setCheckAll(null);
    }
  }, [isMounted, prev, showOptions, setItems]);

  return (
    <TableWrapper
      minHeight={minHeight}
      maxHeight={maxHeight}
      empty={!loading && data?.length === 0}
      loading={loading ? 1 : 0}>
      <Table>
        <thead>
          <TableNav>
            {showOptions && (
              <TitleCell>
                <Inline>
                  <CheckBox
                    name="selectAll"
                    handleCheck={checked => selectAll(checked)}
                    triggerCheck={allChecked}
                    initial={!!items && !!data && items?.length === data?.length}
                  />
                  {items.length > 0 && <Small>{items.length}&nbsp;selected</Small>}
                </Inline>
              </TitleCell>
            )}
            {!showOptions ? (
              Object.keys(headings).map(
                key =>
                  headings[key].header !== "" && (
                    <TitleCell key={key}>
                      <HeaderWrap
                        data-key={key}
                        disabled={headings[key].disabled}
                        onClick={e => {
                          if (!headings[key].disabled) sort(e);
                        }}>
                        {headings[key].header}
                        {!headings[key].disabled && typeof setOrderBy !== "undefined" ? (
                          <Arrow selected={key === groupBy} direction={orderBy} />
                        ) : (
                          <>&nbsp;&nbsp;&nbsp;</>
                        )}
                      </HeaderWrap>
                    </TitleCell>
                  )
              )
            ) : (
              <TitleCell colSpan={Object.keys(headings).length}>{editMenu}</TitleCell>
            )}
          </TableNav>
        </thead>
        {(loading || data?.length > 0) && (
          <tbody>
            {loading && (
              <LoaderContainer>
                <TableLoader data-testid="baseTable.loader" />
              </LoaderContainer>
            )}
            {data?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} data-testid="baseTable.row" deleted={item.isDeleted}>
                {showOptions && (item.canAccess ?? true) && (
                  <Cell>
                    <CheckBox
                      handleCheck={e => setCheckMark(e, item)}
                      triggerCheck={checkAll}
                      initial={
                        !!items?.some(selected => getIdentifier(selected) === getIdentifier(item))
                      }
                    />
                  </Cell>
                )}
                {Object.keys(headings).map(
                  key =>
                    headings[key] &&
                    headings[key].header !== "" && (
                      <Cell
                        key={key}
                        data-testid={headings[key].header}
                        alignRows={alignRows}
                        cellFullWidth={cellFullWidth}
                        cellOverflow={cellOverflow}>
                        {cellOverflow ? (
                          item[key]
                        ) : (
                          <Abbr
                            title={
                              item && key in Object.keys(item) && typeof item[key] !== "object"
                                ? item[key]
                                : null
                            }>
                            {item[key]}
                          </Abbr>
                        )}
                      </Cell>
                    )
                )}
              </Row>
            ))}
          </tbody>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoData>
          <Text center>{dataMissing}</Text>
        </NoData>
      )}
    </TableWrapper>
  );
};

BaseTable.propTypes = {
  headings: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  groupBy: PropTypes.string,
  setGroupBy: PropTypes.func,
  editMenu: PropTypes.node,
  showOptions: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  setItems: PropTypes.func,
  alignRows: PropTypes.string,
  dataMissing: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  cellFullWidth: PropTypes.bool,
  cellOverflow: PropTypes.bool
};

BaseTable.defaultProps = {
  data: null,
  loading: false,
  orderBy: "",
  setOrderBy: null,
  groupBy: "",
  setGroupBy: null,
  editMenu: null,
  showOptions: false,
  items: [],
  setItems: () => [],
  alignRows: "middle",
  dataMissing: "Requested Data Not Found",
  minHeight: null,
  maxHeight: null,
  cellFullWidth: false,
  cellOverflow: false
};

const Arrow = ({selected, direction}) => {
  if (!selected) return <TriangleHidden />;

  return direction === "desc" ? <TriangleDown /> : <TriangleUp />;
};

Arrow.propTypes = {
  selected: PropTypes.bool,
  direction: PropTypes.string
};

Arrow.defaultProps = {
  selected: false,
  direction: "up"
};

// Style Overrides
const TableWrapper = styled.div`
  ${flex("column", "nowrap", "start", "start")}

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: ${({loading}) => (loading ? "100px" : "unset")};

  ${scrollbar};

  table {
    background-color: ${({theme}) => theme.tertiary};
  }

  ${({empty, theme}) =>
    empty
      ? css`
          border-bottom: 1px solid ${theme.secondary};
        `
      : ""}

  ${({minHeight}) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};

  ${({maxHeight}) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
      overflow-y: auto;
    `}
`;

const TableNav = styled.tr`
  background: ${({theme}) => theme.secondary};
`;

const Table = styled.table`
  width: 100%;
`;

const LoaderContainer = styled.tr`
  height: 100%;

  min-height: 55px;
`;

const TableLoader = styled.td`
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${pad * 2}px;
  background: rgba(0, 0, 0, 0, 0.5);
  animation: ${animateBackground(0, 0.2)} 0.5s linear 0.3s 1 forwards;
  ${flex("row", "wrap", "center", "start")};

  &:after {
    content: "";
    width: 25px;
    height: 25px;
    border-top: ${radius} solid ${({theme}) => theme.tertiary};
    border-right: ${radius} solid ${({theme}) => theme.tertiary};
    border-bottom: ${radius} solid ${({theme}) => theme.tertiary};
    border-left: ${radius} solid ${({theme}) => theme.primary};
    border-radius: 50%;
    opacity: 0;
    animation: ${rotate} 1s infinite linear 0.2s, ${fadeIn} 0.5s linear 0.3s 1 forwards;
  }
`;

const Row = styled.tr`
  padding: ${pad * 2}px;
  border-bottom: ${border} solid rgba(0, 0, 0, 0.2);
  white-space: nowrap;

  ${({deleted}) =>
    deleted &&
    css`
      background-color: rgba(0, 0, 0, 0.08);
    `}
`;

const Cell = styled.td`
  padding: ${pad}px;
  text-align: left;
  position: relative;
  color: ${({theme}) => theme.secondary};
  vertical-align: ${({alignRows}) => alignRows || "middle"};
  max-width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;

  ${({cellOverflow}) =>
    cellOverflow &&
    css`
      overflow: inherit;
      text-wrap: wrap;
    `}

  ${bp(6)} {
    max-width: 400px;
  }

  ${({cellFullWidth}) =>
    cellFullWidth &&
    css`
      max-width: initial;
    `}
`;

const HeaderWrap = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const TriangleHidden = styled(TriangleDown)`
  opacity: 0;
`;

const NoData = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  height: 100%;
  margin: auto 0;
  min-height: 56px;
  align-self: stretch;
  background-color: ${colors.grayLight};
`;

export default BaseTable;
