import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputSelect from "./InputSelect.js";
import InputTime from "./InputTime.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, LabelBold, Small} from "../../style/components/general.js";

const options = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31"
];

const InputDateTimeGroup = ({...props}) => {
  const {name, label, placeholder, testId} = props;

  const {control, watch} = useFormContext();

  const {fields, remove, insert} = useFieldArray({control, name});

  const getErrorMessage = date => {
    if (!date) return "";
    const parsed = Number.parseInt(date, 10);
    if (parsed && !Number.isNaN(parsed) && parsed > 28)
      return `For months with less than ${date} days, emails will be sent on the last day of the month.`;
    return "";
  };

  return (
    <FormFieldWrapper data-testid={testId} standard>
      <LabelBold htmlFor={name}>{label}</LabelBold>
      {fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <Fragment key={field.id}>
            <InlineFormField>
              <SelectWrapper>
                <InputSelect
                  testId={`${testId}-date`}
                  name={`${name}.${index}.date`}
                  placeholder={placeholder || "Select date..."}
                  options={options}
                  minWidth={150}
                />
              </SelectWrapper>

              <InputTime testId={`${testId}-time`} name={`${name}.${index}.time`} />

              <Edit
                disable={fields.length === 1}
                type="button"
                data-testid={`${testId}.removeInput`}
                onClick={() => remove(index)}>
                <FontAwesomeIcon icon={faMinus} />
              </Edit>

              {options && options.length > 0 && fields.length < options.length && (
                <Edit
                  type="button"
                  data-testid={`${testId}.addInput`}
                  onClick={() => {
                    insert(index + 1, "");
                  }}>
                  <FontAwesomeIcon icon={faPlus} />
                </Edit>
              )}
            </InlineFormField>

            <Small>{getErrorMessage(watch(`${name}.${index}.date`))}</Small>
          </Fragment>
        ))}
    </FormFieldWrapper>
  );
};

InputDateTimeGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  placeholder: PropTypes.string
};

InputDateTimeGroup.defaultProps = {
  testId: "input-date-time-group",
  options: null,
  label: null,
  placeholder: null
};

// Style Overrides
const InlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad * 1.4}px;
  justify-content: flex-start;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad}px;
    }
  }
`;

const Edit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;

  ${props =>
    props.disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const SelectWrapper = styled.div`
  width: 200px;
`;

export default InputDateTimeGroup;
