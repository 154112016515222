import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Utils
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";

// Components
import InputError from "./InputError.js";
import InputTextArea from "./InputTextArea.js";
import Help from "../Help.js";

// Style
import {border, colors, pad} from "../../style/components/variables.js";
import {Abbr, Error, FormFieldWrapper, Label, Pill, Text} from "../../style/components/general.js";

const InputSwitch = ({
  testId,
  name,
  label,
  prompt,
  disabled,
  on,
  off,
  globalUnacceptable,
  globalUnacceptableTitle,
  hasAlert,
  alertMessage,
  alertCondition,
  condition,
  tag
}) => {
  const {
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const value = watch(name);

  const hasYupErrors = useMemo(() => {
    const {message} = get(errors, name) || {};
    return !!message;
  }, [errors, name]);

  return (
    <FormFieldWrapper data-testid={testId} standard>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {tag && (
            <Pill>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
          {globalUnacceptable}
        </LabelWrapper>
      )}
      {prompt && <Prompt quiet>{prompt}</Prompt>}
      <CheckLabel>
        <SwitchWrapper>
          <SwitchOutline disabled={disabled}>
            <CheckInput
              id={name}
              data-testid={`${testId}-checkbox`}
              type="checkbox"
              disabled={disabled}
              {...register(name)}
            />
            <SwitchIcon />
          </SwitchOutline>
        </SwitchWrapper>
        <Text>{value ? on : off}</Text>
      </CheckLabel>
      <InputError errors={errors} name={name} />
      {!hasYupErrors && globalUnacceptableTitle && (
        <Error>Parameter Violation: {globalUnacceptableTitle}</Error>
      )}

      {hasAlert && value === alertCondition && (
        <InputTextArea
          name={`${name}_comment`}
          label={alertMessage || defaultUnacceptableParameterPrompt}
          required
          maxLength={1000}
        />
      )}
    </FormFieldWrapper>
  );
};

InputSwitch.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  disabled: PropTypes.bool,
  on: PropTypes.string,
  off: PropTypes.string,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitle: PropTypes.string,
  hasAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertCondition: PropTypes.any,
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputSwitch.defaultProps = {
  testId: "input-switch",
  label: null,
  prompt: "",
  disabled: false,
  on: "ON",
  off: "OFF",
  globalUnacceptable: null,
  globalUnacceptableTitle: null,
  hasAlert: false,
  alertMessage: null,
  alertCondition: null,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const CheckLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${pad / 2}px;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const SwitchWrapper = styled.div`
  display: flex;
`;

const SwitchOutline = styled.div`
  position: relative;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${pad}px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  ${({disabled}) =>
    disabled &&
    css`
      background: ${colors.grayLight};

      &:hover {
        cursor: auto;
      }
    `}
`;

const SwitchIcon = styled.span`
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: inherit;
  transition: all ease 0.1s;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    background: ${({theme}) => theme.primary};
    border-radius: 50%;
    margin: 2px;
    transition: all ease 0.1s;
  }
`;

const CheckInput = styled.input`
  display: none;

  &:checked + span:after {
    left: 15px;
  }
`;

const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;

export default InputSwitch;
