import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import {getLocation} from "../utils/helpers.js";
import {useToast} from "../contexts/toast.js";

// Components
import {InputText} from "./form/FormInputs.js";
import Address from "./Address.js";

// Style
import {pad} from "../style/components/variables.js";
import {FormField, Button, Inline} from "../style/components/general.js";

const COORDS = "Coordinates";
const ADDRSS = "Address";

const DEFAULT_ADDRESS = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zipCode: ""
};

const DEFAULT_COORDS = {
  lat: "",
  lon: ""
};

const Location = ({targetElement, defaultForm}) => {
  const {addToast} = useToast();

  const {reset, setValue, getValues} = useFormContext();

  const [toggle, setToggle] = useState(defaultForm || COORDS);

  useEffect(() => {
    const {address} = targetElement || getValues();
    if (toggle === COORDS) {
      reset(prev => ({
        ...prev,
        hasAddress: true,
        address: {
          ...address,
          ...DEFAULT_ADDRESS
        }
      }));
    }

    if (toggle === ADDRSS) {
      reset(prev => ({
        ...prev,
        hasAddress: true,
        address: {
          ...address,
          ...DEFAULT_COORDS
        }
      }));
    }
  }, [getValues, reset, targetElement, toggle]);

  const useMyLocation = async () => {
    const {error, lat, lon} = await getLocation();
    if (error) addToast(error, "error");
    setValue("address.lat", lat);
    setValue("address.lon", lon);
  };

  return (
    <>
      <Inline>
        <MenuOption
          data-testid="toggleAddress"
          type="button"
          onClick={() => setToggle(prev => (prev === COORDS ? ADDRSS : COORDS))}>
          Use {toggle === COORDS ? "Address" : "Coordinates"}
        </MenuOption>
        {toggle === "Coordinates" && (
          <MenuOption data-testid="useLocation" type="button" onClick={useMyLocation}>
            Fill Using My Location
          </MenuOption>
        )}
      </Inline>

      {toggle === "Coordinates" ? (
        <>
          <FormField>
            <InputText testId="lat" name="address.lat" label="Latitude" />
          </FormField>
          <FormField>
            <InputText testId="lon" name="address.lon" label="Longitude" />
          </FormField>
        </>
      ) : (
        <Address />
      )}
    </>
  );
};

Location.propTypes = {
  targetElement: PropTypes.objectOf(PropTypes.any),
  defaultForm: PropTypes.string
};

Location.defaultProps = {
  targetElement: null,
  defaultForm: COORDS
};

// Style Overrides
const MenuOption = styled(Button)`
  margin-right: ${pad}px;
`;

export default Location;
