import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import styled, {css} from "styled-components";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {isMobileDevice} from "../../utils/responsive.js";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {Abbr, FormFieldWrapper, Input, Label, Pill, Text} from "../../style/components/general.js";
import {pad} from "../../style/components/variables.js";

const InputDate = ({
  testId,
  label,
  name,
  required,
  disabled,
  defaultValue,
  placeholder,
  inline,
  maxWidth,
  condition,
  prompt,
  tag,
  min,
  max
}) => {
  const isMounted = useMountedState();

  const [focused, setFocused] = useState(false);

  const {
    register,
    watch,
    setValue,
    formState: {errors}
  } = useFormContext();
  const value = watch(name);

  useEffect(() => {
    if (isMounted() && !value) setValue(name, defaultValue);
  }, [isMounted, name, value, defaultValue, setValue]);

  return (
    <FormFieldWrapper selfInline={inline}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      {prompt && <Prompt quiet>{prompt}</Prompt>}
      <Date
        id={name}
        data-testid={testId}
        type={value || focused || !placeholder ? "date" : "text"}
        min={min}
        max={max}
        name={name}
        disabled={disabled}
        maxWidth={maxWidth}
        placeholder={placeholder}
        hasValue={value}
        isMobile={isMobileDevice() ? 1 : 0}
        onFocus={() => setFocused(true)}
        {...register(name, {required: required, onBlur: () => setFocused(false)})}
      />
      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputDate.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  inline: PropTypes.bool,
  maxWidth: PropTypes.string,
  condition: PropTypes.string,
  tag: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

InputDate.defaultProps = {
  testId: "input-date",
  label: null,
  required: false,
  disabled: false,
  defaultValue: "",
  placeholder: null,
  prompt: null,
  min: null,
  max: null,
  inline: false,
  maxWidth: null,
  condition: undefined,
  tag: undefined,
  innerRef: null
};

// Style Overrides
const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const Date = styled(Input)`
  min-width: 135px;
  max-width: 135px;
  height: 35px;

  ${({maxWidth}) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
`;

export default InputDate;
