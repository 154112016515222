import {useState, createContext, useMemo} from "react";
import PropTypes from "prop-types";

export const NavContext = createContext();

const NavProvider = ({children}) => {
  const [online, setOnline] = useState(!!navigator.onLine);
  const [showManageSessions, setShowManageSessions] = useState(false);
  const [idleVisible, setIdleVisible] = useState(false);
  const [showOnTestModal, setShowOnTestModal] = useState(false);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      online,
      setOnline,
      showManageSessions,
      setShowManageSessions,
      idleVisible,
      setIdleVisible,
      showOnTestModal,
      setShowOnTestModal
    }),
    [idleVisible, online, showManageSessions, showOnTestModal]
  );

  return <NavContext.Provider value={memoedValue}>{children}</NavContext.Provider>;
};

NavProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavProvider;
