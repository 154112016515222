import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {io} from "socket.io-client";

const socket = io(process.env.REACT_APP_API, {autoConnect: false});
const SocketContext = createContext(socket);

const SocketProvider = ({children}) => (
  <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
);

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useSocket = () => useContext(SocketContext);

export {SocketContext, useSocket};
export default SocketProvider;
