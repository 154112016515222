import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faEdit, faPlus} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {addField, removeElement, updateElement} from "../../utils/builder.js";
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import ModalAddField from "../checksheet-builder/ModalAddField.js";
import ModalEditElement from "../checksheet-builder/ModalEditElement.js";
import ModalFormula from "../checksheet-builder/ModalFormula.js";
import ModalElapsed from "../checksheet-builder/ModalElapsed.js";
import ModalConditionals from "../checksheet-builder/ModalConditionals.js";
import ModalDeleteElement from "../checksheet-builder/ModalDeleteElement.js";
import ModalRedirect from "../general/ModalRedirect.js";
import RenderChecksheetBuilder from "../checksheet-builder/RenderChecksheetBuilder.js";
import SearchSelect from "../../components/SearchSelect.js";
import InputTextGroupDraggable from "../../components/form/InputTextGroupDraggable.js";
import {InputCheck, InputFileGroup, InputText} from "../../components/form/FormInputs.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  Error,
  Form,
  FormField,
  HeadingMedium,
  Inline,
  Label,
  Small
} from "../../style/components/general.js";

const ModalStage = ({
  visible,
  setVisible,
  goBack,
  stage,
  existing,
  units,
  setUnits,
  unitsToAdd,
  setUnitsToAdd,
  allowNameEdit,
  saveStage
}) => {
  const {roles, currentUser} = useContext(AuthContext);

  const [target, setTarget] = useState(null);
  const [edit, setEdit] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [restrictTo, setRestrictTo] = useState(existing?.restrictTo || []);
  const [triggerAppend, setTriggerAppend] = useState(null);
  const [builder, setBuilder] = useState(existing?.builder || {allIds: [], byId: {}});
  const [activeDroppableId, setActiveDroppableId] = useState("");

  // Modals
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRedirectFormulaModal, setShowRedirectFormulaModal] = useState(false);
  const [formulaMode, setFormulaMode] = useState("create");
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [showConditionalModal, setShowConditionalModal] = useState(false);
  const [showElapsedModal, setShowElapsedModal] = useState(false);
  const [elapsedMode, setElapsedMode] = useState(false);

  const {api: uploadFile} = useApi("files");

  const form = useForm({
    defaultValues: {
      name: existing?.name || stage?.name,
      hasHelp: existing?.help?.filter(c => Object.keys(c).length > 0).length > 0,
      help: existing?.help || [{}]
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Please provide a name for stage."),
        hasHelp: yup.bool(),
        help: yup
          .mixed()
          .nullable()
          .when("hasHelp", {
            is: val => !!val,
            then: () =>
              yup
                .array()
                .of(
                  yup.object().shape({
                    key: yup.string().nullable().required("Please provide a key"),
                    value: yup.string().nullable().required("Please provide a value")
                  })
                )
                .test({
                  test: val => Array.isArray(val) && val.length > 0,
                  message: "Please provide help item"
                })
          })
      })
    )
  });
  const {watch} = form;
  const name = watch("name");
  const hasHelp = watch("hasHelp");
  const help = watch("help");

  const scrollContainer = useRef(null);
  const scrollPos = useRef(null);

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = roles.filter(option => option.label.toLowerCase().includes(lower));
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setSearchResults(tempResults);
    } else setSearchResults(roles);
  };

  const addFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    uploadFile
      .callPost(formData, {
        params: {
          userId: currentUser.publicId
        }
      })
      .then(({status, data: body}) => {
        if (status === 201) {
          setTriggerAppend({
            key: body.data.label.split(".")[0],
            value: body.data.label,
            fileId: body.data.id
          });
        }
      });
  };

  useLayoutEffect(() => {
    if (
      showAddModal ||
      showConditionalModal ||
      showDeleteModal ||
      showEditModal ||
      showFormulaModal ||
      showRedirectFormulaModal ||
      showElapsedModal
    ) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [
    showAddModal,
    showConditionalModal,
    showDeleteModal,
    showEditModal,
    showFormulaModal,
    showRedirectFormulaModal,
    showElapsedModal
  ]);

  const saveBuilder = (updated, newUnits) => {
    if (newUnits && newUnits.length > 0) {
      const temp = newUnits.filter(unit => !unitsToAdd.includes(unit));
      setUnitsToAdd(prev => [...prev, ...temp]);
      setUnits(prev => [...prev, ...temp]);
    }
    setBuilder(updated);
  };

  const handleEditField = update => {
    const newUnits = [];
    if (update.units && !units.includes(update.units)) {
      setUnits(prev => [...prev, update.units].sort());
      newUnits.push(update.units);
    }
    const {builder: updated} = updateElement(builder, update, false);
    saveBuilder(updated, newUnits);
    setShowEditModal(false);
  };

  const handleDeleteField = update => {
    const updated = removeElement(builder, update);
    saveBuilder(updated);
    setShowDeleteModal(false);
  };

  const handleAddField = field => {
    const newUnits = [];
    if (field.units && !units.includes(field.units)) {
      setUnits(prev => [...prev, field.units].sort());
      newUnits.push(field.units);
    }
    const temp = {
      ...field,
      toggle: true
    };
    const updated = addField(builder, temp);
    saveBuilder(updated, newUnits);
  };

  const handleAddMultipleFields = fields => {
    const newUnits = [];
    let updated = {...builder};
    fields.map(field => {
      if (field.units && !units.includes(field.units)) {
        setUnits(prev => [...prev, field.units].sort());
        newUnits.push(field.units);
      }
      const temp = {
        ...field,
        toggle: true
      };
      updated = addField(updated, temp);
    });
    saveBuilder(updated, newUnits);
  };

  const handleFormula = field => {
    if (field.type === "generated") handleEditField(field);
    else {
      const newField = {
        ...field,
        type: "generated",
        disabled: true
      };
      handleAddField(newField);
    }
  };

  const handleConditional = values => {
    const element = {
      ...target,
      condition: values.condition
    };
    handleEditField(element);
  };

  const cloneElement = targetElement => {
    const {element, label, name: stageName} = targetElement;
    const clone = {
      ...builder.byId[stageName],
      label: `${label} copy`,
      generates: [],
      toggle: true,
      condition: null,
      name: null
    };

    let updated;
    if (element === "field") updated = addField(builder, clone);

    saveBuilder(updated);
  };

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type: dragType} = result;
    if (!destination) {
      return;
    }

    let updatedBuilder = {...builder};

    if (dragType === "DEFAULT") {
      const allIds = [...builder.allIds];

      const [removed] = allIds.splice(source.index, 1);
      allIds.splice(destination.index, 0, removed);

      updatedBuilder = {...updatedBuilder, allIds};

      saveBuilder(updatedBuilder);
    }
  };

  const handleShowFormulaModal = element => {
    if (element.type === "generated") setShowRedirectFormulaModal(true);
    else setShowFormulaModal(true);
  };

  const handleShowElapsedModal = element => {
    if (element.type === "generated") {
      setElapsedMode("edit");
      setShowElapsedModal(true);
    } else {
      setElapsedMode("create");
      setShowElapsedModal(true);
    }
  };

  if (showEditModal && showModal)
    return (
      <ModalEditElement
        visible={showEditModal}
        setVisible={setVisible}
        targetElement={target}
        persistEdit={handleEditField}
        builder={builder}
        editing={false}
        units={units}
        hasBackButton
        goBack={() => setShowEditModal(false)}
      />
    );

  if (showAddModal && showModal)
    return (
      <ModalAddField
        visible={showAddModal}
        setVisible={setVisible}
        addFields={handleAddMultipleFields}
        builder={builder}
        parentName={target.name}
        units={units}
        hasBackButton
        goBack={() => setShowAddModal(false)}
      />
    );

  if (showFormulaModal && showModal)
    return (
      <ModalFormula
        visible={showFormulaModal}
        setVisible={setVisible}
        targetElement={target}
        save={handleFormula}
        builder={builder}
        units={units}
        mode={formulaMode}
        hasBackButton
        goBack={() => {
          setFormulaMode("create");
          setShowFormulaModal(false);
        }}
        noPrevious
      />
    );

  if (showConditionalModal && showModal)
    return (
      <ModalConditionals
        visible={showConditionalModal && !!target}
        setVisible={setVisible}
        targetElement={target}
        save={handleConditional}
        builder={builder}
        hasBackButton
        goBack={() => setShowConditionalModal(false)}
      />
    );

  if (showDeleteModal && showModal)
    return (
      <ModalDeleteElement
        builder={builder}
        visible={showDeleteModal}
        setVisible={setVisible}
        persistDelete={handleDeleteField}
        targetElement={target}
        hasBackButton
        goBack={() => setShowDeleteModal(false)}
      />
    );

  if (showRedirectFormulaModal && showModal)
    return (
      <ModalRedirect
        visible={showRedirectFormulaModal}
        setVisible={setShowRedirectFormulaModal}
        buttonOptions={[
          {
            action: () => {
              setFormulaMode("create");
              setShowFormulaModal(true);
              setShowRedirectFormulaModal(false);
            },
            description: "Create formula based on this field",
            icon: faPlus
          },
          {
            action: () => {
              setFormulaMode("edit");
              setShowFormulaModal(true);
              setShowRedirectFormulaModal(false);
            },
            description: "Edit existing formula field",
            icon: faEdit
          }
        ]}
      />
    );

  if (showElapsedModal && showModal)
    return (
      <ModalElapsed
        visible={showElapsedModal}
        setVisible={state => {
          if (!state) setElapsedMode("create");
          setShowElapsedModal(state);
        }}
        targetElement={target}
        mode={elapsedMode}
        save={handleFormula}
        builder={builder}
        goBack={() => setShowElapsedModal(false)}
        hasBackButton
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={goBack !== null}
      goBack={goBack && goBack}
      scrollRef={scrollContainer}>
      <FormProvider {...form}>
        <Form>
          <HeaderWrapper>
            STAGE:&nbsp;
            {edit ? (
              <TextInputWrapper>
                <InputText name="name" />
              </TextInputWrapper>
            ) : (
              <span>{name?.toUpperCase() || "Complete"}</span>
            )}
            {allowNameEdit && (
              <Button type="button" title={name || "Stage"} onClick={() => setEdit(prev => !prev)}>
                <FontAwesomeIcon icon={!edit ? faEdit : faCheck} />
              </Button>
            )}
          </HeaderWrapper>

          <FormField>
            <Label htmlFor="file_name" bold>
              ROLE RESTRICTION
            </Label>
            <Small>Only allow user&apos;s with selected roles can complete this stage.</Small>
            <br />
            {roles?.length > 0 ? (
              <>
                <SearchSelect
                  placeholder="Find role(s)..."
                  results={searchResults}
                  setResults={setSearchResults}
                  search={search}
                  add={({id, name: roleName}) => {
                    setRestrictTo(prev => {
                      const temp = prev ? [...prev] : [];
                      if (temp || !temp.map(curr => curr.id).includes(id))
                        temp.push({id, name: roleName});
                      return temp;
                    });
                  }}
                  showAll
                />
                <Inline>
                  {restrictTo?.map(({id, name: roleName}) => {
                    const {label} = roles.filter(role => role.id === id)[0];
                    return (
                      <SelectedRole key={roleName}>
                        <Abbr title={label.toUpperCase()}>{label.toUpperCase()}</Abbr>
                        <IconButton
                          onClick={() => {
                            setRestrictTo(prev => {
                              let toRemove;
                              prev.map((role, index) => {
                                if (role.id === id) toRemove = index;
                              });
                              const updated = prev.splice(prev, toRemove);
                              return updated;
                            });
                          }}>
                          <FontAwesomeIcon icon={faClose} />
                        </IconButton>
                      </SelectedRole>
                    );
                  })}
                </Inline>
              </>
            ) : (
              <Error>Roles need to be configured to update this stage.</Error>
            )}
          </FormField>

          <FormField>
            <InputCheck name="hasHelp">Do you want to provide additional help?</InputCheck>
          </FormField>

          {hasHelp && (
            <>
              <InputFileGroup name="files" callback={addFile} />

              <FormField>
                <InputTextGroupDraggable
                  name="help"
                  label="Stage Help"
                  triggerAppend={triggerAppend}
                  canSetKey
                />
              </FormField>
            </>
          )}
        </Form>
      </FormProvider>
      <FormField>
        <Label bold>CUSTOM FIELDS</Label>
        <Builder>
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="allIds">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <RenderChecksheetBuilder
                    builder={builder}
                    setBuilder={saveBuilder}
                    setTarget={setTarget}
                    setShowEditModal={setShowEditModal}
                    setShowAddModal={setShowAddModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowFormulaModal={handleShowFormulaModal}
                    setShowConditionalModal={setShowConditionalModal}
                    setShowElapsedModal={handleShowElapsedModal}
                    cloneElement={cloneElement}
                    activeDroppableId={activeDroppableId}
                    inEventBuilder>
                    {provided.placeholder}
                  </RenderChecksheetBuilder>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Builder>
      </FormField>
      <Save
        type="button"
        title="Save"
        onClick={() => saveStage({...stage, name, restrictTo, help, builder})}>
        Update Stage
      </Save>
    </Modal>
  );
};

ModalStage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  stage: PropTypes.objectOf(PropTypes.any).isRequired,
  existing: PropTypes.shape({
    name: PropTypes.string,
    restrictTo: PropTypes.arrayOf(PropTypes.any),
    help: PropTypes.arrayOf(PropTypes.any),
    builder: PropTypes.objectOf(PropTypes.any)
  }),
  units: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUnits: PropTypes.func.isRequired,
  unitsToAdd: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUnitsToAdd: PropTypes.func.isRequired,
  allowNameEdit: PropTypes.bool,
  saveStage: PropTypes.func.isRequired
};

ModalStage.defaultProps = {
  goBack: null,
  existing: null,
  allowNameEdit: false
};

// Style Overrides
const HeaderWrapper = styled(HeadingMedium)`
  display: flex;
  align-items: center;

  ${Button} {
    background: none;

    svg {
      fill: ${({theme}) => theme.secondary};
    }
  }
`;

const TextInputWrapper = styled.div`
  margin-right: ${pad}px;

  div {
    margin: 0;
  }
`;

const SelectedRole = styled.div`
  display: flex;
  justify-content: space-between;
  width: min-content;
  max-width: 200px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  padding: ${pad / 2}px ${pad}px;
  margin: ${pad}px 0;

  ${Abbr} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const IconButton = styled(Button)`
  ${voice.small};
  background-color: transparent;
  width: min-content;
  padding: 0;
  margin-left: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const Builder = styled.div`
  position: relative;
  width: 100%;
`;

const Save = styled(Button)`
  background: ${({theme}) => theme.success};
`;

export default ModalStage;
