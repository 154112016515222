import React, {useContext, useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {faArrowsUpDownLeftRight, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import InputTextGroupDraggable from "../../components/form/InputTextGroupDraggable.js";
import {InputText, InputCheck, InputError} from "../../components/form/FormInputs.js";

// Style
import {voice} from "../../style/components/typography.js";
import {scrollTo} from "../checksheet-builder/helpers.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  FormField,
  Button,
  Form,
  FormGroup,
  ButtonLoader,
  HeadingCenter,
  Label
} from "../../style/components/general.js";

const schema = yup.object().shape({
  name: yup.string().required("Please provide name"),
  details: yup.object().shape({
    hasJobs: yup.bool(),
    jobs: yup.array().when("hasJobs", {
      is: val => !!val,
      then: () =>
        yup.array().of(
          yup.object().shape({
            option: yup.string().required("Job # required when checked")
          })
        )
    }),
    hasNotes: yup.bool(),
    notes: yup.array().when("hasNotes", {
      is: val => !!val,
      then: () =>
        yup.array().of(
          yup.object().shape({
            key: yup.string().required("Key required when checked"),
            value: yup.string().required("Value required when checked")
          })
        )
    }),
    hasContacts: yup.bool(),
    contacts: yup.array().when("hasContacts", {
      is: val => !!val,
      then: () =>
        yup.array().test({
          message: "At least one email is required.",
          test: arr => arr.length > 0
        })
    })
  })
});

const ModalEditFacility = ({visible, setVisible, facility, setFacility, editContacts}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {currentUser} = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const {api} = useApi("facilities");
  const {api: apiUsers} = useApi("facility-users");

  const form = useForm({
    defaultValues: {
      ...facility,
      details: {
        ...facility.details,
        hasContacts:
          editContacts || (facility.details.contacts && facility.details.contacts.length > 0),
        hasNotes: facility.details.notes && facility.details.notes.length > 0,
        hasJobs: facility.details.jobs && facility.details.jobs.length > 0,
        jobs:
          facility.details.jobs &&
          facility.details.jobs.map(job => ({
            option: job
          })),
        contacts:
          facility.details.contacts &&
          facility.details.contacts.map(contact => ({
            email: contact
          }))
      }
    },
    resolver: yupResolver(schema)
  });
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: {errors}
  } = form;

  const hasJobs = watch("details.hasJobs");
  const hasNotes = watch("details.hasNotes");
  const hasContacts = watch("details.hasContacts");
  const {
    fields: contacts,
    append,
    remove,
    insert
  } = useFieldArray({control, name: "details.contacts"});

  useEffect(() => {
    const {details} = facility;
    if (!hasJobs && !details.jobs) setValue("details.jobs", [{option: ""}]);
    if (!hasNotes && !details.notes) setValue("details.notes", [{key: "", value: ""}]);
    if (!hasContacts && !details.contacts) setValue("details.contacts", []);
  }, [facility, hasContacts, hasJobs, hasNotes, setValue]);

  const editFacility = values => {
    setLoading(true);

    const {name, details} = values;

    const detailParams = {};
    if (details.hasJobs && details?.jobs?.length > 0)
      detailParams.jobs = details.jobs.map(job => job.option);
    if (details.hasNotes && details?.notes?.length > 0) detailParams.notes = details.notes;
    if (details.hasContacts && details?.contacts?.length > 0)
      detailParams.contacts = details.contacts.map(contact => contact.email);

    const sanitizedName = name.replace(/\s+/g, " ").trim();

    const editFacilityData = {
      name: sanitizedName,
      details: detailParams,
      userPublicId: currentUser.publicId
    };

    api
      .callPut(facility.id, editFacilityData)
      .then(({status, data}) => {
        if (status === 200 && data) {
          const updated = data.facility;
          setFacility(prev => ({
            ...prev,
            name: updated.name,
            slug: updated.slug,
            details: updated.details,
            addresses: updated.addresses
          }));

          window.history.pushState({}, "Facility", updated.slug);

          // when changing facility name, need to update corresponding paths in facility dashboard tabs
          const matchedPathName = pathname.match(/^\/.+\/.+\/(.+)\/?/);
          const tabPathName = matchedPathName ? `/${matchedPathName[1]}` : "";
          navigate(`/facilities/${updated.slug}${tabPathName}`);
        }
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  const handleSearch = query =>
    apiUsers
      .callGet("", {
        facilityId: facility.id,
        limit: 20,
        filter: query
          ? JSON.stringify({
              Search: query,
              showHidden: true,
              State: ["Active", "Disabled"]
            })
          : JSON.stringify({
              showHidden: true,
              State: ["Active", "Disabled"]
            })
      })
      .then(({status, data}) => {
        if (status === 200 && data) setUsers(data);
      });

  const onDragEnd = result => {
    const {destination, source} = result;

    if (!destination || source.index === destination.index) return;

    const movingField = watch(`details.contacts.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }
    insert(destination.index + destinationOffset, {...movingField});
    remove(source.index + sourceOffset);
  };

  useEffect(() => {
    if (editContacts) {
      const contactsElement = document.getElementById("contacts");
      const modalElement = document.getElementById("modal");
      if (contactsElement && modalElement)
        setTimeout(() => scrollTo(contactsElement, -50, modalElement), 100);
    }
  }, [editContacts]);

  return (
    facility && (
      <Modal visible={visible} setVisible={setVisible}>
        <ModalTitle>Edit Facility</ModalTitle>

        <FormProvider {...form}>
          <Form noValidate>
            <FormGroup>
              <FormField>
                <InputText name="name" placeholder="Name" label="Name" required />
              </FormField>

              <FormField>
                <Label bold>TYPE:</Label> {facility?.type}
              </FormField>
            </FormGroup>

            <FormGroup>
              <Label bold>Facility Information</Label>

              <InputCheck name="details.hasJobs">Include Job number(s)</InputCheck>
              {hasJobs && (
                <FormField>
                  <InputTextGroupDraggable
                    name="details.jobs"
                    valuePlaceholder="Job #"
                    limit={15}
                  />
                </FormField>
              )}

              <InputCheck name="details.hasNotes">Include Note(s)</InputCheck>
              {hasNotes && (
                <FormField>
                  <InputTextGroupDraggable name="details.notes" keyPlaceholder="Label" canSetKey />
                </FormField>
              )}

              <InputCheck name="details.hasContacts">Include Contact(s)</InputCheck>
              {hasContacts && (
                <FormField id="contacts">
                  <SearchSelect
                    label="Contacts"
                    search={handleSearch}
                    results={users}
                    setResults={setUsers}
                    placeholder="Find users..."
                    add={contact => {
                      if (!contacts || !contacts.map(({email}) => email).includes(contact.email))
                        append({email: contact.email, publicId: contact.publicId});
                    }}
                    showAll
                  />
                  <InputError errors={errors} name="details.contacts" />

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="contacts">
                      {outerProvided => (
                        <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                          {contacts?.map(({email}, i) => (
                            <Draggable
                              draggableId={`${email}.${i}`}
                              index={i}
                              key={email}
                              isDragDisabled={contacts.length <= 1}>
                              {innerProvided => (
                                <SelectionMargin
                                  {...innerProvided.draggableProps}
                                  {...innerProvided.dragHandleProps}
                                  ref={innerProvided.innerRef}>
                                  <Selection key={email}>
                                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                    &nbsp;&nbsp;
                                    <span {...innerProvided.dragHandleProps}>{email}</span>
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faClose} onClick={() => remove(i)} />
                                  </Selection>
                                  {innerProvided.placeholder}
                                </SelectionMargin>
                              )}
                            </Draggable>
                          ))}
                          {outerProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </FormField>
              )}
            </FormGroup>

            <FormField>
              <Submit type="button" onClick={handleSubmit(editFacility)} loading={loading ? 1 : 0}>
                Save {loading && <ButtonLoader />}
              </Submit>
            </FormField>
          </Form>
        </FormProvider>
      </Modal>
    )
  );
};

ModalEditFacility.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  setFacility: PropTypes.func.isRequired,
  setRefreshFacility: PropTypes.func.isRequired,
  editContacts: PropTypes.bool
};

ModalEditFacility.defaultProps = {
  editContacts: false
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Submit = styled(Button)`
  margin-bottom: ${pad}px;
`;

const Selection = styled.div`
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px ${pad / 2}px;
  width: fit-content;
  ${voice.quiet};
`;

const SelectionMargin = styled.div`
  padding-top: ${pad}px;
`;

export default ModalEditFacility;
