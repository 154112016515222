import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, FormProvider} from "react-hook-form";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import Modal from "../../components/Modal.js";
import {InputCheckGroup, InputSelect} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  FormGroup,
  FormField,
  ButtonFull,
  Form,
  HeadingCenter
} from "../../style/components/general.js";

const ModalAddToBuilder = ({
  visible,
  setShowModal,
  builder,
  facility,
  saveBuilder,
  element,
  setRefreshMissing
}) => {
  const [componentsMissing, setComponentsMissing] = useState(false);

  const schema = componentsMissing
    ? yup.object().shape({
        include: yup
          .mixed()
          .not([false], `Please select at least one component to add to ${element.label}.`)
      })
    : yup.object().shape({
        after: yup.string().required(`Please select a group after which to place ${element.label}.`)
      });

  const form = useForm({
    defaultValues: componentsMissing ? {include: false} : {after: null},
    resolver: yupResolver(schema)
  });

  const {handleSubmit, reset} = form;

  useEffect(() => {
    if (element.componentsMissing && Object.keys(element.componentsMissing).length > 0)
      setComponentsMissing(true);
  }, [element]);

  const handleAddElement = values => {
    const updated = builder;
    if (componentsMissing) {
      if (Array.isArray(values.include)) {
        updated.byId[element.name].children.push(...values.include);
        values.include.forEach(name => {
          updated.byId[name] = {
            ...facility.builder.byId[name],
            children: [...facility.builder.byId[name].children],
            toggle: true
          };
        });
      } else {
        updated.byId[element.name].children.push(values.include);
        updated.byId[values.include] = {
          ...facility.builder.byId[values.include],
          children: [...facility.builder.byId[values.include].children],
          toggle: true
        };
      }
    } else {
      updated.allIds.splice(
        values.after === "_TOP_" ? 0 : builder.allIds.indexOf(values.after) + 1,
        0,
        element.name
      );
      updated.byId[element.name] = {...element, children: [...element.children], toggle: true};
      element.children.forEach(child => {
        updated.byId[child] = {
          ...facility.builder.byId[child],
          children: [...facility.builder.byId[child].children],
          toggle: true
        };
      });
    }
    saveBuilder(updated);
    setRefreshMissing(true);
    setShowModal(false);
    reset();
  };

  return (
    <Modal testId="facility.addFromFacility" visible={visible} setVisible={setShowModal}>
      <ModalTitle>Add From Facility Builder</ModalTitle>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleAddElement)} style={{marginTop: "30px"}}>
          {componentsMissing ? (
            <FormGroup>
              <FormField>
                <InputCheckGroup
                  name="include"
                  testId="addFromFB.include"
                  label={`Select components to add to ${element.label}.`}
                  options={element.componentsMissing
                    .filter(name => facility?.builder?.byId && name in facility.builder.byId)
                    .map(name => ({
                      value: name,
                      label: facility.builder.byId[name].label
                    }))}
                />
              </FormField>
            </FormGroup>
          ) : (
            <FormGroup>
              <FormField>
                <InputSelect
                  name="after"
                  testId="addFromFB.after"
                  options={[
                    {value: "_TOP_", name: "Top of builder"},
                    ...builder.allIds.map(id => ({
                      value: builder.byId[id].name,
                      label: builder.byId[id].label
                    }))
                  ]}
                  placeholder="Select element..."
                  label={`Select element after which to place ${element.label}.`}
                  {...form}
                />
              </FormField>
            </FormGroup>
          )}

          <Submit type="submit" data-testid="addFromFB.submit">
            {componentsMissing ? "Add Components" : "Add Group"}
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalAddToBuilder.propTypes = {
  visible: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  element: PropTypes.objectOf(PropTypes.any),
  saveBuilder: PropTypes.func.isRequired,
  setRefreshMissing: PropTypes.func.isRequired
};

ModalAddToBuilder.defaultProps = {
  element: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${props => props.theme.field};
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad}px;
`;

export default ModalAddToBuilder;
