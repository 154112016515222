import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Contexts
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {useSocket} from "../contexts/socket.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Components
import RenderChecksheet from "./checksheet-builder/RenderChecksheet.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Modal from "../components/Modal.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {shake} from "../style/components/animations.js";
import {pad, border, radius, navHeight} from "../style/components/variables.js";
import {
  Page,
  Title,
  Inline,
  Abbr,
  NotLoaded,
  Loader,
  Pill,
  Button,
  Text,
  HeadingMedium,
  Input,
  Label,
  StickyWrapper
} from "../style/components/general.js";

// Socket Constants
import Room, {getFacilityRooms, LIST_LOCKS, REQUEST_LOCKS} from "./general/Room.js";

const LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER = "notify:update_checksheet_builder";

const ChecksheetPreview = () => {
  const isMounted = useMountedState();

  const {slug} = useParams();

  const socket = useSocket();

  const {currentUser} = useContext(AuthContext);

  const {edit} = useParams();

  const {facility, setFacility} = useContext(FacilityNavContext);

  const {api: apiUser} = useApi("users");
  const {api: apiFacility} = useApi("facilities");
  const {api: apiChecksheet, loading} = useApi("checksheets");

  const [active, setActive] = useState(null);
  const [locks, setLocks] = useState(null);
  const [userLock, setUserLock] = useState();
  const [interactive, setInteractive] = useState(false);
  const [checksheet, setChecksheet] = useState();
  const [hasChanges, setHasChanges] = useState(false);
  const [showModalModified, setShowModalModified] = useState(false);

  const readOnly = useMemo(() => !interactive, [interactive]);

  const [simulatedDate, setSimulatedDate] = useState(null);

  const getFacility = useCallback(
    id =>
      apiFacility.callGet(id).then(({status, data}) => {
        if (status === 200 && data) setFacility(data);
      }),
    [apiFacility, setFacility]
  );

  const getChecksheet = useCallback(
    id =>
      apiChecksheet.callGet(id).then(({status, data}) => {
        if (status === 200 && data) {
          setChecksheet(data);

          if (facility === null) getFacility(data.facilityId);
        }
      }),
    [apiChecksheet, facility, getFacility]
  );

  useEffect(() => {
    if (isMounted() && !checksheet && edit) getChecksheet(edit);
  }, [isMounted, checksheet, edit, getChecksheet]);

  // Socket Management
  const notifyUpdate = useCallback(() => setHasChanges(true), []);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  useEffect(() => {
    if (isMounted() && currentUser && !locks)
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(slug, "checksheet"),
        user: currentUser.publicId,
        type: "checksheet",
        to_sender: true
      });

    socket.on(LIST_LOCKS, listLocks);

    socket.on(LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER, notifyUpdate);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
      socket.off(LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER, notifyUpdate);
    };
  }, [isMounted, currentUser, socket, slug, locks, listLocks, notifyUpdate]);

  useEffect(() => {
    if (locks && `checksheet_${edit}` in locks && locks[`checksheet_${edit}`]?.user)
      apiUser.callGet(locks[`checksheet_${edit}`]?.user).then(({status, data}) => {
        if (status === 200 && data) setUserLock(`${data.firstName} ${data.lastName}`);
      });
  }, [locks, apiUser, edit]);

  return (
    <Page hasMenu>
      <Room name="checksheet-preview" active={active} setActive={setActive} />

      <Inline fullWidth>
        {facility?.name && <FacilityPageHeader facility={facility} path="/schedule" disabled />}
        {hasChanges && (
          <Changes
            type="button"
            title="Events have changed since your last page load"
            onClick={() => setShowModalModified(true)}>
            Load new changes!
          </Changes>
        )}
        <Button type="button" onClick={() => setInteractive(prev => !prev)}>
          {interactive ? "Preview" : "Interactive"}
        </Button>
      </Inline>

      {checksheet && !loading ? (
        <Wrapper>
          <Menu>
            <Inline>
              <ChecksheetName>
                <Abbr title={checksheet.name}>{checksheet.name}</Abbr>
              </ChecksheetName>
              {checksheet.frequency && <Pill>{checksheet.frequency.name}</Pill>}
            </Inline>

            <Inline>
              {userLock && (
                <Text>
                  <FontAwesomeIcon icon={faLock} />
                  &nbsp;{userLock} is making updates
                </Text>
              )}
              {interactive && (
                <DateInputLabel bold htmlFor="simulatedDate">
                  Simulated Date
                  <Input
                    type="date"
                    onChange={e => setSimulatedDate(e.target.value)}
                    name="simulatedDate"
                    defaultValue={dayjs().format("YYYY-MM-DD")}
                  />
                </DateInputLabel>
              )}
            </Inline>
          </Menu>
          <RenderChecksheet
            task={{...checksheet, name: null, frequency: null}}
            hideMeta
            hideCompleted
            readOnly={readOnly}
            simulatedDate={simulatedDate}
          />
        </Wrapper>
      ) : (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      )}

      {showModalModified && (
        <Modal visible={showModalModified} setVisible={setShowModalModified}>
          <HeadingMedium>Missing Changes</HeadingMedium>
          <Text>This checksheet has been since you last viewed it.</Text>
          <br />
          <Button
            type="button"
            onClick={() => {
              setShowModalModified(false);
              setHasChanges(false);
              getChecksheet(edit);
            }}>
            Update
          </Button>
        </Modal>
      )}
    </Page>
  );
};

// Style Overrides
const Wrapper = styled.article`
  ${flex("row", "wrap", "start", "start")};
  margin: ${pad * 2}px 0;
  width: 100%;
  padding: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
`;

const Menu = styled(StickyWrapper)`
  ${flex("row", "wrap", "space-between", "start")};
  width: 100%;
  top: ${navHeight}px;
  background: ${({theme}) => theme.tertiary};
  box-shadow: 0 5px 15px ${({theme}) => theme.tertiary};
  z-index: ${z("top")};
`;

const ChecksheetName = styled(Title)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DateInputLabel = styled(Label)`
  ${voice.quiet}
  margin-bottom: ${pad}px;
  width: min-content;
`;

const Changes = styled.button`
  ${voice.quiet};
  padding: ${pad}px;
  position: absolute;
  top: 33px;
  bottom: 0;
  animation: ${shake()} 5s linear 2s infinite;

  &:hover {
    text-decoration: underline;
    animation-play-state: paused;
  }
`;

export default ChecksheetPreview;
