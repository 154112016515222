import React, {Fragment, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputTime from "./InputTime.js";
import InputDate from "./InputDate.js";
import InputDay from "./InputDay.js";
import InputSelect from "./InputSelect.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Button, Inline, Warning} from "../../style/components/general.js";

const options = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const InputNotification = ({...props}) => {
  const {name, type} = props;

  const {control, watch} = useFormContext();

  const {fields, remove, insert, append} = useFieldArray({control, name: `${name}.occurrences`});

  const daysOfMonth = useMemo(() => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(`${i}`);
    }
    return days;
  }, []);

  useEffect(() => {
    if (!fields?.length) {
      if (type === "annual") append({dayOfYear: null, time: null});
      if (type === "monthly") append({dayOfMonth: null, time: null});
      if (type === "weekly") append({dayOfWeek: null, time: null});
      if (type === "daily") append({time: null});
    }
  }, [fields, append, type]);

  return (
    type &&
    fields?.map((field, index) => {
      const dayOfMonth = watch(`${name}.occurrences.${index}.dayOfMonth`);

      const parsed = dayOfMonth ? parseInt(dayOfMonth, 10) : null;

      return (
        <Fragment key={field.id}>
          <Row>
            {type === "annual" && <InputDate name={`${name}.occurrences.${index}.dayOfYear`} />}

            {type === "monthly" && (
              <InputSelect
                name={`${name}.occurrences.${index}.dayOfMonth`}
                options={daysOfMonth}
                placeholder="Select day..."
              />
            )}

            {type === "weekly" && (
              <InputDay name={`${name}.occurrences.${index}.dayOfWeek`} align="row" restrictOne />
            )}

            <TimeWrapper noMargin={type === "daily" ? 1 : 0}>
              <InputTime name={`${name}.occurrences.${index}.time`} />
            </TimeWrapper>

            <Button type="button" onClick={() => remove(index)} hide={fields.length === 1}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>

            {options && options.length > 0 && fields.length < options.length && (
              <Button type="button" onClick={() => insert(index + 1, "")}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </Row>
          {!!parsed && parsed > 28 && (
            <MonthWarning>
              For months with less than {parsed} days, notification will be triggered on the last
              day of the month.
            </MonthWarning>
          )}
        </Fragment>
      );
    })
  );
};

InputNotification.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

// Style Overrides
const Row = styled(Inline)`
  align-items: end;
`;

const TimeWrapper = styled.span`
  ${({noMargin}) =>
    !noMargin &&
    css`
      margin-left: ${pad}px;
    `}
`;

const MonthWarning = styled(Warning)`
  margin-bottom: ${pad}px;
`;

export default InputNotification;
