import dayjs from "dayjs";
import {
  faAddressBook,
  faAutomobile,
  faBook,
  faBuilding,
  faCalendar,
  faChartColumn,
  faFlask,
  faScrewdriverWrench
} from "@fortawesome/free-solid-svg-icons";

// Utils
import {formatDate} from "../../utils/helpers.js";
import {labels} from "../../style/components/variables.js";

export const CUSTOM_OPTION = {
  name: "custom",
  label: "Custom..."
};

export const FREQUENCIES = [
  {
    base: "days",
    interval: 1,
    name: "daily"
  },
  {
    base: "weeks",
    interval: 1,
    name: "weekly"
  },
  {
    base: "weeks",
    interval: 2,
    name: "bi-monthly"
  },
  {
    base: "months",
    interval: 1,
    name: "monthly"
  },
  {
    base: "months",
    interval: 3,
    name: "quarterly"
  },
  {
    base: "months",
    interval: 6,
    name: "semi-annually"
  },
  {
    base: "years",
    interval: 1,
    name: "annually"
  }
];

export const BASE_OPTIONS = [
  {name: "days", label: "day"},
  {name: "weeks", label: "week"},
  {name: "months", label: "month"},
  {name: "years", label: "year"}
];

export const BASE_OPTIONS_PLURAL = BASE_OPTIONS.map(option => ({
  ...option,
  label: `${option.label}s`
}));

export const DAY_TO_IDX = {mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6};
export const IDX_TO_DAY = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
  5: "Sat",
  6: "Sun"
};
export const DAY_NAMES = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const DAY_TO_IDX_DAYJS = {sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6};
export const IDX_TO_DAY_DAYJS = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat"
};
export const DAY_NAMES_DAYJS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
export const DAY_IDX = [0, 1, 2, 3, 4, 5, 6];

export const SCHEDULE_ICON_OPTIONS = [
  {icon: faCalendar, value: faCalendar.iconName},
  {icon: faAddressBook, value: faAddressBook.iconName},
  {icon: faBook, value: faBook.iconName},
  {icon: faAutomobile, value: faAutomobile.iconName},
  {icon: faBuilding, value: faBuilding.iconName},
  {icon: faScrewdriverWrench, value: faScrewdriverWrench.iconName},
  {icon: faFlask, value: faFlask.iconName},
  {icon: faChartColumn, value: faChartColumn.iconName}
];

export const getIcon = name => {
  if (!name) return null;
  const matches = SCHEDULE_ICON_OPTIONS.filter(({value}) => value === name);
  return matches[0].icon;
};

/**
 * Add events to calendar day
 * @param {object} event
 * @param {object} eventsCopy
 * @param {object} newEvent
 * @returns {object}
 */
export const transformEventResponseToKeyByDate = (event, eventsCopy, newEvent) => {
  if (!newEvent) return eventsCopy;

  const date = newEvent.format("YYYY-MM-DD");

  const eventObject = {
    id: event.id,
    facility: event.facility,
    type: event.type,
    name: event.name,
    startDate: formatDate(event.startDate),
    endDate: event.endDate ? formatDate(event.endDate) : null,
    frequency: event.frequency,
    dateDue: event.dateDue,
    lastCompleted: event.lastCompleted,
    responses: event.responses,
    group: event.group,
    stages: event.stages,
    available: event.available,
    addition: event.addition,
    isArchived: event.isArchived,
    message: event.message
  };

  if (
    event.frequency &&
    event.frequency.base === "days" &&
    event.frequency.exclude &&
    Array.isArray(event.frequency.exclude) &&
    event.frequency.exclude.includes(newEvent.day())
  )
    return eventsCopy;

  // no events on formatted date
  if (eventsCopy[date] === undefined) eventsCopy[date] = [eventObject];

  // an event exists on formatted date
  if (!eventsCopy[date].some(e => e.id === event.id)) eventsCopy[date].push(eventObject);

  return eventsCopy;
};

export const generateVirtualEvents = (
  events,
  setTimezone = "US/Pacific",
  virtualStartDate = null,
  virtualEndDate = null
) => {
  const virtualStart = virtualStartDate || new Date();

  const eventsInMonth = [];
  const eventsOnDay = {};

  events.map(currentEvent => {
    const {startDate, endDate: eventEndDate, frequency, lastDay} = currentEvent;

    // generate recurring events based off events
    let d = dayjs(startDate).utc(true).tz(setTimezone);
    let date = null;

    const eventEndDateDayjs = eventEndDate ? dayjs(eventEndDate) : null;

    if (!frequency && d.isAfter(virtualStart) && d.isBefore(virtualEndDate))
      eventsInMonth.push({...currentEvent, dateDue: d});

    let name = null;
    let interval = null;
    let base = null;
    let endDateToUse;

    if (frequency) ({name, interval, base} = frequency);

    const isLeapDay = d.date() === 29 && d.month() === 1;
    const lastDayFebruary = lastDay && d.month() === 1;

    if (base === "months") date = lastDay ? 31 : d.date();
    if (base) {
      if (!virtualEndDate && !eventEndDateDayjs) {
        // arbitrarily set virtual end date for recurring events
        if (base === "years") endDateToUse = d.add(100, "year");
        else if (base === "months") endDateToUse = d.add(50, "year").date(0);
        else if (base === "weeks") endDateToUse = d.add(10, "year");
        else if (base === "days") endDateToUse = d.add(2, "year");
      } else if (virtualEndDate && eventEndDateDayjs) {
        if (eventEndDateDayjs.isBefore(virtualEndDate, "day")) endDateToUse = eventEndDate;
        else endDateToUse = virtualEndDate;
      } else {
        endDateToUse = eventEndDate || virtualEndDate;
      }
      // generate events object - adding recurring events
      while (d.isBefore(endDateToUse, "day") || d.isSame(endDateToUse, "day")) {
        if (!virtualStart || d.isAfter(virtualStart) || d.isSame(virtualStart)) {
          eventsInMonth.push({
            ...currentEvent,
            dateDue: dayjs(d.tz("UTC").toLocaleString()).utc(true)
          });
          transformEventResponseToKeyByDate(currentEvent, eventsOnDay, d);
        }
        // Standard Intervals
        if (base === "years") {
          if (!isLeapDay && !lastDayFebruary) d = d.add(interval, "year");
          else {
            const currentYear = d.year();
            const nextYear = currentYear + interval;
            if (nextYear % 4 === 0) {
              // year is leap year
              d = d.year(nextYear);
              d = d.date(29);
            } else {
              // year is normal year
              d = d.date(28);
              d = d.year(nextYear);
            }
          }
        } else if (base === "months") {
          for (let i = 0; i < interval; i++) {
            const currentMonth = d.month();
            const currentYear = d.year();
            const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
            const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
            const nextMonthStart = dayjs(`${nextMonth + 1}-1-${nextYear}`);
            const daysInNextMonth = nextMonthStart.daysInMonth();
            const daysInCurrentMonth = d.daysInMonth();
            if (date >= daysInNextMonth) {
              if (date >= daysInCurrentMonth) {
                d = d.year(nextYear);
                d = d.month(nextMonth);
                d = d.date(daysInNextMonth);
              } else {
                d = d.date(daysInNextMonth);
                d = d.month(nextMonth);
                d = d.year(nextYear);
              }
            } else {
              d = d.month(nextMonth);
              d = d.year(nextYear);
            }
          }
        } else if (base === "weeks") d = d.add(interval, "week");
        else if (base === "days") d = d.add(interval, "day");

        // Overrides
        if (name === "bi-monthly") {
          d = d.date() < 15 ? d.date(14) : d.endOf("month");
        }
      }
    } else transformEventResponseToKeyByDate(currentEvent, eventsOnDay, d);

    // Handle addtions
    currentEvent?.addition?.map(({date: addDate, message}) => {
      eventsInMonth.push({...currentEvent, dateDue: addDate, message});
      transformEventResponseToKeyByDate(
        {...currentEvent, dateDue: addDate, message},
        eventsOnDay,
        dayjs(addDate)
      );
    });
  });

  return {
    inMonth: eventsInMonth,
    onDay: eventsOnDay
  };
};

export const dateHasEvent = (event, setTimezone = "US/Pacific", target = null) => {
  const targetDate = new Date(target);

  const {startDate, endDate: eventEndDate, frequency} = event;

  // generate recurring events based off events
  let d = dayjs(startDate).utc(true).tz(setTimezone);
  let date = null;

  let passed = d.isAfter(targetDate, "day");
  let alreadyHasEvent = d.isSame(targetDate, "day");

  if (alreadyHasEvent) return true;

  const endDate = eventEndDate ? dayjs(eventEndDate).utc(true).tz(setTimezone) : null;

  let name = null;
  let interval = null;
  let base = null;

  if (frequency) ({name, interval, base} = frequency);

  if (base === "months") date = d.date();
  if (base) {
    // generate events object - adding recurring events
    while (!passed && !alreadyHasEvent && (!endDate || d.isBeforeOrSame(endDate))) {
      // Standard Intervals
      if (base === "years") d = d.add(interval, "year");
      else if (base === "months") {
        d = d.add(interval, "month");
        if (date <= d.daysInMonth()) d = d.set("date", date);
      } else if (base === "weeks") d = d.add(interval, "week");
      else if (base === "days") d = d.add(interval, "day");

      // Overrides
      if (name === "bi-monthly") {
        d = d.date() < 15 ? d.date(14) : d.endOf("month");
      }

      passed = d.isAfter(targetDate, "day");
      alreadyHasEvent = d.isSame(targetDate, "day");
    }
  }

  // Handle addtions
  if (!alreadyHasEvent)
    alreadyHasEvent = !!event.addition?.some(({date: addDate}) =>
      dayjs(addDate).isSame(targetDate, "day")
    );

  return alreadyHasEvent;
};

/**
 * Determines Event Label
 * @param {array} eventTypes
 * @param {object} event
 * @returns {number}
 */
export const eventTypeColor = type => {
  if (type?.color) return `#${type.color}`;
  if (type?.id) return labels[(type.id - 1) % labels.length];
  return labels[0];
};

export const eventColor = (eventTypes, event) => {
  let eventType = 0;
  if (eventTypes)
    eventTypes.map(type => {
      if (type.name && event.type && type.name.toLowerCase() === event.type.name.toLowerCase())
        eventType = type.color ? `#${type.color}` : labels[(type.id - 1) % labels.length];
    });
  return eventType || 0;
};

export const eventIcon = (eventTypes, event) => {
  let icon = faCalendar;

  if (eventTypes)
    eventTypes.map(type => {
      if (
        type.name &&
        event.type &&
        type.name.toLowerCase() === event.type.name.toLowerCase() &&
        event.type.icon
      )
        icon = getIcon(event.type.icon);
    });
  return icon || faCalendar;
};

/**
 * Extract Field Response
 * @param {object} currentEvent
 * @param {string} key
 * @returns {string}
 */
export const getResponse = (currentEvent, key) => {
  if (
    `${key}_qualifier` in currentEvent.submission &&
    `${key}_qualifierEnabled` in currentEvent.submission &&
    currentEvent.submission.responses &&
    currentEvent.submission.responses[`${key}_qualifierEnabled`]
  ) {
    const qualifier = currentEvent.submission.responses[`${key}_qualifier`];
    if (qualifier !== "ND" && qualifier !== "DNQ" && qualifier !== "EST")
      return `${qualifier} ${currentEvent.submission[key]}`;
    if (qualifier !== "ND") return `${currentEvent.submission[key]} (${qualifier})`;
    return `< ${currentEvent.submission[key]} (${qualifier})`;
  }
  if (!currentEvent.submission.responses[key]) return "NO RESPONSE PROVIDED";
  return currentEvent.submission.responses[key];
};

/**
 * @param {Object} filters filter object with facilities, types, names
 * @param {Object} event event object with id, type, name, dateDue
 * @param {Object} recordMap date map for event records
 * @returns filtered event list
 */
export const handleFilters = (
  {facilities, types, names, groups, stages, frequencies},
  {facility, id, type, name, group, dateDue, frequency},
  recordMap
) => {
  let match = null;
  // Event matches
  if (facilities)
    match = facilities.includes(`${facility.name.toUpperCase()} ${facility.type.toUpperCase()}`);
  if (frequencies)
    match =
      (match === null || match) && frequencies.filter(f => f.name === frequency?.name).length === 1;
  if (types) match = (match === null || match) && types.includes(type.name.toUpperCase());
  if (names) match = (match === null || match) && names.includes(name.toUpperCase());
  if (groups)
    match = (match === null || match) && groups.filter(g => g.name === group).length === 1;

  // Record matches
  if (types?.length === 1 && stages) {
    if (
      recordMap &&
      Object.keys(recordMap)?.includes(formatDate(dateDue)) &&
      id in recordMap[formatDate(dateDue)]
    ) {
      const {stage, event} = recordMap[formatDate(dateDue)][id];
      const {byId} = event?.stages || {};
      match = (match === null || match) && byId && stages.includes(byId[stage].name);
    } else match = false;
  }
  return match;
};

/**
 * @param {String} a
 * @param {String} b
 * @param {String} groupBy
 * @param {String} orderBy
 * @param {Object} recordMap date map for event records
 * @returns sorted event list
 */
export const handleSort = (a, b, groupBy, orderBy, recordMap) => {
  let targetA = a[groupBy];
  let targetB = b[groupBy];

  if (targetA === null) return 1;
  if (targetB === null) return -1;

  if (groupBy === "type" || groupBy === "facility") {
    targetA = targetA.name;
    targetB = targetB.name;
  }

  if (groupBy === "frequency") {
    targetA = targetA?.base;
    targetB = targetB?.base;
  }

  if (groupBy === "group") {
    targetA = targetA?.toLowerCase();
    targetB = targetB?.toLowerCase();
  }

  const recordA = recordMap && recordMap[formatDate(a.dateDue)];
  const recordB = recordMap && recordMap[formatDate(b.dateDue)];

  if (groupBy === "status" && recordA && recordB) {
    targetA = recordA?.status;
    targetB = recordB?.status;
  }

  if (groupBy === "stage") {
    targetA = recordA?.stage;
    targetB = recordB?.stage;
  }

  if (groupBy === "completedAt") {
    targetA = recordA?.completedAt;
    targetB = recordB?.completedAt;
  }

  if (groupBy === "completedUserId") {
    targetA = recordA?.completedBy;
    targetA = targetA ? `${targetA.firstName} ${targetA.lastName}` : null;
    targetB = recordB?.completedBy;
    targetB = targetB ? `${targetB.firstName} ${targetB.lastName}` : null;
  }

  // Handle dates
  if (dayjs(targetA).isValid() && dayjs(targetB).isValid()) {
    if (dayjs(targetA).isAfter(dayjs(targetB))) return orderBy === "desc" ? 1 : -1;
    if (dayjs(targetA).isBefore(dayjs(targetB))) return orderBy === "desc" ? -1 : 1;
  } else {
    if (targetA > targetB) return orderBy === "desc" ? 1 : -1;
    if (targetA < targetB) return orderBy === "desc" ? -1 : 1;
  }

  return 0;
};
