import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {toTitleCase} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  Form,
  FormGroup,
  FormField,
  HeadingCenter,
  Text,
  ButtonFull,
  ButtonLoader,
  NotLoaded,
  Loader
} from "../../style/components/general.js";

const ModalUserAssign = ({visible, setVisible, users, setUsers, setShowOptions}) => {
  const isMounted = useMountedState();

  const options = useRef(null);

  const schema = yup.object().shape({
    facilities: yup.mixed().not([false], "Please select at least one facility")
  });

  const form = useForm({
    defaultValues: {facilities: []},
    resolver: yupResolver(schema)
  });

  const {handleSubmit, reset, setValue} = form;

  const {api: apiFacilities, loading: loadingFacilities} = useApi("facilities");
  const {api: facilityUsers} = useApi("facility-users");

  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isMounted())
      apiFacilities.callGet(null, {withDeleted: true, getAll: true}).then(({data, status}) => {
        if (status === 200)
          setFacilities(
            data.map(facility => ({
              label: `${facility.name} ${toTitleCase(facility.type)}${
                facility.isDeleted ? " (Archived)" : ""
              }`,
              value: facility.id
            }))
          );
      });
  }, [isMounted, apiFacilities]);

  useEffect(() => {
    if (users.length === 1) {
      facilityUsers.callGet("", {email: users[0].email.props.children}).then(({status, data}) => {
        if (status === 200 && data)
          setValue(
            "facilities",
            data.map(id => `${id}`)
          );
      });
    }
  }, [facilities, facilityUsers, users, setValue]);

  const assignUsers = selected => {
    setLoading(true);

    const formatted = users.map(user => user.email.props.children);
    facilityUsers
      .callPost({
        users: formatted,
        facilities: selected.facilities.map(id => parseInt(id, 10))
      })
      .then(() => {
        setVisible(false);
        setUsers([]);
        setShowOptions(false);
        reset({});
        setLoading(false);
      });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Assign Users</ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(assignUsers)}>
          <FormGroup>
            <ModalHeading>
              {users.length === 1 ? "Reassign the following user:" : "Assign the following users:"}
            </ModalHeading>
            {users?.map(user => (
              <div key={user.email.props.children}>{user.firstName.props.children}</div>
            ))}
          </FormGroup>
          <FormGroup>
            <ModalHeading>
              To the following Facilities (user will be removed from unchecked facilities):
            </ModalHeading>
            {!loadingFacilities ? (
              <FormField ref={options}>
                <InputCheckGroup name="facilities" options={facilities} all />
              </FormField>
            ) : (
              <NotLoaded>
                <Loader />
              </NotLoaded>
            )}
            <ButtonFull type="submit" loading={loading ? 1 : 0}>
              {users.length === 1 ? "Reassign" : "Assign"}
              {loading && <ButtonLoader />}
            </ButtonFull>
          </FormGroup>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalUserAssign.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUsers: PropTypes.func.isRequired,
  setShowOptions: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const ModalHeading = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  margin: ${pad}px 0;
`;

export default ModalUserAssign;
