import React, {useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faClose} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {exists, objectGuard} from "../../utils/helpers.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";

// Components
import InputError from "./InputError.js";
import InputTextArea from "./InputTextArea.js";
import Help from "../Help.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Select,
  Button,
  Pill,
  Abbr,
  Error,
  Text
} from "../../style/components/general.js";

const InputSelect = ({
  name,
  label,
  tag,
  testId,
  placeholder,
  prompt,
  options,
  required,
  fullWidth,
  defaultValue,
  disabled,
  other,
  minWidth,
  globalUnacceptable,
  globalUnacceptableTitle,
  hasAlert,
  alertMessage,
  alertCondition,
  condition
}) => {
  const isMounted = useMountedState();

  const {
    watch,
    setValue,
    register,
    formState: {errors}
  } = useFormContext();

  const values = watch(name);

  useEffect(() => {
    if (isMounted() && defaultValue) setValue(name, defaultValue);
  }, [isMounted, defaultValue, setValue, name]);

  const hasYupErrors = useMemo(() => {
    const {message} = get(errors, name) || {};
    return !!message;
  }, [errors, name]);

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
          {globalUnacceptable}
        </LabelWrapper>
      )}

      {prompt && <Prompt quiet>{prompt}</Prompt>}

      <Select
        id={name}
        data-testid={`${testId}-select`}
        name={name}
        fullWidth={fullWidth}
        disabled={disabled}
        minWidth={minWidth}
        hidden={values && typeof values === "string" && values.includes("Other")}
        {...register(name, {required: required})}>
        {placeholder && <option value="">{placeholder}</option>}
        {!disabled &&
          options?.map(option => (
            <option
              key={
                option.key ||
                option.name ||
                option.value ||
                option.option ||
                option.label ||
                objectGuard(option)
              }
              value={
                option.valueOverride ||
                option.value ||
                option.option ||
                option.name ||
                option.label ||
                objectGuard(option)
              }
              data-testid={`${testId}-option`}>
              {option.label || option.name || option.value || option.option || objectGuard(option)}
            </option>
          ))}
        {other && <option value="Other">Other</option>}
      </Select>

      {exists(values) && typeof values === "string" && values.includes("Other") && (
        <Inline>
          <Other
            type="text"
            placeholder="Provide value..."
            defaultValue={values.includes("Other") && values.split("Other: ")[1]}
            onChange={e => setValue(name, `Other: ${e.target.value}`)}
            required
            data-testid={`${testId}-other`}
          />
          <Close
            data-testid={`${testId}-close-button`}
            type="button"
            onClick={() => setValue(name, "")}>
            <FontAwesomeIcon icon={faClose} />
          </Close>
        </Inline>
      )}

      <InputError errors={errors} name={name} />

      {!hasYupErrors && globalUnacceptableTitle && (
        <Error>Parameter Violation: {globalUnacceptableTitle}</Error>
      )}

      {hasAlert &&
        ((Array.isArray(alertCondition) && alertCondition?.includes(values)) ||
          values === alertCondition) && (
          <InputTextArea
            name={`${name}_comment`}
            label={alertMessage || defaultUnacceptableParameterPrompt}
            required
            maxLength={1000}
          />
        )}
    </FormFieldWrapper>
  );
};

InputSelect.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  other: PropTypes.bool,
  minWidth: PropTypes.number,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitle: PropTypes.string,
  hasAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertCondition: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputSelect.defaultProps = {
  testId: "input-select",
  label: null,
  placeholder: null,
  prompt: null,
  options: [],
  required: false,
  fullWidth: false,
  defaultValue: null,
  disabled: false,
  other: false,
  minWidth: null,
  globalUnacceptable: null,
  globalUnacceptableTitle: null,
  hasAlert: false,
  alertMessage: null,
  alertCondition: null,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const Other = styled(Input)`
  padding-right: ${pad * 2.5}px;

  &[type="text"] {
    max-width: 130px;
  }
`;

const Close = styled(Button)`
  background: none;
  position: absolute;
  right: 0;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

export default InputSelect;
