import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputSelect from "./InputSelect.js";
import InputTime from "./InputTime.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, LabelBold} from "../../style/components/general.js";

const options = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const InputDayTimeGroup = ({...props}) => {
  const {name, label, placeholder, testId} = props;

  const {control} = useFormContext();

  const {fields, remove, insert, append} = useFieldArray({control, name});

  useEffect(() => {
    if (!fields?.length) append({day: null, time: null});
  }, [fields, append]);

  return (
    <FormFieldWrapper standard>
      <LabelBold htmlFor={name}>{label}</LabelBold>
      {fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <StyledInlineFormField key={field.id}>
            <SelectWrapper>
              <InputSelect
                testId={`${testId}-day`}
                name={`${name}.${index}.day`}
                placeholder={placeholder || "Select day..."}
                options={options}
                minWidth={150}
              />
            </SelectWrapper>

            <InputTime testId={`${testId}-time`} name={`${name}.${index}.time`} />

            <StyledEdit
              disable={fields.length === 1}
              type="button"
              data-testid={`${testId}.removeInput`}
              onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faMinus} />
            </StyledEdit>

            {options && options.length > 0 && fields.length < options.length && (
              <StyledEdit
                type="button"
                data-testid={`${testId}.addInput`}
                onClick={() => {
                  insert(index + 1, "");
                }}>
                <FontAwesomeIcon icon={faPlus} />
              </StyledEdit>
            )}
          </StyledInlineFormField>
        ))}
    </FormFieldWrapper>
  );
};

InputDayTimeGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

InputDayTimeGroup.defaultProps = {
  testId: "input-day-time-group",
  label: null,
  placeholder: null
};

// Style Overrides
const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad * 1.4}px;
  justify-content: flex-start;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad}px;
    }
  }
`;

const StyledEdit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;

  ${props =>
    props.disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const SelectWrapper = styled.div`
  width: 200px;
`;

export default InputDayTimeGroup;
