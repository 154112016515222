import React, {useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {Draggable} from "react-beautiful-dnd";
import {faEdit, faTrash, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Utils
import {COMPONENT, GROUP} from "../../utils/builder.js";
import {AuthContext} from "../../contexts/auth.js";
import {generateUniqueKey, getMapFromCoords} from "../../utils/helpers.js";

// Components
import ElementOptions from "../../components/ElementOptions.js";
import AddElement from "./AddElement.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {pad, radius} from "../../style/components/variables.js";
import {Abbr, Button, Label, Text} from "../../style/components/general.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

const RenderFacilityBuilder = ({
  builder,
  editElement,
  removeElement,
  setElementLocation,
  handleAddGroup,
  handleAddComponent,
  activeDroppableId,
  children,
  querying,
  markerIconMap,
  activeMarker,
  setActiveMarker,
  active,
  locks,
  readOnly,
  groupsOnly,
  view,
  setView
}) => {
  const {atLeast, currentUser, roleCanAccessResource} = useContext(AuthContext);

  const renderOptions = element => {
    if (active && locks && locks[element.name]) {
      const {user} = locks[element.name];
      const {users} = active;
      if (user !== currentUser.publicId) {
        const match = users.filter(curr => curr.publicId === user)[0];
        return (
          <Lock>
            <FontAwesomeIcon icon={faLock} />
            &nbsp;Locked by {match.name}
          </Lock>
        );
      }
    }

    return (
      <Options
        className="renderOptions"
        data-testid={element.element === GROUP ? "group.options" : "component.options"}
        element={element.element}>
        {roleCanAccessResource("facility", "update") && (
          <>
            <Option
              data-testid="element.editButton"
              type="button"
              onClick={() => editElement(element)}>
              <abbr title="Edit">
                <FontAwesomeIcon icon={faEdit} />
              </abbr>
            </Option>
            <Option
              type="button"
              onClick={() => removeElement(element)}
              data-testid="element.deleteButton">
              <abbr title="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </abbr>
            </Option>
          </>
        )}
      </Options>
    );
  };

  const numOptions = item => {
    if (roleCanAccessResource("facility", "create")) return 2;
    // "Set Location" Button is about the size of 2.5 standard option buttons
    return !item.hasAddress && item.parentName === "" ? 2.5 : 0;
  };

  const renderElements = (ids, level) =>
    ids
      .filter(id => {
        if (groupsOnly) {
          const item = builder.byId[id];
          if (item?.hasAddress || item?.hasHelp) return true;
          return false;
        }
        return true;
      })
      .map((id, index) => {
        const item = builder.byId[id];
        let newLevel;
        if (level === "top") newLevel = "group";
        if (level === "group") newLevel = "component";
        return (
          item && (
            <Draggable
              draggableId={id}
              index={index}
              key={id}
              isDragDisabled={
                !atLeast("creator") ||
                !roleCanAccessResource("facility", "update") ||
                querying ||
                readOnly
              }>
              {provided => (
                <Wrapper
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  key={id}
                  readOnly={readOnly ? 1 : 0}
                  data-testid="builder.field">
                  <ElementOptions
                    builder={builder}
                    element={item}
                    activeDroppableId={activeDroppableId}
                    handleAddComponent={handleAddComponent}
                    renderOptions={readOnly ? () => {} : renderOptions}
                    setElementLocation={setElementLocation}
                    numOptions={numOptions(item)}
                    readOnly={readOnly}
                    inFacilityBuilder>
                    {readOnly && item.hasGlobalPrompt && <Label bold>{item.globalPrompt}</Label>}

                    {((item.hasAddress && Object.keys(item.address).length > 0) ||
                      item.hasHelp ||
                      item.internalIds?.length > 0) && (
                      <>
                        {readOnly &&
                          item?.help
                            ?.filter(helpItem => !helpItem.id)
                            ?.map(({key, value}) => (
                              <Detail key={`${item.name}-help-${generateUniqueKey(key)}`}>
                                <DetailLabel>
                                  <Abbr title={key}>{key}</Abbr>
                                </DetailLabel>
                                <StyledNotes>
                                  <StyledNoteText bold>{value}</StyledNoteText>
                                </StyledNotes>
                              </Detail>
                            ))}

                        {item?.internalIds?.map(
                          ({key, value}) =>
                            key &&
                            value && (
                              <Detail key={`${item.name}-id-${generateUniqueKey(key)}`}>
                                <DetailLabel>
                                  <Abbr title={key?.toUpperCase()}>{key?.toUpperCase()}</Abbr>
                                </DetailLabel>
                                <StyledNotes>
                                  <StyledNoteText bold>{value}</StyledNoteText>
                                </StyledNotes>
                              </Detail>
                            )
                        )}

                        {item?.address && (
                          <View
                            type="button"
                            onClick={() => {
                              setActiveMarker(item.name);
                              if (view && setView) setView("map");
                            }}
                            active={activeMarker === item.name}
                            plain>
                            View on Map
                            <Icon
                              icon={markerIconMap[item.markerId]?.icon ?? DEFAULT_MARKER.icon}
                              color={`#${
                                markerIconMap[item.markerId]?.color ?? DEFAULT_MARKER.color
                              }`}
                            />
                          </View>
                        )}

                        {readOnly && item?.address && (
                          <AddressLink
                            href={getMapFromCoords(item.address.lat, item.address.lon)}
                            target="_blank"
                            rel="noreferrer">
                            Get Directions
                          </AddressLink>
                        )}
                      </>
                    )}

                    {newLevel &&
                      item.children &&
                      !groupsOnly &&
                      renderElements(item.children, newLevel)}
                  </ElementOptions>
                  {provided.placeholder}
                </Wrapper>
              )}
            </Draggable>
          )
        );
      });

  return (
    builder && (
      <>
        {renderElements(builder.allIds, "top")}
        {children}
        {atLeast("creator") &&
          roleCanAccessResource("facility", "create") &&
          handleAddGroup &&
          !querying && (
            <Wrapper data-testid="builder.field">
              <AddElement builder={builder} addElement={handleAddGroup} />
            </Wrapper>
          )}
        {querying && builder.allIds.length === 0 && <Text>Search returned 0 results</Text>}
      </>
    )
  );
};

RenderFacilityBuilder.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node,
  removeElement: PropTypes.func,
  editElement: PropTypes.func,
  setElementLocation: PropTypes.func,
  handleAddGroup: PropTypes.func,
  handleAddComponent: PropTypes.func,
  activeDroppableId: PropTypes.string,
  querying: PropTypes.bool,
  markerIconMap: PropTypes.objectOf(PropTypes.any),
  activeMarker: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setActiveMarker: PropTypes.func.isRequired,
  active: PropTypes.objectOf(PropTypes.any),
  locks: PropTypes.objectOf(PropTypes.any),
  readOnly: PropTypes.bool,
  groupsOnly: PropTypes.bool,
  view: PropTypes.string,
  setView: PropTypes.func
};

RenderFacilityBuilder.defaultProps = {
  children: null,
  querying: false,
  activeMarker: null,
  markerIconMap: null,
  active: null,
  locks: null,
  removeElement: null,
  editElement: null,
  setElementLocation: null,
  handleAddGroup: null,
  handleAddComponent: null,
  activeDroppableId: "",
  readOnly: false,
  groupsOnly: false,
  view: null,
  setView: null
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  padding-top: ${({readOnly}) => (readOnly ? 0 : pad)}px;

  div {
    /* margin-bottom: 0; // override element options for components */
  }
`;

const Lock = styled.p`
  padding: ${pad}px;
`;

const Options = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  z-index: ${z("base")};
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
  min-height: 38px;

  ${({element}) =>
    element === COMPONENT &&
    css`
      top: ${pad / 2}px;
    `}
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  font-weight: bold;
  height: 100%;
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${pad}px 0;

  p {
    color: ${({theme}) => theme.secondary};
  }
`;

const DetailLabel = styled(Text)`
  width: 25%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 80px;
`;

const StyledNotes = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const StyledNoteText = styled.span`
  font-weight: 700;
  color: ${({theme}) => theme.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &:before {
    content: attr(title);
  }
`;

const View = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: ${radius};

  ${({active, theme}) =>
    active &&
    css`
      border-color: ${theme.primary};
    `};

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  padding: ${pad}px 0 ${pad}px ${pad / 2}px;
  fill: ${({color, theme}) => color || theme.primary};
`;

const AddressLink = styled.a`
  width: fit-content;
  color: ${({theme}) => theme.secondary};

  &:hover {
    text-decoration: underline;
  }
`;

export default RenderFacilityBuilder;
