import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Utils
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";

// Components
import InputRadio from "./InputRadio.js";
import InputTextArea from "./InputTextArea.js";
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  FormFieldWrapper,
  Label,
  Input,
  Text,
  Pill,
  Abbr,
  Error
} from "../../style/components/general.js";

const RadioGroupInput = ({
  name,
  label,
  options,
  other,
  required,
  disabled,
  orient,
  prompt,
  testId,
  defaultValue,
  globalUnacceptable,
  globalUnacceptableTitle,
  hasAlert,
  alertMessage,
  alertCondition,
  condition,
  tag
}) => {
  const {
    watch,
    setValue,
    formState: {errors}
  } = useFormContext();

  const values = watch(name);

  const sanitizeValue = option => {
    if (option?.value) return option.value;
    if (option?.name) return option.name;
    if (option?.option) return option.option;
    if (option?.label) return option.label;
    return option;
  };

  const sanitizeLabel = option => {
    if (option?.label) return option.label;
    if (option?.option) return option.option;
    if (option?.value) return option.value;
    return option;
  };

  const hasYupErrors = useMemo(() => {
    const {message} = get(errors, name) || {};
    return !!message;
  }, [errors, name]);

  return (
    <>
      <FormFieldWrapper data-testid={testId} standard>
        {label && (
          <LabelWrapper htmlFor={name} data-testid={`${testId}-label`} bold inline>
            {label.toUpperCase()}
            {required && <span>*</span>}
            {tag && (
              <Pill quiet>
                <Abbr title={tag}>{tag}</Abbr>
              </Pill>
            )}
            {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
            {globalUnacceptable}
          </LabelWrapper>
        )}
        {prompt && <Text quiet>{prompt}</Text>}
        <OptionsWrapper orient={orient ? "column" : "row"} data-testid={`${testId}-wrapper`}>
          {options?.length > 0 &&
            options.map((option, index) => {
              const sanitizedLabel = sanitizeLabel(option);
              const sanitizedValue = sanitizeValue(option);
              if (defaultValue && sanitizedValue === defaultValue) {
                return (
                  <InputRadio
                    key={sanitizedValue}
                    testId={`${testId}-option${index + 1}`}
                    name={name}
                    label={sanitizedLabel}
                    icon={option.icon}
                    value={sanitizedValue}
                    color={option.color}
                    disabled={disabled}
                    showError={false}
                    defaultChecked
                  />
                );
              }
              return (
                <InputRadio
                  key={sanitizedValue}
                  testId={`${testId}-option${index + 1}`}
                  name={name}
                  label={sanitizedLabel}
                  icon={option.icon}
                  value={sanitizedValue}
                  color={option.color}
                  disabled={disabled}
                  showError={false}
                />
              );
            })}

          {other && (
            <InputRadio
              testId={`${testId}-other`}
              name={name}
              disabled={disabled}
              label="Other"
              value="Other"
            />
          )}
        </OptionsWrapper>

        <InputError errors={errors} name={name} />

        {!hasYupErrors && globalUnacceptableTitle && (
          <Error>Parameter violation: {globalUnacceptableTitle}</Error>
        )}
      </FormFieldWrapper>

      {values?.includes("Other") && (
        <>
          <Other
            data-testid={`${testId}-other-text-input`}
            type="text"
            name={`${name}_other`}
            placeholder="Provide value..."
            onChange={e => {
              setValue(name, `Other: ${e.target.value}`);
              // Keep radio input checked
              document.querySelector(`[data-testid="${testId}-other-input"]`).checked = true;
            }}
            required
          />
          <InputError errors={errors} name={`${name}_other`} />
        </>
      )}

      {hasAlert &&
        ((Array.isArray(alertCondition) && alertCondition?.includes(values)) ||
          values === alertCondition) && (
          <>
            <InputTextArea
              name={`${name}_comment`}
              label={alertMessage || defaultUnacceptableParameterPrompt}
              required
              maxLength={1000}
            />
            <InputError errors={errors} name={`${name}_comment`} />
          </>
        )}
    </>
  );
};

RadioGroupInput.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  other: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  orient: PropTypes.bool,
  prompt: PropTypes.string,
  defaultValue: PropTypes.string,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitle: PropTypes.string,
  hasAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertCondition: PropTypes.any,
  condition: PropTypes.string,
  tag: PropTypes.string
};

RadioGroupInput.defaultProps = {
  testId: "input-radio-group",
  label: null,
  required: false,
  other: false,
  disabled: false,
  orient: false,
  prompt: "",
  defaultValue: null,
  globalUnacceptable: null,
  globalUnacceptableTitle: null,
  hasAlert: false,
  alertMessage: null,
  alertCondition: null,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;

const Other = styled(Input)`
  margin-top: ${pad}px;
  max-width: 300px;
`;

export const OptionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({orient}) =>
    orient &&
    css`
      flex-direction: ${orient};
    `}
`;

export default RadioGroupInput;
