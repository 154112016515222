import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {useSocket} from "../../contexts/socket.js";

// Components
import Event from "./Event.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {Button, List, ListItem, Text} from "../../style/components/general";

// Socket Constants
import {RELOAD_EVENT, ADD_EVENTS} from "../general/Room.js";

const LISTEN_NOTIFY_COMPLETED = "notify:completed";
const LISTEN_NOTIFY_DRAFT = "notify:draft";

const EventList = ({room, type, markers, events, locks, setHide}) => {
  const socket = useSocket();

  const isMounted = useMountedState();

  const {api} = useApi("events");

  const [available, setAvailable] = useState(events.filter(({userCanAccess}) => userCanAccess));
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    if (isMounted() && available?.length === 0)
      setHide(prev => {
        if (prev) return !prev.includes(type) ? [...prev, type] : prev;
        return [type];
      });
  }, [isMounted, available, type, setHide]);

  const getEvent = useCallback(
    eventId =>
      api.callGet(eventId).then(({status, data}) => {
        if (status === 200) {
          const {stages, userCanAccess} = data;

          if (!!stages && userCanAccess) {
            setHide(prev => prev.filter(curr => curr !== data.type.name));

            // Add to available
            setAvailable(prev => {
              if (prev.filter(curr => curr.id === eventId)?.length === 0) return [...prev, data];
              return prev;
            });
          }
          // Remove from available
          else {
            setAvailable(prev => prev.filter(({id}) => id !== eventId));
          }
        }
      }),
    [api, setHide]
  );

  const reloadEvent = useCallback(
    ({action, eventType, eventId}) => {
      if (action === "delete") setAvailable(prev => prev.filter(({id}) => id !== eventId));

      if (
        action === "put" &&
        type === eventType &&
        events.filter(({id}) => id === eventId)?.length > 0
      )
        getEvent(eventId);

      if (
        action === "post" &&
        type === eventType &&
        events.filter(({id}) => id === eventId)?.length === 0
      )
        api.callGet(eventId).then(({status, data}) => {
          if (status === 200) {
            setHide(prev => prev.filter(curr => curr !== eventType));
            setAvailable(prev => [...prev, data]);
          }
        });
    },
    [events, api, type, setHide, getEvent]
  );

  const addEvents = useCallback(
    eventsByType => {
      if (type in eventsByType && eventsByType[type]?.length > 0) {
        setHide(prev => prev.filter(curr => curr !== type));
        setUpdates(eventsByType[type]);
      }
    },
    [type, setHide]
  );

  const notifySubmission = useCallback(
    ({resourceKey, unavailable, type: targetType}) => {
      const [taskType, eventId] = resourceKey.split("_");
      if (taskType !== "event" || targetType !== type) return;
      if (unavailable) setAvailable(prev => prev.filter(({id}) => `${id}` !== eventId));
      else if (events.map(({id}) => `${id}`).includes(eventId)) {
        api.callGet(eventId).then(({status, data}) => {
          if (status === 200) {
            setAvailable(prev => {
              let eventIdx = prev.length;
              for (let i = 0; i < prev.length; i++) {
                if (`${prev[i].id}` === eventId) {
                  eventIdx = i;
                  break;
                }
              }
              const left = prev.filter((_e, i) => i < eventIdx);
              const right = prev.filter((_e, i) => i > eventIdx);
              if (data.userCanAccess) return [...left, data, ...right];
              return [...left, ...right];
            });
          }
        });
      } else getEvent(eventId);
    },
    [api, getEvent, events, type]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted()) {
      socket.on(LISTEN_NOTIFY_COMPLETED, notifySubmission);
      socket.on(LISTEN_NOTIFY_DRAFT, notifySubmission);
      socket.on(RELOAD_EVENT, reloadEvent);
      socket.on(ADD_EVENTS, addEvents);
    }

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_NOTIFY_COMPLETED, notifySubmission);
      socket.off(LISTEN_NOTIFY_DRAFT, notifySubmission);
      socket.off(RELOAD_EVENT, reloadEvent);
      socket.off(ADD_EVENTS, addEvents);
    };
  }, [isMounted, socket, reloadEvent, addEvents, notifySubmission]);

  const handleUpdates = () =>
    api.callGet(null, {eventIds: updates}).then(({status, data}) => {
      if (status === 200) {
        setAvailable(data);
        setUpdates([]);
      }
    });

  return (
    <>
      {updates.length > 0 && (
        <Text inline>
          New Samples have been added.&nbsp;
          <Button type="button" title="Update" onClick={handleUpdates} inText>
            Update
          </Button>
        </Text>
      )}
      <ListEvent>
        {markers &&
          available.map(event => (
            <ListItem key={event.id}>
              <Event room={room} event={event} locks={locks} markers={markers} />
            </ListItem>
          ))}
      </ListEvent>
    </>
  );
};

EventList.propTypes = {
  room: PropTypes.string,
  type: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.any),
  events: PropTypes.arrayOf(PropTypes.any),
  locks: PropTypes.objectOf(PropTypes.any).isRequired,
  setHide: PropTypes.func.isRequired
};

EventList.defaultProps = {
  room: null,
  type: null,
  events: [],
  markers: []
};

// Style Overrides
const ListEvent = styled(List)`
  ${flex("column", "nowrap", "center", "start")};
  gap: ${pad}px;
  margin-bottom: ${pad}px;
  width: 100%;

  ${ListItem} {
    width: 100%;
    padding: 0;
  }
`;

export default EventList;
