import React, {useState, useContext, useCallback, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faNoteSticky,
  faPaperclip,
  faStickyNote
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Utils
import {
  formatDate,
  getWithExpiry,
  prettyDateInUserTimezone,
  setWithExpiry
} from "../../utils/helpers.js";
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {CalendarContext} from "../../contexts/calendar.js";
import {handleFilters, handleSort} from "./helpers.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import ModalAttachments from "../checksheets/ModalAttachments.js";
import ModalNotes from "../checksheets/ModalNotes.js";
import ModalExport from "./ModalExport.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {Button, Inline, Abbr, Pill, TableFooter, Text} from "../../style/components/general.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

const TableEvents = ({
  facility,
  eventTypes,
  events,
  filters,
  loading,
  refresh,
  setShowEventForm,
  showModalExport,
  setShowModalExport,
  markerIconMap
}) => {
  const {atLeast} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);

  const {records, getActive, getView, getStart, getEnd} = useContext(CalendarContext);

  const [currentRecord, setCurrentRecord] = useState(null);
  // Modals
  const [modalNotesVisible, setModalNotesVisible] = useState(false);
  const [modalAttachmentsVisible, setModalAttachmentsVisible] = useState(false);
  // Pagination
  const [orderBy, setOrderBy] = useState(getWithExpiry("tableEventRecordOrderBy") || "desc");
  const [groupBy, setGroupBy] = useState(getWithExpiry("tableEventRecordGroupBy") || "dateDue");

  const headings = facility
    ? {
        event: {header: "Event", disabled: true},
        name: {header: "Name", disabled: false},
        type: {header: "Type", disabled: false},
        frequency: {header: "Frequency", disabled: false},
        status: {header: "Status", disabled: false},
        group: {header: "Location", disabled: false},
        stage: {header: "Stage", disabled: false},
        noteCount: {
          header: (
            <Abbr title="Notes">
              <NoteColumnHeader icon={faNoteSticky} />
            </Abbr>
          ),
          disabled: false
        },
        fileCount: {
          header: (
            <Abbr title="Attachments">
              <AttachmentColumnHeader icon={faPaperclip} />
            </Abbr>
          ),
          disabled: true
        },
        dateDue: {header: "Due", disabled: false},
        completedAt: {header: "Completed On", disabled: false},
        completedUserId: {header: "Completed By", disabled: false}
      }
    : {
        event: {header: "Event", disabled: true},
        facility: {header: "Facility", disabled: false},
        name: {header: "Event", disabled: false},
        type: {header: "Type", disabled: false},
        frequency: {header: "Frequency", disabled: false},
        status: {header: "Status", disabled: false},
        group: {header: "Location", disabled: false},
        stage: {header: "Stage", disabled: false},
        noteCount: {
          header: (
            <Abbr title="Notes">
              <NoteColumnHeader icon={faNoteSticky} />
            </Abbr>
          ),
          disabled: false
        },
        fileCount: {
          header: (
            <Abbr title="Attachments">
              <AttachmentColumnHeader icon={faPaperclip} />
            </Abbr>
          ),
          disabled: true
        },
        dateDue: {header: "Due", disabled: false},
        completedAt: {header: "Completed On", disabled: false},
        completedUserId: {header: "Completed By", disabled: false}
      };

  const getNotesColumnContent = useCallback(
    record => {
      if (atLeast("admin") && record?.overdue && !record?.archived)
        return (
          <Inline>
            {!!record.noteCount && (
              <NoteIcon
                onClick={() => {
                  setCurrentRecord(record);
                  setModalNotesVisible(true);
                }}>
                <FontAwesomeIcon icon={faStickyNote} />
                <p>{record.noteCount}</p>
              </NoteIcon>
            )}
          </Inline>
        );

      if (record?.noteCount || record?.fileCount)
        return (
          !!record.noteCount && (
            <NoteIcon
              onClick={() => {
                setCurrentRecord(record);
                setModalNotesVisible(true);
              }}>
              <FontAwesomeIcon icon={faStickyNote} />
              <p>
                {record.noteCount +
                  (atLeast("admin") && record.archivedNotes ? record.archivedNotes.length : 0)}
              </p>
            </NoteIcon>
          )
        );

      return null;
    },
    [atLeast]
  );

  const getAttachmentsColumnContent = useCallback(
    record =>
      !!record?.fileCount && (
        <Abbr title={`${record.fileCount} record attachment(s)`}>
          <AttachmentIcon
            onClick={() => {
              setCurrentRecord(record);
              setModalAttachmentsVisible(true);
            }}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>{record.fileCount}</p>
          </AttachmentIcon>
        </Abbr>
      ),
    []
  );

  const formatRow = useCallback(
    event => {
      const {id, name, type, frequency, group, stages, dateDue, facility: eventFacility} = event;

      const due = formatDate(dateDue);

      const eventsOnDay = events?.onDay[due];

      const record = records && records[due] && id in records[due] ? records[due][id] : null;

      const row = {};

      let recordUser = record?.completedBy
        ? `${record.completedBy.firstName} ${record.completedBy.lastName}`
        : null;

      if (!recordUser)
        recordUser = record?.user ? `${record.user.firstName} ${record.user.lastName}` : null;

      row["event"] = (
        <Button type="button" onClick={() => setShowEventForm(event, due, eventsOnDay)}>
          View
        </Button>
      );

      if (!facility) row["facility"] = `${eventFacility.name} ${eventFacility.type}`;

      row["name"] = name;
      row["type"] = (
        <Pill color={`#${type.color}`} quiet>
          {type.name}
        </Pill>
      );
      row["frequency"] = frequency?.name ? <Pill quiet>{frequency.name}</Pill> : "";
      row["status"] = record?.status ? <Status status={record.status}>{record.status}</Status> : "";
      row["group"] =
        Object.keys(markerIconMap)?.length > 0 && group && eventFacility.builder.byId[group] ? (
          <>
            <Icon
              icon={faMapMarkerAlt}
              color={`#${
                markerIconMap[eventFacility.builder.byId[group]?.markerId]?.color ||
                DEFAULT_MARKER.color
              }`}
            />
            <span>{eventFacility.builder.byId[group].label}</span>
          </>
        ) : (
          ""
        );
      row["stage"] =
        record?.stage && stages?.byId[record.stage] ? stages?.byId[record.stage].name : "";
      row["noteCount"] = getNotesColumnContent(record);
      row["fileCount"] = getAttachmentsColumnContent(record);
      row["dateDue"] = prettyDateInUserTimezone(
        record?.dateDue ? record.dateDue : dateDue,
        settings.timezone,
        "ddd, MMM DD YYYY"
      );
      row["completedAt"] =
        record && !record?.draft && record?.status !== "In Progress"
          ? prettyDateInUserTimezone(
              record.completedAt || record.createdAt,
              settings.timezone,
              "ddd, MMM DD YYYY"
            )
          : "";
      row["completedUserId"] = record?.draft ? null : recordUser;

      return row;
    },
    [
      facility,
      events,
      records,
      markerIconMap,
      getNotesColumnContent,
      getAttachmentsColumnContent,
      settings.timezone,
      setShowEventForm
    ]
  );

  const start = useMemo(
    () => (getView() === "month" ? dayjs(getActive()).set("date", 1) : dayjs(getStart())),
    [getView, getActive, getStart]
  );

  const end = useMemo(
    () =>
      getView() === "month"
        ? dayjs(getActive()).set("date", dayjs(getActive()).daysInMonth())
        : dayjs(getEnd()),
    [getView, getActive, getEnd]
  );

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters)
      Object.keys(filters).map(filter => {
        count += filters[filter]?.length || 0;
      });
    return count;
  }, [filters]);

  const rows = useMemo(() => {
    if (!events || !events?.inMonth || events?.inMonth?.length === 0) return [];

    let virtual = events?.inMonth || [];

    if (filterCount)
      virtual = events.inMonth.filter(event => handleFilters(filters, event, records));

    return virtual
      .sort((a, b) => handleSort(a, b, groupBy, orderBy, records))
      .map(event => formatRow(event));
  }, [events, filters, filterCount, groupBy, orderBy, records, formatRow]);

  useEffect(() => {
    setWithExpiry("tableEventRecordGroupBy", groupBy);
    setWithExpiry("tableEventRecordOrderBy", orderBy);
  }, [groupBy, orderBy]);

  return (
    <>
      <BaseTable
        headings={headings}
        data={rows}
        updateData={refresh}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        loading={loading}
        minHeight="230px"
      />

      <TableFooter>
        <Total>{rows.length}&nbsp;total</Total>
      </TableFooter>

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          start={formatDate(start)}
          end={formatDate(end)}
          filters={filters}
          eventTypes={eventTypes}
          facility={facility}
          events={rows}
        />
      )}

      {modalNotesVisible && currentRecord && (
        <ModalNotes
          visible={modalNotesVisible}
          setVisible={setModalNotesVisible}
          pendingNotes={currentRecord.postedNotes}
          resolvedNotes={currentRecord.resolvedNotes}
          noteFileCount={currentRecord.noteFileCount}
          noteCt={currentRecord.noteCount}
          fileCt={currentRecord.fileCount}
          files={currentRecord.files}
          archivedNotes={atLeast("admin") ? currentRecord.archivedNotes : null}
          updateData={refresh}
          checksheetName={currentRecord.event?.name}
          dateDue={currentRecord.dateDue}
          builder={currentRecord.event?.stages}
          isEvent
        />
      )}

      {modalAttachmentsVisible && currentRecord && (
        <ModalAttachments
          visible={modalAttachmentsVisible}
          setVisible={setModalAttachmentsVisible}
          builder={currentRecord.event?.stages}
          files={currentRecord.files}
          pendingNotes={currentRecord.postedNotes}
          resolvedNotes={currentRecord.resolvedNotes}
          stageName={
            currentRecord.event?.stages?.byId
              ? currentRecord.event.stages.byId[currentRecord.stage]?.name
              : undefined
          }
        />
      )}
    </>
  );
};

TableEvents.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any),
  eventTypes: PropTypes.arrayOf(PropTypes.any),
  events: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
  setShowEventForm: PropTypes.func,
  showModalExport: PropTypes.bool,
  setShowModalExport: PropTypes.func,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired
};

TableEvents.defaultProps = {
  facility: null,
  eventTypes: null,
  events: null,
  filters: null,
  loading: true,
  setShowEventForm: null,
  showModalExport: false,
  setShowModalExport: null
};

// Style Overrides
const Status = styled(Pill)`
  ${voice.quiet};

  background: ${({status, theme}) => {
    if (status === "Draft") return theme.warning;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};
`;

const NoteIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;
  gap: 2px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const AttachmentIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const NoteColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const AttachmentColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin-right: ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const Total = styled(Text)`
  ${voice.quiet};
`;

export default TableEvents;
