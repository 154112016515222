import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputNumber from "./InputNumber.js";
import InputSelect from "./InputSelect.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, Label} from "../../style/components/general.js";

const InputLimit = ({name, index, option, selected, testId}) => {
  const {watch, setValue} = useFormContext();

  const label = watch(`${name}.${index}.label`);

  useEffect(() => {
    if (label === "Regulatory") setValue(`${name}.${index}.color`, colors.red);
    if (label === "Design") setValue(`${name}.${index}.color`, colors.orange);
  }, [label, setValue, index, name]);

  return (
    <>
      <DropdownWrapper>
        <InputSelect
          name={`${name}.${index}.label`}
          placeholder="Select label..."
          defaultValue={option.label || null}
          testId={`${testId}-dropdown`}
          options={["Regulatory", "Design"].filter(
            n => !selected.includes(n) || n === option.label
          )}
        />
      </DropdownWrapper>

      <NumberWrapper>
        <InputNumber
          name={`${name}.${index}.value`}
          placeholder="Value"
          defaultValue={option.value || null}
          testId={`${testId}-number`}
        />
      </NumberWrapper>
    </>
  );
};

InputLimit.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  option: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.arrayOf(PropTypes.any).isRequired,
  testId: PropTypes.string
};

InputLimit.defaultProps = {
  testId: "limit"
};

const InputLimitGroup = ({name, label, limit, required, testId}) => {
  const {control, watch} = useFormContext();

  const {remove, insert} = useFieldArray({control, name});

  const watchedFields = watch(name);
  const currentlySelected = watchedFields ? watchedFields.map(field => field.label) : [];

  return (
    <FormFieldWrapper standard>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      {watchedFields &&
        watchedFields.length > 0 &&
        watchedFields.map((option, index) => (
          <StyledInlineFormField key={option.id || index}>
            <InputLimit
              name={name}
              index={index}
              option={option}
              selected={currentlySelected}
              testId={testId}
            />

            <StyledEdit
              disable={index === 0 && watchedFields.length === 1}
              type="button"
              data-testid={`${testId}.removeInput`}
              onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faMinus} />
            </StyledEdit>
            <StyledEdit
              disable={watchedFields.length === limit}
              type="button"
              data-testid={`${testId}.addInput`}
              onClick={() => {
                insert(index + 1, "");
              }}>
              <FontAwesomeIcon icon={faPlus} />
            </StyledEdit>
          </StyledInlineFormField>
        ))}
    </FormFieldWrapper>
  );
};

InputLimitGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  limit: PropTypes.number
};

InputLimitGroup.defaultProps = {
  testId: "input-limit-group",
  label: null,
  required: false,
  limit: 10
};

// Style Overrides
const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin: 0 0 ${pad * 2}px;
  align-items: space-between;
  justify-content: flex-start;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad / 2}px;
    }
  }

  span {
    margin-left: ${pad / 2}px;
  }
`;

const StyledEdit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;

  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const DropdownWrapper = styled.span`
  margin-top: -3px;
  width: 100%;
  max-width: 200px;
  height: 30px;
`;

const NumberWrapper = styled.span``;

export default InputLimitGroup;
