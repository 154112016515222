import {createContext, useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Utils
import Gregorian from "../utils/gregorian";
import useMountedState from "../hooks/useMountedState";
import useApi from "../hooks/useApi";
import {formatDate} from "../utils/helpers";

const now = new Date();
now.setHours(0, 0, 0, 0);
const today = now;

const greg = new Gregorian(today);

export const CalendarContext = createContext();

const CalendarProvider = ({children}) => {
  const isMounted = useMountedState();

  const {api: apiRecords} = useApi("event-records");

  const [calendar, setCalendar] = useState(null);
  const [records, setRecords] = useState(null);

  const getRecords = useCallback(
    () =>
      apiRecords
        .callGet(null, {
          filter: JSON.stringify({
            start: formatDate(greg.getStart()),
            end: formatDate(greg.getEnd())
          })
        })
        .then(({status, data}) => {
          if (status === 200) {
            const recordMap = {};
            data.map(record => {
              const {dateDue, event} = record;
              const date = dayjs(dateDue).format("YYYY-MM-DD");
              if (date in recordMap && !(event.id in recordMap[date]))
                recordMap[date][record.event.id] = record;
              else recordMap[date] = {[record.event.id]: record};
            });
            setRecords(recordMap);
          }
        }),
    [apiRecords]
  );

  useEffect(() => {
    if (isMounted() && calendar && records === null) getRecords();
  }, [isMounted, calendar, records, getRecords]);

  const update = () => {
    setCalendar(greg.getMatrix());
    setRecords(null);
  };

  useEffect(() => {
    if (isMounted() && calendar === null) {
      greg.setView("month");
      update();
    }
  }, [isMounted, calendar]);

  const updateRecord = useCallback(
    ({eventId, recordId}) =>
      apiRecords.callGet(recordId).then(({status, data}) => {
        if (status === 200) {
          const {dateDue} = data;
          const date = dayjs(dateDue).format("YYYY-MM-DD");
          setRecords(prev => ({...prev, [date]: {[eventId]: data}}));
        }
      }),
    [apiRecords]
  );

  // Actions
  const setView = useCallback(v => {
    greg.setView(v);
    update();
  }, []);

  const increment = useCallback(() => {
    if (greg.monthView()) greg.setNextMonth();
    else greg.setNextWeek();
    update();
  }, []);

  const decrement = useCallback(() => {
    if (greg.monthView()) greg.setPrevMonth();
    else greg.setPrevWeek();
    update();
  }, []);

  const setMonth = useCallback(date => {
    greg.setMonth(date);
    update();
  }, []);

  const reset = useCallback(() => {
    greg.setMonth(today);
    update();
  }, []);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      // Managed States
      calendar,
      records,
      getRecords,
      updateRecord,
      // Actions
      setView,
      increment,
      decrement,
      setMonth,
      reset,
      triggerUpdate: update,
      // Static
      getView: () => greg.getView(),
      getStart: () => greg.getStart(),
      getEnd: () => greg.getEnd(),
      getActive: () => greg.getActive(),
      getActiveWeek: () => greg.getActiveWeek(),
      getActiveMonth: () => greg.getActiveMonth(),
      getActiveYear: () => greg.getActiveYear()
    }),
    [calendar, records, getRecords, updateRecord, setView, increment, decrement, setMonth, reset]
  );

  return <CalendarContext.Provider value={memoedValue}>{children}</CalendarContext.Provider>;
};

CalendarProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CalendarProvider;
