import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Utils
import {getFields, replacePseudoFields} from "../../utils/builder.js";
import {getAncestryName} from "./helpers.js";

// Components
import SearchSelect from "../../components/SearchSelect.js";
import Dropdown from "../../components/Dropdown.js";
import {
  InputSelect,
  InputRadioGroup,
  InputDate,
  InputDay,
  InputText,
  InputTime
} from "../../components/form/FormInputs.js";

// Style
import {cross} from "../../style/components/shapes.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Inline,
  NotLoaded,
  Loader
} from "../../style/components/general.js";

const initialConditional = {
  check: "",
  compare: ""
};

const defaultValues = {
  trigger: "date",
  condition: {
    depends: "date",
    conditionArray: [{...initialConditional}],
    operator: "or"
  }
};

const schema = yup.object().shape({
  trigger: yup.string().nullable().required(),
  condition: yup.object().shape({
    depends: yup.string().required("Dependant field is required."),
    conditionArray: yup.lazy((_v, ctx) => {
      if (ctx.parent.depends === "weekday")
        return yup.array().of(
          yup.object().shape({
            check: yup.mixed().nullable(),
            compare: yup.array().of(yup.string()).required("This field is required.")
          })
        );
      if (ctx.parent.depends === "date")
        return yup.array().of(
          yup.object().shape({
            check: yup.mixed().nullable(),
            compare: yup.string().required("This field is required.")
          })
        );
      return yup.array().of(
        yup.object().shape({
          check: yup.string().required("This field is required."),
          compare: yup.string().required("This field is required.")
        })
      );
    }),
    operator: yup.string().nullable()
  })
});

const ManageConditional = ({
  builder,
  submission,
  existing,
  setExisting,
  open,
  canCancel,
  targetElement,
  hasPrimaryAddress
}) => {
  const {allIds, byId} = builder;

  const initialized = useRef(false);

  const [results, setResults] = useState([]);

  const [dependsDropdownVal, setDependsDropdownVal] = useState(null);

  const form = useForm({
    // we need to generate the options for given dependency before setting the default values if editing
    defaultValues: existing || defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, control, reset, setValue, watch} = form;
  const {append, remove} = useFieldArray({
    control,
    name: "condition.conditionArray"
  });
  const watchTrigger = watch("trigger");
  const watchDepends = watch("condition.depends");
  const fields = watch("condition.conditionArray");

  useEffect(() => {
    // Depends is same as trigger if not a field condition
    if (!["field", "weather"].includes(watchTrigger) && initialized.current)
      setValue("condition.depends", watchTrigger);
    else if (initialized.current) setValue("condition.depends", null);

    // reset on change
    if (watchTrigger && initialized.current)
      setValue("condition.conditionArray", [{...initialConditional}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (!initialized.current) initialized.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTrigger, setValue]);

  const getAllOptions = useCallback(() => {
    const tempFields = getFields(allIds, byId).filter(({name}) => name !== targetElement.name);

    const tempSiblings = tempFields
      .filter(el => el.parentName === targetElement.parentName && el.type !== "weather")
      .map(({name, ancestry: label}) => ({name, label}));
    const external = tempFields
      .filter(el => el.parentName !== targetElement.parentName && el.type !== "weather")
      .map(({name, ancestry: label}) => ({name, label}));

    return [...tempSiblings, ...external];
  }, [allIds, byId, targetElement]);

  const getDropdownOptions = useCallback(() => {
    const tempFields = getFields(allIds, byId).filter(({name}) => name !== targetElement.name);

    const tempSiblings = tempFields
      .filter(el => el.parentName === targetElement.parentName && el.type !== "weather")
      .map(({name, ancestry: label}) => ({name, label}));
    const external = tempFields
      .filter(el => el.parentName !== targetElement.parentName && el.type !== "weather")
      .map(({name, ancestry: label}) => ({name, label}));

    if (external.length) return [...tempSiblings, {label: "Other field", name: "external"}];
    // otherwise, if siblings exist provide them as options
    return [...tempSiblings];
  }, [allIds, byId, targetElement]);

  const getCheckOptions = useCallback(() => {
    const depends = watchDepends ?? existing?.condition?.depends;
    let check = [];
    if (depends) {
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type} = byId[sanitizedDepends];
        check = [
          {label: "is", name: "==="},
          {label: "is not", name: "!=="}
        ];

        if ((type && type === "number") || type === "generated" || type === "range")
          check = check.concat([
            {label: "is greater than", name: ">"},
            {label: "is less than", name: "<"},
            {label: "is greater than or equal to", name: ">="},
            {label: "is less than or equal to", name: "<="}
          ]);

        if (type && type === "multiselect") check = [{label: "has", name: "contains"}];

        if (type && type === "text") check.push({label: "contains", name: "contains"});

        if (type && type === "time")
          check = check.concat([
            {label: "is after", name: ">"},
            {label: "is before", name: "<"}
          ]);
      } else if (["rainfall", "cumulative"].includes(depends)) {
        check = check.concat([
          {label: "is", name: "==="},
          {label: "is not", name: "!=="},
          {label: "is greater than", name: ">"},
          {label: "is less than", name: "<"},
          {label: "is greater than or equal to", name: ">="},
          {label: "is less than or equal to", name: "<="}
        ]);
      }
    }
    return check;
  }, [byId, existing, watchDepends]);

  const getAllowOther = useCallback(() => {
    const depends = watchDepends ?? existing?.condition?.depends;
    if (depends) {
      if (depends === "rainfall" || depends === "cumulative") return true;
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type} = byId[sanitizedDepends];

        const noOther = [
          "checkbox",
          "confirm",
          "radio",
          "dropdown",
          "switch",
          "multiselect",
          "upload"
        ];

        if (!type || noOther.includes(type)) return false;

        return true;
      }
    }
    return false;
  }, [byId, existing, watchDepends]);

  const getCompareOptions = useCallback(() => {
    const depends = watchDepends ?? existing?.condition?.depends;
    let compare = [];
    if (depends) {
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type, options, on, off} = byId[sanitizedDepends];

        if (type === "checkbox" || type === "confirm")
          compare = [
            {label: "True", name: "true"},
            {label: "False", name: "false"}
          ];

        if (type === "radio" || type === "dropdown")
          compare = options.map(select => ({
            label: select.value || select.option || select,
            name: select.value || select.option || select
          }));

        if (type === "switch")
          compare = [
            {label: on, name: on},
            {label: off, name: off}
          ];

        if (type === "number")
          compare = [
            {label: "0", name: "0"},
            {label: "1", name: "1"}
          ];

        if (type === "multiselect")
          compare = [
            {label: "all checked", name: "checked"},
            {label: "all unchecked", name: "unchecked"},
            {label: "one checked", name: "one"},
            {label: "at least one checked", name: "more"},
            ...options.map(select => ({
              label: `"${select.value || select.option || select}" selected`,
              name: select.value || select.option || select
            }))
          ];

        if (type === "upload") compare = [{label: "uploaded", name: "uploaded"}];
      }
    }
    return compare;
  }, [byId, existing, watchDepends]);

  const getRadioOptions = useCallback(() => {
    if (hasPrimaryAddress)
      return [
        {label: "Date", value: "date"},
        {label: "Weekday", value: "weekday"},
        {label: "Weather", value: "weather"},
        {label: "Field", value: "field"}
      ];

    return [
      {label: "Date", value: "date"},
      {label: "Weekday", value: "weekday"},
      {label: "Field", value: "field"}
    ];
  }, [hasPrimaryAddress]);

  const radioOptions = useMemo(getRadioOptions, [getRadioOptions]);
  const allOptions = useMemo(getAllOptions, [getAllOptions]);
  const dropdownOptions = useMemo(getDropdownOptions, [getDropdownOptions]);

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allOptions.filter(option => option.label.toLowerCase().includes(lower));
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else setResults(allOptions);
  };

  const handleAdd = ({trigger, condition}) => {
    submission({trigger, ...condition});

    // Handle reset
    reset(defaultValues);
    open(false);
  };

  const checkOptions = useMemo(getCheckOptions, [getCheckOptions]);
  const compareOptions = useMemo(getCompareOptions, [getCompareOptions]);
  const allowOther = useMemo(getAllowOther, [getAllowOther]);

  const dependsLabel = useMemo(() => {
    if (watchDepends === "rainfall") return "Rainfall";
    if (watchDepends === "cumulative") return "Cumulative Rainfall";
    const element = byId[watchDepends];
    if (!element) return "";
    return getAncestryName(element, {byId});
  }, [watchDepends, byId]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleAdd)} noValidate>
        <FormField>
          <InputRadioGroup
            testId="condition.trigger"
            name="trigger"
            label="Trigger on..."
            options={radioOptions}
            disabled={!!existing}
          />
        </FormField>
        <Columns>
          {["field", "weather"].includes(watchTrigger) && (
            <ColLeft>
              <InputText name="condition.depends" hidden required />
              {!watchDepends ? (
                <DependsWrapper>
                  {watchTrigger === "field" && dependsDropdownVal !== "external" && (
                    <Dropdown
                      testId="condition.fieldSelect"
                      options={dropdownOptions}
                      showPlaceholder
                      placeholder="Select..."
                      selection={dependsDropdownVal}
                      setSelection={val => {
                        if (val !== "external") setValue("condition.depends", val);
                        setDependsDropdownVal(val);
                      }}
                    />
                  )}
                  {watchTrigger === "weather" && dependsDropdownVal !== "external" && (
                    <Dropdown
                      options={[
                        {name: "rainfall", label: "Rainfall"},
                        {name: "cumulative", label: "Cumulative Rainfall"}
                      ]}
                      placeholder="Select..."
                      selection={dependsDropdownVal}
                      setSelection={val => {
                        setValue("condition.depends", val);
                        setDependsDropdownVal(val);
                      }}
                      showPlaceholder
                    />
                  )}
                  {watchTrigger === "field" && dependsDropdownVal === "external" && (
                    <FieldDropdown>
                      <SearchSelect
                        results={results}
                        setResults={setResults}
                        search={search}
                        add={item => setValue("condition.depends", item.name)}
                        showAll
                      />
                      <DeleteButton onClick={() => setValue("condition.depends", null)}>
                        <IconWrapper>
                          <CloseIcon data-testid="formula.cancelSearch" />
                        </IconWrapper>
                      </DeleteButton>
                    </FieldDropdown>
                  )}
                </DependsWrapper>
              ) : (
                <Depends
                  type="button"
                  title={dependsLabel}
                  onClick={() => {
                    setValue("condition.depends", null);
                    setDependsDropdownVal("");
                  }}>
                  <span>{dependsLabel}</span>
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faClose} />
                </Depends>
              )}
            </ColLeft>
          )}
          <ColRight>
            {fields ? (
              fields.map((_f, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <SpacedWrapper key={`compareArray-${i}`} fullWidth>
                  {watchDepends === "date" && (
                    <>
                      <FormField inline>
                        <InputDate name={`condition.conditionArray.${i}.compare`} />
                      </FormField>
                      <FormField inline>
                        <InputSelect
                          name={`condition.conditionArray.${i}.frequency`}
                          placeholder="Does not repeat..."
                          options={["weekly", "monthly", "annually"]}
                          minWidth={120}
                          required
                        />
                      </FormField>
                      {watch(`condition.conditionArray.${i}.frequency`) === "monthly" && (
                        <FormField inline>
                          <LastDay
                            type="button"
                            onClick={() => {
                              setValue(
                                `condition.conditionArray.${i}.compare`,
                                dayjs().endOf("month").format("YYYY-MM-DD")
                              );
                              setValue(
                                `condition.conditionArray.${i}.lastDay`,
                                !watch(`condition.conditionArray.${i}.lastDay`)
                              );
                            }}
                            enabled={watch(`condition.conditionArray.${i}.lastDay`)}>
                            Last Day of Month
                          </LastDay>
                        </FormField>
                      )}
                    </>
                  )}
                  {watchDepends === "weekday" && (
                    <FormField>
                      <InputDay testId="day-input" name={`condition.conditionArray.${i}.compare`} />
                    </FormField>
                  )}
                  {(watchTrigger === "field" ||
                    ["rainfall", "cumulative"].includes(watchDepends)) && (
                    <>
                      <InputSelect
                        testId="condition.check"
                        name={`condition.conditionArray.${i}.check`}
                        placeholder="Condition..."
                        options={checkOptions}
                        minWidth={120}
                        disabled={!checkOptions || !watchDepends}
                        required
                      />
                      {byId[watchDepends]?.type === "time" ? (
                        <InputTime
                          testId="condition.compare"
                          name={`condition.conditionArray.${i}.compare`}
                          placeholder="Compare to..."
                        />
                      ) : (
                        <InputSelect
                          testId="condition.compare"
                          name={`condition.conditionArray.${i}.compare`}
                          placeholder="Compare to..."
                          options={compareOptions}
                          other={allowOther}
                          minWidth={120}
                          disabled={
                            !compareOptions || !watch(`condition.conditionArray.${i}.check`)
                          }
                          required
                        />
                      )}
                    </>
                  )}
                  {fields.length > 1 && watchDepends && watchDepends !== "weekday" && (
                    <Button data-testid="condition.remove" type="button" onClick={() => remove(i)}>
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  )}
                  {fields.length < 5 && watchDepends && watchDepends !== "weekday" && (
                    <Button
                      data-testid="condition.add"
                      type="button"
                      onClick={() => append(initialConditional)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                </SpacedWrapper>
              ))
            ) : (
              <NotLoaded>
                <Loader />
              </NotLoaded>
            )}
          </ColRight>
        </Columns>

        {watchDepends !== "weekday" && fields && fields.length > 1 && (
          <FormField>
            <InputRadioGroup
              name="condition.operator"
              label="Logical Operator"
              options={[{option: "or"}, {option: "and"}]}
              testId="condition.operator"
              defaultValue="or"
            />
          </FormField>
        )}

        <SpacedWrapper>
          <Button type="submit" data-testid="condition.stage">
            {existing ? "Update" : "Add"}
          </Button>
          {open && setExisting && canCancel && (
            <Button
              type="button"
              onClick={() => {
                reset(defaultValues);
                setExisting(null);
                open(false);
              }}>
              Cancel
            </Button>
          )}
        </SpacedWrapper>
      </Form>
    </FormProvider>
  );
};

ManageConditional.propTypes = {
  submission: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  existing: PropTypes.objectOf(PropTypes.any),
  setExisting: PropTypes.func,
  allOptions: PropTypes.arrayOf(PropTypes.any),
  open: PropTypes.func,
  canCancel: PropTypes.bool,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  hasPrimaryAddress: PropTypes.bool
};

ManageConditional.defaultProps = {
  existing: null,
  setExisting: () => null,
  allOptions: [],
  open: null,
  canCancel: true,
  hasPrimaryAddress: false
};

// Style Overrides
const Columns = styled(Inline)`
  width: 100%;
  margin-bottom: ${pad * 2}px;
`;

const Col = styled(FormGroup)`
  align-self: center;
  margin: 0;
`;

const ColLeft = styled(Col)`
  align-self: start;
  width: max-content;
  max-width: 50%;
`;

const ColRight = styled(Col)`
  max-width: 50%;
`;

const Depends = styled(Button)`
  display: flex;
  align-items: center;

  span {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SpacedWrapper = styled(Inline)`
  gap: ${pad}px;
  margin-bottom: ${pad}px;
  align-items: start;

  div {
    margin-top: 0;
  }
`;

const DependsWrapper = styled(Inline)`
  max-width: max-content;
`;

const FieldDropdown = styled.div`
  margin: 0;
  width: 180px;
  height: min-content;
  border-radius: ${radius};
  background-color: ${({theme}) => theme.primary};
`;

const DeleteButton = styled(Button)`
  background-color: transparent;
  padding: ${pad / 2}px ${pad / 4}px;
`;

const CloseIcon = styled.div`
  ${({theme}) => cross("100%", theme.secondary)};
`;

const IconWrapper = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
`;

const LastDay = styled(Button)`
  ${({enabled}) => css`
    opacity: ${enabled ? 1 : 0.5};
  `}
`;

export default ManageConditional;
