import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {useToast} from "../../contexts/toast.js";
import {getLocation} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";

// Style
import {
  HeadingCenter,
  Button,
  FormGroup,
  FormField,
  Form,
  Text
} from "../../style/components/general.js";

const ModalSetLocation = ({visible, setVisible, targetElement, setTargetElement, persistEdit}) => {
  const {addToast} = useToast();

  const schema = yup.object().shape({
    lat: yup.string().nullable(),
    lon: yup.string().nullable()
  });

  const form = useForm({
    defaultValues: {coords: {...targetElement.coords}},
    resolver: yupResolver(schema)
  });
  const {reset, setValue, handleSubmit} = form;

  useEffect(() => {
    if (!visible) {
      reset({...targetElement});
      setTargetElement(null);
    }
  }, [reset, setTargetElement, targetElement, visible]);

  const handleEditElement = values => {
    const {coords} = values;
    persistEdit(coords);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Edit Location</HeadingCenter>
      <Text>
        <strong>Element:</strong> {targetElement.label}
      </Text>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleEditElement)} noValidate>
          <FormGroup>
            <FormField>
              <InputText name="coords.lat" label="Latitude" testId="edit.lat" {...form} />
            </FormField>
            <FormField>
              <InputText name="coords.lon" label="Longitude" testId="edit.long" {...form} />
            </FormField>
            <FormField>
              <Button
                type="button"
                onClick={async () => {
                  const {error, lat, lon} = await getLocation();
                  if (error) addToast(error, "error");
                  else {
                    setValue("coords.lat", lat);
                    setValue("coords.lon", lon);
                  }
                }}
                data-testid="edit.useLocation">
                Use my Location
              </Button>
            </FormField>
          </FormGroup>
          <Button data-testid="edit.save" type="submit">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalSetLocation.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  setTargetElement: PropTypes.func.isRequired,
  persistEdit: PropTypes.func.isRequired
};

export default ModalSetLocation;
