import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import CustomTable from "./CustomTable.js";
import ModalCustomTable from "./ModalCustomTable.js";
import ModalViewAllRows from "./ModalViewAllRows.js";

// Styles
import {Button, Loader, Text} from "../../style/components/general.js";
import {border, pad, radius} from "../../style/components/variables.js";

const CustomTables = ({facility, inGlobalTables, setHasNoTables, query}) => {
  const isMounted = useMountedState();

  const {roleCanAccessResource} = useContext(AuthContext);

  const {api, loading} = useApi("custom-tables");

  const retrieving = useRef(false);
  const initialized = useRef(false);

  const [tables, setTables] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewAllVisible, setViewAllVisible] = useState(false);
  const [active, setActive] = useState(1);
  const [target, setTarget] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (setHasNoTables && tables) setHasNoTables(tables.length === 0);
  }, [setHasNoTables, tables]);

  const getCustomTables = useCallback(
    (page, refresh) => {
      retrieving.current = true;

      if (refresh) {
        setCurrentPage(1);
        setPages(1);
        setTables(null);
      }

      const params = {
        page: page || 1,
        limit: 20
      };

      if (facility?.id) params.facilityId = facility.id;
      else params.global = true;

      if (query)
        params.filter = JSON.stringify({
          Search: query
        });

      api.callGet(null, params).then(({status, data}) => {
        if (status === 200) {
          setCurrentPage(data.page);
          setPages(data.pages);
          setTables(prev => (prev ? [...prev, ...data.tables] : data.tables));
          retrieving.current = false;
          initialized.current = false;
        }
      });
    },
    [api, facility, query]
  );

  // Handle Load More
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + Math.ceil(window.scrollY) >= document.documentElement.offsetHeight &&
        currentPage < pages &&
        !retrieving.current
      )
        getCustomTables(currentPage + 1);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, getCustomTables, pages]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !initialized.current) getCustomTables();
  }, [isMounted, getCustomTables]);

  useEffect(() => {
    if (isMounted()) getCustomTables(1, true, query);
  }, [isMounted, getCustomTables, query]);

  const handleEditTable = t => {
    setTarget(t);
    setEditModalVisible(true);
  };

  const handleViewAll = t => {
    setTarget(t);
    setViewAllVisible(true);
  };

  return (
    <>
      {tables?.length === 0 && (
        <NotFound>
          <Text>
            No {inGlobalTables ? "Global" : "Custom"} Tables{" "}
            {query ? "were found" : "have been created"}
            {facility?.id && " for this facility"}.
          </Text>
        </NotFound>
      )}

      {tables?.map((table, index) => (
        <div key={table.id}>
          <CustomTable
            index={index + 1}
            table={table}
            singleActive={false}
            active={active}
            setActive={setActive}
            handleViewAll={handleViewAll}
            handleEditTable={handleEditTable}
            reload={() => getCustomTables(null, true)}
            inGlobalTables={inGlobalTables}
          />
        </div>
      ))}

      <LoadWrapper show={loading}>
        <Loader />
      </LoadWrapper>

      {!inGlobalTables &&
        roleCanAccessResource("custom_table", "create") &&
        !facility?.isDeleted && (
          <AddTable type="button" onClick={() => setEditModalVisible(true)}>
            <FontAwesomeIcon icon={faPlus} /> Table
          </AddTable>
        )}

      {facility?.id && editModalVisible && (
        <ModalCustomTable
          visible={editModalVisible}
          setVisible={state => {
            if (!state) setTarget(null);
            setEditModalVisible(state);
          }}
          facilityId={facility.id}
          target={target}
          setTarget={setTarget}
          tables={
            tables
              ? tables.map(table => ({name: table.label, value: table.name, order: table.order}))
              : []
          }
          reload={() => getCustomTables(null, true)}
        />
      )}

      {viewAllVisible && (
        <ModalViewAllRows
          visible={viewAllVisible}
          setVisible={state => {
            if (!state) setTarget(null);
            setViewAllVisible(state);
          }}
          target={target}
          inGlobalTables={inGlobalTables}
        />
      )}
    </>
  );
};

CustomTables.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any),
  inGlobalTables: PropTypes.bool,
  setHasNoTables: PropTypes.func,
  query: PropTypes.string
};

CustomTables.defaultProps = {
  facility: null,
  inGlobalTables: false,
  setHasNoTables: null,
  query: ""
};

// Style Overrides
const AddTable = styled(Button)`
  float: right;
`;

const LoadWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 20px;
  opacity: ${({show}) => (show ? 1 : 0)};

  div {
    margin-top: 0;
  }
`;

const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  min-height: 75px;
  padding: ${pad}px;
  margin-right: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  width: 100%;
`;

export default CustomTables;
