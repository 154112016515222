import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import {toTitleCase} from "../../utils/helpers";

// Components
import SearchSelect from "../../components/SearchSelect";
import {InputSelect, InputError, InputCalendar} from "../../components/form/FormInputs.js";

// Style
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, Button, Inline} from "../../style/components/general";
import {flex} from "../../style/components/mixins";
import {voice} from "../../style/components/typography";

const noLastDayOption = ["daily", "weekly", "bi-weekly", "work-week", "semi-monthly"];

const BulkApplyUnit = ({
  defaultEvent,
  template,
  index,
  eventTypes,
  groups,
  frequencies,
  allTemplates,
  hasSubmitted
}) => {
  const templateMap = {};

  const [templateResults, setTemplateResults] = useState(
    allTemplates.map(t => {
      templateMap[t.id] = t;
      return {name: t.id, label: t.name};
    })
  );

  const [groupResults, setGroupResults] = useState([]);
  const {
    watch,
    control,
    setValue,
    trigger,
    formState: {errors}
  } = useFormContext();

  const {insert, remove} = useFieldArray({control, name: "templates"});

  const templates = watch("templates");
  const frequency = watch(`templates.${index}.frequency`);
  const startDate = watch(`templates.${index}.startDate`);
  const type = watch(`templates.${index}.type`);
  const group = watch(`templates.${index}.group`);

  const eventType = useMemo(() => eventTypes.find(t => t.name === type), [eventTypes, type]);

  useEffect(() => {
    if (hasSubmitted.current) trigger();
  }, [frequency, startDate, hasSubmitted, trigger]);

  const searchTemplates = query => {
    if (query)
      setTemplateResults(
        allTemplates
          .filter(({name}) => name.toLowerCase().includes(query.toLowerCase()))
          .map(({id, name}) => ({name: id, label: name}))
      );
    else setTemplateResults(allTemplates.map(({id, name}) => ({name: id, label: name})));
  };

  const searchGroups = query => {
    if (query)
      setGroupResults(
        groups.filter(({label}) => label.toLowerCase().includes(query.toLowerCase()))
      );
    else setGroupResults(groups);
  };

  const applyTemplate = ({type: localType, name: eventName, responses, stages, ...rest}, i) => {
    setValue(`templates.${i}`, {
      ...defaultEvent,
      type: localType.name,
      name: eventName,
      responses,
      stages,
      group,
      ...rest
    });

    if (hasSubmitted.current) trigger();
  };

  const addGroup = (g, i) => {
    setValue(`templates.${i}.group`, g);

    if (hasSubmitted.current) trigger();
  };

  return (
    <Fragment key={`template-${index}`}>
      <TemplateWrapper>
        <InputGroup>
          <SearchInPlace>
            {!template.name && (
              <SearchSelect
                results={templateResults}
                setResults={setTemplateResults}
                search={searchTemplates}
                add={({name}) => applyTemplate(templateMap[name], index)}
                placeholder="Name..."
                showAll
                showMore
              />
            )}
            {template.label && (
              <Applied
                type="button"
                title="Unapply Template"
                onClick={() => {
                  setValue(`templates.${index}`, {
                    ...defaultEvent,
                    frequency,
                    startDate,
                    group
                  });
                }}>
                <span>
                  <Abbr title={template.label}>{template.label}</Abbr>
                </span>
                {eventType && (
                  <EventType type="button" title={eventType.name} color={`#${eventType.color}`}>
                    {toTitleCase(eventType.name)}
                  </EventType>
                )}
                <FontAwesomeIcon icon={faClose} />
              </Applied>
            )}
          </SearchInPlace>
          <SearchInPlace>
            {!group?.label && (
              <SearchSelect
                results={groupResults}
                setResults={setGroupResults}
                search={searchGroups}
                add={t => addGroup(t, index)}
                placeholder="Group..."
                showAll
                showMore
              />
            )}
            {group?.label && (
              <Applied
                type="button"
                title="Deselect Group"
                onClick={() => {
                  setValue(`templates.${index}.group`, null);
                }}>
                <span>
                  <Abbr title={group.label}>{group.label}</Abbr>
                </span>
                <FontAwesomeIcon icon={faClose} />
              </Applied>
            )}
          </SearchInPlace>
          <SelectWrapper>
            <InputSelect
              name={`templates.${index}.frequency`}
              placeholder="Frequency..."
              options={
                frequencies
                  ? [
                      ...frequencies.map(freq => ({
                        name: freq.name,
                        label: freq.label
                      }))
                    ]
                  : []
              }
              minWidth={150}
            />
          </SelectWrapper>
          <DateWrapper>
            <InputCalendar
              name={`templates.${index}.startDate`}
              showLastDay={!!frequency && !noLastDayOption.includes(frequency)}
              placeholder="First Due Date..."
            />
          </DateWrapper>
        </InputGroup>
        <ButtonBar>
          <StyledEdit disable={templates.length === 1} type="button" onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faMinus} />
          </StyledEdit>
          <StyledEdit
            type="button"
            onClick={() => {
              insert(index + 1, defaultEvent);
            }}>
            <FontAwesomeIcon icon={faPlus} />
          </StyledEdit>
        </ButtonBar>
      </TemplateWrapper>
      <InputError name={`templates.${index}.name`} errors={errors} />
    </Fragment>
  );
};

BulkApplyUnit.propTypes = {
  defaultEvent: PropTypes.objectOf(PropTypes.any).isRequired,
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any),
  groups: PropTypes.arrayOf(PropTypes.any),
  frequencies: PropTypes.arrayOf(PropTypes.any),
  allTemplates: PropTypes.arrayOf(PropTypes.any),
  hasSubmitted: PropTypes.objectOf(PropTypes.any)
};

BulkApplyUnit.defaultProps = {
  eventTypes: [],
  groups: [],
  frequencies: [],
  allTemplates: [],
  hasSubmitted: null
};

// Style Overrides
const DateWrapper = styled.div`
  width: 200px;

  margin-right: -${pad}px;
`;

const TemplateWrapper = styled(Inline)`
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  padding: ${pad * 1.4}px;
  width: 100%;

  justify-content: space-between;
`;

const InputGroup = styled(Inline)`
  width: calc(100% - 100px);
`;

const ButtonBar = styled(Inline)`
  width: 100px;

  justify-content: end;
`;

const StyledEdit = styled(Button)`
  padding: ${pad / 2}px ${pad}px;

  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const SearchInPlace = styled.div`
  width: 28%;
  max-width: 300px;
  min-width: 150px;
`;

const SelectWrapper = styled.div`
  margin-top: -${pad}px;
  width: 28%;
  max-width: 300px;
  min-width: 150px;

  input {
    max-width: 300px !important;
  }
`;

const Applied = styled(Button)`
  ${flex("row", "nowrap", "space-between", "center")};
  width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const EventType = styled.div`
  border-radius: ${radius};
  border: ${border} solid ${({color}) => color};
  margin-right: ${pad / 2}px;
  color: ${({theme}) => theme.tertiary};
  background: ${({color}) => color};
  padding: 0;
  ${voice.quiet};
`;

export default BulkApplyUnit;
