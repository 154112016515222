import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

// Utils
import {getFields} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import RenderChecksheet from "./RenderChecksheet.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Inline,
  Input,
  Label,
  Pill,
  StickyWrapper,
  Text,
  Title
} from "../../style/components/general.js";

const ModalChecksheetPreview = ({visible, setVisible, checksheet}) => {
  const {name, frequency, builder} = checksheet;

  const [simulatedDate, setSimulatedDate] = useState(null);

  const hasFields = getFields(builder.allIds, builder.byId).length > 0;

  return (
    <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[4]}>
      <Menu>
        <Inline>
          <ChecksheetName>
            <Abbr title={name}>{name}</Abbr>
          </ChecksheetName>
          {frequency && <Frequency>{frequency}</Frequency>}
        </Inline>

        <Inline>
          <DateInputLabel bold htmlFor="simulatedDate">
            Simulated Date
            <Input
              type="date"
              onChange={e => setSimulatedDate(e.target.value)}
              name="simulatedDate"
              defaultValue={dayjs().format("YYYY-MM-DD")}
            />
          </DateInputLabel>
        </Inline>
      </Menu>

      {hasFields ? (
        <RenderChecksheet
          task={{...checksheet, name: null, frequency: null}}
          hideMeta
          hideCompleted
          simulatedDate={simulatedDate}
        />
      ) : (
        <Text>No Fields have been added.</Text>
      )}
    </Modal>
  );
};

ModalChecksheetPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Menu = styled(StickyWrapper)`
  ${flex("row", "wrap", "space-between", "start")};
  width: 100%;
  top: 0;
  padding-top: ${pad * 2}px;
  background: ${({theme}) => theme.tertiary};
  box-shadow: 0 5px 15px ${({theme}) => theme.tertiary};
  z-index: ${z("top")};

  padding-top: ${({showPadding}) => showPadding}px;
`;

const ChecksheetName = styled(Title)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Frequency = styled(Pill)`
  ${voice.quiet};
`;

const DateInputLabel = styled(Label)`
  ${voice.quiet}
  margin-bottom: ${pad}px;
  width: min-content;
`;

export default ModalChecksheetPreview;
