import React, {useState, useEffect, useRef} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faCheck, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {iconLookup} from "../../components/form/InputFieldType.js";

// Components
import AccordionWrapped from "../../components/AccordionWrapped.js";
import Help from "../../components/Help.js";
import {InputText, InputCheck, InputColor} from "../../components/form/FormInputs.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {colors, pad, radius} from "../../style/components/variables.js";
import {Abbr, Button, Error, Inline, Label, Small, Text} from "../../style/components/general.js";

const icons = iconLookup();

const Stage = ({
  control,
  index,
  stage,
  allowUpdates,
  allowUpdateStageList,
  setTargetStage,
  setShowModalStage
}) => {
  const outside = useRef(null);

  const [edit, setEdit] = useState(false);

  const {
    watch,
    formState: {errors}
  } = useFormContext();
  const watchStages = watch("stages");

  const {remove: removeScheduled} = useFieldArray({
    control,
    name: "stages"
  });

  // Keep open
  useEffect(() => {
    if (stage.name === "") setEdit(true);
  }, [stage.name]);

  useEffect(() => {
    const handleClickOutside = e =>
      outside &&
      outside.current !== null &&
      !outside.current.contains(e.target) &&
      !outside.current.contains(e.target.nextSibling) &&
      stage.name !== "" &&
      setEdit(false);

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [outside, stage, setEdit]);

  const renderStageFields = idx => {
    const {restrictTo, builder} = watch(`stages.${idx}`);

    const roleLabel =
      restrictTo?.length > 0
        ? restrictTo
            .map(
              ({name}, roleIdx) =>
                `${name.split("-").join(" ").toUpperCase()}${
                  roleIdx !== restrictTo.length - 1 ? "," : ""
                }`
            )
            .join(" ")
        : "Unset";

    const watchNext = watch(`stages.${idx + 1}`);

    return (
      <StageContent>
        <Small>
          Available:&nbsp;
          <Abbr title={roleLabel}>{roleLabel || "All"}</Abbr>
        </Small>
        <br />
        {builder?.allIds?.length > 0 ? (
          builder.allIds.map(id => {
            const {type: fieldType, name: fieldName, label} = builder.byId[id];
            return (
              <Field key={fieldName}>
                <FontAwesomeIcon icon={icons[fieldType]} />
                &nbsp;
                <Abbr title={label}>{label}</Abbr>
              </Field>
            );
          })
        ) : (
          <Text inverted>No fields</Text>
        )}
        <br />
        {watchNext && watchNext !== null && (
          <Small>
            Triggers:&nbsp;
            <Abbr title={watchNext.name.toUpperCase()}>{watchNext.name.toUpperCase()}</Abbr>
          </Small>
        )}
      </StageContent>
    );
  };

  return (
    <Wrapper>
      {index === 0 && (
        <>
          <Status color={colors.blue}>
            <Label bold>
              <Abbr title="SCHEDULED">SCHEDULED</Abbr>
            </Label>
          </Status>
          <Arrow>
            <FontAwesomeIcon icon={faArrowDown} />
          </Arrow>
        </>
      )}

      <StageWrapper ref={outside}>
        <AccordionWrapped
          labelNode={
            edit ? (
              <TextInputWrapper>
                <InputText
                  name={`stages.${index}.name`}
                  placeholder="Stage..."
                  onClick={e => e.stopPropagation()}
                  hideError
                  invertColors
                />
              </TextInputWrapper>
            ) : (
              <Title>{stage.name.toUpperCase()}</Title>
            )
          }
          disallowOpen={!allowUpdates}
          menu={
            allowUpdates ? (
              <Inline>
                <Button
                  type="button"
                  title={stage.name || "Stage"}
                  onClick={e => {
                    e.stopPropagation();
                    if (stage.name !== "" && !watch(`stages.${index}.hasFields`))
                      setEdit(prev => !prev);
                    else {
                      setTargetStage({...stage, index});
                      setShowModalStage(prev => !prev);
                    }
                  }}>
                  <FontAwesomeIcon icon={!edit ? faEdit : faCheck} />
                </Button>
                {allowUpdateStageList && index > 0 && watchStages.length > 1 && (
                  <Button
                    type="button"
                    title="Remove"
                    onClick={e => {
                      e.stopPropagation();
                      removeScheduled(index);
                    }}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </Inline>
            ) : null
          }
          invertColors>
          {allowUpdates && (
            <>
              <CheckGroupWrapper>
                <InputCheck name={`stages.${index}.hasFields`} invertColors>
                  Define stage on Type&nbsp;
                  <Help inverted>
                    These fields will be associated with the type and cannot be adjusted on event
                    creation. This will also allow these fields to be available for bulk completion.
                  </Help>
                </InputCheck>
              </CheckGroupWrapper>

              {watch(`stages.${index}.hasFields`) && renderStageFields(index)}
            </>
          )}
        </AccordionWrapped>

        <InputColor name={`stages.${index}.color`} hidden />

        <Arrow>
          <FontAwesomeIcon icon={faArrowDown} />
        </Arrow>
      </StageWrapper>

      <ErrorWrapper>
        {errors?.stages && errors.stages[index]?.name && (
          <Error>{errors.stages[index].name.message}</Error>
        )}
      </ErrorWrapper>

      {/* Show Completed */}
      {index === watchStages.length - 1 && (
        <Status color={colors.green}>
          <Label bold>
            <Abbr title="SCHEDULED">COMPLETED</Abbr>
          </Label>
        </Status>
      )}
    </Wrapper>
  );
};

Stage.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  stage: PropTypes.objectOf(PropTypes.any).isRequired,
  allowUpdates: PropTypes.bool,
  allowUpdateStageList: PropTypes.bool,
  setTargetStage: PropTypes.func.isRequired,
  setShowModalStage: PropTypes.func.isRequired
};

Stage.defaultProps = {
  allowUpdates: true,
  allowUpdateStageList: true
};

// Style Overrides
const Wrapper = styled.div`
  margin-bottom: ${pad}px;
  width: 100%;
`;

const StageWrapper = styled.div`
  width: 100%;
  color: ${({theme}) => theme.tertiary};
`;

const Title = styled(Label)`
  font-weight: bold;
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 2}px;
  margin: ${pad / 2}px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CheckGroupWrapper = styled.div`
  padding-left: ${pad}px;

  div:first-of-type {
    margin-top: 0;
  }
`;

const TextInputWrapper = styled.div`
  margin-top: -${pad}px;
  padding: ${pad / 2}px;
  width: max-content;
`;

const ErrorWrapper = styled.div`
  margin-top: -${pad / 2}px;
  margin-bottom: ${pad}px;
`;

const Arrow = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  font-size: 30px;
  margin: 0 auto;
  padding: ${pad}px;
  width: 100%;

  ${({hidden}) =>
    hidden &&
    css`
      display: block;
      opacity: 0;
    `}
`;

const Status = styled.div`
  padding: ${pad}px;
  border-radius: ${radius};
  background: ${({color}) => color};
  overflow: hidden;

  ${Label} {
    display: block;
    width: 100%;
    color: ${({theme}) => theme.tertiary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StageContent = styled.div`
  padding: ${pad}px;
  display: flex;
  flex-direction: column;
  background: ${({color, theme}) => color || theme.secondary};
`;

const Field = styled.div`
  padding: ${pad}px 0;
  color: ${({theme}) => theme.tertiary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default Stage;
