import React, {useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Utils
import {getWithExpiry, setWithExpiry} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";

// Style
import {
  HeadingCenter,
  Button,
  Form,
  ButtonLoader,
  FormField,
  Inline
} from "../../style/components/general.js";

const TYPES = [
  "WATER",
  "WASTEWATER",
  "LIFT STATION",
  "COLLECTION SYSTEM",
  "INDUSTRIAL WASTE",
  "STORMWATER"
];

const CHECKSHEET_STATUSES = ["DUE", "INCOMPLETE", "OVERDUE"];

const ModalFacilityFilter = ({
  visible,
  setVisible,
  filters,
  setFilters,
  showArchived,
  setShowArchived,
  hasBackButton,
  goBack
}) => {
  const loading = false;

  const [archived, setArchived] = useState(showArchived);

  const form = useForm({
    defaultValues: {...filters, archived: showArchived}
  });
  const {handleSubmit, reset} = form;

  const applyFilters = ({types, checksheets}) => {
    const updated = {};

    if (types?.length > 0) updated.types = types;
    if (checksheets?.length > 0) updated.checksheets = checksheets;

    if (archived !== showArchived) {
      setWithExpiry("facilitiesDashboardFiltersDeleted", JSON.stringify(archived));
      setShowArchived(archived);
    }

    setFilters(updated);
    setWithExpiry("facilitiesDashboardFilters", JSON.stringify(updated));
    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Facility Filters</HeadingCenter>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <FormProvider {...form}>
          <FormField>
            <InputCheckGroup
              name="types"
              label="Facility Type"
              options={TYPES}
              defaultSelection={getWithExpiry("facilitiesDashboardFilters")}
            />
          </FormField>

          <FormField>
            <InputCheckGroup
              name="checksheets"
              label="Checksheet Status"
              options={CHECKSHEET_STATUSES}
              defaultSelection={getWithExpiry("facilitiesDashboardFilters")}
            />
          </FormField>

          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              Apply
              {loading && <ButtonLoader />}
            </Button>
            <Button
              type="button"
              onClick={() => {
                setFilters(null);
                reset();
              }}>
              Clear
            </Button>
            <Button type="button" onClick={() => setArchived(prev => !prev)}>
              <FontAwesomeIcon icon={archived ? faEyeSlash : faEye} />
              &nbsp;Archived
            </Button>
          </Inline>
        </FormProvider>
      </Form>
    </Modal>
  );
};

ModalFacilityFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  setShowArchived: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalFacilityFilter.defaultProps = {
  filters: null,
  hasBackButton: false,
  goBack: null
};

export default ModalFacilityFilter;
