import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

// Style
import {voice} from "../style/components/typography.js";
import {flex, z} from "../style/components/mixins.js";
import {border, pad, radius} from "../style/components/variables.js";
import {List, ListItem, Label, SearchWrapper, Button} from "../style/components/general.js";
import {bp} from "../style/components/breakpoints.js";

const Select = ({label, options, selection, setSelection, placeholder, disabled, large}) => {
  const [focus, setFocus] = useState(false);
  const [skipSet, setSkipSet] = useState(false);

  const outside = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        focus &&
        (outside?.current?.contains(e.target) || outside?.current?.nextSibling?.contains(e.target))
      )
        setSkipSet(true);
      if (focus && !optionsRef?.current?.contains(e.target)) setFocus(false);
    };

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [focus]);

  return (
    <>
      {label && <Label bold>{label.toUpperCase()}</Label>}
      <SearchWrapper
        ref={outside}
        onClick={() => {
          if (options.length > 1) {
            if (skipSet) setSkipSet(false);
            else setFocus(true);
          }
        }}>
        <TypeSelect disabled={disabled} length={options.length}>
          {selection || placeholder || "Select..."}
          {options.length > 1 && <DownIcon icon={faCaretDown} />}
        </TypeSelect>

        {focus ? (
          <Options large={large} ref={optionsRef}>
            {options?.map(result => (
              <Option
                key={`result-${result.name}`}
                onClick={() => {
                  setSelection(result);
                  setFocus(false);
                }}
                selected={selection === result.name}>
                {result.label}
              </Option>
            ))}
          </Options>
        ) : (
          ""
        )}
      </SearchWrapper>
    </>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  selection: PropTypes.string,
  setSelection: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  disabled: PropTypes.bool
};

Select.defaultProps = {
  label: null,
  selection: null,
  options: [],
  placeholder: null,
  disabled: false,
  large: false
};

// Style Overrides

const Options = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: min-content;
  min-width: ${({large}) => (large ? "210px" : "155px")};
  margin-top: ${pad}px;
  background: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  box-shadow: 0 3px 5px rgb(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: ${z("top")};
  overflow: hidden;

  ${bp(2)} {
    right: 0;
    left: unset;
  }
`;

const Option = styled(ListItem)`
  ${voice.small}
  position: relative;
  display: inline-block;
  width: 100%;
  padding: ${pad / 2}px ${pad}px;
  white-space: nowrap;
  border: ${border} solid ${({theme}) => theme.tertiary};
  text-align: center;

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.secondary};
    border: ${border} solid ${({theme}) => theme.secondary};

    ${Button} {
      color: ${({theme}) => theme.tertiary};
    }

    svg {
      fill: ${({theme}) => theme.tertiary};
    }
  }

  ${({selected}) =>
    selected &&
    css`
      color: ${({theme}) => theme.tertiary};
      background: ${({theme}) => theme.secondary};
      border: ${border} solid ${({theme}) => theme.secondary};

      ${Button} {
        color: ${({theme}) => theme.tertiary};
      }

      svg {
        fill: ${({theme}) => theme.tertiary};
      }
    `}

  ${Button} {
    width: 100%;
    text-align: left;
    padding: 0;
    background: 0;
    color: ${({theme}) => theme.secondary};
  }
`;

const TypeSelect = styled.div`
  ${voice.small};
  ${flex("row", "nowrap")}
  white-space: nowrap;
  border: 0;
  font-weight: bold;
  border-radius: ${pad * 3}px;
  background: ${({theme}) => theme.primary};
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 2}px ${pad * 3}px;
  border-right: ${pad / 2}px solid transparent;
  text-overflow: ellipsis;
  text-align: center;

  ${({disabled}) =>
    disabled &&
    css`
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: "";
    `}

  ${({length}) =>
    length > 1 &&
    css`
      appearance: none;
      text-indent: 1px;
    `}

  ${({length}) =>
    length === 1 &&
    css`
      pointer-events: none;
    `}
`;

const DownIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 7px;
  top: 5.5px;
  fill: ${({theme}) => theme.tertiary};
`;

export default Select;
