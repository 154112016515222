import React, {useContext, useState, useEffect, useCallback} from "react";
import {useParams} from "react-router";
import styled from "styled-components";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Contexts
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {useSocket} from "../contexts/socket.js";

// Components
import Room, {ROOM_JOINED} from "./general/Room.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import ChecksheetsDue from "./tasks/ChecksheetsDue.js";
import EventsDue from "./tasks/EventsDue.js";

// Style
import {pad} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {Heading, Loader, Page, Text} from "../style/components/general.js";

const Tasks = () => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {facility, setFacility} = useContext(FacilityNavContext);
  const {roleCanAccessResource} = useContext(AuthContext);

  const {slug} = useParams();

  const [active, setActive] = useState(null);
  const [canRequestLocks, setCanRequestLocks] = useState(false);

  const {api, loading} = useApi("facilities");

  const getFacility = useCallback(
    () =>
      api.callGet(slug).then(({status, data}) => {
        if (status === 200 && data) setFacility(data);
      }),
    [api, setFacility, slug]
  );

  const roomJoined = useCallback(() => {
    setCanRequestLocks(true);
  }, []);

  // Initial Load
  useEffect(() => {
    if (facility === null) {
      if (canRequestLocks) setCanRequestLocks(false);
      getFacility();
    }
  }, [facility, getFacility, canRequestLocks]);

  useEffect(() => {
    socket.on(ROOM_JOINED, roomJoined);

    return () => {
      // unbind all event handlers used in this component
      socket.off(ROOM_JOINED, roomJoined);
    };
  }, [isMounted, roomJoined, socket]);

  return (
    <Page hasMenu>
      <Room name="tasks" active={active} setActive={setActive} />

      {facility?.name && (
        <FacilityPageHeader
          facility={facility}
          reloadData={() => {
            setFacility(null);
            setCanRequestLocks(false);
          }}
          path="/tasks"
        />
      )}

      {!loading && facility?.id ? (
        <Wrapper>
          {roleCanAccessResource("facility_checksheet_record", "create") ||
          roleCanAccessResource("event_record", "create") ? (
            <>
              <EventsDue
                room={`${slug}-tasks`}
                facilityId={facility.id}
                canRequestLocks={canRequestLocks}
              />
              <ChecksheetsDue
                room={`${slug}-tasks`}
                facilityId={facility.id}
                canRequestLocks={canRequestLocks}
              />
            </>
          ) : (
            <div>
              <Heading>Not Authorized</Heading>
              <Text>Your role is not authorized to complete tasks.</Text>
            </div>
          )}
        </Wrapper>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

// Style Overrides
const Wrapper = styled.div`
  ${flex("column", "nowrap", "start")};
  gap: ${pad * 3}px;
`;

export default Tasks;
