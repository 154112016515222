import React, {useState, useEffect, useContext, useCallback, useMemo} from "react";
import {useParams} from "react-router";
import {NavLink, Navigate, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Contexts
import {AuthContext} from "../contexts/auth.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {useSocket} from "../contexts/socket.js";
import {NotificationContext} from "../contexts/notify.js";

// Components
import Room, {ROOM_JOINED} from "./general/Room.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Analytics from "./facility/Analytics.js";
import Checksheets from "./facility/Checksheets.js";
import Reports from "./facility/Reports.js";
import CustomTables from "./general/CustomTables.js";
import Contacts from "./facility/Contacts.js";
import Information from "./facility/Information.js";
import PrimaryAddress from "./facility/PrimaryAddress.js";
import TableFacilityUsers from "./facility/TableFacilityUsers.js";
import ModalEditFacility from "./facility/ModalEditFacility.js";
import ModalPreviewDetails from "./facility/ModalPreviewDetails.js";

// Style
import {bp} from "../style/components/breakpoints.js";
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {pad, border, radius, transition} from "../style/components/variables.js";
import {Page} from "../style/components/general.js";

const FacilityDashboard = () => {
  const isMounted = useMountedState();
  const {slug} = useParams();
  const {pathname} = useLocation();

  const socket = useSocket();

  const {currentUser, roleCanAccessPage, roleCanAccessResource} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);
  const {removeOptions, facility, setFacility, setAnalytics} = useContext(FacilityNavContext);

  const {api: apiFacility} = useApi(`facilities/${slug}`);

  // Facility
  const [showFacilityDetails, setShowFacilityDetails] = useState(false);
  const [showEditFacility, setShowEditFacility] = useState(false);
  const [active, setActive] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [canRequestLocks, setCanRequestLocks] = useState(false);
  const [editContacts, setEditContacts] = useState(false);

  const tabPath = useMemo(
    () => pathname?.replace(`facilities/${slug}`, "")?.replace("/", ""),
    [pathname, slug]
  );

  // Component unmount
  useEffect(
    () => () => {
      if (!isMounted()) removeOptions(["chart_builder", "stat_builder"]);
    },
    [isMounted, removeOptions]
  );

  const roomJoined = useCallback(() => setCanRequestLocks(true), []);

  useEffect(() => {
    if (isMounted()) socket.on(ROOM_JOINED, roomJoined);

    return () => {
      socket.off(ROOM_JOINED, roomJoined);
    };
  }, [isMounted, roomJoined, socket]);

  useEffect(() => {
    if (currentUser?.publicId && facility === null)
      apiFacility
        .callGet(null, {
          withChecksheets: true,
          withEvents: true
        })
        .then(({status, data}) => {
          if (!data || status === 404) setNotFound(true);
          if (status === 200 && data) {
            setFacility(data);
            getNotifications();
          }
        });
  }, [currentUser, facility, apiFacility, setFacility, getNotifications, slug]);

  if (notFound) return <Navigate to="/facilities" />;

  return (
    <Page hasMenu>
      {facility?.id && (
        <>
          <Room name="home" active={active} setActive={setActive} />

          <FacilityPageHeader
            facility={facility}
            reloadData={() => {
              setFacility(null);
              setAnalytics(null);
              setCanRequestLocks(false);
            }}
            path={tabPath}
          />

          <Dashboard>
            {(roleCanAccessResource("facility_information", "view") ||
              (roleCanAccessResource("facility_address", "view") && facility.primaryAddress)) && (
              <Left>
                {facility?.details && facility.details !== undefined && (
                  <>
                    {roleCanAccessResource("facility_information", "view") && (
                      <Information
                        details={{...facility.details, address: facility.primaryAddress}}
                        control={
                          <>
                            {roleCanAccessResource("facility_information", "update") &&
                              !facility.isDeleted && (
                                <Edit type="button" onClick={() => setShowEditFacility(true)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Edit>
                              )}
                            <Preview type="button" onClick={() => setShowFacilityDetails(true)}>
                              Preview
                            </Preview>
                          </>
                        }
                      />
                    )}
                    {roleCanAccessResource("facility_address", "view") &&
                      facility.primaryAddress && (
                        <PrimaryAddress
                          details={{...facility.details, address: facility.primaryAddress}}
                        />
                      )}
                    {roleCanAccessResource("facility_information", "view") && (
                      <Contacts
                        details={{...facility.details, address: facility.primaryAddress}}
                        control={
                          <>
                            {roleCanAccessResource("facility_information", "update") &&
                              !facility.isDeleted && (
                                <Edit
                                  type="button"
                                  onClick={() => {
                                    setEditContacts(true);
                                    setShowEditFacility(true);
                                  }}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Edit>
                              )}
                            <Preview type="button" onClick={() => setShowFacilityDetails(true)}>
                              Preview
                            </Preview>
                          </>
                        }
                      />
                    )}
                  </>
                )}
              </Left>
            )}
            <Main
              fullWidth={
                !roleCanAccessResource("facility_information", "view") &&
                !(roleCanAccessResource("facility_address", "view") && facility.primaryAddress)
              }>
              <Tabs
                count={
                  currentUser?.role.permissions.page.byId.facility_dashboard.children.filter(
                    key => currentUser?.role.permissions.page.byId[key].visible
                  ).length
                }>
                {roleCanAccessPage("analytics") && roleCanAccessResource("analytic", "view") && (
                  <TabLink to="" active={pathname.match(`^/facilities/${slug}/?$`) ? 1 : 0}>
                    <span>Overview</span>
                  </TabLink>
                )}
                {roleCanAccessPage("records") &&
                  roleCanAccessResource("facility_checksheet", "view") && (
                    <TabLink
                      to="checksheets"
                      active={pathname.match(`^/facilities/${slug}/checksheets/?$`) ? 1 : 0}>
                      <span>Records</span>
                    </TabLink>
                  )}
                {roleCanAccessPage("reports") && roleCanAccessResource("report", "view") && (
                  <TabLink
                    to="reports"
                    active={pathname.match(`^/facilities/${slug}/reports/?$`) ? 1 : 0}>
                    <span>Reports</span>
                  </TabLink>
                )}
                {roleCanAccessPage("documents") &&
                  roleCanAccessResource("custom_table", "view") && (
                    <TabLink
                      to="tables"
                      active={pathname.match(`^/facilities/${slug}/tables/?$`) ? 1 : 0}>
                      <span>Documents</span>
                    </TabLink>
                  )}
                {roleCanAccessPage("users") && roleCanAccessResource("user", "view") && (
                  <TabLink
                    to="users"
                    active={pathname.match(`^/facilities/${slug}/users/?$`) ? 1 : 0}>
                    <span>Users</span>
                  </TabLink>
                )}
              </Tabs>

              {roleCanAccessPage("analytics") &&
                roleCanAccessResource("analytic", "view") &&
                pathname === `/facilities/${slug}` && <Analytics facility={facility} />}
              {roleCanAccessPage("records") &&
                roleCanAccessResource("facility_checksheet", "view") &&
                pathname === `/facilities/${slug}/checksheets` && (
                  <Checksheets facilityId={facility.id} canRequestLocks={canRequestLocks} />
                )}
              {pathname === `/facilities/${slug}/reports` && <Reports facilityId={facility.id} />}
              {roleCanAccessPage("documents") &&
                roleCanAccessResource("custom_table", "view") &&
                pathname === `/facilities/${slug}/tables` && <CustomTables facility={facility} />}
              {roleCanAccessPage("users") &&
                roleCanAccessResource("user", "view") &&
                pathname === `/facilities/${slug}/users` && (
                  <TableFacilityUsers
                    facilityId={facility.id}
                    setRefreshFacility={() => setFacility(null)}
                  />
                )}
            </Main>

            {showFacilityDetails && (
              <ModalPreviewDetails
                visible={showFacilityDetails}
                setVisible={setShowFacilityDetails}
                facility={facility}
              />
            )}

            {showEditFacility && (
              <ModalEditFacility
                visible={showEditFacility}
                setVisible={state => {
                  if (!state) setEditContacts(false);
                  setShowEditFacility(state);
                }}
                facility={facility}
                setFacility={setFacility}
                setRefreshFacility={() => setFacility(null)}
                editContacts={editContacts}
              />
            )}
          </Dashboard>
        </>
      )}
    </Page>
  );
};

// Style Overrides
const Dashboard = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${pad}px;

  ${bp(3)} {
    display: flex;
    flex-direction: row;
  }
`;

const Left = styled.section`
  ${bp(3)} {
    width: 30%;
    padding-right: ${pad / 2}px;
  }
`;

const Main = styled.section`
  margin-bottom: ${pad}px;

  ${bp(3)} {
    width: ${({fullWidth}) => (fullWidth ? "100%" : "70%")};
    padding-left: ${pad / 2}px;
  }
`;

const TabLink = styled(NavLink)`
  ${voice.quiet};
  text-align: center;
  padding: ${pad / 2}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.secondary};
  background-color: transparent;
  transition: ${transition};

  &:hover {
    background-color: ${({theme}) => theme.secondary};
    color: ${({theme}) => theme.tertiary};
  }

  ${bp(5)} {
    min-width: 120px;
    ${voice.normal};
  }

  ${({active, theme}) =>
    active &&
    css`
      background-color: ${theme.secondary};
      color: ${theme.tertiary};
    `};

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
`;

const Tabs = styled.div`
  ${flex("row", "nowrap", "start", "center")};
  margin-bottom: ${pad}px;
  gap: ${pad / 2}px;
  width: 100%;

  ${TabLink} {
    width: ${({count}) => (count && count > 2 ? `${100 / count}%` : "110px")};
  }
`;

const Edit = styled.button`
  position: absolute;
  top: ${pad / 2}px;
  right: ${pad}px;
  width: fit-content;
  z-index: ${z("base") + 1};
  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const Preview = styled.button`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${z("base")};
`;

export default FacilityDashboard;
