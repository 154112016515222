import React, {useState, useEffect, useContext, useCallback, useMemo, useRef} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {formatSubmissions} from "../../utils/builder.js";
import {getCompletionRate, prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Abbr, Inline, Page, Pill, Text, Title} from "../../style/components/general.js";

// Socket Constants
import Room, {
  LIST_LOCKS,
  LOCK,
  RELEASE,
  REQUEST_LOCKS,
  ROOM_JOINED,
  getFacilityRooms
} from "../general/Room.js";

const LISTEN_NOTIFY_COMPLETED = "notify:completed";
const LISTEN_NOTIFY_DRAFT = "notify:draft";

const NOTIFY_COMPLETED = "notify_completed";
const NOTIFY_DRAFT = "notify_draft";

const Checksheet = () => {
  const {slug, checksheetSlug} = useParams();
  const checksheetId = checksheetSlug.replace("checksheet_", "");
  const room = `${slug}-tasks-checksheet`;

  const isMounted = useMountedState();

  const socket = useSocket();

  const {facility, setFacility, available} = useContext(FacilityNavContext);

  const {settings} = useContext(SettingsContext);

  const {currentUser} = useContext(AuthContext);

  const {api: apiFacility} = useApi("facilities");
  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiUsers} = useApi("users");

  const requestingLocks = useRef(false);

  const [canRequestLocks, setCanRequestLocks] = useState(false);
  const [locks, setLocks] = useState(null);
  const [active, setActive] = useState(null);
  const [checksheet, setChecksheet] = useState(null);
  const [canAccess, setCanAccess] = useState(false);
  const [keyHolder, setKeyHolder] = useState(null);
  const [recordDraft, setRecordDraft] = useState(null);
  const [completeLoading, setCompleteLoading] = useState(null);
  const [draftLoading, setDraftLoading] = useState(null);

  // Initial Load
  useEffect(() => {
    if (isMounted() && slug && facility === null)
      apiFacility.callGet(slug).then(({status, data}) => {
        if (status === 200) setFacility(data);
      });
  }, [isMounted, apiFacility, slug, facility, setFacility]);

  const getChecksheet = useCallback(
    () =>
      apiChecksheets.callGet(checksheetId).then(({status, data}) => {
        if (status === 200) {
          const {restrictTo} = data;
          const roleIds = restrictTo?.map(({id}) => id) || [];
          setCanAccess(!currentUser?.role?.id || roleIds?.includes(currentUser.role.id));
          setChecksheet(data);
        }
      }),
    [apiChecksheets, checksheetId, currentUser]
  );

  useEffect(() => {
    if (isMounted() && checksheetId && checksheet === null) getChecksheet();
  }, [isMounted, checksheetId, checksheet, getChecksheet, socket, slug, room, checksheetSlug]);

  const roomJoined = useCallback(() => setCanRequestLocks(true), []);

  const listLocks = useCallback(
    ({lockList, userId, fromPercentComplete, type}) => {
      // if type is null, this event should be accepted by both Events and Checksheets
      // if userId is null, then it was another event action that triggered listLocks
      // if userId is defined, it is either from request_locks or percent_complete and should be ignored if:
      //        userId is current user, and request was not from percent complete
      //        userId is not the current user, and request was from percent complete
      if (
        (!type || type === "checksheet") &&
        (!userId ||
          (userId === currentUser.publicId && !fromPercentComplete) ||
          (userId !== currentUser.publicId && fromPercentComplete))
      )
        setLocks(lockList);
    },
    [currentUser]
  );

  const notifyDraft = useCallback(
    ({resourceKey}) => {
      if (resourceKey) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "checksheet" && resourceId === `${checksheetId}`)
          apiChecksheets.callGet(resourceId).then(({status, data}) => {
            if (status === 200 && data?.recordDraft) {
              setRecordDraft(data.recordDraft);
              getChecksheet();
            }
          });
      }
    },
    [apiChecksheets, checksheetId, getChecksheet]
  );

  const notifyCompleted = useCallback(
    ({resourceKey}) => {
      if (resourceKey) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "checksheet" && resourceId === `${checksheetId}`) getChecksheet();
      }
    },
    [checksheetId, getChecksheet]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && slug && currentUser) {
      if (canRequestLocks && !locks && !requestingLocks.current) {
        requestingLocks.current = true;
        socket.emit(REQUEST_LOCKS, {
          rooms: room,
          user: currentUser.publicId,
          type: "checksheet"
        });
      }
    }

    socket.on(ROOM_JOINED, roomJoined);
    socket.on(LIST_LOCKS, listLocks);
    socket.on(LISTEN_NOTIFY_DRAFT, notifyDraft);
    socket.on(LISTEN_NOTIFY_COMPLETED, notifyCompleted);

    return () => {
      // unbind all event handlers used in this component
      socket.off(ROOM_JOINED, roomJoined);
      socket.off(LIST_LOCKS, listLocks);
      socket.off(LISTEN_NOTIFY_DRAFT, notifyDraft);
      socket.off(LISTEN_NOTIFY_COMPLETED, notifyCompleted);
    };
  }, [
    isMounted,
    socket,
    slug,
    checksheetSlug,
    currentUser,
    canRequestLocks,
    locks,
    room,
    roomJoined,
    listLocks,
    notifyDraft,
    notifyCompleted
  ]);

  // Set "Key Holder" from current locks
  useEffect(() => {
    if (isMounted() && currentUser?.publicId && !checksheet?.completed && locks) {
      if (locks && locks[checksheetSlug] && locks[checksheetSlug].user !== currentUser.publicId) {
        apiUsers.callGet(locks[checksheetSlug].user).then(({status, data}) => {
          if (status === 200) {
            const {firstName, lastName} = data;
            setKeyHolder({
              name: `${firstName} ${lastName}`,
              percentage: locks[checksheetSlug]?.percentage
            });
          }
        });
      }

      if (!locks || !locks[checksheetSlug]) {
        setKeyHolder(null);
        socket.emit(LOCK, [...getFacilityRooms(slug, "checksheet"), room], checksheetSlug);
      }
    }
  }, [isMounted, currentUser, checksheet, slug, locks, checksheetSlug, room, apiUsers, socket]);

  const saveChecksheet = (
    record,
    draftId = null,
    isDraft = false,
    outOfRange = null,
    outOfRangeMultiple = null,
    notifyFieldCondition = null
  ) => {
    const params = {
      ...record,
      draft: isDraft,
      outOfRange: outOfRange ? Object.keys(outOfRange) : null,
      outOfRangeMultiple: outOfRangeMultiple ? Object.keys(outOfRangeMultiple) : null,
      notifyFieldCondition: notifyFieldCondition ? Object.keys(notifyFieldCondition) : null
    };

    const success = draftId ? 200 : 201;
    const request = draftId
      ? apiChecksheetRecords.callPut(draftId, params)
      : apiChecksheetRecords.callPost(params);

    request.then(({status}) => {
      if (status === success) {
        socket.emit(
          RELEASE,
          [...getFacilityRooms(slug, "checksheet"), room],
          true // should release locks
        );

        socket.emit(
          isDraft ? NOTIFY_DRAFT : NOTIFY_COMPLETED,
          room,
          `checksheet_${checksheetSlug}`
        );
      }

      setCompleteLoading(null);
      setDraftLoading(null);
      getChecksheet();
    });
  };

  const buildChecksheetRecord = (
    responses,
    draftId = null,
    isDraft = false,
    outOfRange = null,
    outOfRangeMultiple = null,
    notifyFieldCondition = null
  ) => {
    const {id, name, frequency, builder} = checksheet;

    const info = {
      userId: currentUser.publicId,
      facilityChecksheetId: id
    };

    if (isDraft) setDraftLoading(id);
    else setCompleteLoading(id);

    const {completedAt, ...rest} = responses;
    const formattedResponses = formatSubmissions(rest, builder);
    const submission = {
      name,
      responses: formattedResponses
    };

    if (frequency) submission.frequency = frequency.name;

    const checksheetRecord = {...info, submission: {...submission}, completedAt};

    saveChecksheet(
      checksheetRecord,
      draftId,
      isDraft,
      outOfRange,
      outOfRangeMultiple,
      notifyFieldCondition
    );
  };

  const getCompletionRateHelper = () => {
    if (!recordDraft.submission.responses) return 0;
    const {completedAt: _ca, ...rest} = {...recordDraft.submission.responses};
    return getCompletionRate(rest);
  };

  const nextAvailable = useMemo(() => {
    if (checksheet?.dateDue) {
      const {
        dateDue,
        frequency: {interval, base}
      } = checksheet;
      const date =
        base === "days" && interval === 1
          ? "tomorrow"
          : `on ${prettyDateInUserTimezone(
              dayjs(dateDue).subtract(interval, base.replace("s", "")),
              settings.timezone,
              "MMM DD, YYYY"
            )}`;
      return date;
    }
    return "later";
  }, [checksheet, settings]);

  return (
    <Page hasMenu={available.length > 1}>
      <Room name="tasks-checksheet" active={active} setActive={setActive} />

      <Inline>
        {checksheet?.name && (
          <ChecksheetName>
            <Abbr title={checksheet.name}>{checksheet.name}</Abbr>
          </ChecksheetName>
        )}
        {checksheet?.frequency?.name && (
          <Frequency>
            <Abbr title={checksheet.frequency.name}>{checksheet.frequency.name}</Abbr>
          </Frequency>
        )}
        {recordDraft && recordDraft.submission && (
          <Draft>
            <Abbr title="INCOMPLETE">
              INCOMPLETE&nbsp;
              {getCompletionRateHelper(recordDraft.submission.responses)}%
            </Abbr>
          </Draft>
        )}
      </Inline>
      {locks && checksheet?.builder?.allIds?.length > 0 && (
        <div>
          {canAccess && !keyHolder && !checksheet?.completed ? (
            <RenderChecksheet
              room={room}
              task={checksheet}
              responses={recordDraft ? recordDraft.submission.responses : null}
              previousSubmission={checksheet.previousSubmission}
              setSubmission={(responses, outOfRange, outOfRangeMultiple, notifyFieldCondition) =>
                buildChecksheetRecord(
                  responses,
                  recordDraft ? recordDraft.id : null,
                  false,
                  outOfRange,
                  outOfRangeMultiple,
                  notifyFieldCondition
                )
              }
              setDraft={(responses, outOfRange, outOfRangeMultiple, notifyFieldCondition) =>
                buildChecksheetRecord(
                  responses,
                  recordDraft ? recordDraft.id : null,
                  true,
                  outOfRange,
                  outOfRangeMultiple,
                  notifyFieldCondition
                )
              }
              completeLoading={completeLoading === checksheet.id}
              draftLoading={draftLoading === checksheet.id}
              dateDue={checksheet.dateDue}
              startDate={checksheet.startDate ?? checksheet.dateDue}
              showDates
              user={
                recordDraft ? `${recordDraft.user.firstName} ${recordDraft.user.lastName}` : null
              }
              completedAt={recordDraft ? recordDraft.completedAt : null}
              draftDate={recordDraft ? recordDraft.createdAt : null}
              readOnly={
                !locks ||
                (locks[checksheetSlug] && locks[checksheetSlug].user !== currentUser.publicId)
              }
              preview={false}
              persistToLocalStorage
              allowDeleteFromLocalStorage
            />
          ) : (
            <Unavailable>
              <Text>
                {nextAvailable &&
                  keyHolder &&
                  `Locked by ${keyHolder.name} (Progress: ${
                    keyHolder?.percentage ? keyHolder.percentage : 0
                  }%)
                    .`}
                {!canAccess && nextAvailable
                  ? "You do not have permission to access this checksheet. If this is a mistake please contact you system administrator."
                  : `Checksheet already completed for this availablity window. Check back ${nextAvailable}.`}
              </Text>
            </Unavailable>
          )}
        </div>
      )}
    </Page>
  );
};

// Style Overrides
const ChecksheetName = styled(Title)`
  align-self: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 90%;
`;

const Draft = styled(Pill)`
  background: ${({theme}) => theme.warning};
  margin-left: ${pad}px;
`;

const Frequency = styled(Pill)`
  background: ${({theme}) => theme.primary};
`;

const Unavailable = styled.div`
  padding: ${pad}px 0;
`;

export default Checksheet;
