import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled, {css} from "styled-components";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

// Utils
import {exists, generateUniqueKey} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import FormulaReadOnly from "../../components/FormulaReadOnly.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {
  Button,
  Form,
  HeadingCenter,
  Inline,
  LabelBold,
  Small,
  Text
} from "../../style/components/general.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {cross} from "../../style/components/shapes.js";
import {FIELD} from "../../utils/builder.js";
import {getAncestryName} from "./helpers.js";

const checkMap = {
  "===": "is",
  "!==": "is not"
};

const compareMap = {
  checked: "has all checked",
  unchecked: "has all unchecked",
  one: "has one checked",
  more: "has at least one checked"
};

const ModalExternalTemplateFields = ({
  external,
  externalIdx,
  setExternalIdx,
  setAssociations,
  associations,
  byId,
  templateFields,
  templateName,
  componentNames,
  setComplete,
  setVisible
}) => {
  const [results, setResults] = useState([]);
  const [currentExtern, setCurrentExtern] = useState(Object.keys(external)[0]);
  const [allOptions, setAllOptions] = useState(null);

  const schema = yup.object().shape({
    field: yup
      .string()
      .nullable()
      .required("Please select a field to substitute external reference.")
  });

  const form = useForm({
    resolver: yupResolver(schema)
  });

  // When current external field changes (current page), update the options for replacement
  useEffect(() => {
    if (currentExtern) {
      setAllOptions(
        Object.values(byId)
          .filter(
            el =>
              // either the type of the option must match the type of the original field being
              // replaced, or the option and original field must both be either a number or generated field
              // (as these can be used interchangably)
              !componentNames.includes(el.parentName) &&
              el.element === FIELD &&
              (((external[currentExtern].type === "number" ||
                external[currentExtern].type === "generated" ||
                external[currentExtern].type === "range") &&
                (el.type === "number" || el.type === "generated" || el.type === "range")) ||
                el.type === external[currentExtern].type)
          )
          .map(el => ({
            // this field label is simply attached to carry it along, as this will become the label
            // once added to the associations
            fieldLabel: el.label,
            // this label is for the result display
            label: getAncestryName(el, {byId}),
            value: el.name
          }))
      );
    }
  }, [byId, componentNames, currentExtern, external]);

  // When external index changes, update current external field (i.e. page of replacement process)
  useEffect(() => {
    if (externalIdx < Object.keys(external).length) {
      const key = Object.keys(external)[externalIdx];
      setCurrentExtern(key);
    }
  }, [byId, componentNames, external, externalIdx]);

  const confirmSelection = ({label, fieldLabel, value}) => {
    // fieldLabel is the true label of the field, label is the display for the result button
    // that contains the ancestry
    if (currentExtern)
      setAssociations(prev => ({
        ...prev,
        [currentExtern]: {
          label: fieldLabel || label,
          value: value
        }
      }));
  };

  const search = query => {
    if (query)
      setResults(allOptions.filter(({label}) => label.toLowerCase().includes(query.toLowerCase())));
    else setResults(allOptions);
  };

  const currentIsDependency = field => {
    let matches = [];
    let usedInFormula = false;
    let usedInCondition = false;

    if (field.formula) {
      const formulaMatches = field.formula.filter(
        item => item.type === "variable" && item.value === currentExtern
      );
      if (formulaMatches.length) {
        usedInFormula = true;
        matches = [...matches, ...formulaMatches];
      }
    }

    if (field.condition?.list) {
      const conditionMatches = field.condition.list.filter(item => item.depends === currentExtern);
      if (conditionMatches.length) {
        usedInCondition = true;
        matches = [...matches, ...conditionMatches];
      }
    }
    return {match: matches.length > 0, usedInCondition, usedInFormula};
  };

  const retrieveFromTemplate = name => {
    const idx = Number.parseInt(name.replace("field", ""), 10);
    if (!exists(idx)) return null;
    const temp = templateFields[idx];
    return temp || null;
  };

  const retrieveFromExternal = name => {
    const idx = Number.parseInt(name.replace("ext", ""), 10);
    if (!exists(idx)) return null;
    const temp = external[Object.keys(external)[idx]];
    return temp || null;
  };

  const getCompareVals = (compare, i, compareList, dep, operator) => {
    let transformed = compare;
    if (dep === "date") transformed = dayjs(compare.slice(5)).format("MMM D");
    if (dep === "weekday" || dep === "date") {
      if (i < compareList.length - 2) return `${transformed}, `;
      if (i === compareList.length - 2 && compareList.length === 2) return `${transformed} or `;
      if (i === compareList.length - 2) return `${transformed}, or `;
      return transformed;
    }

    transformed =
      transformed in compareMap ? compareMap[transformed] : transformed.replace("Other: ", "");

    if (i < compareList.length - 2) return `${transformed}, `;
    if (i === compareList.length - 2 && compareList.length === 2)
      return `${transformed} ${operator} `;
    if (i === compareList.length - 2) return `${transformed}, ${operator} `;
    return transformed;
  };

  const retrieveConditionalLabel = condition => {
    if (condition.depends in associations && associations[condition.depends].label)
      return associations[condition.depends].label;
    if (condition.depends.match("^ext[0-9]*$"))
      return retrieveFromExternal(condition.depends)?.label;
    return retrieveFromTemplate(condition.depends)?.label;
  };

  return (
    <Modal visible setVisible={setVisible}>
      <HeadingCenter>Apply Template</HeadingCenter>
      <Text>
        One or more fields referenced within this template were originally located outside the
        component. These fields will need to be linked with fields in the current checksheet.
        Instances of fields that require relinking are highlighted in red below.
      </Text>
      <hr />
      {currentExtern && (
        <FormProvider {...form}>
          <Form noValidate>
            <LabelBold>
              Relink <NormalWeight>{external[currentExtern].label}</NormalWeight>
            </LabelBold>

            {!associations[currentExtern] || !associations[currentExtern].label ? (
              <FieldDropdown>
                <SearchSelect
                  results={results}
                  setResults={setResults}
                  search={search}
                  add={value => confirmSelection(value)}
                  showAll
                />
              </FieldDropdown>
            ) : (
              <AssociationTagContainer>
                <Inline>
                  <AssociationName>{associations[currentExtern].label}</AssociationName>
                  <DeleteButton
                    type="button"
                    onClick={() =>
                      setAssociations(prev => {
                        const temp = {...prev};
                        delete temp[currentExtern];
                        return temp;
                      })
                    }>
                    <IconWrapper>
                      <CloseIcon data-testid="condition.cancelDepends" />
                    </IconWrapper>
                  </DeleteButton>
                </Inline>
              </AssociationTagContainer>
            )}
            <br />
            <LabelBold>Used In</LabelBold>
            {templateFields.map((field, idx) => {
              const {match, usedInCondition, usedInFormula} = currentIsDependency(field);
              if (!match) return null;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`assoc-${externalIdx}-${idx}`}>
                  {field.type === "generated" && usedInFormula && (
                    <>
                      <Text>Formula for {field.label}</Text>
                      <FormulaWrapper>
                        <Inner>
                          <FormulaReadOnly
                            readOnlyFormula={field.formula}
                            // highlight carries the value of the field that should be highlighted in the formula
                            // as well as the replacement label if one exists
                            highlight={
                              associations[currentExtern]
                                ? {...associations[currentExtern], value: currentExtern}
                                : {value: currentExtern}
                            }
                            defaultAncestry={templateName}
                            byId={{
                              ...Object.fromEntries(
                                templateFields.map(f => [f.name, {...f, parentName: ""}])
                              ),
                              ...external
                            }}
                          />
                        </Inner>
                      </FormulaWrapper>
                    </>
                  )}
                  {field.condition?.list && usedInCondition && (
                    <>
                      <Text>Conditional for {field.label}</Text>
                      {field.condition.list
                        .filter(
                          ({depends}) =>
                            (depends && depends.match("^ext[0-9]*$")) ||
                            retrieveFromTemplate(depends)
                        )
                        .map(condition => (
                          <Wrapper key={`${generateUniqueKey(condition.depends)}`}>
                            <ConditionLabel data-testid="condition">
                              <HighlightDepends
                                highlight={
                                  condition.depends === currentExtern &&
                                  !associations[currentExtern]
                                }>
                                {retrieveConditionalLabel(condition)}
                              </HighlightDepends>
                              {condition.depends === "weekday" && "Current Day is "}
                              {condition.depends === "date" && "Current Date is "}
                              {condition.compare &&
                                Array.isArray(condition.compare) &&
                                condition.compare.map((compare, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <span key={`compare-${idx}-${i}`}>
                                    {!(compare in compareMap) &&
                                      condition.check &&
                                      condition.check[i] &&
                                      ` ${
                                        condition.check[i] in checkMap
                                          ? checkMap[condition.check[i]]
                                          : condition.check[i]
                                      }`}{" "}
                                    {getCompareVals(
                                      compare,
                                      i,
                                      condition.compare,
                                      condition.depends,
                                      condition.operator
                                    )}
                                  </span>
                                ))}
                              {condition &&
                                condition.compare &&
                                !Array.isArray(condition.compare) && (
                                  <span>
                                    {!(condition.compare in compareMap) &&
                                      condition.check &&
                                      ` ${
                                        condition.check in checkMap
                                          ? checkMap[condition.check]
                                          : condition.check
                                      }`}{" "}
                                    {getCompareVals(
                                      condition.compare,
                                      0,
                                      [condition.compare],
                                      condition.depends
                                    )}
                                  </span>
                                )}
                            </ConditionLabel>
                          </Wrapper>
                        ))}
                    </>
                  )}
                </div>
              );
            })}
            <ButtonRow>
              <Button
                type="button"
                onClick={() => {
                  setExternalIdx(prev => (prev > 0 ? prev - 1 : null));
                  setComplete(false);
                }}>
                Back
              </Button>
              <Continue
                type="button"
                onClick={() => {
                  if (externalIdx >= Object.keys(external).length - 1) setComplete(true);
                  else setExternalIdx(prev => prev + 1);
                }}
                disabled={
                  !associations[currentExtern] || !associations[currentExtern].label ? 1 : 0
                }>
                {externalIdx === Object.keys(external).length - 1 ? "Apply" : "Continue"}
              </Continue>
            </ButtonRow>
          </Form>
        </FormProvider>
      )}
    </Modal>
  );
};

ModalExternalTemplateFields.propTypes = {
  external: PropTypes.objectOf(PropTypes.any).isRequired,
  externalIdx: PropTypes.number,
  setExternalIdx: PropTypes.func.isRequired,
  setAssociations: PropTypes.func.isRequired,
  associations: PropTypes.objectOf(PropTypes.any).isRequired,
  byId: PropTypes.objectOf(PropTypes.any).isRequired,
  templateFields: PropTypes.arrayOf(PropTypes.any).isRequired,
  componentNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateName: PropTypes.string.isRequired,
  setComplete: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired
};

ModalExternalTemplateFields.defaultProps = {
  externalIdx: null
};

// Style Overrides
const FieldDropdown = styled.div`
  margin: 0;
  width: max-content;
  height: min-content;
  border-radius: ${radius};
  background-color: ${props => props.theme.primary};
  @media (min-width: ${breakpoint.width[3]}) {
    margin-bottom: ${pad}px;
  }
`;

const DeleteButton = styled(Button)`
  background-color: transparent;
  padding: ${pad / 2}px ${pad / 4}px;
`;

const CloseIcon = styled.div`
  ${props => cross("100%", props.theme.secondary)};
`;

const IconWrapper = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
`;

const AssociationTagContainer = styled.div`
  width: max-content;
  height: min-content;
  border-radius: ${radius};
  background-color: ${props => props.theme.primary};
  padding-left: ${pad / 2}px;
  margin-right: ${pad / 4}px;
`;

const AssociationName = styled(Small)`
  color: ${props => props.theme.secondary};
`;

const ConditionLabel = styled(LabelBold)`
  width: max-content;
`;

const HighlightDepends = styled.span`
  ${props =>
    props.highlight &&
    css`
      color: ${props.theme.error};
    `}
`;

const ButtonRow = styled(Inline)`
  flex-direction: row;
  justify-content: flex-end;
  gap: ${pad}px;
  width: 100%;
`;

const NormalWeight = styled.span`
  font-weight: normal;
`;

const FormulaWrapper = styled.div`
  width: 100%;
  border-radius: ${radius};
  border: ${border} solid ${props => props.theme.secondary};
  margin-bottom: ${pad}px;
  height: 64px;
`;

const Inner = styled.div`
  padding: 20px;
  overflow-x: auto;
  box-sizing: border-box;
`;

const Continue = styled(Button)`
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

const Wrapper = styled(Inline)`
  position: relative;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad * 0.8}px;
  padding-bottom: ${pad / 2}px;
  width: max-content;
  margin-bottom: ${pad}px;
`;

export default ModalExternalTemplateFields;
