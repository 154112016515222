import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";

// Components
import InputText from "./InputText.js";
import InputError from "./InputError.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {FormFieldWrapper, LabelBold} from "../../style/components/general.js";
import {flex} from "../../style/components/mixins.js";

const COLM_LIMIT = 4;
const CELL_LIMIT = 10;

const InputTable = ({testId, name, label, colCount, cellCount, required}) => {
  const {
    formState: {errors}
  } = useFormContext();

  return (
    <FormFieldWrapper data-testid={testId} standard>
      {label && (
        <LabelBold htmlFor={name}>
          {label}
          {required && <span>&nbsp;*</span>}
        </LabelBold>
      )}
      <Table>
        {Array.from(Array(colCount)).map(
          (_i, col) =>
            col < COLM_LIMIT && (
              // eslint-disable-next-line react/no-array-index-key
              <Column key={`col${col}`}>
                {Array.from(Array(cellCount + 1)).map(
                  (_j, cell) =>
                    cell < CELL_LIMIT && (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`col${col}-cell${cell}`} first={cell === 0}>
                        <InputText
                          name={`${name}.col${col}.cell${cell}`}
                          placeholder={cell === 0 ? "Column Header..." : "Cell Value..."}
                        />
                      </Cell>
                    )
                )}
              </Column>
            )
        )}
      </Table>

      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputTable.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  colCount: PropTypes.number,
  cellCount: PropTypes.number,
  required: PropTypes.bool
};

InputTable.defaultProps = {
  testId: "input-text-group",
  label: null,
  colCount: 1,
  cellCount: 1,
  required: false
};

// Style Overrides
const Table = styled.div`
  ${flex("row", "nowrap", "start", "start")};
  gap: ${pad}px;
`;

const Column = styled.div`
  position: relative;
  ${flex("column", "nowrap", "start", "start")};
`;

const Cell = styled.div`
  ${flex("column", "nowrap", "start", "start")};

  ${props =>
    props.first &&
    css`
      input {
        border: 3px solid ${props.theme.primary};
        border-radius: ${radius};
        background: ${props.theme.tertiary};
      }
    `}
`;

export default InputTable;
