import React, {useState, useEffect, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Map from "../../components/Map.js";

// Style
import {Loader} from "../../style/components/general.js";
import {bp} from "../../style/components/breakpoints.js";

const FacilityMap = ({query, filters}) => {
  const isMounted = useMountedState();

  const {currentUser} = useContext(AuthContext);

  const [allLocations, setAllLocations] = useState(null);
  const [mapQueryResult, setMapQueryResult] = useState(null);
  const [activeMarker, setActiveMarker] = useState("");

  const {api} = useApi("facilities");

  const getFacilities = useCallback(() => {
    setAllLocations(null);

    const payload = {
      userPublicId: currentUser.publicId
    };

    const filter = {};

    if (filters?.types) filter.Type = filters.types;
    if (filters?.checksheets) filter.Checksheet = filters.checksheets;

    payload.filters = JSON.stringify(filter);

    api.callGet(null, payload).then(({status, data}) => {
      if (status === 200) {
        const tempLocations = [];
        data.map(({name, addresses, type, details}) =>
          addresses.map(address => {
            const facilityDetails = [];
            if (type) facilityDetails.push({key: "Type", value: type});
            if (details?.jobs?.length > 0)
              facilityDetails.push({key: "Jobs", value: details.jobs.join(" | ")});
            const temp = {
              label: name,
              ...address,
              details: address.details
                ? [...address.details, ...facilityDetails]
                : [...facilityDetails],
              jobs: details?.jobs
            };

            tempLocations.push(temp);
          })
        );
        setAllLocations(tempLocations);
      }
    });
  }, [api, currentUser.publicId, filters]);

  useEffect(() => {
    const lower = query?.toLowerCase();
    if (lower && allLocations)
      setMapQueryResult(
        allLocations.filter(
          ({label, jobs}) =>
            label?.toLowerCase()?.includes(lower) ||
            jobs?.some(job => job?.toLowerCase()?.includes(lower))
        )
      );
    else setMapQueryResult(null);
  }, [query, allLocations]);

  // Initial Load
  useEffect(() => {
    if (isMounted()) getFacilities();
  }, [isMounted, getFacilities]);

  return (
    <MapWrapper>
      {allLocations ? (
        <Map
          locations={mapQueryResult || allLocations}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
        />
      ) : (
        <Loader />
      )}
    </MapWrapper>
  );
};

FacilityMap.propTypes = {
  query: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any)
};

FacilityMap.defaultProps = {
  query: null,
  filters: null
};

// Style Overrides
const MapWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 50vh;

  ${bp(3)} {
    height: 75vh;
  }
`;

export default FacilityMap;
