import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faExternalLink, faRedo} from "@fortawesome/free-solid-svg-icons";

// Utils
import {SettingsContext} from "../../contexts/settings.js";
import {CalendarContext} from "../../contexts/calendar.js";
import {eventColor, eventIcon} from "./helpers.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {pad, radius, colors} from "../../style/components/variables.js";
import {Button, Heading, Text} from "../../style/components/general.js";

const ModalEvents = ({
  visible,
  setVisible,
  isGlobal,
  events,
  eventTypes,
  handleClickEvent,
  handleBulkComplete,
  date,
  markerIconMap
}) => {
  const {settings} = useContext(SettingsContext);
  const {records} = useContext(CalendarContext);

  const facilities = {};
  const getSlug = {};
  events.map(event => {
    const {facility} = event;
    getSlug[facility.name] = facility.slug;
    facilities[facility.name] = facilities[facility.name] || [];
    facilities[facility.name].push(event);
  });

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      renderModalControls={
        <div>
          {handleBulkComplete && (
            <Bulk type="button" onClick={() => handleBulkComplete(events)} quiet>
              BULK COMPLETE
            </Bulk>
          )}
        </div>
      }>
      <Heading>Events - {dayjs(date).format("MMM DD, YYYY")}</Heading>
      {Object.keys(facilities).map(facility => (
        <div key={facility}>
          {isGlobal && (
            <FacilityLink to={`/facilities/${getSlug[facility]}/schedule`}>
              {facility}&nbsp;&nbsp;
              <FontAwesomeIcon icon={faExternalLink} />
            </FacilityLink>
          )}
          {facilities[facility].map(event => {
            const {id, name, slug, stages, group, draft, updatedAt, createdAt, completedAt} = event;

            const {byId} = event.facility.builder;

            const record =
              records && records[date] && id in records[date] ? records[date][id] : null;

            let stagesComplete = !stages || stages.allIds?.length === 0;
            let progress = "";

            if (record?.stage && stages?.allIds?.length) {
              const offset = record?.draft ? 0 : 1;
              const currentIndex = stages.allIds.indexOf(record?.stage) + offset;
              const numStages = stages.allIds?.length;

              stagesComplete = stages.allIds[numStages - 1] === record?.stage;

              const show = record?.stage && numStages > 1 && currentIndex < numStages;
              progress = show ? `${currentIndex + 1}/${numStages}` : "";
            }

            const targetGroup = byId && group in byId && byId[group] ? byId[group] : null;

            let groupMarker = null;

            if (targetGroup)
              groupMarker =
                Object.keys(markerIconMap)?.length > 0 &&
                targetGroup?.hasAddress &&
                targetGroup?.markerId
                  ? markerIconMap[targetGroup?.markerId]
                  : DEFAULT_MARKER;

            return (
              <Event
                key={`${slug}-${id}`}
                aria-label={`event-${id}`}
                color={eventColor(eventTypes, event)}
                disabled={!handleClickEvent}
                onClick={() => {
                  if (handleClickEvent) {
                    handleClickEvent(event, date);
                    setVisible(false);
                  }
                }}
                completed={stagesComplete ? 1 : 0}>
                {event?.type?.icon && (
                  <>
                    <Icon icon={eventIcon(eventTypes, event)} color={`#${event.type.color}`} />
                    {event.type.name.toUpperCase()}
                    &nbsp;
                  </>
                )}
                {event?.frequency?.name && (
                  <>
                    <Icon icon={faRedo} />
                    {event.frequency.name.toUpperCase()}
                    &nbsp;
                  </>
                )}
                {dayjs(date).isBefore(dayjs(), "days") && (!records || !records[date]) && (
                  <Overdue icon={faExclamationCircle} />
                )}
                {records && records[date]?.draft && <Warning icon={faExclamationCircle} />}
                {records && records[date] && stages?.allIds?.length > 0 && progress}
                {targetGroup && groupMarker && (
                  <>
                    <Icon icon={groupMarker.icon} color={`#${groupMarker.color}`} />
                    {event.facility.builder.byId[event.group].label}&nbsp;-
                  </>
                )}
                &nbsp;
                <Text inverted>
                  {name.toUpperCase()}
                  {completedAt &&
                    stagesComplete &&
                    ` - Completed ${prettyDateInUserTimezone(createdAt, settings.timezone)}`}
                  {!stagesComplete &&
                    draft &&
                    ` - Incomplete ${prettyDateInUserTimezone(
                      updatedAt || createdAt,
                      settings.timezone
                    )}`}
                </Text>
              </Event>
            );
          })}
        </div>
      ))}
    </Modal>
  );
};

ModalEvents.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClickEvent: PropTypes.func,
  handleBulkComplete: PropTypes.func,
  date: PropTypes.string.isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired
};

ModalEvents.defaultProps = {
  handleClickEvent: null,
  handleBulkComplete: null
};

// Style Overrides
const Bulk = styled(Button)`
  ${voice.quiet};
`;

const Event = styled.div`
  ${flex("row", "wrap", "start", "center")};
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: ${pad / 2}px ${pad}px;
  margin-bottom: ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({alternate}) =>
    alternate &&
    css`
      background-color: ${colors.blue};
    `}

  ${({completed}) =>
    completed &&
    css`
      opacity: 0.5;
    `}

  ${({disabled}) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({hidden}) =>
    hidden &&
    css`
      display: none;
    `}
`;

const FacilityLink = styled(Link)`
  ${voice.medium};
  font-weight: bold;
  margin: ${pad * 2}px 0 ${pad}px;

  svg {
    ${voice.normal};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin: 0 ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const Overdue = styled(Icon)`
  fill: ${({theme}) => theme.error};
`;

const Warning = styled(Icon)`
  fill: ${({theme}) => theme.warning};
`;

export default ModalEvents;
