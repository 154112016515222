import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import {
  InputText,
  InputSelect,
  InputCheck,
  InputPhoneGroup
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  HeadingCenter,
  FormGroup,
  ButtonFull,
  Form,
  FormField,
  ButtonLoader,
  Loader
} from "../../style/components/general.js";

const ModalUserEdit = ({visible, setVisible, selected, updateTable, hasBackButton, goBack}) => {
  const isMounted = useMountedState();

  const {currentUser, types, roles, departments} = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [update, setUpdate] = useState(null);

  const {api: apiUser} = useApi("users");

  const schema = yup.object().shape({
    firstName: yup.string().required("Please provide first name."),
    lastName: yup.string().required("Please provide last name."),
    email: yup.string().email("Invalid email address").required("Please provide email."),
    phones: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        number: yup
          .string()
          .nullable()
          .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
            message: "Please provide valid phone #.",
            excludeEmptyString: true
          }),
        extension: yup.string().nullable(),
        type: yup.string().required("Type is required."),
        primary: yup.bool()
      })
    ),
    department: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val => !!val,
        then: () => yup.string().nullable().required("Please provide department."),
        else: () => yup.mixed().nullable()
      }),
    userRoleId: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val => roles && roles.length > 0 && !!val,
        then: () => yup.string().nullable().required("Please provide user role."),
        else: () => yup.mixed().nullable()
      }),
    userTypeId: yup
      .mixed()
      .nullable()
      .when(["userRoleId", "isActive"], {
        is: (userRoleId, active) =>
          (!roles || roles.length === 0 || `${userRoleId}` === "-1") && active,
        then: () => yup.string().nullable().required("Please provide user type."),
        else: () => yup.mixed().nullable()
      }),
    company: yup.string().nullable(),
    isActive: yup.bool()
  });

  const form = useForm({
    defaultValues: {
      firstName: selected.firstName?.props?.children[0] || selected.firstName,
      lastName: selected.firstName?.props?.children[2] || selected.lastName,
      email: selected.email?.props?.children || selected.email,
      phones:
        selected.phones && selected.phones.length > 0
          ? selected.phones
          : [
              {
                id: "",
                number: "",
                extension: "",
                type: "mobile",
                primary: false
              }
            ],
      userRoleId: selected.isInvited ? selected.role?.id || -1 : null,
      userTypeId: selected.isInvited ? selected.type?.id : null,
      department: selected.department,
      company: selected.details && selected.details.company ? selected.details.company : null,
      isActive: (selected.isInvited && !selected.isAccepted) || selected.isActive
    },
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit} = form;

  const isActive = watch("isActive");
  const role = watch("userRoleId");

  useEffect(() => {
    if (isMounted() && types) {
      const filtered = types.filter(userType => userType.id <= currentUser.type.id);
      setUserTypes(filtered);
    }
  }, [isMounted, types, currentUser]);

  useEffect(() => {
    if (selected !== null)
      setUpdate({
        userRoleId: selected.role?.label || null,
        department: selected.department,
        type: selected.type?.name || null
      });
  }, [selected]);

  const editUser = values => {
    setLoading(true);
    const {firstName, lastName, phones} = values;

    const user = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phones: null,
      ...values
    };

    if (phones?.length > 0 && phones[0].number) user["phones"] = phones;

    apiUser.callPut(selected.publicId, user).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        updateTable();
        setLoading(false);
      }
    });
  };

  return (
    update && (
      <Modal
        visible={visible}
        setVisible={setVisible}
        maxWidth="570px"
        hasBackButton={hasBackButton}
        goBack={goBack}>
        <ModalTitle>
          Update {selected.firstName} {selected.lastName}&apos;s Account Details
        </ModalTitle>
        {departments.length > 0 && selected ? (
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(editUser)} noValidate>
              <FormGroup>
                <FormField>
                  <InputText name="firstName" label="First Name" required />
                </FormField>
                <FormField>
                  <InputText name="lastName" label="Last Name" required />
                </FormField>
                <FormField>
                  <InputText name="email" label="Email" required />
                </FormField>
                <FormField>
                  <InputPhoneGroup name="phones" label="Phone(s)" limit={3} />
                </FormField>

                <FormField>
                  <InputText name="company" label="Company" placeholder="Name..." />
                </FormField>

                <FormField>
                  <InputCheck name="isActive" label="Activate or Disable Account" active={isActive}>
                    <AccountStatus active={isActive}>
                      {isActive ? "Account Active" : "Account Inactive!"}
                    </AccountStatus>
                  </InputCheck>
                </FormField>

                {(selected.isInvited || isActive) && (
                  <>
                    <FormField>
                      <InputSelect
                        name="department"
                        label="Department"
                        options={departments.map(department => department.name)}
                      />
                    </FormField>
                    {roles?.length > 0 && (
                      <FormField>
                        <InputSelect
                          name="userRoleId"
                          label="Current Role"
                          placeholder="Role..."
                          options={[
                            {name: -1, label: "Assign Later"},
                            ...roles.map(({id, label}) => ({
                              name: id,
                              label
                            }))
                          ]}
                        />
                      </FormField>
                    )}
                    {(!roles || roles.length === 0 || `${role}` === "-1") && (
                      <FormField>
                        <InputSelect
                          name="userTypeId"
                          label={
                            `${role}` === "-1"
                              ? "User Type (must be provided when role is omitted)."
                              : "User Type"
                          }
                          placeholder="User Type..."
                          options={userTypes.map(({id, name}) => ({
                            name: id,
                            label: name.toUpperCase()
                          }))}
                        />
                      </FormField>
                    )}
                  </>
                )}
                {!(selected.isInvited || isActive) && roles?.length > 0 && (
                  <FormField>
                    <InputSelect
                      name="userRoleId"
                      label="Role"
                      placeholder="Role..."
                      options={[
                        {name: -1, label: "Assign Later"},
                        ...roles
                          .filter(({type: {name}}) => name === "guest")
                          .map(({id, label}) => ({
                            name: id,
                            label
                          }))
                      ]}
                    />
                  </FormField>
                )}
              </FormGroup>
              <ButtonFull type="submit" loading={loading ? 1 : 0}>
                Update User{loading && <ButtonLoader />}
              </ButtonFull>
            </Form>
          </FormProvider>
        ) : (
          <Loader />
        )}
      </Modal>
    )
  );
};

ModalUserEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalUserEdit.defaultProps = {
  selected: null,
  hasBackButton: false,
  goBack: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const AccountStatus = styled.span`
  ${({active, theme}) =>
    !active &&
    css`
      color: ${theme.error};
    `}
`;

export default ModalUserEdit;
