import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faFilter,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import ModalExport from "../../components/ModalExport.js";
import ModalFacilityFilter from "../facilities/ModalFacilityFilters.js";
import Badge from "../../components/Badge.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Inline,
  Text,
  TableFooter,
  TableWrapper,
  Small,
  SearchWrapper,
  Search,
  Button,
  Heading,
  TableMenu,
  SearchIcon,
  List,
  ListItem,
  Error,
  Warning,
  Abbr
} from "../../style/components/general.js";
import voice from "../../style/components/typography.js";

const ModalViewAllFacilities = ({visible, setVisible}) => {
  const isMounted = useMountedState();

  const {atLeast, roleCanAccessResource, currentUser} = useContext(AuthContext);

  const [tableData, setTableData] = useState(null);
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState();
  const [showDeleted, setShowDeleted] = useState(false);

  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);

  const filterCount = useMemo(() => {
    let count = 0;
    if (activeFilters)
      Object.keys(activeFilters).map(filter => {
        count += activeFilters[filter]?.length || 0;
      });
    return count + showDeleted ? 1 : 0;
  }, [showDeleted, activeFilters]);

  const scrollPos = useRef(null);
  const scrollContainer = useRef(null);

  const {api, loading} = useApi("facilities");

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const formatTableData = useCallback(
    facilities =>
      facilities.map(({isDeleted, id, slug, name, type, details, checksheets, events}) => ({
        isDeleted,
        id: id,
        name: (
          <TableLink to={`/facilities/${slug}`} title={name}>
            {name}
          </TableLink>
        ),
        type: <List column>{type}</List>,
        jobs: details?.jobs?.length > 0 && (
          <List column>
            {details.jobs.map(job => (
              <ListItem key={job}>{job}</ListItem>
            ))}
          </List>
        ),
        checksheets: checksheets?.map(
          ({id: cId, name: cName, frequency, draftCount, overdueCount}) => (
            <NotifyList key={cId} column>
              <TableLink to={`/facilities/${slug}/tasks`} title={cName}>
                {cName}
                {frequency?.name && <Frequency>({frequency.name})</Frequency>}
              </TableLink>
              <NotifyListItem>
                <Small>Not completed today</Small>
              </NotifyListItem>
              {draftCount > 0 && (
                <NotifyListItem>
                  <Warning>{draftCount} incomplete record(s)</Warning>
                </NotifyListItem>
              )}
              {overdueCount > 0 && (
                <NotifyListItem>
                  <Error>{overdueCount} overdue record(s)</Error>
                </NotifyListItem>
              )}
            </NotifyList>
          )
        ),
        events: events?.map(({id: eId, name: eName, available, draftCount, overdueCount}) => (
          <NotifyList key={eId} column>
            <TableLink to={`/facilities/${slug}/schedule`} title={eName}>
              {eName}
            </TableLink>
            {available && (
              <NotifyListItem>
                <Small>Not completed today</Small>
              </NotifyListItem>
            )}
            {draftCount > 0 && (
              <NotifyListItem>
                <Warning>{draftCount} incomplete record(s)</Warning>
              </NotifyListItem>
            )}
            {overdueCount > 0 && (
              <NotifyListItem>
                <Error>{overdueCount} overdue record(s)</Error>
              </NotifyListItem>
            )}
          </NotifyList>
        ))
      })),
    []
  );

  const getTableData = useCallback(
    (page, l) => {
      const filter = {};

      const payload = {
        userPublicId: currentUser.publicId,
        page: page || 1,
        orderBy: orderBy,
        groupBy: "name",
        withChecksheets: true,
        withEvents: true,
        withDeleted: showDeleted,
        limit: l ?? limit.current
      };

      if (query) filter.Search = query;
      if (activeFilters?.types) filter.Type = activeFilters.types;
      if (activeFilters?.checksheets) filter.Checksheet = activeFilters.checksheets;

      if (filter && Object.keys(filter).length > 0) payload.filter = JSON.stringify(filter);

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200) {
          const {facilities: facilityData, page: cPage, pages: cPages, total: cTotal} = data;

          const formatted = formatTableData(facilityData);

          setCurrent(cPage);
          setPages(cPages);
          setTotal(cTotal);
          setTableData(prev => (page === 1 ? formatted : [...prev, ...formatted]));
        }
      });
    },
    [api, currentUser, activeFilters, formatTableData, limit, orderBy, query, showDeleted]
  );

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.ceil((window.innerHeight * 0.9 - 200) / 50);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  useEffect(() => {
    const modal = document.getElementById("modal");

    const handleScroll = () => {
      const maxScrollTop = modal.scrollHeight - modal.clientHeight;
      if (modal.scrollTop + 1 >= maxScrollTop && current < pages && !loading)
        getTableData(current + 1);
    };

    modal.addEventListener("scroll", handleScroll);

    return () => modal.removeEventListener("scroll", handleScroll);
  }, [current, pages, getTableData, loading]);

  useLayoutEffect(() => {
    if (showModalFilters || showModalExport) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [showModalFilters, showModalExport]);

  const handleSearch = e => {
    setCurrent(1);
    setPages(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrent(1);
    setPages(1);
    getTableData(1);
  };

  if (showModal && showModalFilters) {
    return (
      <ModalFacilityFilter
        visible={showModalFilters}
        setVisible={setShowModalFilters}
        filters={activeFilters}
        setFilters={f => {
          setActiveFilters(f);
          setShowModalFilters(false);
          handleReload();
        }}
        showArchived={showDeleted}
        setShowArchived={setShowDeleted}
        hasBackButton
        goBack={() => setShowModalFilters(false)}
      />
    );
  }

  if (showModal && showModalExport)
    return (
      <ModalExport
        visible={showModalExport}
        setVisible={setShowModalExport}
        getExport={exportParams =>
          api.callGet(null, {
            userPublicId: currentUser.publicId,
            ...exportParams
          })
        }
        hasBackButton
        goBack={() => setShowModalExport(false)}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} scrollRef={scrollContainer}>
      <TableWrapper>
        <TableMenuRow>
          <StyledInline>
            <Heading>Facilities</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                <Option
                  type="button"
                  onClick={() => {
                    if (orderBy === "asc") setOrderBy("desc");
                    if (orderBy === "desc") setOrderBy("asc");
                  }}>
                  <Abbr title={orderBy === "desc" ? "Sort Z to A" : "Sort A to Z"}>
                    <FontAwesomeIcon icon={orderBy === "desc" ? faSortAlphaDown : faSortAlphaUp} />
                  </Abbr>
                </Option>
                {atLeast("admin") &&
                  (tableData?.length > 0 ||
                    (activeFilters && Object.keys(activeFilters)?.length > 0)) && (
                    <Option
                      type="button"
                      onClick={() => {
                        setShowModalFilters(!showModalFilters);
                      }}>
                      <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
                      <FontAwesomeIcon icon={faFilter} />
                    </Option>
                  )}
                {roleCanAccessResource("facility", "export") &&
                  tableData &&
                  tableData.length > 0 && (
                    <Option
                      type="button"
                      onClick={() => {
                        setShowModalExport(true);
                      }}>
                      <abbr title="Export">
                        <FontAwesomeIcon icon={faFileExport} />
                      </abbr>
                    </Option>
                  )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableMenuRow>
        <BaseTable
          headings={{
            id: {header: "", disabled: true},
            name: {header: "Name", disabled: true},
            type: {header: "Type(s)", disabled: true},
            jobs: {header: "Job Number(s)", disabled: true},
            checksheets: {header: "Checksheets", disabled: true},
            events: {header: "Events", disabled: true}
          }}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllFacilities.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

// Style Overrides

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const NotifyList = styled(List)`
  list-style: circle;
`;

const NotifyListItem = styled(ListItem)`
  padding: ${pad / 2}px 0;
  margin-left: ${pad * 1.5}px;
`;

const TableLink = styled(Link)`
  font-weight: bold;
  align-self: flex-start;
  vertical-align: top;

  &:hover {
    color: ${({theme}) => theme.primary};
    transition: all ease 0.5s;
  }
`;

const Frequency = styled.span`
  text-transform: uppercase;
  padding: 0 ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
  ${voice.quiet};
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

export default ModalViewAllFacilities;
