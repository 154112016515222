import React, {useCallback, useEffect, useRef, useState, Fragment, useMemo} from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

// Utils
import {exists} from "../utils/helpers.js";

// Components
import ChartAxis from "./chart/ChartAxis.js";
import ChartLimit from "./chart/ChartLimit.js";
import ChartPath from "./chart/ChartPath.js";
import ChartPoint from "./chart/ChartPoint.js";
import ChartShaded from "./chart/ChartShaded.js";
import ChartTooltip from "./chart/ChartTooltip.js";

// Style
import {colors, lines} from "../style/components/variables.js";

const LineChart = ({
  dataset = {},
  sources = [],
  xDomain,
  yDomain,
  ranges,
  noVals,
  width,
  height,
  labels,
  units,
  allSources,
  acceptable,
  chartId,
  tooltips,
  allowZoom,
  limits = [],
  limitColors,
  infinite,
  infiniteSources,
  active,
  setActive,
  axes,
  isScatter
}) => {
  const svgRef = useRef(null);

  const [scale, setScale] = useState(null);
  const [yAxis1, setYAxis1] = useState(null);
  const [yAxis2, setYAxis2] = useState(null);
  const [zoomState, setZoomState] = useState(null);
  const [max1, setMax1] = useState(null);
  const [max2, setMax2] = useState(null);
  const [xTicks, setXTicks] = useState(null);
  const [refreshTicks, setRefreshTicks] = useState(false);
  const [tooltipData, setTooltipData] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({});

  const validateAcceptableRange = useCallback(() => {
    if (!acceptable || !sources) return false;
    const first = acceptable[sources[0]];
    for (let i = 0; i < sources.length; i++) {
      if (
        !acceptable[sources[i]] ||
        acceptable[sources[i]][0] !== first[0] ||
        acceptable[sources[i]][1] !== first[1]
      )
        return false;
    }
    return true;
  }, [acceptable, sources]);

  const validateUnits = useCallback(() => {
    if (!units || !sources) return false;
    const first = units[sources[0]];
    for (let i = 0; i < sources.length; i++) {
      if (!units[sources[i]] || units[sources[i]] !== first) return false;
    }
    return true;
  }, [sources, units]);

  const [showRange, setShowRange] = useState(validateAcceptableRange());
  const [showUnits, setShowUnits] = useState(validateUnits());

  // need to regenerate ticks after the first initialization because xScale returns incorrect values
  const [initializing, setInitializing] = useState(0);

  const getMaxOrderOfMagnitude = source => {
    let max;

    if (axes === 2 && ranges && source) max = Math.max(...ranges[source].map(val => Math.abs(val)));
    else if (axes === 1 && yDomain) max = Math.max(...yDomain.map(val => Math.abs(val)));
    max = exists(max) ? max.toFixed(0).length : 3;
    return max;
  };

  const marginTop = 20;
  const marginRight = 30 + (axes === 2 ? getMaxOrderOfMagnitude(sources[1]) : 0) * 6;
  const marginBottom = 40;
  const marginLeft = 30 + getMaxOrderOfMagnitude(sources[0]) * 6;

  const getXTicks = useCallback(
    xScale => {
      if (dataset.length < 1 || noVals) return [new Date()];
      if (dataset.length === 1) return [new Date(dataset[0].x)];
      let start = new Date(dataset[0].x);
      start = new Date(start.setHours(0, 0, 0, 0));
      let end = new Date(dataset[dataset.length - 1].x);
      end = new Date(end.setDate(end.getDate() + 1));
      end = new Date(end.setHours(0, 0, 0, 0));
      const regionWidth = xScale(end) - xScale(start);
      const startTime = start.getTime();
      const endTime = end.getTime();
      const dayWidth = (endTime - startTime) / (1000 * 60 * 60 * 24);
      const internalTickCount = Math.min(
        Math.floor(regionWidth / (width > 300 ? 60 : 40)) - 1,
        dayWidth
      );
      const tickInterval = (endTime - startTime) / internalTickCount;
      const newTicks = [start];
      for (let i = 0; i < internalTickCount; i++) {
        newTicks.push(new Date(startTime + tickInterval * (i + 1)));
      }
      newTicks.push(end);
      return newTicks;
    },
    [dataset, width, noVals]
  );

  const formatYTick = (val, newMax, originalMax) => {
    if (val === newMax) return "∞";
    if (val > originalMax) return "";
    return val;
  };

  const xDomainAdj = useMemo(() => xDomain.map(xVal => new Date(xVal)), [xDomain]);

  const x = point => new Date(point.x);
  const y = useCallback(
    (point, source) => {
      if (source in point && !!point[source]) {
        if (point[source].infinite && exists(max1) && exists(max2)) return Math.max(max1, max2);
        if (point[source].infinite && exists(max1)) return max1;
        if (point[source].infinite && exists(max2)) return max2;
        return point[source].val;
      }
      return null;
    },
    [max1, max2]
  );

  const buildYAxis = useCallback(
    (source, right) => {
      const axisConstructor = right ? d3.axisRight : d3.axisLeft;
      const yRange = [height - marginBottom, marginTop];
      let yScale = d3
        .scaleLinear(ranges && ranges[source] ? ranges[source] : yDomain, yRange)
        .nice();
      let yAxis = axisConstructor(yScale).ticks(Math.ceil(height / 50));

      const originalDomain = yAxis?.scale()?.domain();
      let originalMax;
      if (originalDomain) [, originalMax] = originalDomain;
      else if (ranges && source in ranges) [, originalMax] = ranges[source];
      else [originalMax] = yDomain;
      let newMax = originalMax;

      let yTicks = null;

      if (
        axes === 2 &&
        sources.length === 2 &&
        infiniteSources &&
        source in infiniteSources &&
        ranges &&
        source in ranges
      ) {
        yScale = d3.scaleLinear([ranges[source][0], infiniteSources[source]], yRange).nice();
        yAxis = axisConstructor(yScale).ticks(Math.ceil(height / 50));
        const newDomain = yAxis?.scale()?.domain();
        newMax = newDomain ? newDomain[1] : originalMax;
        yTicks = yAxis?.scale()?.ticks(Math.ceil(height / 50));
        if (yTicks)
          yAxis = axisConstructor(yScale)
            .tickValues([...yTicks, newMax])
            .tickFormat(val => formatYTick(val, newMax, originalMax));
      }

      if ((axes === 1 || sources.length === 1) && exists(infinite)) {
        yScale = d3
          .scaleLinear(
            [
              // for 2-axis case when only one source selected and is infinite
              ranges &&
              infiniteSources &&
              Object.keys(infiniteSources).length > 0 &&
              ranges[Object.keys(infiniteSources)[0]]
                ? ranges[Object.keys(infiniteSources)[0]][0]
                : yDomain[0],
              infinite
            ],
            yRange
          )
          .nice();
        yAxis = axisConstructor(yScale).ticks(Math.ceil(height / 50));
        const newDomain = yAxis?.scale()?.domain();
        newMax = newDomain ? newDomain[1] : originalMax;
        yTicks = yAxis?.scale()?.ticks(Math.ceil(height / 50));
        if (yTicks)
          yAxis = axisConstructor(yScale)
            .tickValues([...yTicks, newMax])
            .tickFormat(val => formatYTick(val, newMax, originalMax));
      }

      return {yAxis, max: newMax, yScale};
    },
    [axes, height, infinite, infiniteSources, ranges, sources.length, yDomain]
  );

  useEffect(() => {
    setRefreshTicks(true);
  }, [width, dataset, zoomState]);

  useEffect(() => {
    setShowRange(validateAcceptableRange());
    setShowUnits(validateUnits());
    setMax1(null);
    setMax2(null);
  }, [sources, validateAcceptableRange, validateUnits]);

  useEffect(() => {
    const xRange = [marginLeft, width - marginRight];
    const svg = d3.select(svgRef.current);
    const xScale = d3.scaleTime(xDomainAdj, xRange);

    if (zoomState && allowZoom) {
      const newXScale = zoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    if (!zoomState) {
      const current = d3.zoomTransform(svg.node());
      setZoomState(current);
    }

    const ticks = getXTicks(xScale);

    if (!ticks || ticks.length === 0 || initializing < 2 || refreshTicks) {
      if (initializing < 2) setInitializing(prev => prev + 1);
      setRefreshTicks(false);
    }

    setXTicks(ticks);

    const {yAxis: localYAxis1, max: newMax1, yScale: yScale1} = buildYAxis(sources[0]);

    setYAxis1(() => localYAxis1);

    if (axes === 2 && sources.length === 2) {
      const {yAxis: localYAxis2, max: newMax2, yScale: yScale2} = buildYAxis(sources[1], true);
      setYAxis2(() => localYAxis2);
      if (!exists(max1) && infiniteSources && sources[0] in infiniteSources) setMax1(newMax1);
      if (!exists(max2) && infiniteSources && sources[1] in infiniteSources) setMax2(newMax2);
      setScale({x: xScale, [sources[0]]: yScale1, [sources[1]]: yScale2});
    } else {
      if (!exists(max1) && infiniteSources && Object.keys(infiniteSources).length > 0)
        setMax1(newMax1);
      setScale({x: xScale, [sources[0]]: yScale1});
    }

    const zoom = d3.zoom().on("zoom", () => {
      const current = d3.zoomTransform(svg.node());
      setZoomState(current);
    });

    svg.call(zoom);
  }, [
    allowZoom,
    axes,
    buildYAxis,
    getXTicks,
    height,
    infiniteSources,
    initializing,
    marginLeft,
    marginRight,
    max1,
    max2,
    refreshTicks,
    sources,
    width,
    xDomainAdj,
    zoomState
  ]);

  const buildAcceptableRanges = () => {
    const range = acceptable[sources[0]];
    const [minimum, maximum] = range;
    const isSingularData = ranges
      ? Object.values(ranges).every(([min, max]) => min === max)
      : yDomain[0] === yDomain[1];
    const w = Math.max(width - marginRight - marginLeft, 0);

    if (!scale) return null;

    if (isSingularData) {
      const datapoint = ranges ? ranges[sources[0]][0] : yDomain[0];
      if (!exists(datapoint)) return null;
      if (datapoint === minimum)
        return (
          <>
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="green"
            />
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={scale[sources[0]](minimum)}
              height={Math.max(height - marginBottom - scale[sources[0]](datapoint), 0)}
              width={w}
              color="red"
            />
          </>
        );
      if (datapoint === maximum)
        return (
          <>
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="red"
            />
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="green"
            />
          </>
        );

      const isAboveMin = exists(minimum) && datapoint > minimum;
      const isBelowMax = exists(maximum) && datapoint < maximum;
      const isInGreenRegion = (!exists(minimum) || isAboveMin) && (!exists(maximum) || isBelowMax);

      return (
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={marginTop}
          height={Math.max(height - marginBottom - marginTop, 0)}
          width={w}
          color={isInGreenRegion ? "green" : "red"}
        />
      );
    }

    if (exists(minimum) && exists(maximum)) {
      const maxBound = Math.max(
        Math.min(scale[sources[0]](maximum), height - marginBottom),
        marginTop
      );
      const minBound = Math.max(
        Math.min(scale[sources[0]](minimum), height - marginBottom),
        marginTop
      );

      return (
        <>
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={marginTop}
            height={Math.max(maxBound - marginTop, 0)}
            width={w}
            color="red"
          />
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={maxBound}
            height={Math.max(minBound - maxBound, 0)}
            width={w}
            color="green"
          />
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={minBound}
            height={Math.max(height - marginBottom - minBound, 0)}
            width={w}
            color="red"
          />
        </>
      );
    }

    const divider = minimum ?? maximum;
    const dividerBound = scale[sources[0]](divider);
    return (
      <>
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={marginTop}
          height={Math.max(dividerBound - marginTop, 0)}
          width={w}
          color={exists(minimum) ? "green" : "red"}
        />
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={dividerBound}
          height={Math.max(height - marginBottom - dividerBound, 0)}
          width={w}
          color={exists(minimum) ? "red" : "green"}
        />
      </>
    );
  };

  const buildInfinity = () => {
    let scaleWithMax = scale[sources[0]];
    let maxToUse = max1;
    if (exists(max2) && max2 > max1) {
      scaleWithMax = scale[sources[1]];
      maxToUse = max2;
    }

    if (!scaleWithMax || !exists(maxToUse)) return null;

    return (
      <ChartLimit
        chartId={chartId}
        color="gray"
        xValues={[marginLeft, width - marginRight]}
        yLocation={scaleWithMax(maxToUse)}
        width={width}
      />
    );
  };

  const scale1Initialized = sources && sources.length > 0 && scale && sources[0] in scale;
  const dualAxis = axes === 2 && sources && sources.length === 2;
  const scale2Initialized = dualAxis && sources.length === 2 && scale && sources[1] in scale;

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        {xTicks && scale && yAxis1 && (
          <>
            <ChartAxis
              constructor={d3
                .axisBottom(scale.x)
                .tickValues(
                  xTicks.filter(val => {
                    const xLoc = scale.x(val);
                    return xLoc > marginLeft && xLoc < width - marginRight;
                  })
                )
                .tickFormat(d3.timeFormat("%-m/%-d/%Y"))
                .tickSizeOuter(0)
                .tickPadding(5)}
              transform={`translate(0,${height - marginBottom})`}
              label="DATE"
              labelX={(width - marginLeft - marginRight) / 2 + marginLeft}
              labelY={height - 5}
            />
            <ChartAxis
              constructor={axis => {
                yAxis1(axis);
                axis.select(".domain").remove();
                axis.selectAll(".tickdup").remove();
                axis.selectAll(".tick line").clone().classed("tickdup", true);
                axis
                  .selectAll(".tickdup")
                  .attr("x2", width - marginLeft - marginRight)
                  .attr("stroke-opacity", 0.2);
              }}
              transform={`translate(${marginLeft},0)`}
              label={
                sources.length === 1 || (axes === 2 && sources.length === 2)
                  ? `${labels[sources[0]]} (${units[sources[0]].toUpperCase()})`
                  : `${
                      showUnits && Object.keys(units).length > 0
                        ? units[sources[0]].toUpperCase()
                        : "VALUE"
                    }`
              }
              labelX={
                axes === 2 && sources.length === 2
                  ? -height / 2 + 2
                  : -(height - marginBottom + marginTop) / 2
              }
              labelY={10}
              labelTransform="rotate(-90)"
            />
            {scale2Initialized && yAxis2 && (
              <ChartAxis
                constructor={axis => {
                  yAxis2(axis);
                  axis.select(".domain").remove();
                }}
                transform={`translate(${width - marginRight + 2},0)`}
                label={
                  sources.length === 1
                    ? `${labels[sources[0]]} (${units[sources[0]]})`
                    : `${
                        showUnits && Object.keys(units).length > 0
                          ? units[sources[0]].toUpperCase()
                          : "VALUE"
                      }`
                }
                labelX={height / 2 + 2}
                labelY={-width + 15}
                labelTransform="rotate(90)"
              />
            )}
            {scale1Initialized && (!dualAxis || scale2Initialized) && (
              <>
                {sources.map(source => {
                  const X = d3.map(
                    dataset.filter(d => source in d && !!d[source]),
                    x
                  );
                  const Y = d3.map(
                    dataset.filter(d => source in d && !!d[source]),
                    d => y(d, source)
                  );
                  const I = d3.range(X.length);

                  const line = d3
                    .line()
                    .curve(d3.curveBumpX)
                    .x(i => scale.x(X[i]))
                    .y(i => scale[axes === 2 && sources.length === 2 ? source : sources[0]](Y[i]));

                  const color = lines[allSources.indexOf(source) % lines.length];

                  return (
                    <Fragment key={source}>
                      {!isScatter && <ChartPath chartId={chartId} line={line(I)} color={color} />}
                      {dataset
                        .filter(d => source in d && !!d[source])
                        .map(d => (
                          <ChartPoint
                            id={`${chartId}.${source}.point.${d.x}`}
                            key={`${chartId}.${source}.point.${d.x}`}
                            chartId={chartId}
                            x={scale.x(x(d))}
                            xDisplay={x(d).toLocaleString()}
                            y={scale[axes === 2 && sources.length === 2 ? source : sources[0]](
                              y(d, source)
                            )}
                            yDisplay={`${d[source].display}`}
                            r={width - marginLeft - marginRight > 400 ? 4 : 3}
                            qualifier={d[source].qualifier}
                            missing={d[source].missing}
                            color={color}
                            source={source}
                            setActive={setActive}
                            active={active}
                            setTooltipData={setTooltipData}
                            setTooltipPosition={setTooltipPosition}
                            tooltipPosition={tooltipPosition}
                            units={units[source]}
                            label={labels[source]}
                            width={width}
                            tooltips={tooltips}
                          />
                        ))}
                    </Fragment>
                  );
                })}
                {(axes === 1 || sources.length === 1) &&
                  limits &&
                  limits.map(([label, value]) => (
                    <ChartLimit
                      key={`${chartId}-limit-${label}`}
                      chartId={chartId}
                      label={label}
                      value={value}
                      yLocation={scale[sources[0]](value)}
                      xValues={[marginLeft, width - marginRight]}
                      setTooltipData={setTooltipData}
                      setTooltipPosition={setTooltipPosition}
                      tooltipPosition={tooltipPosition}
                      tooltips={tooltips}
                      width={width}
                      units={showUnits ? units[sources[0]].toUpperCase() : ""}
                      color={limitColors[label] || colors.red}
                    />
                  ))}
                {(infiniteSources || exists(infinite)) &&
                  (exists(max1) || exists(max2)) &&
                  buildInfinity()}
                {(axes === 1 || sources.length === 1) &&
                  (sources.length === 1 || showRange) &&
                  acceptable &&
                  acceptable[sources[0]] &&
                  buildAcceptableRanges()}
              </>
            )}
            <clipPath id={`clip-${chartId}`}>
              <rect
                x={marginLeft - 6}
                y={marginTop - 10}
                width={Math.max(width - marginLeft - marginRight + 11, 0)}
                height={Math.max(height - marginTop - marginBottom + 40, 0)}
              />
            </clipPath>
            <clipPath id={`clip-sp-${chartId}`}>
              <rect
                x={marginLeft}
                y={marginTop}
                width={Math.max(width - marginLeft - marginRight, 0)}
                height={Math.max(height - marginTop - marginBottom, 0)}
              />
            </clipPath>
          </>
        )}
      </svg>
      <ChartTooltip chartId={chartId} {...tooltipPosition} {...tooltipData} />
    </>
  );
};

LineChart.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.any),
  sources: PropTypes.arrayOf(PropTypes.any),
  xDomain: PropTypes.arrayOf(PropTypes.any),
  yDomain: PropTypes.arrayOf(PropTypes.any),
  ranges: PropTypes.objectOf(PropTypes.any),
  noVals: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  labels: PropTypes.objectOf(PropTypes.any),
  units: PropTypes.objectOf(PropTypes.any),
  allSources: PropTypes.arrayOf(PropTypes.string),
  acceptable: PropTypes.objectOf(PropTypes.any),
  chartId: PropTypes.string,
  tooltips: PropTypes.bool,
  allowZoom: PropTypes.bool,
  limits: PropTypes.arrayOf(PropTypes.any),
  limitColors: PropTypes.objectOf(PropTypes.any),
  infinite: PropTypes.number,
  infiniteSources: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.objectOf(PropTypes.any),
  setActive: PropTypes.func,
  axes: PropTypes.number,
  isScatter: PropTypes.bool
};

LineChart.defaultProps = {
  dataset: [],
  sources: [],
  yDomain: [0, 100],
  xDomain: [0, 1],
  noVals: true,
  ranges: null,
  width: 200,
  height: 200,
  labels: {},
  acceptable: {},
  units: {},
  allSources: [],
  chartId: "container",
  tooltips: true,
  allowZoom: false,
  limits: [],
  limitColors: {},
  infiniteSources: null,
  infinite: null,
  active: null,
  setActive: null,
  axes: 1,
  isScatter: false
};

export default LineChart;
