import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";
import {getLocation} from "../../utils/helpers.js";
import {useToast} from "../../contexts/toast.js";

// Components
import {
  InputFileGroup,
  InputText,
  InputTextArea,
  InputCheck,
  InputSelect
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Form, FormField, Inline, Button, ButtonLoader} from "../../style/components/general.js";

const AddNote = ({setVisible, record, updateData, acknowledging, showCancel}) => {
  const {addToast} = useToast();

  const {currentUser} = useContext(AuthContext);

  const {api: apiNotes, loading} = useApi("record-notes");

  const [files, setFiles] = useState([]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(acknowledging ? "Please select a reason" : "Please provide note name")
      .test({
        test: value => !!value,
        message: acknowledging ? "Please select a reason" : "Please provide note name"
      }),
    message: yup
      .string()
      .nullable()
      .test({
        test: (value, ctx) =>
          (acknowledging && ctx.parent.name === "Hero was not implemented at this time") || !!value,
        message: acknowledging ? "Please provide a description" : "Please provide note message"
      })
  });

  const form = useForm({
    defaultValues: {
      name: "",
      message: ""
    },
    resolver: yupResolver(schema)
  });

  const {handleSubmit, watch, setValue} = form;

  const hasCoords = watch("hasCoords");
  const name = watch("name");

  const submit = values => {
    const {name: nameProvided, message, coords} = values;

    const params = {
      name: nameProvided,
      message: message || "Hero was not implemented at this time",
      userId: currentUser.publicId,
      taskRecordId: record.id,
      acknowledging,
      status: acknowledging ? "archived" : "posted"
    };

    if (coords && coords.lat !== "" && coords.lat !== "") {
      params.lat = coords.lat;
      params.lon = coords.lon;
    }

    const fileData = new FormData();

    if (files.length > 0)
      for (let i = 0; i < files.length; i++) {
        fileData.append(`file-${i}`, new File([files[i]], files[i].name, {type: files[i].type}));
      }

    apiNotes
      .callPost(fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
      .then(({status, data: resData}) => {
        if (status === 201) {
          if (record.postedNotes && params.status === "posted")
            record.postedNotes.push(resData.data);
          else if (params.status === "posted") record.postedNotes = [resData.data];
          updateData();
          setVisible(false);
        }
      });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        {acknowledging ? (
          <FormField>
            <InputSelect
              name="name"
              label="Reason"
              placeholder="Select..."
              options={[
                "Hero was not implemented at this time",
                "Manager error",
                "Employee error",
                "Other"
              ]}
            />
          </FormField>
        ) : (
          <FormField>
            <InputText name="name" label="Label" testId="addNote.name" />
          </FormField>
        )}
        {name !== "Hero was not implemented at this time" && (
          <FormField>
            <InputTextArea
              name="message"
              label={acknowledging ? "Description" : "Message"}
              testId="addNote.message"
              maxLength={1000}
            />
          </FormField>
        )}
        {!acknowledging && (
          <>
            <FormField>
              <InputCheck name="hasCoords">Add Coordinates?</InputCheck>
            </FormField>
            {hasCoords && (
              <>
                <Coords>
                  <InputText name="coords.lat" label="Latitude" />
                  <InputText name="coords.lon" label="Longitude" />
                </Coords>
                <FormField>
                  <Button
                    type="button"
                    onClick={async () => {
                      const {error, lat, lon} = await getLocation();
                      if (error) addToast(error, "error");
                      else {
                        setValue("coords.lat", lat);
                        setValue("coords.lon", lon);
                      }
                    }}>
                    Use my Location
                  </Button>
                </FormField>
              </>
            )}
          </>
        )}

        <InputFileGroup name="addNote.files" setFiles={setFiles} />

        <ButtonWrapper>
          <Button type="submit" data-testid="addNote.submit" loading={loading ? 1 : 0}>
            Save
            {loading && <ButtonLoader />}
          </Button>
          {showCancel && (
            <Button type="button" data-testid="addNote.cancel" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          )}
        </ButtonWrapper>
      </Form>
    </FormProvider>
  );
};

AddNote.propTypes = {
  setVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.func.isRequired,
  acknowledging: PropTypes.bool,
  showCancel: PropTypes.bool
};

AddNote.defaultProps = {
  acknowledging: false,
  showCancel: false
};

// Style Overrides
const Coords = styled(Inline)`
  display: flex;
  gap: ${pad}px;
`;

const ButtonWrapper = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad}px;
`;

export default AddNote;
