import {useContext, useState, useEffect, useCallback} from "react";
import {useParams} from "react-router";
import styled from "styled-components";
import PropTypes from "prop-types";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";
import {NotificationContext} from "../../contexts/notify.js";

// Style
import {navHeight, pad, shadow} from "../../style/components/variables.js";
import {circle} from "../../style/components/shapes.js";
import {voice} from "../../style/components/typography.js";
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {pulse} from "../../style/components/animations.js";
import {Button} from "../../style/components/general.js";

// Socket Constants
export const DISCONNECTED = "disconnected";
export const CONNECTED = "connected";

export const ROOM_JOIN = "join";
export const ROOM_LEAVE = "leave";
export const ROOM_JOINED = "room:joined";
export const ROOM_LEFT = "room:left";

export const REQUEST_LOCKS = "handle_list_locks";
export const LIST_LOCKS = "lock:list";
export const LOCK = "set_lock";
export const BULK_LOCK = "set_locks";
export const RELEASE = "release_lock";
export const RELEASE_LOCKS = "release_locks";

export const NOTIFY_COMPLETED = "notify_completed";
export const NOTIFY_DRAFT = "notify_draft";
export const LISTEN_NOTIFY_COMPLETED = "notify:completed";
export const LISTEN_NOTIFY_DRAFT = "notify:draft";

export const EVENT_MODIFIED = "event_modified";
export const RELOAD_EVENT = "reload_event";
export const EVENTS_ADDED = "events_added";
export const ADD_EVENTS = "add_events";
export const STAGE_COMPLETED = "event_stage_completed";
export const UPDATE_EVENT = "update_event";

export const CHECK_ON_TEST = "is_facility_on_test";
export const FACILITY_ON_TEST = "notify:facility_on_test";
export const FACILITY_OFF_TEST = "notify:facility_off_test";
export const SET_FACILITY_ON_TEST = "facility_on_test";
export const SET_FACILITY_OFF_TEST = "facility_off_test";

export const getFacilityRooms = (slug, type) => {
  if (slug)
    return type === "checksheet"
      ? [`${slug}-tasks`, `${slug}-home`]
      : [`${slug}-tasks`, `${slug}-schedule`];

  if (!slug && type === "event") return ["schedule"];

  return null;
};

const Room = ({name, active, setActive}) => {
  const isMounted = useMountedState();

  const {slug} = useParams();

  const {currentUser} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);

  const socket = useSocket();

  const [showUsers, setShowUsers] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [onTest, setOnTest] = useState(null);

  useEffect(() => {
    if (isMounted() && slug) getNotifications(slug);
  }, [isMounted, slug, getNotifications]);

  const roomJoined = useCallback(
    users => {
      if (setActive) setActive(users);
    },
    [setActive]
  );

  const roomLeft = useCallback(
    users => {
      if (setActive) setActive(users);
    },
    [setActive]
  );

  const facilityOnTest = useCallback(
    ({facility, setBy}) => {
      if (setBy && facility && facility === slug) setOnTest(setBy);
    },
    [slug]
  );

  const facilityOffTest = useCallback(
    ({facility}) => {
      if (facility === slug) setOnTest(null);
    },
    [slug]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && currentUser?.publicId) {
      const room = slug ? `${slug}-${name}` : name;
      socket.emit(ROOM_JOIN, currentUser.publicId, room);
      socket.emit(CHECK_ON_TEST, slug);
    }

    socket.on(ROOM_JOINED, roomJoined);

    socket.on(ROOM_LEFT, roomLeft);

    socket.on(FACILITY_ON_TEST, facilityOnTest);

    socket.on(FACILITY_OFF_TEST, facilityOffTest);

    return () => {
      socket.emit(ROOM_LEAVE, currentUser.publicId, `${slug}-${name}`);
      // unbind all event handlers used in this component
      socket.off(ROOM_JOINED, roomJoined);
      socket.off(ROOM_LEFT, roomLeft);
      socket.off(FACILITY_ON_TEST, facilityOnTest);
      socket.off(FACILITY_OFF_TEST, facilityOffTest);
    };
  }, [
    isMounted,
    socket,
    currentUser,
    slug,
    name,
    roomJoined,
    roomLeft,
    facilityOnTest,
    facilityOffTest
  ]);

  // (Re)Join room whenever a new tab becomes active
  useEffect(() => {
    const joinRoom = () => {
      if (isMounted() && currentUser?.publicId) {
        const room = slug ? `${slug}-${name}` : name;
        socket.emit(ROOM_JOIN, currentUser.publicId, room);
      }
    };

    if (isMounted()) window.addEventListener("focus", joinRoom);

    return () => {
      if (!isMounted()) window.removeEventListener("focus", joinRoom);
    };
  }, [isMounted, slug, name, currentUser, socket]);

  return (
    <Wrapper>
      {onTest && (
        <OnTest type="button" onClick={() => setShowUser(prev => !prev)}>
          <span>ON TEST</span>
          <br />
          {showUser && (
            <span>
              Set By: {onTest.name}&nbsp;
              {currentUser.publicId === onTest.publicId && "(you)"}
            </span>
          )}
        </OnTest>
      )}
      {active?.users?.length > 1 && (
        <ActiveUsers
          type="button"
          onClick={() => setShowUsers(prev => !prev)}
          data-testid="tasks.activeusers">
          <span>{active.users.length} Users</span>
          {showUsers && (
            <ul>
              {active.users.map(user => (
                <li key={user.publicId}>
                  {user.name}&nbsp;
                  {currentUser.publicId === user.publicId && "(you)"}
                </li>
              ))}
            </ul>
          )}
        </ActiveUsers>
      )}
    </Wrapper>
  );
};

Room.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.objectOf(PropTypes.any),
  setActive: PropTypes.func
};

Room.defaultProps = {
  active: null,
  setActive: null
};

// Style Overrides
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  margin: ${navHeight + pad}px ${pad}px 0;
  z-index: ${z("top")};

  ${bp(2)} {
    margin: ${navHeight + pad}px ${pad * 2}px 0;
  }
`;

const Banner = styled(Button)`
  ${voice.quiet};
  height: min-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  background-color: ${({theme}) => theme.primary};
  margin-left: ${pad}px;
  box-shadow: ${shadow};

  li {
    ${voice.quiet};
  }
`;

const ActiveUsers = styled(Banner)`
  background-color: ${({theme}) => theme.primary};

  ${({theme}) => circle(8, theme.tertiary)};

  &:before {
    margin: 0 auto;
  }

  span {
    margin: 0 ${pad / 4}px;
  }
`;

const OnTest = styled(Banner)`
  position: relative;
  background-color: ${({theme}) => theme.error};
  padding: ${pad / 4}px ${pad}px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.error};
    border-radius: 10px;
    z-index: -1;
    animation: ${({theme}) => pulse(theme.error)} 2s infinite;
  }
`;

export default Room;
