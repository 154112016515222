import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faRedo} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Utils
import useApi from "../../hooks/useApi";
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {CalendarContext} from "../../contexts/calendar";
import {eventIcon} from "./helpers";
import {getSnakeCase} from "../../utils/helpers";
import {DEFAULT_MARKER} from "../../utils/google/maps";
import {formatSubmissions} from "../../utils/builder";

// Components
import Modal from "../../components/Modal";
import InputSelect from "../../components/form/InputSelect";
import RenderChecksheet from "../checksheet-builder/RenderChecksheet";
import Help from "../../components/Help";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {pad} from "../../style/components/variables";
import {voice} from "../../style/components/typography";
import {
  Button,
  Form,
  FormField,
  Heading,
  List,
  Bullet,
  Text,
  SearchWrapper,
  Inline
} from "../../style/components/general";

// Sockets
import {BULK_LOCK, RELEASE_LOCKS} from "../general/Room.js";

const ModalBulkComplete = ({
  visible,
  setVisible,
  date,
  events,
  eventTypes,
  getEvents,
  markerIconMap
}) => {
  const socket = useSocket();

  const {currentUser} = useContext(AuthContext);

  const {records} = useContext(CalendarContext);

  const {api} = useApi("event-records");

  const [facilityResults, setFacilityResults] = useState([]);
  const [facilityFilter, setFacilityFilter] = useState([]);
  const [showModalStage, setShowModalStage] = useState(false);
  const [bulkCompleteLoading, setBulkCompleteLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      type: "",
      stage: ""
    },
    resolver: yupResolver(
      yup.object().shape({
        type: yup.string().required("Type is required."),
        stage: yup.string().required("Stage is required.")
      })
    )
  });
  const {setValue, watch, handleSubmit} = form;
  const watchType = watch("type");
  const watchStage = watch("stage");

  const typeMap = useMemo(() => {
    const map = {};
    events.map(({type: {id, name, stages}}) => {
      if (!(id in map)) map[id] = {id, name, stages};
    });
    return map;
  }, [events]);

  const typeOptions = useMemo(
    () =>
      Object.values(typeMap)
        .filter(({name}) => name !== "reminder")
        .map(({id, name}) => ({name: id, label: name.toUpperCase()})),
    [typeMap]
  );

  useEffect(() => {
    if (typeOptions?.length === 1) setValue("type", typeOptions[0].name);
  }, [typeOptions, setValue]);

  const stageOptions = useMemo(
    () =>
      typeMap[watchType]?.stages
        ?.filter(({hasFields}) => hasFields)
        ?.map(({name}) => ({name: getSnakeCase(name), label: name.toUpperCase()})),
    [typeMap, watchType]
  );

  useEffect(() => {
    if (stageOptions?.length === 1) setValue("stage", stageOptions[0].name);
  }, [stageOptions, setValue]);

  useEffect(() => {
    const targetFacilites = [];
    events?.map(({facility: {name}}) => {
      if (!targetFacilites.includes(name)) targetFacilites.push(name);
    });
    setFacilityResults(targetFacilites);
  }, [events]);

  const searchFacilities = query => {
    const targetFacilites = [];
    events?.map(({facility: {name}}) => {
      if (!targetFacilites.includes(name)) targetFacilites.push(name);
    });
    if (query) {
      setFacilityResults(
        targetFacilites
          ? targetFacilites.filter(f => f.name.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    } else setFacilityResults(targetFacilites);
  };

  const targetEvents = useMemo(
    () =>
      watchStage &&
      events.filter(({id, stages, facility}) => {
        if (!stages) return false;
        if (facilityFilter?.length > 0 && !facilityFilter.includes(facility.name)) return false;
        const {allIds, byId} = stages;
        const record = records && records[date] && id in records[date] ? records[date][id] : null;
        const targetStage = byId[record?.stage]?.next || allIds[0];
        return watchStage === targetStage;
      }),
    [watchStage, events, records, date, facilityFilter]
  );

  const currentStage = useMemo(
    () => typeMap[watchType]?.stages?.filter(({name}) => getSnakeCase(name) === watchStage)[0],
    [typeMap, watchType, watchStage]
  );

  const bulkComplete = ({completedAt, ...responses}) => {
    setBulkCompleteLoading(true);
    const submission = {
      responses: formatSubmissions(responses, currentStage.builder)
    };
    api
      .callPatch(null, {
        eventIds: targetEvents.map(({id}) => id),
        date,
        stage: watchStage,
        bulkComplete: {submission, completedAt}
      })
      .then(({status}) => {
        if (status === 200) {
          setBulkCompleteLoading(false);
          setVisible(false);
          setShowModalStage(false);
          getEvents();
          socket.emit(RELEASE_LOCKS, currentUser.publicId);
        }
      });
  };

  if (showModalStage && currentStage)
    return (
      <Modal
        visible={visible}
        setVisible={setVisible}
        goBack={() => {
          setShowModalStage(false);
          setBulkCompleteLoading(false);
        }}
        hasBackButton>
        <RenderChecksheet
          task={{...targetEvents[0], builder: currentStage.builder}}
          stage={watchStage}
          dateDue={date}
          setSubmission={submission => bulkComplete(submission)}
          completeLoading={bulkCompleteLoading}
          cancelFunction={() => {
            socket.emit(RELEASE_LOCKS, currentUser.publicId);
            setBulkCompleteLoading(false);
            setShowModalStage(false);
          }}
        />
      </Modal>
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>Bulk Complete events on {dayjs(date).format("MMM D YYYY")}</Heading>
      <FormProvider {...form}>
        <Form noValidate>
          <FormField>
            {typeOptions?.length > 0 ? (
              <InputSelect name="type" placeholder="Type..." options={typeOptions} />
            ) : (
              <Text>No event types are eligible for bulk completion on this date.</Text>
            )}
          </FormField>
          {watchType && (
            <FormField>
              {stageOptions?.length > 0 ? (
                <InputSelect name="stage" placeholder="Stage..." options={stageOptions} />
              ) : (
                <Text>No event stages are eligible for bulk completion on this date.</Text>
              )}
            </FormField>
          )}
          {watchStage && (
            <FormField>
              <Text>
                Target events for completion&nbsp;
                <Help>Bulk completion is only available for stages defined at the type level.</Help>
              </Text>
              <SearchWrapper>
                <SearchSelect
                  placeholder="Filter..."
                  results={facilityResults}
                  setResults={setFacilityResults}
                  search={query => searchFacilities(query)}
                  add={value => {
                    if (!facilityFilter) setFacilityFilter([value]);
                    else if (!facilityFilter.some(name => name === value))
                      setFacilityFilter([...facilityFilter, value]);
                  }}
                  showAll
                />
              </SearchWrapper>
              {facilityFilter?.length > 0 && (
                <Selected>
                  {facilityFilter?.map(name => (
                    <Button
                      key={name}
                      type="button"
                      title="Remove Group"
                      onClick={() =>
                        setFacilityFilter(facilityFilter.filter(curr => curr !== name))
                      }>
                      <span>{name}</span>
                      &nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Selected>
              )}
              <List column>
                {targetEvents?.length > 0 ? (
                  targetEvents?.map(event => {
                    const {
                      id,
                      facility: {
                        name: facilityName,
                        builder: {byId}
                      },
                      type,
                      frequency,
                      name,
                      group
                    } = event;

                    let groupMarker = null;

                    const targetGroup = byId && group in byId && byId[group] ? byId[group] : null;
                    if (targetGroup)
                      groupMarker =
                        Object.keys(markerIconMap)?.length > 0 &&
                        targetGroup?.hasAddress &&
                        targetGroup?.markerId
                          ? markerIconMap[targetGroup?.markerId]
                          : DEFAULT_MARKER;

                    return (
                      <Event key={id}>
                        {facilityName.toUpperCase()}
                        {type?.icon && (
                          <>
                            <Icon icon={eventIcon(eventTypes, event)} color={`#${type.color}`} />
                            {type.name.toUpperCase()}
                          </>
                        )}
                        {frequency?.name && (
                          <>
                            <Icon icon={faRedo} />
                            {frequency.name.toUpperCase()}
                          </>
                        )}
                        &nbsp;
                        {name}
                        {targetGroup && groupMarker && (
                          <>
                            <Icon icon={groupMarker.icon} color={`#${groupMarker.color}`} />
                            {byId[group].label}
                          </>
                        )}
                      </Event>
                    );
                  })
                ) : (
                  <None>No events available for completion at selected stage.</None>
                )}
              </List>
            </FormField>
          )}
          <Button
            type="submit"
            onClick={handleSubmit(() => {
              setShowModalStage(true);
              const eventKeys = events.map(({id}) => `event_${id}_${date}`);
              socket.emit(BULK_LOCK, eventKeys);
            })}
            disabled={targetEvents?.length === 0}>
            Complete{currentStage?.name ? `: ${currentStage.name.toUpperCase()}` : ""}
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalBulkComplete.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  getEvents: PropTypes.func.isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Event = styled(Bullet)`
  padding: ${pad / 2}px 0;
  margin-top: ${pad / 2}px;
  width: fit-content;
  max-width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin: 0 ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.secondary};
`;

const None = styled(Text)`
  padding-top: ${pad}px;
`;

const Selected = styled(Inline)`
  margin-top: ${pad}px;

  ${Button} {
    ${voice.quiet};
  }
`;

export default ModalBulkComplete;
