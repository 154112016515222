import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faEye,
  faEyeSlash,
  faKeyboard,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import {isMobileSize} from "../../utils/responsive.js";
import usePrevious from "../../hooks/usePrevious.js";
import {openLink, prettyDateInUserTimezone} from "../../utils/helpers.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {useToast} from "../../contexts/toast.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import ModalRowPreview from "./ModalRowPreview.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  Button,
  Heading,
  Abbr,
  Inline,
  SearchWrapper,
  Search,
  SearchIcon,
  TableMenu,
  TableWrapper,
  TableFooter,
  Text
} from "../../style/components/general.js";
import {bp} from "../../style/components/breakpoints.js";

const ModalViewAllRows = ({visible, setVisible, target, inGlobalTables}) => {
  const {id: tableId, label, columns, facility} = target || {};

  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {currentUser, roleCanAccessResource, atLeast} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  const [showModalPreview, setShowModalPreview] = useState(false);
  const [targetRow, setTargetRow] = useState(null);
  const [searchType, setSearchType] = useState("text");
  const [tableData, setTableData] = useState(null);
  const [headings, setHeadings] = useState({});
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);

  const showDeleted = useRef(false);

  const {api, loading} = useApi("custom-table-rows", {suppress: {longRequest: false}});
  const {api: apiFiles} = useApi("files", {suppress: {longRequest: false}});

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const openFile = fileId => {
    apiFiles.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
    });
  };

  const formatTableData = useCallback(
    payload =>
      payload.map(item => {
        const newItem = {};

        const isSuper = !currentUser.role?.id && atLeast("super");
        const canAccess =
          isSuper ||
          !item.roles?.length ||
          item.roles.some(role => currentUser.role?.id === role.id);

        Object.keys(headings).forEach(heading => {
          if (heading === "preview" && roleCanAccessResource("custom_table_row", "view")) {
            if (!canAccess)
              newItem[heading] = (
                <NotAllowed
                  onClick={() => {
                    addToast("You do not have permission to view this row", "error");
                  }}>
                  <Abbr title="You do not have permission to view this row">View</Abbr>
                </NotAllowed>
              );
            else
              newItem[heading] = (
                <Button
                  onClick={() => {
                    setTargetRow(item);
                    setShowModalPreview(true);
                  }}>
                  View
                </Button>
              );
          } else if (heading === "createdAt" || heading === "updatedAt")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone);
          else if (heading === "date")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone, "MMM D YYYY");
          else newItem[heading] = item[heading];
          newItem.message = item.message;
          newItem.files = item.files;
          newItem.id = item.id;
          newItem.tags = item.tags;
          newItem.isDeleted = item.isDeleted;
          newItem.roles = item.roles;
          newItem.canAccess = canAccess;
        });
        return newItem;
      }),
    [headings, roleCanAccessResource, timezone, currentUser, atLeast, addToast]
  );

  const getTableData = useCallback(
    (page, l) => {
      setTargetRow(null);

      const filter = {showDeleted: showDeleted.current};

      if (query) filter.Search = query;

      const payload = {
        page: page || 1,
        orderBy: orderBy,
        groupBy,
        limit: l ?? limit.current,
        filter: JSON.stringify(filter)
      };

      if (tableId) payload.tableId = tableId;

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data) {
          const formatted = data.notes ? formatTableData(data.notes) : [];

          // Page Limit
          const {total: dataTotal, page: tPage, pages: tPages} = data;

          setCurrent(tPage);
          setPages(tPages);
          setTableData(prev => (page === 1 ? formatted : [...prev, ...formatted]));
          setTotal(dataTotal);
        }
      });
    },
    [api, formatTableData, tableId, query, orderBy, groupBy]
  );

  useEffect(() => {
    if (isMounted()) {
      if (columns) {
        const tempHeaders = {};
        columns.map(col => {
          if (col.name === "preview" || col.name === "updatedBy")
            tempHeaders[col.name] = {header: col.label, disabled: true};
          else tempHeaders[col.name] = {header: col.label, disabled: false};
        });
        setHeadings(tempHeaders);
      } else {
        setHeadings({
          preview: {header: "Preview", disabled: true},
          facilityName: {header: "Facility", disabled: false},
          name: {header: "Label", disabled: false},
          createdBy: {header: "Created By", disabled: false},
          createdAt: {header: "Created At", disabled: false}
        });
      }
    }
  }, [isMounted, api, columns]);

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.ceil((window.innerHeight * 0.9 - 200) / 50);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  useEffect(() => {
    const modal = document.getElementById("modal");

    const handleScroll = () => {
      const maxScrollTop = modal.scrollHeight - modal.clientHeight;
      if (modal.scrollTop + 1 >= maxScrollTop && current < pages && !loading)
        getTableData(current + 1);
    };

    modal.addEventListener("scroll", handleScroll);

    return () => modal.removeEventListener("scroll", handleScroll);
  }, [current, pages, getTableData, loading]);

  const handleSearch = e => {
    setCurrent(1);
    setPages(1);
    setQuery(e.target.value);
  };

  if (showModalPreview && targetRow)
    return (
      <ModalRowPreview
        visible={showModalPreview}
        setVisible={setShowModalPreview}
        openFile={openFile}
        selected={targetRow}
        setSelected={setTargetRow}
        hasBackButton
        goBack={() => {
          setShowModalPreview(false);
          setTargetRow(null);
        }}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <TableWrapper>
        {label && (
          <TableMenuRow>
            <TableHeading>
              {inGlobalTables && facility?.name && `${facility.name.toUpperCase()} `}
              {label}
            </TableHeading>
          </TableMenuRow>
        )}
        <TableMenuRow>
          <HeaderMenu>
            <MenuItems>
              <SearchWrapper>
                {searchType === "text" && (
                  <SearchIcon>
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchIcon>
                )}
                <Search
                  name="search"
                  type={searchType}
                  placeholder="Search..."
                  onChange={handleSearch}
                />
                <Spaced>
                  <ToggleSwitch
                    iconLeft={faKeyboard}
                    iconRight={faCalendarDay}
                    textLeft="Text"
                    textRight="Date"
                    onChange={() => setSearchType(prev => (prev === "text" ? "date" : "text"))}
                    options={["text", "date"]}
                    defaultSelected="text"
                  />
                </Spaced>
              </SearchWrapper>
              <TableMenu>
                {(roleCanAccessResource("custom_table_row", "update") ||
                  roleCanAccessResource("custom_table_row", "archive")) &&
                  !isMobileSize() && (
                    <Option
                      type="button"
                      title={showDeleted.current ? "Show Archived" : "Hide Archived"}
                      onClick={() => {
                        showDeleted.current = !showDeleted.current;
                        getTableData(1);
                      }}>
                      <FontAwesomeIcon icon={showDeleted.current ? faEyeSlash : faEye} />
                      &nbsp;Archived
                    </Option>
                  )}
              </TableMenu>
            </MenuItems>
          </HeaderMenu>
        </TableMenuRow>
        <BaseTable
          headings={headings}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllRows.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any),
  inGlobalTables: PropTypes.bool
};

ModalViewAllRows.defaultProps = {
  target: null,
  inGlobalTables: false
};

// Style Overrides

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const TableHeading = styled(Heading)`
  white-space: nowrap;
  display: inline-flex;
`;

const NotAllowed = styled(Button)`
  cursor: not-allowed;
`;

const HeaderMenu = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(2)} {
    display: flex;
  }
`;

const MenuItems = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const Spaced = styled.div`
  margin-left: ${pad}px;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

export default ModalViewAllRows;
