import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {Button, Small} from "../../style/components/general.js";

const ModalRedirect = ({visible, setVisible, buttonOptions}) => (
  <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[1]}>
    <Options>
      {buttonOptions.map(({action, description, icon}) => (
        <Option
          key={description}
          onClick={() => {
            action();
            setVisible(false);
          }}>
          <OptionLabel>
            <FontAwesomeIcon icon={icon} />
            <Small>{description}</Small>
          </OptionLabel>
        </Option>
      ))}
    </Options>
  </Modal>
);

ModalRedirect.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  buttonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.any
    })
  ).isRequired
};

// Style Overrides
const Options = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pad}px;
`;

const Option = styled.div`
  align-self: stretch;
  width: 50%;
`;

const OptionLabel = styled(Button)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  width: 100%;
  height: 100%;
  padding: ${pad}px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.tertiary};
  background-color: ${props => props.theme.secondary};

  svg {
    fill: ${props => props.theme.tertiary};
    margin-bottom: ${pad}px;
  }
`;

export default ModalRedirect;
