import React, {useState, useContext, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faEdit, faPlus, faArchive} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {useToast} from "../../contexts/toast.js";
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import ModalEventManage from "./ModalEventManage.js";
import ModalEventDetail from "./ModalEventDetail.js";
import ModalEventAddition from "./ModalEventAddition.js";
import ModalEventDelete from "./ModalEventDelete.js";
import ModalEventArchive from "./ModalEventArchive.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {ModalButton, Button} from "../../style/components/general.js";

// Socket Constants
import {getFacilityRooms, LIST_LOCKS, REQUEST_LOCKS} from "../general/Room.js";

const ModalEvent = ({
  visible,
  setVisible,
  isGlobal,
  isEditing,
  date,
  facility,
  event,
  eventTypes,
  editEvent,
  cancelEdit,
  handleBulkComplete,
  restoreEvent,
  showEventForm,
  getEvents,
  frequencies,
  units,
  setUnits,
  markerIconMap
}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const {addToast} = useToast();

  const [locks, setLocks] = useState(null);
  const [keyHolder, setKeyHolder] = useState(null);
  const [completing, setCompleting] = useState(false);
  // Modals
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalAddition, setShowModalAddition] = useState(false);

  const {api: apiUsers} = useApi("users");

  const listLocks = useCallback(
    ({lockList, userId}) => {
      // if userId is null, then it was another event that triggered listLocks
      // if userId is defined, it is from request_locks and should be ignored unless the current user sent the request
      if (!userId || userId === currentUser.publicId) setLocks(lockList || {});
    },
    [currentUser.publicId]
  );

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && currentUser && locks === null) {
      socket.emit(REQUEST_LOCKS, {
        rooms: ["schedule", ...getFacilityRooms(facility?.slug, "event")],
        user: currentUser.publicId,
        type: "event"
      });
    }

    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [isMounted, socket, currentUser, facility, listLocks, locks]);

  // Set "Key Holder" from current locks
  useEffect(() => {
    if (isMounted() && event?.id && locks) {
      const key = `event_${event.id}_${date}`;
      if (locks[key] && locks[key].user !== currentUser.publicId)
        apiUsers.callGet(locks[key].user).then(({status, data}) => {
          if (status === 200) {
            const {firstName, lastName} = data;
            setKeyHolder(`${firstName} ${lastName}`);
          }
        });
      else setKeyHolder("");
    }
  }, [isMounted, apiUsers, event, date, locks, currentUser, setKeyHolder]);

  if (showModalAddition)
    return (
      <ModalEventAddition
        visible={showModalAddition}
        setVisible={setVisible}
        event={event}
        date={date}
        reload={getEvents}
        goBack={() => setShowModalAddition(false)}
        hasBackButton
      />
    );

  if (showModalDelete)
    return (
      <ModalEventDelete
        visible={showModalDelete}
        setVisible={setVisible}
        event={event}
        getEvents={getEvents}
        goBack={() => setShowModalDelete(false)}
        hasBackButton
      />
    );

  if (showModalArchive)
    return (
      <ModalEventArchive
        visible={showModalArchive}
        setVisible={setVisible}
        event={event}
        getEvents={getEvents}
        goBack={() => setShowModalArchive(false)}
        hasBackButton
      />
    );

  const renderModalControls = () => {
    if (isEditing && showEventForm)
      return (
        <EventControls>
          <ModalButton onClick={cancelEdit} type="button">
            Cancel
          </ModalButton>
        </EventControls>
      );
    if (!event || completing || showEventForm) return null;
    return (
      <EventControls>
        {isGlobal && facility?.slug && (
          <PageLink to={`/facilities/${facility.slug}/schedule`}>VIEW ON FACILITY</PageLink>
        )}
        {handleBulkComplete &&
          roleCanAccessResource("event", "create") &&
          !showEventForm &&
          !facility?.isDeleted &&
          !event.isArchived &&
          !event?.message && (
            <Addition type="button" onClick={() => handleBulkComplete()} quiet>
              BULK COMPLETE
            </Addition>
          )}
        {roleCanAccessResource("event", "create") &&
          !showEventForm &&
          !facility?.isDeleted &&
          !event.isArchived &&
          !event?.message && (
            <Addition type="button" onClick={() => setShowModalAddition(true)} quiet>
              <FontAwesomeIcon icon={faPlus} /> {event.type.name.toUpperCase()} DATE
            </Addition>
          )}
        {roleCanAccessResource("event", "update") &&
          !isGlobal &&
          !facility?.isDeleted &&
          !event.isArchived && (
            <LoadingModalButton
              data-testid="event.editButton"
              loading={!locks ? 1 : 0}
              onClick={() => {
                if (keyHolder) addToast(`Locked by ${keyHolder}`);
                else if (event.addition?.map(({date: d}) => d).includes(date))
                  setShowModalAddition(true);
                else editEvent();
              }}
              title="Edit Event">
              <FontAwesomeIcon icon={faEdit} inverse />
            </LoadingModalButton>
          )}
        {roleCanAccessResource("event", "archive") &&
          !isGlobal &&
          !facility?.isDeleted &&
          !event.addition?.includes(event.dateDue) && (
            <>
              {event.isArchived && (
                <ModalButton
                  loading={!locks ? 1 : 0}
                  onClick={() => restoreEvent()}
                  title="Restore Event">
                  Restore
                </ModalButton>
              )}
              {!event.isArchived && (
                <LoadingModalButton
                  data-testid="event.archiveButton"
                  loading={!locks ? 1 : 0}
                  onClick={() => {
                    if (keyHolder) addToast(`Locked by ${keyHolder}`);
                    else setShowModalArchive(true);
                  }}
                  title="Archive Event">
                  <FontAwesomeIcon icon={faArchive} inverse />
                </LoadingModalButton>
              )}
            </>
          )}
        {roleCanAccessResource("event", "delete") && !isGlobal && !facility?.isDeleted && (
          <LoadingModalButton
            data-testid="event.deleteButton"
            loading={!locks ? 1 : 0}
            onClick={() => {
              if (keyHolder) addToast(`Locked by ${keyHolder}`);
              else setShowModalDelete(true);
            }}
            title="Delete Event">
            <FontAwesomeIcon icon={faTrash} inverse />
          </LoadingModalButton>
        )}
      </EventControls>
    );
  };

  if (locks !== null)
    return showEventForm ? (
      <ModalEventManage
        visible={visible}
        setVisible={setVisible}
        renderModalControls={renderModalControls}
        isGlobal={isGlobal}
        isEditing={isEditing}
        facility={facility}
        date={date}
        event={event}
        eventTypes={eventTypes}
        getEvents={getEvents}
        frequencies={frequencies}
        units={units}
        setUnits={setUnits}
      />
    ) : (
      <ModalEventDetail
        visible={visible}
        setVisible={setVisible}
        isGlobal={isGlobal}
        event={event}
        eventTypes={eventTypes}
        keyHolder={keyHolder}
        completing={completing}
        setCompleting={setCompleting}
        date={date}
        markerIconMap={markerIconMap}
        renderModalControls={renderModalControls}
      />
    );

  return null;
};

ModalEvent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  facility: PropTypes.objectOf(PropTypes.any),
  event: PropTypes.objectOf(PropTypes.any),
  date: PropTypes.string.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any),
  editEvent: PropTypes.func,
  restoreEvent: PropTypes.func,
  cancelEdit: PropTypes.func,
  handleBulkComplete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isEditing: PropTypes.bool,
  showEventForm: PropTypes.bool,
  getEvents: PropTypes.func
};

ModalEvent.defaultProps = {
  facility: null,
  event: null,
  eventTypes: [],
  editEvent: null,
  restoreEvent: null,
  cancelEdit: null,
  handleBulkComplete: null,
  getEvents: null,
  isEditing: false,
  showEventForm: false
};

// Style Overrides
const EventControls = styled.div`
  display: flex;
  margin-right: ${pad}px;
`;

const PageLink = styled(Link)`
  ${voice.quiet};
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 2}px;
  border-radius: ${radius};
  background: ${({theme}) => theme.secondary};
`;

const Addition = styled(Button)`
  ${voice.quiet};
  padding: ${pad / 2}px;
  margin-left: ${pad}px;
`;

const LoadingModalButton = styled(ModalButton)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

export default ModalEvent;
