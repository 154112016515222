import React, {useContext, useEffect, useMemo, useState} from "react";
import {NavLink, useLocation, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled, {css} from "styled-components";
import {
  faHome,
  faClipboardCheck,
  faMapMarked,
  faScrewdriverWrench,
  faHouseCircleExclamation,
  faChartLine,
  faPercent,
  faCalendar,
  faCog,
  faChevronLeft,
  faChevronRight,
  faClipboardList,
  faExclamation
} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {AuthContext} from "../../contexts/auth.js";
import {NotificationContext} from "../../contexts/notify.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getSnakeCase, setWithExpiry, slugify} from "../../utils/helpers.js";
import {isSmallMobile} from "../../utils/responsive.js";

// Components
import ModalMap from "./ModalMap.js";
import ModalOnTest from "./ModalOnTest.js";
import ModalChartBuilder from "./ModalChartBuilder.js";
import ModalStatBuilder from "./ModalStatBuilder.js";
import Badge from "../../components/Badge.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {
  border,
  colors,
  navHeight,
  navWidth,
  pad,
  radius,
  shadowRight,
  transition
} from "../../style/components/variables.js";
import {Abbr, Button, Small, scrollbar} from "../../style/components/general.js";

const FacilityNav = ({innerRef, open, setOpen}) => {
  const isMounted = useMountedState();

  const {slug} = useParams();

  const {pathname} = useLocation();

  const {notifications} = useContext(NotificationContext);

  const {options, addOptions, available, facility, setFacility, setAnalytics, setAllOrder} =
    useContext(FacilityNavContext);

  const {currentUser, atLeast, roleCanAccessPage, roleCanAccessResource} = useContext(AuthContext);

  // Modals
  const [showMapModal, setShowMapModal] = useState(false);
  const [showOnTest, setShowOnTest] = useState(false);
  const [showModalNewChart, setShowModalNewChart] = useState(false);
  const [showModalNewStat, setShowModalNewStat] = useState(false);

  const isActive = useMemo(() => facility && !facility.isDeleted, [facility]);

  useEffect(() => {
    if (isMounted()) document.body.style.overflow = open ? "hidden" : "inherit";
  }, [isMounted, open]);

  useEffect(
    () => () => {
      if (!isMounted()) {
        setFacility(null);
        setAnalytics(null);
        setAllOrder(null);
      }
    },
    [isMounted, setAllOrder, setAnalytics, setFacility]
  );

  useEffect(() => {
    if (facility?.type) setWithExpiry(`${slugify(facility.name)}.link`, facility.slug);
  }, [facility]);

  useEffect(() => {
    if (isMounted() && currentUser && atLeast && facility)
      addOptions({
        facility_dashboard: {
          rule: roleCanAccessPage("facility_dashboard"),
          action: `/facilities/${slug}`,
          icon: faHome,
          callout: "Home"
        },
        tasks: {
          rule:
            roleCanAccessPage("tasks") &&
            (roleCanAccessResource("event_record", "create") ||
              roleCanAccessResource("facility_checksheet_record", "create")) &&
            isActive,
          action: `/facilities/${slug}/tasks`,
          icon: faClipboardCheck,
          callout: "Tasks",
          count:
            isActive && notifications
              ? (notifications.checksheetDueCount ?? 0) + (notifications.eventDueCount ?? 0)
              : 0
        },
        schedule: {
          rule: roleCanAccessPage("schedule") && roleCanAccessResource("event", "view"),
          action: `/facilities/${slug}/schedule`,
          icon: faCalendar,
          callout: "Schedule",
          count: isActive && notifications ? notifications.eventTotalCount : 0
        },
        facility_builder: {
          divider: "Builders",
          rule: roleCanAccessPage("facility_builder"),
          action: () => {
            setShowMapModal(true);
            setOpen(false);
          },
          icon: faMapMarked,
          callout: atLeast("creator") ? "Facility Builder" : "Facility Map"
        },
        checksheet_builder: {
          rule:
            roleCanAccessPage("checksheet_builder") &&
            roleCanAccessResource("facility_checksheet", "create") &&
            isActive,
          action: `/facilities/${slug}/checksheet-builder`,
          icon: faScrewdriverWrench,
          callout: "Checksheet Builder"
        },
        report_builder: {
          rule: roleCanAccessPage("report_builder") && !isSmallMobile() && isActive,
          action: `/facilities/${slug}/report-builder`,
          icon: faClipboardList,
          callout: "Report Builder"
        },
        on_test: {
          divider: "Page Options",
          rule:
            roleCanAccessResource("facility", "view") &&
            roleCanAccessResource("facility_on_test", "view") &&
            isActive,
          action: () => {
            setShowOnTest(true);
            setOpen(false);
          },
          icon: faHouseCircleExclamation,
          callout: "Set Facility on Test"
        },
        create_chart: {
          rule:
            (facility?.hasChecksheets || facility?.hasEvents) &&
            roleCanAccessResource("analytic", "create") &&
            pathname === `/facilities/${slug}` &&
            isActive,
          action: () => {
            setShowModalNewChart(true);
            setOpen(false);
          },
          icon: faChartLine,
          callout: "New Chart"
        },
        create_stat: {
          rule:
            (facility?.hasChecksheets || facility?.hasEvents) &&
            roleCanAccessResource("analytic", "create") &&
            pathname === `/facilities/${slug}` &&
            isActive,
          action: () => {
            setShowModalNewStat(true);
            setOpen(false);
          },
          icon: faPercent,
          callout: "New Statistic"
        }
      });
  }, [
    isMounted,
    currentUser,
    atLeast,
    roleCanAccessPage,
    roleCanAccessResource,
    slug,
    addOptions,
    notifications,
    pathname,
    setOpen,
    facility,
    isActive
  ]);

  const renderOptionContent = (count, icon, callout) => (
    <>
      {count && count > 0 ? (
        <Badge count={count} color={colors.gray} offsetY="20px" offsetX="10px" />
      ) : null}
      <FontAwesomeIcon icon={icon} className="primaryIcon" />
      <Callout open={open}>{callout}</Callout>
    </>
  );

  const renderOption = ({action, icon, callout, count = null}) => {
    if (typeof action === "string")
      return (
        <BuilderLink to={action} title={callout} active={action === pathname ? 1 : 0}>
          {renderOptionContent(count, icon, callout)}
        </BuilderLink>
      );

    if (typeof action === "function")
      return (
        <BuilderButton
          type="button"
          onClick={action}
          title={callout}
          data-testid={callout}
          active={
            callout === "Facility Builder" && pathname === `/facilities/${slug}/builder` ? 1 : 0
          }>
          {renderOptionContent(count, icon, callout)}
        </BuilderButton>
      );

    return null;
  };

  if (available.length <= 1) return null;

  return (
    <Outer open={open}>
      <DashboardMenu
        ref={innerRef}
        open={open}
        hasSettings={roleCanAccessResource("notifications", "view") && isActive}>
        <Open onClick={() => setOpen(prev => !prev)}>
          <FontAwesomeIcon icon={open ? faChevronLeft : faChevronRight} className="primaryIcon" />
        </Open>
        {Object.keys(options).map(
          index =>
            options[index].rule && (
              <ItemWrapper key={getSnakeCase(options[index].callout)} open={open}>
                {options[index].divider && (
                  <Divider open={open}>
                    <Small>{options[index].divider}</Small>
                    <hr />
                  </Divider>
                )}
                {renderOption(options[index])}
              </ItemWrapper>
            )
        )}
        {roleCanAccessResource("notifications", "view") && isActive && (
          <ItemWrapper>
            <BuilderLink to={`/facilities/${slug}/notifications`} title="Settings">
              {facility && !facility.hasNotifications && (
                <Badge color={colors.red} offsetY="20px" offsetX="10px">
                  <Abbr title="Notifications not configured">
                    <StyledFontAwesomeIcon icon={faExclamation} />
                  </Abbr>
                </Badge>
              )}
              <FontAwesomeIcon icon={faCog} className="primaryIcon" />
              <Callout open={open}>Settings</Callout>
            </BuilderLink>
          </ItemWrapper>
        )}
      </DashboardMenu>

      {showMapModal && (
        <ModalMap
          visible={showMapModal}
          setVisible={setShowMapModal}
          facility={facility}
          setFacility={setFacility}
          currentPath={pathname}
        />
      )}

      {showOnTest && facility && (
        <ModalOnTest visible={showOnTest} setVisible={setShowOnTest} facility={facility} />
      )}

      {showModalNewChart && (
        <ModalChartBuilder visible={showModalNewChart} setVisible={setShowModalNewChart} />
      )}

      {showModalNewStat && (
        <ModalStatBuilder visible={showModalNewStat} setVisible={setShowModalNewStat} />
      )}
    </Outer>
  );
};

FacilityNav.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

// Style Overrides
const Open = styled.button`
  ${voice.quiet};
  display: none;
  opacity: 0;

  ${bp(3)} {
    display: flex;
    position: absolute;
    top: ${pad}px;
    right: -8px;
    width: min-content;
    background: ${props => props.theme.primary};
    padding: 3px ${pad / 2}px;
    border-radius: 50%;
    z-index: ${z("top") + 1};

    .primaryIcon {
      fill: ${props => props.theme.secondary};
    }
  }
`;

const Outer = styled.div`
  position: relative;
  width: ${props => (!props.open ? navWidth : 250)}px;
  transition: all ease 0.5s;

  &:hover {
    ${Open} {
      opacity: 1;
      transition: all ease 0.5s;
    }
  }
`;

const DashboardMenu = styled.nav`
  ${flex("column", "nowrap", "start", "center")};
  gap: ${pad}px;
  position: fixed;
  top: ${props => (props.open ? `${navHeight}px` : "-100vh")};
  left: 0;
  width: 100%;
  height: calc(100vh - ${navHeight}px);
  padding: ${pad}px;
  background: ${colors.heroBlack}; // fallback
  background: ${props =>
    props.theme.name === "light" ? props.theme.secondary : props.theme.tertiary};
  opacity: ${props => (props.open ? 1 : 0)};
  transition: all ease 0.5s;
  box-shadow: ${shadowRight};
  z-index: ${z("top")};
  ${scrollbar};

  ${`${bp(3)} and (min-height: 708px)`} {
    top: ${navHeight}px;
    padding: ${pad * 2}px 0 0 ${pad / 2}px;
    gap: ${pad}px;
    width: ${props => (!props.open ? navWidth : 250)}px;
    border-right: ${border} solid ${props => props.theme.primary};
    visibility: visible;
    opacity: 1;
  }

  ${props =>
    props.hasSettings &&
    css`
      div:last-child {
        margin-top: auto;
      }
    `}
`;

const ItemWrapper = styled.div`
  overflow: hidden;
  padding-top: ${pad / 2}px;

  ${bp(3)} {
    width: 100%;
  }
`;

const Divider = styled.div`
  position: relative;
  margin: ${pad * 1.5}px 0;

  ${Small} {
    position: absolute;
    bottom: 0;
    padding: 0 ${pad * 2}px ${pad / 2}px;

    ${bp(3)} {
      visibility: hidden;
      opacity: 0;

      ${props =>
        props.open &&
        css`
          opacity: 1;
          visibility: visible;
          transition: ${transition} 0.1s; // with delay
        `}
    }
  }

  hr {
    border: 1px solid ${props => props.theme.primary};
    border-radius: ${radius};
    margin-bottom: ${pad}px;

    ${bp(3)} {
      margin: ${pad}px ${pad * 2}px;
    }
  }
`;

const Callout = styled.span`
  ${voice.normal};
  min-width: 200px;
  text-align: left;
  margin-left: ${pad * 2}px;
  visibility: visible;
  opacity: 1;

  ${props =>
    props.open &&
    css`
      transition: ${transition} 0.1s; // with delay
    `}

  ${bp(3)} {
    ${props =>
      !props.open &&
      css`
        visibility: hidden;
        opacity: 0;
      `}
  }
`;

const MenuItem = css`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${pad / 2}px ${navWidth / 2 - pad}px;
  color: ${props => props.theme.primary};
  border-radius: 0;
  background: transparent;

  .primaryIcon {
    width: ${pad * 2}px;
    margin: 0 auto;
    fill: ${props => props.theme.primary};
  }

  ${`${bp(3)} and (min-height: 708px)`} {
    padding: ${pad}px ${navWidth / 2 - pad - 3}px ${pad * 2}px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 90%;
    margin: 0 2px;
    border: 2px solid ${props => props.theme.primary};
    border-radius: ${radius};
    opacity: 0;

    ${`${bp(3)} and (min-height: 708px)`} {
      opacity: ${props => (props.active ? 1 : 0)};
    }
  }
`;

const BuilderLink = styled(NavLink)`
  ${MenuItem};
  width: min-content;
`;

const BuilderButton = styled(Button)`
  ${MenuItem};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  fill: ${props => props.theme.tertiary};
`;

export default FacilityNav;
