import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

// Components
import Modal from "../../components/Modal.js";
import AddNote from "./AddNote.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Heading, Text} from "../../style/components/general.js";
import {breakpoint} from "../../style/components/breakpoints.js";

const ResolveRecord = ({
  visible,
  setVisible,
  record,
  checksheet,
  updateData,
  hasBackButton,
  goBack
}) => (
  <Modal
    visible={visible}
    setVisible={setVisible}
    isChecksheetPreview
    hasBackButton={hasBackButton}
    goBack={goBack}>
    <Heading>Archive Overdue Record</Heading>

    <Detail>
      <Text>
        <strong>Checksheet:</strong> {checksheet.name}
      </Text>
      <Text>
        <strong>Due:</strong> {dayjs(record.dateDue).format("MMM D, YYYY")}
      </Text>
    </Detail>

    <AddNote
      setVisible={setVisible}
      record={record}
      updateData={updateData}
      acknowledging
      showCancel
    />
  </Modal>
);

ResolveRecord.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ResolveRecord.defaultProps = {
  hasBackButton: false,
  goBack: () => {}
};

// Style Overrides
const Detail = styled.div`
  margin-right: ${pad}px;

  @media (min-width: ${breakpoint.width[3]}) {
    margin-bottom: ${pad}px;
  }
`;

export default ResolveRecord;
