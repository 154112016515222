import React from "react";
import PropTypes from "prop-types";

// Components
import Modal from "../../components/Modal.js";
import Details from "./Information.js";
import Contacts from "./Contacts.js";

const ModalPreviewDetails = ({visible, setVisible, facility}) => (
  <Modal visible={visible} setVisible={setVisible}>
    <Details details={{...facility.details, address: facility.primaryAddress}} fullWidth />
    <Contacts details={{...facility.details, address: facility.primaryAddress}} />
  </Modal>
);

ModalPreviewDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalPreviewDetails;
