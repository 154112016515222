import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMapMarkerAlt, faSync} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";

// Utils
import {
  getMapFromCoords,
  openLink,
  prettyDateInUserTimezone,
  prettyDateWithDayInUserTimezone
} from "../../utils/helpers.js";
import {getIcon} from "./helpers.js";
import {formatSubmissions} from "../../utils/builder.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import Modal from "../../components/Modal.js";
import AccordionWrapped from "../../components/AccordionWrapped.js";
import RenderSubmission from "../checksheets/RenderSubmission.js";
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";
import Weather from "../../components/Weather.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {circle} from "../../style/components/shapes.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {
  Heading,
  scrollbar,
  Inline,
  Pill,
  Button,
  Small,
  Text,
  Abbr,
  NotLoaded,
  Loader,
  Label
} from "../../style/components/general.js";

const ViewEventRecord = ({
  visible,
  setVisible,
  hasBackButton,
  goBack,
  record,
  event,
  editing,
  updateData,
  markerIconMap
}) => {
  const {facility, name, type, frequency, stages, group, isArchived} = event;
  const {allIds, byId} = stages;
  const {submission, stage} = record;

  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);

  const {atLeast, roleCanAccessResource, currentUser} = useContext(AuthContext);

  const printRef = useRef();

  const [edit, setEdit] = useState(editing);
  const [viewModification, setViewModification] = useState(false);
  const [activeModification, setActiveModification] = useState(null);
  const [stageOpen, setStageOpen] = useState(null);
  const [versions, setVersions] = useState(null);
  const [currentVersion, setCurrentVersion] = useState({
    ...record,
    latest: true,
    completedAt:
      record.completedAt && !record.draft ? dayjs(record.completedAt).format("YYYY-MM-DD") : null
  });

  const {api: recordApi} = useApi("event-records");
  const {api: versionApi} = useApi("event-record-versions");
  const {api: fileApi} = useApi("files");
  const {api: noteApi} = useApi("event-record-notes");

  const [versionMap, setVersionMap] = useState(null);

  // Load Initial Versions
  useEffect(() => {
    versionApi.callGet(null, {recordId: record.id}).then(({status, data}) => {
      if (isMounted() && status === 200 && data?.versions && data?.versionMap) {
        setVersions(data.versions);
        setVersionMap(data.versionMap);
      }
    });
  }, [isMounted, versionApi, record]);

  const openFile = fileId =>
    fileApi.callGet(fileId).then(res => res.status === 200 && openLink(res.data.link));

  const handleResolve = (noteId, groupKey) => {
    noteApi
      .callPut(noteId, null, {
        params: {userId: currentUser.publicId, status: "resolved"},
        headers: {"Content-Type": "multipart/form-data"}
      })
      .then(({status}) => {
        if (status === 200) {
          if (record.postedNotes[groupKey]) {
            const temp = record.postedNotes[groupKey].filter(note => note.id === noteId);
            record.postedNotes[groupKey] = record.postedNotes[groupKey].filter(
              note => note.id !== noteId
            );
            if (record.postedNotes[groupKey].length === 0) delete record.postedNotes[groupKey];
            if (temp?.length > 0) {
              if (groupKey in record.resolvedNotes) record.resolvedNotes[groupKey].push(temp[0]);
              else record.resolvedNotes[groupKey] = [temp[0]];
            }
          }
          updateData();
        }
      });
  };

  const handleEdit = (values, draft = false) => {
    const {completedAt, ...responses} = values;

    const target = currentVersion?.stage || stage;
    const formatted = formatSubmissions(responses, byId[target].builder);

    const edited = {
      stage: target,
      submission: {
        name: submission.name,
        frequency: submission.frequency,
        responses: formatted
      },
      draft,
      completedAt: completedAt || null
    };

    recordApi.callPut(record.id, edited).then(({status}) => {
      if (status === 200) {
        updateData();
        setVisible(false);
      }
    });
  };

  const buildNotesDisplay = (notes, canResolve) => {
    let groups = Object.keys(notes);
    if (stages) {
      groups = groups.filter(g => stages.allIds.includes(g));

      groups.sort((a, b) => stages.allIds.indexOf(a) - stages.allIds.indexOf(b));
    }
    return groups.map(groupKey => (
      <NotesListWrapper key={`notes-${groupKey}`}>
        <Label bold>{byId[groupKey].label}</Label>
        {notes[groupKey].map(note => (
          <NoteWrapper
            key={`${byId[groupKey].name}_note_${note.id}`}
            data-testid="preview.noteWrapper">
            <Message>{note.message}</Message>
            {note.coords && (
              <AddressLink
                href={getMapFromCoords(note.coords.lat, note.coords.lon)}
                target="_blank"
                rel="noreferrer">
                View in Maps
              </AddressLink>
            )}
            {note?.files?.length > 0 && (
              <Files>
                <hr />
                <p>Attached Files</p>
                {note.files.map(file => (
                  <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                    {file.label}
                  </FileLink>
                ))}
              </Files>
            )}
            {note?.user && <Author>By: {note.user}</Author>}

            {canResolve && (
              <ResolveButton
                data-testid="resolveButton"
                onClick={() => handleResolve(note.id, groupKey)}>
                Resolve
              </ResolveButton>
            )}
          </NoteWrapper>
        ))}
      </NotesListWrapper>
    ));
  };

  const roleCanAccess = useMemo(() => {
    const current = currentVersion || record;
    const currStage = current && stages?.byId ? stages.byId[current.stage] : null;
    return (
      !!currStage &&
      ((currentUser.type.name === "super" && !currentUser.role?.id) ||
        !currStage.restrictTo ||
        currStage.restrictTo.filter(role => currentUser.role.id === role.id).length !== 0)
    );
  }, [currentUser, currentVersion, stages, record]);

  const getVersionResponses = () => {
    if (edit && currentVersion) return currentVersion.submission.responses;
    if (edit) return submission.responses;
    return null;
  };

  const linkedGroup = useMemo(() => {
    if (group && group in facility.builder.byId && facility.builder.byId[group])
      return Object.keys(markerIconMap)?.length > 0 && facility.builder.byId[group]?.markerId
        ? markerIconMap[facility.builder.byId[group].markerId]
        : DEFAULT_MARKER;

    return null;
  }, [group, facility, markerIconMap]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={hasBackButton}
      goBack={goBack}
      clickOutsideToClose={false}
      maxWidth={breakpoint.width[5]}>
      <ModalHeader>
        <div>
          <Inline>
            {type?.name && (
              <Pill color={`#${type.color}`} quiet>
                <Icon icon={getIcon(type.icon)} />
                {type.name.toUpperCase()}
              </Pill>
            )}
            {group && (
              <div>
                <Pill color={`#${linkedGroup.color}`} quiet>
                  <Icon icon={faMapMarkerAlt} />
                  {facility.builder.byId[group].label}
                </Pill>
              </div>
            )}
            {frequency && (
              <Pill quiet>
                <Icon icon={faSync} />
                {frequency.label}
              </Pill>
            )}
            {isArchived && (
              <Pill color={colors.yellow} quiet>
                Archived
              </Pill>
            )}
          </Inline>

          <Heading>{name}</Heading>

          <Text>
            {record.dateDue && (
              <>
                <strong>DATE DUE</strong>:&nbsp;
                {prettyDateWithDayInUserTimezone(record.dateDue, settings.timezone)}
              </>
            )}
            <br />
            {record.completedAt && (
              <>
                <strong>DATE COMPLETED</strong>:&nbsp;
                {prettyDateInUserTimezone(
                  record.completedAt,
                  settings.timezone,
                  "ddd, MMM D, YYYY"
                )}
              </>
            )}
            {record.completedBy && (
              <>
                &nbsp;by {record.completedBy.firstName} {record.completedBy.lastName}
              </>
            )}
          </Text>
        </div>
        {updateData && atLeast("admin") && !editing && submission && (
          <Inline>
            {roleCanAccessResource("event_record_version", "create") &&
              roleCanAccess &&
              !viewModification &&
              !activeModification && (
                <Button type="button" title="Edit" onClick={() => setEdit(prev => !prev)}>
                  {!edit ? (
                    <Abbr title="Edit">
                      <FontAwesomeIcon icon={faEdit} />
                    </Abbr>
                  ) : (
                    "Cancel"
                  )}
                </Button>
              )}
          </Inline>
        )}
      </ModalHeader>

      <Wrapper>
        <PageLeft>
          {!edit && (
            <RenderSubmission
              ref={printRef}
              submission={currentVersion ? currentVersion.submission : submission}
              builder={
                currentVersion?.stage ? byId[currentVersion.stage].builder : byId[stage].builder
              }
              dateDue={record.dateDue}
              startDate={record.startDate ?? record.dateDue}
              draft={currentVersion?.draft ?? record.draft}
              modifications={currentVersion ? currentVersion.modification : null}
              viewModification={viewModification}
              submissionName={
                currentVersion?.stage
                  ? `Stage: ${byId[currentVersion.stage].name}`
                  : `Stage: ${byId[stage].name}`
              }
            />
          )}

          <Hide hide={!edit ? 1 : 0}>
            <RenderChecksheet
              task={currentVersion ? byId[currentVersion.stage] : byId[stage]}
              taskRecord={record}
              responses={getVersionResponses()}
              setSubmission={(responses, outOfRange) => handleEdit(responses, outOfRange)}
              setDraft={
                record.overdue
                  ? (responses, outOfRange) => handleEdit(responses, outOfRange, true)
                  : null
              }
              cancelFunction={() => setEdit(false)}
              previousSubmission={record.previousSubmission}
              dateDue={record.dateDue}
              draft={currentVersion?.draft}
              overdue={record.overdue}
              completedAt={currentVersion ? currentVersion.completedAt : record.completedAt}
              stage={currentVersion?.stage || stage}
              visible={edit}
            />
          </Hide>
        </PageLeft>

        {record && (
          <PageRight>
            {record.weather && <Weather weather={record.weather} />}
            <NotesWrapper>
              <NotesHeader>History</NotesHeader>
              <hr />
              <VersionsWrapper>
                {allIds?.toReversed()?.map(
                  stageKey =>
                    versionMap &&
                    stageKey in versionMap &&
                    versionMap[stageKey]?.versions?.length > 0 && (
                      <AccordionWrapped
                        key={stageKey}
                        label={prettyDateWithDayInUserTimezone(
                          versionMap[stageKey].original,
                          settings.timezone
                        )}
                        menu={
                          <Pill quiet>
                            <Abbr title={byId[stageKey].name}>{byId[stageKey].name}</Abbr>
                          </Pill>
                        }
                        handleOpen={() => {
                          setCurrentVersion(versionMap[stageKey].versions[0]);
                          setStageOpen(prev => (prev === stageKey ? null : stageKey));
                          setViewModification(false);
                          setActiveModification(null);
                        }}
                        toggleOpen={stageOpen === stageKey}
                        disabled={edit && stageOpen !== currentVersion.stage}>
                        {versionMap[stageKey]?.versions?.map((version, idx) => {
                          const {
                            id,
                            stage: targetStage,
                            submission: sub,
                            modification,
                            draft,
                            user,
                            createdAt
                          } = version;

                          let subject = byId[targetStage].name;
                          if (modification) subject = "Modified";
                          if (draft) subject = "Incomplete saved";

                          const date = sub.responses.completedAt || createdAt;
                          return (
                            <Version key={`version-${id}`}>
                              <Mod
                                type="button"
                                title="View Modification"
                                onClick={() => {
                                  setCurrentVersion(version);
                                  setViewModification(true);
                                  setActiveModification(idx);
                                }}
                                active={idx === activeModification}
                                enabled={versionMap[stageKey].versions.length > 1}>
                                {subject}&nbsp;by&nbsp;
                                {user.firstName} {user.lastName} on&nbsp;
                                {prettyDateInUserTimezone(
                                  date,
                                  settings.timezone,
                                  "ddd, MMM D YYYY"
                                )}
                              </Mod>
                            </Version>
                          );
                        })}
                      </AccordionWrapped>
                    )
                )}
                {record.overdue && !record.archived && !record.draft && versions?.length === 0 && (
                  <Small>No history</Small>
                )}
                {!versions && (
                  <LoadingWrapper>
                    <HistoryLoader data-testid="history.loader" />
                  </LoadingWrapper>
                )}
              </VersionsWrapper>
            </NotesWrapper>
            <NotesWrapper>
              <NoteSection>
                <NotesHeader>Posted Notes</NotesHeader>
                <hr />
                {record.postedNotes && Object.keys(record.postedNotes).length > 0 ? (
                  buildNotesDisplay(record.postedNotes, true)
                ) : (
                  <NoNotes>No Posted Notes</NoNotes>
                )}
              </NoteSection>
              <NoteSection>
                <NotesHeader>Resolved Notes</NotesHeader>
                <hr />
                {record.resolvedNotes && Object.keys(record.resolvedNotes).length > 0 ? (
                  buildNotesDisplay(record.resolvedNotes, false)
                ) : (
                  <NoNotes>No Resolved Notes</NoNotes>
                )}
              </NoteSection>
              {atLeast("admin") && record.archivedNotes && record.archivedNotes.length > 0 && (
                <NoteSection>
                  <NotesHeader>Checksheet Archive Note</NotesHeader>
                  <hr />
                  {record.archivedNotes.map(note => (
                    <NoteWrapper
                      key={`archive_note_${note.id}`}
                      data-testid="preview.resolvedWrapper"
                      resolved>
                      {note.name.includes("error") && <Label bold>{note.name}</Label>}
                      <Message>
                        {note.name === "Hero was not implemented at this time"
                          ? note.name
                          : note.message}
                      </Message>
                      {note.files && note.files.length > 0 && (
                        <Files>
                          <hr />
                          <p>Attached Files</p>
                          {note.files.map(file => (
                            <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                              {file.label}
                            </FileLink>
                          ))}
                        </Files>
                      )}
                      {note.user && (
                        <>
                          <hr />
                          <Author>By: {note.user}</Author>
                        </>
                      )}
                    </NoteWrapper>
                  ))}
                </NoteSection>
              )}
            </NotesWrapper>
          </PageRight>
        )}
      </Wrapper>
    </Modal>
  );
};

ViewEventRecord.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  editing: PropTypes.bool,
  updateData: PropTypes.func,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired
};

ViewEventRecord.defaultProps = {
  hasBackButton: false,
  goBack: () => {},
  updateData: null,
  editing: false
};

// Style Overrides
const ModalHeader = styled.section`
  width: 100%;
  padding: 0 0 ${pad}px;
  margin-bottom: ${pad}px;
  ${flex("row", "wrap", "space-between", "end")};
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin-right: ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const Wrapper = styled.article`
  ${flex("row", "wrap-reverse", "start", "start")};
`;

const PageLeft = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad * 1.5}px;

  ${({mode}) =>
    mode === "notes" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    display: block;
    width: 60%;
  }
`;

const PageRight = styled.div`
  width: 100%;

  ${({mode}) =>
    mode === "record" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    position: relative;
    display: block;
    width: 40%;
    padding-left: ${pad}px;

    ${({addingNote}) =>
      addingNote &&
      css`
        position: sticky;
        left: 70%;
        top: 60px;
        z-index: 3;
      `}
  }
`;

const Hide = styled.div`
  ${({hide}) =>
    hide &&
    css`
      display: none;
    `}
`;

const NotesWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  hr {
    margin-bottom: 0;
  }
`;

const NotesHeader = styled.h1`
  ${voice.normal};
  color: ${({theme}) => theme.secondary};
`;

const VersionsWrapper = styled.div`
  max-height: 350px;
  min-height: min-content;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar};
`;

const Version = styled.div`
  display: flex;
  align-items: start;
  margin: 0 ${pad}px ${pad / 2}px;
  border: ${border} solid ${({theme}) => theme.tertiary};

  &:last-child {
    margin-bottom: 0;
  }

  ${({theme}) => circle(5, theme.secondary)};

  &:before {
    margin-top: ${pad / 2}px;
  }
`;

const Mod = styled.button`
  ${voice.quiet};
  margin-left: ${pad / 2}px;
  text-align: left;
  pointer-events: ${({enabled}) => (enabled ? "pointer" : "none")};

  &:hover {
    text-decoration: underline;
  }

  ${({active, theme}) =>
    active &&
    css`
      color: ${theme.primary};
    `}
`;

const LoadingWrapper = styled(NotLoaded)`
  height: min-content;
  width: calc(100% - 1px);
  margin: ${pad / 2}px 0 0 0;
`;

const HistoryLoader = styled(Loader)`
  height: min-content;
  width: calc(100% - 1px);
  margin: 0;
`;

const NoteSection = styled.div`
  margin-bottom: ${pad * 2}px;

  &:last-child {
    margin: 0;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  width: 100%;
  flex: 1 auto;

  &:last-child {
    margin: 0;
  }
`;

const NoNotes = styled(Small)`
  margin-left: ${pad / 2}px;
  margin-top: ${pad / 2}px;
`;

const Message = styled(Text)`
  margin-bottom: ${pad}px;
`;

const Files = styled.div`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  margin-bottom: ${pad / 2}px;

  p {
    font-weight: bold;
  }

  a {
    color: ${({theme}) => theme.primary};

    :hover {
      text-decoration: underline;
    }
  }

  ${voice.quiet}
`;

const FileLink = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  cursor: pointer;
  ${voice.quiet};

  :hover {
    text-decoration: underline;
  }
`;

const ResolveButton = styled(Button)`
  position: absolute;
  right: ${pad}px;
  margin: 0;
  bottom: 8px;
  ${voice.quiet};
`;

const Author = styled.p`
  ${voice.quiet}
`;

const AddressLink = styled.a`
  color: ${({theme}) => theme.primary};

  p {
    color: ${({theme}) => theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const NotesListWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${pad / 2}px;
`;

export default ViewEventRecord;
