import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faFilter, faSearch, faUserPlus} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import ModalUserFilter from "./ModalUserFilter.js";
import ModalExport from "../../components/ModalExport.js";
import ModalUserAdd from "../users/ModalUserAdd.js";
import ModalUserEdit from "../users/ModalUserEdit.js";
import ModalUserDelete from "../users/ModalUserDelete.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";
import Badge from "../../components/Badge.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Inline,
  Text,
  TableFooter,
  TableWrapper,
  Small,
  SearchWrapper,
  Search,
  Button,
  Heading,
  TableMenu,
  TableEditMenu,
  TableEditMenuButton,
  SearchIcon
} from "../../style/components/general.js";

const ModalViewAllUsers = ({visible, setVisible, reload}) => {
  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {atLeast, roleCanAccessResource} = useContext(AuthContext);

  const [tableData, setTableData] = useState(null);
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState();
  const [items, setItems] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const selectedUser = useMemo(() => (items?.length === 1 ? items[0] : null), [items]);

  const scrollPos = useRef(null);
  const scrollContainer = useRef(null);

  const {api, loading} = useApi("users");
  const {api: apiRestore} = useApi("users");
  const {api: apiSend} = useApi("send-invite");

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const formatTableData = useCallback(
    formattedUsers =>
      formattedUsers.map(user => {
        const {
          role,
          department,
          firstName,
          lastName,
          isInvited,
          isAccepted,
          isActive,
          isDeleted,
          email,
          primary,
          phones,
          details
        } = user;

        return {
          ...user,
          userRoleId: role ? role.label : null,
          userDepartmentId: department,
          firstName: (
            <span>
              {firstName} {lastName}&nbsp;
              {isDeleted && (
                <ErrorSmall>
                  <Abbr title="User is deleted">deleted!</Abbr>
                </ErrorSmall>
              )}
              {isInvited && !isAccepted && !isDeleted && (
                <Small>
                  <Abbr title="User has not accepted invitation">invited</Abbr>
                </Small>
              )}
              {isInvited && isAccepted && !isActive && !isDeleted && (
                <Small>
                  <Abbr title="User is disabled">disabled!</Abbr>
                </Small>
              )}
              {!isInvited && !isActive && !isDeleted && (
                <Small>
                  <Abbr title="User has not been invited">contact</Abbr>
                </Small>
              )}
            </span>
          ),
          email: <a href={`mailto:${email}`}>{email}</a>,
          phone: primary && <PhoneNumbers primary={primary} phones={phones} />,
          company: details && details.company ? details.company : ""
        };
      }),
    []
  );

  const getTableData = useCallback(
    (page, l) => {
      const filter = {};

      if (query) filter.Search = query;
      if (activeFilters?.departments) filter.Departments = activeFilters.departments;
      if (activeFilters?.roles) filter.Roles = activeFilters.roles;
      if (showDisabled) filter.showDisabled = true;
      if (showDeleted) filter.showDeleted = true;

      const payload = {
        page: page || 1,
        orderBy: orderBy,
        groupBy,
        limit: l ?? limit.current,
        filter: JSON.stringify(filter),
        userManagement: true
      };

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data?.users) {
          const formatted = formatTableData(data.users);

          // Page Limit
          const {total: dataTotal, page: tPage, pages: tPages} = data;

          setCurrent(tPage);
          setPages(tPages);
          setTableData(prev => (page === 1 ? formatted : [...prev, ...formatted]));
          setTotal(dataTotal);
        }
      });
    },
    [api, formatTableData, query, orderBy, groupBy, showDeleted, activeFilters, showDisabled]
  );

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.ceil((window.innerHeight * 0.9 - 200) / 50);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  useEffect(() => {
    const modal = document.getElementById("modal");

    const handleScroll = () => {
      const maxScrollTop = modal.scrollHeight - modal.clientHeight;
      if (modal.scrollTop + 1 >= maxScrollTop && current < pages && !loading)
        getTableData(current + 1);
    };

    modal.addEventListener("scroll", handleScroll);

    return () => modal.removeEventListener("scroll", handleScroll);
  }, [current, pages, getTableData, loading]);

  useLayoutEffect(() => {
    if (showModalFilters || showModalExport || showModalDelete || showModalEdit || showModalAdd) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [showModalFilters, showModalExport, showModalEdit, showModalDelete, showModalAdd]);

  const handleSearch = e => {
    setCurrent(1);
    setPages(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrent(1);
    setPages(1);
    getTableData(1);
    setItems([]);
    setShowOptions(false);
    reload();
  };

  const restore = () => {
    const [user] = items;
    const payload = {restore: true};
    apiRestore.callPut(user.publicId, payload).then(({status}) => {
      if (status === 200) {
        addToast("User restored.", "success");
        handleReload();
      } else addToast("Failed to restore user", "error");
    });
  };

  if (showModal && showModalFilters)
    return (
      <ModalUserFilter
        visible={showModalFilters}
        setVisible={setShowModalFilters}
        filters={activeFilters}
        setFilters={setActiveFilters}
        showDisabled={showDisabled}
        setShowDisabled={setShowDisabled}
        showDeleted={showDeleted}
        setShowDeleted={setShowDeleted}
        hasBackButton
        goBack={() => setShowModalFilters(false)}
      />
    );

  if (showModal && showModalExport)
    return (
      <ModalExport
        visible={showModalExport}
        setVisible={setShowModalExport}
        getExport={exportParams =>
          api.callGet(null, {
            ...exportParams,
            filter: JSON.stringify({ExcludeCreator: true})
          })
        }
        hasBackButton
        goBack={() => setShowModalExport(false)}
      />
    );

  if (showModal && showModalAdd)
    return (
      <ModalUserAdd
        visible={showModalAdd}
        setVisible={setShowModalAdd}
        updateTable={handleReload}
        hasBackButton
        goBack={() => setShowModalAdd(false)}
      />
    );

  if (showModal && showModalEdit && selectedUser)
    return (
      <ModalUserEdit
        selected={selectedUser}
        visible={showModalEdit}
        setVisible={setShowModalEdit}
        updateTable={handleReload}
        hasBackButton
        goBack={() => setShowModalEdit(false)}
      />
    );

  if (showModal && showModalDelete)
    return (
      <ModalUserDelete
        selected={items}
        visible={showModalDelete}
        setVisible={setShowModalDelete}
        updateTable={handleReload}
        hasBackButton
        goBack={() => setShowModalDelete(false)}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} scrollRef={scrollContainer}>
      <TableWrapper>
        <TableMenuRow>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {atLeast("admin") && tableData?.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      if (showOptions) setItems([]);
                      setShowOptions(!showOptions);
                    }}>
                    {showOptions ? "Cancel" : "Options"}
                  </Option>
                )}
                {atLeast("admin") &&
                  (tableData?.length > 0 ||
                    (activeFilters && Object.keys(activeFilters)?.length > 0)) && (
                    <Option
                      type="button"
                      onClick={() => {
                        setShowModalFilters(!showModalFilters);
                      }}>
                      <Badge
                        count={
                          (activeFilters?.departments ? activeFilters.departments.length : 0) +
                          (activeFilters?.roles ? activeFilters.roles.length : 0) +
                          (showDisabled ? 1 : 0) +
                          (showDeleted ? 1 : 0)
                        }
                        offset="14px"
                        color={colors.heroGreen}
                      />
                      <FontAwesomeIcon icon={faFilter} />
                    </Option>
                  )}
                {roleCanAccessResource("user", "export") && tableData && tableData.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      setShowModalExport(true);
                    }}>
                    <abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </abbr>
                  </Option>
                )}
                {roleCanAccessResource("user", "create") && (
                  <Option type="button" onClick={() => setShowModalAdd(true)}>
                    <abbr title="Add User">
                      <FontAwesomeIcon icon={faUserPlus} />
                    </abbr>
                  </Option>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableMenuRow>
        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            userRoleId: {header: "Role", disabled: false},
            userDepartmentId: {header: "Department", disabled: false},
            firstName: {header: "Name", disabled: false},
            email: {header: "Email", disabled: false},
            phone: {header: "Phone", disabled: false},
            company: {header: "Company", disabled: false}
          }}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={items}
          setItems={setItems}
          showOptions={showOptions}
          editMenu={
            <TableEditMenu>
              {items.length === 0 && <Text inverted>Please select users below for options.</Text>}
              {items.length === 1 && !items[0].isDeleted && (
                <>
                  <TableEditMenuButton
                    type="button"
                    onClick={() => {
                      apiSend
                        .callPatch(null, {publicId: items[0].publicId})
                        .then(() => setShowOptions(false));
                    }}>
                    Send/Resend Invite
                  </TableEditMenuButton>
                  {roleCanAccessResource("user", "update") && (
                    <TableEditMenuButton type="button" onClick={() => setShowModalEdit(true)}>
                      Edit
                    </TableEditMenuButton>
                  )}
                </>
              )}
              {items.length === 1 &&
                items[0].isDeleted &&
                roleCanAccessResource("user", "delete") && (
                  <TableEditMenuButton type="button" onClick={() => restore()}>
                    Restore
                  </TableEditMenuButton>
                )}
              {items.length >= 1 &&
                !items[0].isDeleted &&
                roleCanAccessResource("user", "delete") && (
                  <TableEditMenuButton type="button" onClick={() => setShowModalDelete(true)}>
                    Delete
                  </TableEditMenuButton>
                )}
            </TableEditMenu>
          }
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllUsers.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired
};

// Style Overrides
const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const ErrorSmall = styled(Small)`
  color: ${props => props.theme.error};
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

export default ModalViewAllUsers;
