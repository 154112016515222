import {useState, useRef, useEffect, useMemo} from "react";
import axios, {AxiosError} from "axios";

// Utils
import {useToast} from "../contexts/toast.js";
import useMountedState from "./useMountedState.js";

const API_URL = `${process.env.REACT_APP_API}/api/v1/`;

// Attaches to all requests
axios.defaults.withCredentials = true;

const DEFAULT_MESSAGE =
  "Sorry, that error is on us, please contact support if this wasn't an accident.";

const DETACH_MESSAGE = "Processing request in the background...";

/**
 * @param {String} baseUrl // resource name (e.g. "facilities")
 * @param {Object} suppressMessage // No message
 * @param {Object} suppressError // No message or log to sentry
 * @param {Boolean} redirect // redirect to path
 * @returns {Object}
 */
const useApi = (
  baseUrl,
  options = {suppress: {success: false, error: false, longRequest: true}, redirect: false}
) => {
  const {suppress} = options;

  const isMounted = useMountedState();

  const {addToast} = useToast();

  const url = useRef(`${API_URL}${baseUrl}`);
  const controller = useRef(null);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (baseUrl) url.current = `${API_URL}${baseUrl}`;
  }, [baseUrl]);

  useEffect(() => {
    if (isMounted() && data) setLoading(false);
  }, [isMounted, data]);

  /**
   * Process error response
   * @param {Exception} e
   * @returns {string}
   */
  const formatErrorMessage = e => {
    const {response} = e;
    if (typeof e === "string") return e;
    if (e instanceof Error || e instanceof AxiosError) return response?.data?.message || e.message;
    return DEFAULT_MESSAGE;
  };

  /**
   * @param {Number} id // resource name (e.g. "facilities")
   * @param {Object} params // axios url params overriding global params
   * @param {Object} args // additional arguments for axios config
   * @returns {Object}
   */
  const callGet = async (id, params, args) => {
    try {
      setLoading(true);

      // Handle abort
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      let config = {
        params,
        signal: controller.current.signal
      };

      if (args) config = {...config, ...args};

      const response = await axios.get(id ? `${url.current}/${id}` : url.current, config);

      if (isMounted() && response && response.data)
        setData(Array.isArray(response.data) ? [...response.data] : {...response.data});

      return response;
    } catch (e) {
      const toastMessage = formatErrorMessage(e);

      if (suppress && !suppress.error && toastMessage !== "canceled") {
        addToast(toastMessage, "error");
        setLoading(false);
      }

      return toastMessage;
    }
  };

  /**
   * @param {Object} payload
   * @param {Object} args
   * @returns {Object}
   */
  const callPost = async (payload, args) => {
    setLoading(true);

    let response = null;

    const triggerDetached = setTimeout(() => {
      if (!response && suppress?.longRequest === false) {
        setLoading(false);
        addToast(DETACH_MESSAGE, "info", true);
      }
    }, 3000);

    try {
      // Handle abort request
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      response = await axios.post(url.current, payload, {
        ...args,
        signal: controller.current.signal
      });

      if (suppress && !suppress.success && response?.data?.message)
        addToast(response.data.message, "success");

      if (isMounted() && response?.data)
        setData(Array.isArray(response.data) ? [response.data] : {...response.data.data});

      return response;
    } catch (e) {
      clearTimeout(triggerDetached);

      const toastMessage = formatErrorMessage(e);

      if (suppress && !suppress.error && toastMessage !== "canceled") {
        addToast(toastMessage, "error");
        setLoading(false);
      }

      return toastMessage;
    }
  };

  /**
   * @param {Number} id // resource name (e.g. "facilities")
   * @param {Object} payload // axios url params overriding global params
   * @param {Object} args // additional args
   * @returns {Object}
   */
  const callPut = async (id, payload, args = {}) => {
    setLoading(true);

    let response = null;

    setTimeout(() => {
      if (!response && suppress?.longRequest === false) {
        setLoading(false);
        addToast(DETACH_MESSAGE);
      }
    }, 2000);

    try {
      // Handle abort request
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      response = await axios.put(id ? `${url.current}/${id}` : url.current, payload, {
        ...args,
        signal: controller.current.signal
      });

      if (suppress && !suppress.success && response?.data?.message)
        addToast(response.data.message, "success");

      // Update data to include update
      if (isMounted() && response?.data && data) {
        if (Array.isArray(response.data)) {
          const copy = [...data];
          const index = copy.findIndex(item => item.id === id);
          copy[index] = payload;
          setData(copy);
        } else setData({...response.data.data});
      }

      return response;
    } catch (e) {
      const toastMessage = formatErrorMessage(e);

      if (suppress && !suppress.error && toastMessage !== "canceled") {
        addToast(toastMessage, "error");
        setLoading(false);
      }

      return toastMessage;
    }
  };

  /**
   * @param {Number} id // resource name (e.g. "facilities")
   * @param {Object} params // axios url params overriding global params
   * @returns {Object}
   */
  const callPatch = async (id, payload) => {
    setLoading(true);

    let response = null;

    setTimeout(() => {
      if (!response && suppress?.longRequest === false) {
        setLoading(false);
        addToast(DETACH_MESSAGE);
      }
    }, 2000);

    try {
      // Handle abort request
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      response = await axios.patch(id ? `${url.current}/${id}` : url.current, payload, {
        signal: controller.current.signal
      });

      if (suppress && !suppress.success && response?.data?.message)
        addToast(response.data.message, "success");

      // Update data to include update
      if (isMounted() && response?.data && data) {
        if (Array.isArray(response.data)) {
          const copy = [...data];
          const index = copy.findIndex(item => item.id === id);
          copy[index] = payload;
          setData(copy);
        } else setData({...response.data.data});
      }

      return response;
    } catch (e) {
      const toastMessage = formatErrorMessage(e);

      if (suppress && !suppress.error && toastMessage !== "canceled") {
        addToast(toastMessage, "error");
        setLoading(false);
      }

      return toastMessage;
    }
  };

  /**
   * @param {Number} id // resource name (e.g. "facilities")
   * @param {Object} params // axios url params overriding global params
   * @returns {Object}
   */
  const callDelete = async (id, payload) => {
    setLoading(true);

    let response = null;

    setTimeout(() => {
      if (!response && suppress?.longRequest === false) {
        setLoading(false);
        addToast(DETACH_MESSAGE);
      }
    }, 2000);

    try {
      // Handle abort request
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      const endpoint = id ? `${url.current}/${id}` : url.current;
      response = await axios.delete(endpoint, payload, {signal: controller.current.signal});

      if (suppress && !suppress.success && response?.data?.message)
        addToast(response.data.message, "success");

      // Update data to exclude deleted item
      if (isMounted() && response && data) {
        const copy = [...data];
        copy.splice(
          copy.findIndex(item => item.id === id),
          1
        );

        setData(copy);
      }

      return response;
    } catch (e) {
      const toastMessage = formatErrorMessage(e);

      if (suppress && !suppress.error && toastMessage !== "canceled") {
        addToast(toastMessage, "error");
        setLoading(false);
      }

      return toastMessage;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const api = useMemo(() => ({callGet, callPost, callPut, callPatch, callDelete}), []);

  return {data, api, loading};
};

export default useApi;
