import React, {useCallback, useEffect, useRef, useState, useMemo} from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

// Utils
import {exists} from "../utils/helpers.js";

// Components
import ChartAxis from "./chart/ChartAxis.js";
import ChartLimit from "./chart/ChartLimit.js";
import ChartShaded from "./chart/ChartShaded.js";
import ChartTooltip from "./chart/ChartTooltip.js";
import ChartBar from "./chart/ChartBar.js";

// Style
import {colors, lines} from "../style/components/variables.js";

const BarChart = ({
  dataset = [],
  sources = [],
  yDomain,
  noVals,
  width,
  height,
  labels,
  units,
  allSources,
  acceptable,
  chartId,
  tooltips,
  allowZoom,
  limits = [],
  limitColors,
  infinite,
  infiniteSources,
  active,
  setActive
}) => {
  const svgRef = useRef(null);

  const [scale, setScale] = useState(null);
  const [yAxis, setYAxis] = useState(null);
  const [xAxis, setXAxis] = useState(null);
  const [zoomState, setZoomState] = useState(null);
  const [max, setMax] = useState(null);
  const [tooltipData, setTooltipData] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({});

  const validateAcceptableRange = useCallback(() => {
    if (!acceptable || !sources) return false;
    const first = acceptable[sources[0]];
    for (let i = 0; i < sources.length; i++) {
      if (
        !acceptable[sources[i]] ||
        acceptable[sources[i]][0] !== first[0] ||
        acceptable[sources[i]][1] !== first[1]
      )
        return false;
    }
    return true;
  }, [acceptable, sources]);

  const validateUnits = useCallback(() => {
    if (!units || !sources) return false;
    const first = units[sources[0]];
    for (let i = 0; i < sources.length; i++) {
      if (!units[sources[i]] || units[sources[i]] !== first) return false;
    }
    return true;
  }, [sources, units]);

  const [showUnits, setShowUnits] = useState(validateUnits());

  const getMaxOrderOfMagnitude = () => {
    let maximum;

    if (yDomain) maximum = Math.max(...yDomain.map(val => Math.abs(val)));
    maximum = exists(maximum) ? maximum.toFixed(0).length : 3;
    return maximum;
  };

  const barWidth = useMemo(() => {
    if (sources && dataset && zoomState?.k)
      return (
        Math.min(width / (sources.length * dataset.length), 15) * Math.max(zoomState.k * 0.87, 1)
      );
    if (sources && dataset) return Math.min(width / sources.length, 15) * dataset.length;
    return 15;
  }, [width, sources, dataset, zoomState]);

  const marginTop = 20;
  const marginRight = 30;
  const marginBottom = 40;
  const marginLeft = 30 + getMaxOrderOfMagnitude(sources[0]) * 6;

  const getTicks = useCallback(
    xScale => {
      if (dataset.length < 1 || noVals) return [new Date()];
      if (dataset.length === 1) return [new Date(dataset[0].x)];
      let start = new Date(dataset[0].x);
      start = new Date(start.setHours(0, 0, 0, 0));
      let end = new Date(dataset[dataset.length - 1].x);
      end = new Date(end.setHours(0, 0, 0, 0));
      const regionWidth = xScale(end) - xScale(start);
      const startTime = start.getTime();
      const endTime = end.getTime();
      const dayWidth = (endTime - startTime) / (1000 * 60 * 60 * 24);
      const internalTickCount = Math.min(
        Math.floor(regionWidth / (width > 300 ? 60 : 40)) - 1,
        dayWidth
      );
      const tickInterval = (endTime - startTime) / internalTickCount;
      const newTicks = [start];
      for (let i = 0; i < internalTickCount; i++) {
        newTicks.push(new Date(startTime + tickInterval * (i + 1)));
      }
      newTicks.push(end);
      return newTicks;
    },
    [dataset, width, noVals]
  );

  const formatYTick = (val, newMax, originalMax) => {
    if (val === newMax) return "∞";
    if (val > originalMax) return "";
    return val;
  };

  const x = data => new Date(data.x);
  const y = useCallback(
    (data, source) => {
      if (source in data && !!data[source]) {
        if (data[source].infinite && exists(max)) return max;
        return data[source].val;
      }
      return null;
    },
    [max]
  );

  const getYDomain = useCallback(() => {
    const [minVal, maxVal] = yDomain;
    if (maxVal <= 0) return [minVal, 0];
    if (minVal >= 0) return [0, maxVal];
    return yDomain;
  }, [yDomain]);

  const buildYAxis = useCallback(
    source => {
      const yRange = [height - marginBottom, marginTop];
      let yScale = d3.scaleLinear(getYDomain(source), yRange);
      let axisLeft = d3.axisLeft(yScale).ticks(Math.ceil(height / 50));

      const originalDomain = axisLeft?.scale()?.domain();
      let originalMax;
      if (originalDomain) [, originalMax] = originalDomain;
      else [originalMax] = yDomain;
      let newMax = originalMax;

      let yTicks = null;

      if (exists(infinite)) {
        yScale = d3.scaleLinear([yDomain[0], infinite], yRange).nice();
        axisLeft = d3.axisLeft(yScale).ticks(Math.ceil(height / 50));
        const newDomain = axisLeft?.scale()?.domain();
        newMax = newDomain ? newDomain[1] : originalMax;
        yTicks = axisLeft?.scale()?.ticks(Math.ceil(height / 50));
        if (yTicks)
          axisLeft = d3
            .axisLeft(yScale)
            .tickValues([...yTicks, newMax])
            .tickFormat(val => formatYTick(val, newMax, originalMax));
      }

      return {yAxis: axisLeft, max: newMax, yScale};
    },
    [height, infinite, yDomain, getYDomain]
  );

  useEffect(() => {
    setShowUnits(validateUnits());
    setMax(null);
  }, [sources, validateAcceptableRange, validateUnits]);

  useEffect(() => {
    // bar needs to fit, and bound of xrange will be where the center of the bar lies
    const svg = d3.select(svgRef.current);
    const xScale = d3
      .scaleBand()
      .range([marginLeft, width - marginRight])
      .padding(0.1);
    xScale.domain(dataset.map(d => x(d)));

    const ticksLimit = Math.floor((width - marginRight - marginLeft) / 50);

    const freq = Math.ceil(dataset.length / ticksLimit);

    const localXAxis = d3
      .axisBottom(xScale)
      .tickValues(
        xScale
          .domain()
          .filter((_d, i) => dataset.length < ticksLimit || i % freq === Math.floor(freq / 2))
          .filter(val => {
            const xLoc = xScale(val);
            return xLoc > marginLeft && xLoc < width - marginRight;
          })
      )
      .tickFormat(d3.timeFormat("%-m/%-d/%Y"))
      .tickSizeOuter(0);

    setXAxis(() => localXAxis);

    if (!zoomState) {
      const current = d3.zoomTransform(svg.node());
      setZoomState(current);
    }

    if (zoomState && allowZoom) {
      xScale.range([marginLeft, width - marginRight].map(d => zoomState.applyX(d)));
      svg.selectAll(".bars rect").attr("width", barWidth);
      svg.selectAll(".x-axis").call(localXAxis);
    }

    const {yAxis: localYAxis, max: newMax1, yScale: yScale1} = buildYAxis(sources[0]);

    setYAxis(() => localYAxis);

    if (!exists(max) && infiniteSources && Object.keys(infiniteSources).length > 0) setMax(newMax1);
    setScale({x: xScale, [sources[0]]: yScale1});

    const zoom = d3
      .zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [marginLeft, marginTop],
        [width - marginRight, height - marginBottom]
      ])
      .extent([
        [marginLeft, marginTop],
        [width - marginRight, height - marginBottom]
      ])
      .on("zoom", () => {
        const current = d3.zoomTransform(svg.node());
        setZoomState(current);
      });

    svg.call(zoom);
  }, [
    allowZoom,
    buildYAxis,
    getTicks,
    height,
    infiniteSources,
    marginLeft,
    marginRight,
    max,
    sources,
    width,
    zoomState,
    barWidth,
    dataset
  ]);

  const buildAcceptableRanges = () => {
    const range = acceptable[sources[0]];
    const [minimum, maximum] = range;
    const isSingularData = yDomain[0] === yDomain[1];
    const w = Math.max(width - marginRight - marginLeft, 0);

    if (!scale) return null;

    if (isSingularData) {
      const datapoint = yDomain[0];
      if (!exists(datapoint)) return null;
      if (datapoint === minimum)
        return (
          <>
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="green"
            />
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={scale[sources[0]](minimum)}
              height={Math.max(height - marginBottom - scale[sources[0]](datapoint), 0)}
              width={w}
              color="red"
            />
          </>
        );
      if (datapoint === maximum)
        return (
          <>
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="red"
            />
            <ChartShaded
              chartId={chartId}
              x={marginLeft}
              y={marginTop}
              height={Math.max(scale[sources[0]](datapoint) - marginTop, 0)}
              width={w}
              color="green"
            />
          </>
        );

      const isAboveMin = exists(minimum) && datapoint > minimum;
      const isBelowMax = exists(maximum) && datapoint < maximum;
      const isInGreenRegion = (!exists(minimum) || isAboveMin) && (!exists(maximum) || isBelowMax);

      return (
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={marginTop}
          height={Math.max(height - marginBottom - marginTop, 0)}
          width={w}
          color={isInGreenRegion ? "green" : "red"}
        />
      );
    }

    if (exists(minimum) && exists(maximum)) {
      const maxBound = Math.max(
        Math.min(scale[sources[0]](maximum), height - marginBottom),
        marginTop
      );
      const minBound = Math.max(
        Math.min(scale[sources[0]](minimum), height - marginBottom),
        marginTop
      );

      return (
        <>
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={marginTop}
            height={Math.max(maxBound - marginTop, 0)}
            width={w}
            color="red"
          />
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={maxBound}
            height={Math.max(minBound - maxBound, 0)}
            width={w}
            color="green"
          />
          <ChartShaded
            chartId={chartId}
            x={marginLeft}
            y={minBound}
            height={Math.max(height - marginBottom - minBound, 0)}
            width={w}
            color="red"
          />
        </>
      );
    }

    const divider = minimum ?? maximum;
    const dividerBound = scale[sources[0]](divider);
    return (
      <>
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={marginTop}
          height={Math.max(dividerBound - marginTop, 0)}
          width={w}
          color={exists(minimum) ? "green" : "red"}
        />
        <ChartShaded
          chartId={chartId}
          x={marginLeft}
          y={dividerBound}
          height={Math.max(height - marginBottom - dividerBound, 0)}
          width={w}
          color={exists(minimum) ? "red" : "green"}
        />
      </>
    );
  };

  const buildInfinity = () => {
    if (!scale[sources[0]] || !exists(max)) return null;
    return (
      <ChartLimit
        chartId={chartId}
        color="gray"
        xValues={[marginLeft, width - marginRight]}
        yLocation={scale[sources[0]](max)}
        width={width}
      />
    );
  };

  const scaleInitialized =
    sources && sources.length > 0 && scale && sources[0] in scale && scale.x.bandwidth();

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        {scale && yAxis && xAxis && (
          <>
            <ChartAxis
              constructor={axis => {
                axis.attr("class", "x-axis");
                xAxis(axis);
                axis.select(".domain").remove();
              }}
              transform={`translate(0,${height - marginBottom})`}
              label="DATE"
              labelX={(width - marginLeft - marginRight) / 2 + marginLeft}
              labelY={height - 5}
              clipPathId={`url(#clip-x-tick-labels-${chartId})`}
            />
            <ChartAxis
              constructor={axis => {
                yAxis(axis);
                axis.select(".domain").remove();
                axis.selectAll(".tickdup").remove();
                axis.selectAll(".tick line").clone().classed("tickdup", true);
                axis
                  .selectAll(".tickdup")
                  .attr("x2", width - marginLeft - marginRight)
                  .attr("stroke-opacity", 0.2);
              }}
              transform={`translate(${marginLeft},0)`}
              label={
                sources.length === 1
                  ? `${labels[sources[0]]} (${units[sources[0]].toUpperCase()})`
                  : `${
                      showUnits && Object.keys(units).length > 0
                        ? units[sources[0]].toUpperCase()
                        : "VALUE"
                    }`
              }
              labelX={-(height - marginBottom + marginTop) / 2}
              labelY={10}
              labelTransform="rotate(-90)"
            />

            {scaleInitialized && (
              <>
                {sources.map((source, sourceIdx) => {
                  const color = lines[allSources.indexOf(source) % lines.length];
                  return dataset
                    .filter(d => source in d && !!d[source])
                    .map(d => {
                      const yVal = y(d, source);
                      let zero = scale[sources[0]](0);

                      if (yDomain[0] === yDomain[1] && yVal) {
                        if (yVal < 0) zero = marginTop;
                        else zero = height - marginBottom;
                      }

                      let divisor = (dataset?.length || 0) - 1;
                      if (!divisor || divisor < 0) divisor = 1;

                      const xVal = x(d);

                      return (
                        <ChartBar
                          id={`${chartId}.${source}.bar.${d.x}`}
                          key={`${chartId}.${source}.bar.${d.x}`}
                          chartId={chartId}
                          x={scale.x(xVal) + scale.x.bandwidth() / 2}
                          xDisplay={new Date(x(d)).toLocaleDateString()}
                          y={scale[sources[0]](yVal)}
                          yDisplay={`${d[source].display}`}
                          zero={zero}
                          isNegative={exists(yVal) && yVal < 0}
                          qualifier={d[source].qualifier}
                          missing={d[source].missing}
                          color={color}
                          source={source}
                          setActive={setActive}
                          active={active}
                          setTooltipData={setTooltipData}
                          setTooltipPosition={setTooltipPosition}
                          tooltipPosition={tooltipPosition}
                          units={units[source]}
                          label={labels[source]}
                          width={width}
                          tooltips={tooltips}
                          sourceIdx={sourceIdx}
                          numSources={sources?.length}
                          barWidth={barWidth || 15}
                        />
                      );
                    });
                })}
                {limits &&
                  limits.map(([label, value]) => (
                    <ChartLimit
                      key={`${chartId}-limit-${label}`}
                      chartId={chartId}
                      label={label}
                      value={value}
                      yLocation={scale[sources[0]](value)}
                      xValues={[marginLeft, width - marginRight]}
                      setTooltipData={setTooltipData}
                      setTooltipPosition={setTooltipPosition}
                      tooltipPosition={tooltipPosition}
                      tooltips={tooltips}
                      width={width}
                      units={showUnits ? units[sources[0]].toUpperCase() : ""}
                      color={limitColors[label] || colors.red}
                    />
                  ))}
                {(infiniteSources || exists(infinite)) && exists(max) && buildInfinity()}
                {sources.length === 1 &&
                  acceptable &&
                  acceptable[sources[0]] &&
                  buildAcceptableRanges()}
              </>
            )}

            <clipPath id={`clip-${chartId}`}>
              <rect
                x={marginLeft - 6}
                y={marginTop - 10}
                width={Math.max(width - marginLeft - marginRight + 11, 0)}
                height={Math.max(height - marginTop - marginBottom + 40, 0)}
              />
            </clipPath>
            <clipPath id={`clip-x-tick-labels-${chartId}`}>
              <rect
                x={marginLeft - 2}
                y={0}
                width={Math.max(width - marginLeft - marginRight + 4, 0)}
                height={height + marginTop + marginBottom + 50}
              />
            </clipPath>
            <clipPath id={`clip-sp-${chartId}`}>
              <rect
                x={marginLeft}
                y={marginTop}
                width={Math.max(width - marginLeft - marginRight, 0)}
                height={Math.max(height - marginTop - marginBottom, 0)}
              />
            </clipPath>
          </>
        )}
      </svg>
      <ChartTooltip chartId={chartId} {...tooltipPosition} {...tooltipData} />
    </>
  );
};

BarChart.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.any),
  sources: PropTypes.arrayOf(PropTypes.any),
  yDomain: PropTypes.arrayOf(PropTypes.any),
  ranges: PropTypes.objectOf(PropTypes.any),
  noVals: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  labels: PropTypes.objectOf(PropTypes.any),
  units: PropTypes.objectOf(PropTypes.any),
  allSources: PropTypes.arrayOf(PropTypes.string),
  acceptable: PropTypes.objectOf(PropTypes.any),
  chartId: PropTypes.string,
  tooltips: PropTypes.bool,
  allowZoom: PropTypes.bool,
  limits: PropTypes.arrayOf(PropTypes.any),
  limitColors: PropTypes.objectOf(PropTypes.any),
  infinite: PropTypes.number,
  infiniteSources: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.objectOf(PropTypes.any),
  setActive: PropTypes.func,
  axes: PropTypes.number
};

BarChart.defaultProps = {
  dataset: [],
  sources: [],
  yDomain: [0, 100],
  noVals: true,
  ranges: null,
  width: 200,
  height: 200,
  labels: {},
  acceptable: {},
  units: {},
  allSources: [],
  chartId: "container",
  tooltips: true,
  allowZoom: false,
  limits: [],
  limitColors: {},
  infiniteSources: null,
  infinite: null,
  active: null,
  setActive: null,
  axes: 1
};

export default BarChart;
