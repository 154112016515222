import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faBell, faClone, faExclamation, faTrash} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {Heading, Text, Button, ButtonLoader} from "../../style/components/general.js";

const ModalPreviewDetails = ({visible, setVisible, user, facility, reloadFacilities}) => {
  const {id, name, types, details, builder} = facility;

  const navigate = useNavigate();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const {api: facilityApi} = useApi("facilities");

  const [restoreLoading, setRestoreLoading] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [deletePermanentLoading, setDeletePermanentLoading] = useState(false);

  const restoreFacility = () => {
    setRestoreLoading(true);
    facilityApi
      .callPut(facility.id, {
        userPublicId: currentUser.publicId,
        restore: false
      })
      .then(() => {
        reloadFacilities();
        setVisible(false);
        setRestoreLoading(false);
      });
  };

  const cloneFacility = () => {
    setCloneLoading(true);
    facilityApi
      .callPost({
        userPublicId: user.publicId,
        types: types.map(({id: typeId}) => typeId),
        name,
        details,
        builder,
        duplicate: true
      })
      .then(() => {
        reloadFacilities();
        setVisible(false);
        setCloneLoading(false);
      });
  };

  const deleteFacility = type => {
    setDeleteLoading(prev => ({...prev, [type]: true}));
    facilityApi
      .callDelete(facility.id, {data: {userPublicId: currentUser.publicId, name, type}})
      .then(() => {
        reloadFacilities();
        setVisible(false);
        setDeleteLoading(prev => ({...prev, [type]: false}));
      });
  };

  const deleteAll = () => {
    setDeleteAllLoading(true);
    facilityApi
      .callDelete(facility.id, {data: {userPublicId: currentUser.publicId, name, all: true}})
      .then(() => {
        reloadFacilities();
        setVisible(false);
        setDeleteAllLoading(false);
      });
  };

  const deleteFacilityPermenant = () => {
    setDeletePermanentLoading(true);
    facilityApi
      .callDelete(id, {data: {userPublicId: currentUser.publicId, permanent: true}})
      .then(() => {
        reloadFacilities();
        setVisible(false);
        setDeletePermanentLoading(false);
      });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Manage {name}</ModalTitle>

      {!facility.isDeleted ? (
        <>
          {roleCanAccessResource("notifications", "edit") && (
            <>
              <>
                <Text>Configure notifications.&nbsp;</Text>
                {!facility.hasNotifications && (
                  <StyledSpanQuiet>
                    <Circle>
                      <FontAwesomeIcon icon={faExclamation} />
                    </Circle>
                    &nbsp;&nbsp;
                    <Text>Notifications have not been configured for this facility.</Text>
                  </StyledSpanQuiet>
                )}
              </>
              <Option
                type="button"
                onClick={() => navigate(`/facilities/${facility.slug}/notifications`)}>
                <FontAwesomeIcon icon={faBell} />
                Go to Notifications
              </Option>
            </>
          )}
          {roleCanAccessResource("facility", "create") && (
            <>
              <Text>Use as template.</Text>

              <Option
                type="button"
                title="Clone"
                onClick={cloneFacility}
                loading={cloneLoading ? 1 : 0}>
                <FontAwesomeIcon icon={faClone} />
                Clone {cloneLoading ? <ButtonLoader /> : ""}
              </Option>
            </>
          )}
          {roleCanAccessResource("facility", "archive") && (
            <>
              <Danger>Archive facility.</Danger>
              {types.map(({id: typeId, name: typeName}) => (
                <DangerButton
                  key={typeId}
                  type="button"
                  title={`Archive ${typeName}`}
                  onClick={() => deleteFacility(typeName)}
                  loading={deleteLoading[typeName] ? 1 : 0}>
                  <FontAwesomeIcon icon={faTrash} />
                  Archive {typeName} {deleteLoading[typeName] ? <ButtonLoader /> : ""}
                </DangerButton>
              ))}
              {types.length > 1 && (
                <DangerButton
                  type="button"
                  title="Archive All"
                  onClick={deleteAll}
                  loading={deleteAllLoading ? 1 : 0}>
                  <FontAwesomeIcon icon={faTrash} />
                  Archive All
                </DangerButton>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {roleCanAccessResource("facility", "archive") && (
            <>
              <Text>Restore archived Facility?</Text>
              <Button
                type="button"
                title="Restore"
                onClick={restoreFacility}
                loading={restoreLoading ? 1 : 0}>
                Restore {restoreLoading ? <ButtonLoader /> : ""}
              </Button>
            </>
          )}
          {roleCanAccessResource("facility", "delete") && (
            <>
              <hr />
              <Danger>Danger zone.</Danger>
              <Danger>Permanantly Delete Facility (This action cannot be undone).</Danger>
              <DangerButton
                type="button"
                title="Permanent Delete"
                onClick={deleteFacilityPermenant}
                loading={deletePermanentLoading ? 1 : 0}>
                <FontAwesomeIcon icon={faTrash} />
                Delete {deletePermanentLoading ? <ButtonLoader /> : ""}
              </DangerButton>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

ModalPreviewDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  reloadFacilities: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(Heading)`
  margin: ${pad}px 0;
`;

const Option = styled(Button)`
  margin-bottom: ${pad / 2}px;
  svg {
    margin-right: ${pad}px;
  }
`;

const Danger = styled(Text)`
  color: ${({theme}) => theme.error};
`;

const DangerButton = styled(Button)`
  background: ${({theme}) => theme.error};
  margin-bottom: ${pad / 2}px;

  svg {
    margin-right: ${pad}px;
  }
`;

const Circle = styled.span`
  background: ${({theme}) => theme.error};
  border-radius: 50%;
  padding: ${pad / 2}px;
  padding-left: 6px;
  text-align: center;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const StyledSpanQuiet = styled.span`
  ${flex("row", "nowrap", "start", "center")}
  ${voice.quiet}

  margin-bottom: ${pad / 2}px;

  p {
    ${voice.quiet}
  }
`;

export default ModalPreviewDetails;
