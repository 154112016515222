import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import {generateUniqueKey, addCommas} from "../../utils/helpers.js";

// Style
import {Abbr, LabelBold, TextCenter, TitleCell} from "../../style/components/general.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";

const ReportTable = ({
  id,
  title,
  headers,
  data,
  continued,
  footerRowCount,
  printable,
  fontSize,
  alignment,
  commas,
  bandedRows,
  hideGridlines
}) => {
  const sortedHeadingKeys = useMemo(() => {
    if (!headers) return null;
    const headingKeys = Object.keys(headers);
    headingKeys.sort((a, b) => headers[a].index - headers[b].index);
    return headingKeys;
  }, [headers]);

  const getRegionDataId = idx => {
    if (!continued && !printable) return `${id}-data-${idx}`;
    return undefined;
  };

  const getRegionHeaderId = () => {
    if (!continued && !printable) return `${id}-headers`;
    return undefined;
  };

  return (
    <TableWrapper fontSize={fontSize} alignment={alignment}>
      <LabelPadding>
        <LabelBold>
          {title.toUpperCase()}
          {continued && " (continued)"}
        </LabelBold>
      </LabelPadding>
      {headers && sortedHeadingKeys && (
        <Table>
          <StyledHeader id={getRegionHeaderId()}>
            <tr>
              {sortedHeadingKeys.map(name => {
                const heading = headers[name];
                const {label} = heading;

                return (
                  <StyledTitleCell key={`header-${name}`}>{label.toUpperCase()}</StyledTitleCell>
                );
              })}
            </tr>
          </StyledHeader>
          {data && (
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((item, i) => (
                  <Row
                    id={getRegionDataId(i)}
                    key={generateUniqueKey(item.date)}
                    deleted={item.isDeleted}
                    isFooter={i >= data.length - footerRowCount}
                    isGray={bandedRows && i % 2 === 1}
                    hideGridlines={hideGridlines}>
                    {sortedHeadingKeys.map(
                      (key, j) =>
                        item[key] && (
                          <Cell
                            key={generateUniqueKey(`${key}-${item.date}`)}
                            isFirstColumn={j === 0}
                            alignment={alignment}>
                            <Abbr title={item[key] || ""}>
                              {j > 0 && commas ? addCommas(item[key], 2) : item[key]}
                            </Abbr>
                          </Cell>
                        )
                    )}
                  </Row>
                ))}
            </tbody>
          )}
          {!data ||
            (data.length === 0 && (
              <tbody>
                <Row>
                  <Cell colSpan={Object.keys(headers).length}>
                    <StyledTextCenter>Requested Data Not Found</StyledTextCenter>
                  </Cell>
                </Row>
              </tbody>
            ))}
        </Table>
      )}
    </TableWrapper>
  );
};

ReportTable.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headers: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  continued: PropTypes.bool,
  footerRowCount: PropTypes.number,
  printable: PropTypes.bool,
  fontSize: PropTypes.number,
  alignment: PropTypes.string,
  commas: PropTypes.bool,
  bandedRows: PropTypes.bool,
  hideGridlines: PropTypes.bool
};

ReportTable.defaultProps = {
  data: null,
  headers: null,
  continued: false,
  footerRowCount: 0,
  printable: false,
  fontSize: 14,
  alignment: "left",
  commas: false,
  bandedRows: false,
  hideGridlines: false
};

// Style Overrides
const TableWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  font-size: ${props => props.fontSize}px;
  text-align: ${props => props.alignment};

  @media print {
    font-size: ${props => props.fontSize / 1.3333}pt;
  }
`;

const LabelPadding = styled.div`
  width: 100%;
  padding: ${pad / 2}px;
`;

const Table = styled.table`
  width: 100%;
`;

const Row = styled.tr`
  padding: ${pad * 2}px;
  border-bottom: ${border} solid rgba(0, 0, 0, 0.2);

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.hideGridlines
      ? css`
          border-bottom: none;
        `
      : ""}

  ${props =>
    props.isFooter
      ? css`
          background-color: ${props.theme.secondary};

          td {
            color: ${props.theme.tertiary};
          }
        `
      : ""}


${props =>
    props.isGray && !props.isFooter
      ? css`
          background-color: rgba(0, 0, 0, 0.13);
        `
      : ""}

  ${props =>
    props.deleted &&
    css`
      background-color: rgba(0, 0, 0, 0.08);
    `}
`;

const Cell = styled.td`
  padding: ${pad * 0.7}px;
  position: relative;
  color: ${props => props.theme.secondary};
  vertical-align: ${props => props.alignRows || "middle"};
  max-width: 300px;

  ${props =>
    !props.isFirstColumn
      ? css`
          word-break: break-all;
          word-wrap: break-word;
        `
      : ""}

  ${bp(6)} {
    max-width: 400px;
  }
`;

const StyledHeader = styled.thead`
  background: ${props => props.theme.secondary};

  th {
    vertical-align: middle;
  }
`;

const StyledTitleCell = styled(TitleCell)`
  word-wrap: break-word;
  text-align: inherit;
  line-height: 1.5;
`;

const StyledTextCenter = styled(TextCenter)`
  font-size: inherit;
`;

export default ReportTable;
