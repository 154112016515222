import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, useFieldArray, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {initialFieldValues, fieldValidationSchema} from "../../utils/builder.js";
import {generateUniqueKey, getRandomColor} from "../../utils/helpers.js";
import {SCHEDULE_ICON_OPTIONS} from "./helpers.js";

// Components
import FieldBuilder from "../../components/FieldBuilder.js";
import Help from "../../components/Help.js";
import Stage from "./Stage.js";
import Modal from "../../components/Modal.js";
import ModalStage from "./ModalStage.js";
import {InputText, InputCheck, InputIcon, InputColor} from "../../components/form/FormInputs.js";

// Style
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {
  Button,
  ButtonFull,
  HeadingCenter,
  FormGroup,
  FormField,
  Form,
  Label,
  Text
} from "../../style/components/general.js";

const REMINDER = "reminder";
const ACTION = "action";
const DEFAULTS = [REMINDER, ACTION];

const DEFAULT_COLOR = colors.steelTeal.slice(1);

const DEFAULT_STAGE = [{name: "", color: "2D2E2E", hasFields: false}];

const defaultValues = {
  name: "",
  color: DEFAULT_COLOR,
  icon: SCHEDULE_ICON_OPTIONS[0].value,
  stages: DEFAULT_STAGE,
  tracked: false,
  hasFields: false,
  fields: [{...initialFieldValues, required: false}]
};

const ModalEventType = ({
  visible,
  setVisible,
  goBack,
  types,
  existingType,
  updateTemplates,
  units,
  setUnits
}) => {
  const isMounted = useMountedState();

  const [targetStage, setTargetStage] = useState(null);
  const [showModalStage, setShowModalStage] = useState(false);
  const [unitsToAdd, setUnitsToAdd] = useState([]);

  const {api} = useApi("event-types");

  const schema = yup.object().shape({
    name: yup.string().required("Name is a required field."),
    color: yup
      .string()
      .test({
        message: "Color has already been selected",
        test: val => {
          let filtered = types;
          if (existingType) filtered = types.filter(({name}) => existingType.name !== name);
          return !filtered.map(({color}) => color.toUpperCase()).includes(val.toUpperCase());
        }
      })
      .required("Color is required"),
    icon: yup.string().required("Icon is required"),
    stages: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Name is required"),
        hasFields: yup.bool()
      })
    ),
    tracked: yup.bool(),
    hasFields: yup.bool(),
    fields: yup
      .array()
      .nullable()
      .when("hasFields", {
        is: val => !!val,
        then: () => yup.array().of(fieldValidationSchema()).nullable()
      })
  });

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });
  const {control, watch, setValue, handleSubmit, reset} = form;

  const {append: addStage} = useFieldArray({control, name: "stages"});
  const {
    fields: watchFields,
    insert: addField,
    remove: removeField
  } = useFieldArray({control, name: "fields"});

  const watchName = watch("name");
  const watchColor = watch("color");
  const watchIcon = watch("icon");
  const watchTracked = watch("tracked");
  const watchStages = watch("stages");
  const watchHasFields = watch("hasFields");

  const getDefaultColor = useCallback(
    random => {
      if (!types.map(({color}) => color).includes(random)) return random;
      return getDefaultColor(getRandomColor());
    },
    [types]
  );

  // Set default color to non-exsiting option
  useEffect(() => {
    if (isMounted() && !existingType) setValue("color", getDefaultColor(DEFAULT_COLOR));
  }, [isMounted, existingType, getDefaultColor, setValue]);

  // Edit Template - set defaults
  useEffect(() => {
    if (isMounted() && existingType) {
      const {name, fields, ...rest} = existingType;

      const {allIds, byId} = fields || {};
      reset(prev => ({
        ...prev,
        ...rest,
        name: name.toUpperCase(),
        hasFields: !!allIds?.length,
        fields: allIds?.map(key => ({...byId[key], name: key}))
      }));
    }
  }, [isMounted, existingType, reset]);

  const getTemplateFields = existingTypeFields => {
    const allIds = [];
    const byId = {};
    existingTypeFields.map(field => {
      const generated = generateUniqueKey(field.label);
      if (!field.name) field.name = generated;
      field.element = "field";
      field.children = [];
      allIds.push(field.name);
      byId[field.name] = field;
    });
    return {
      allIds,
      byId
    };
  };

  const saveStage = ({index, name, restrictTo, help, builder}) => {
    const watchTarget = watch(`stages.${index}`);
    setValue(`stages.${index}`, {
      ...watchTarget,
      name,
      restrictTo,
      help,
      builder
    });

    setTargetStage(null);
    setShowModalStage(false);
    // setStagesOpen(prev => ({
    //   ...prev,
    //   [key]: true
    // }));

    // if (hasSubmitted.current) trigger();
  };

  const handleSubmitEventType = ({name, color, icon, tracked, stages, hasFields, fields}) => {
    let existingTypeFields;

    if (hasFields) existingTypeFields = getTemplateFields(fields);

    const params = {
      name,
      color,
      icon,
      tracked,
      stages,
      fields: {
        allIds: existingTypeFields ? existingTypeFields.allIds : [],
        byId: existingTypeFields ? existingTypeFields.byId : {}
      },
      units: unitsToAdd
    };

    const request = existingType ? api.callPut(existingType.id, params) : api.callPost(params);

    request.then(({status}) => {
      if (status === 200 || status === 201) {
        reset(defaultValues);
        updateTemplates();
        goBack();
      }
    });
  };

  if (showModalStage && targetStage)
    return (
      <ModalStage
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalStage(false)}
        stage={targetStage}
        existing={targetStage && watch(`stages.${targetStage.index}`)}
        units={units}
        setUnits={setUnits}
        unitsToAdd={unitsToAdd}
        setUnitsToAdd={setUnitsToAdd}
        allowNameEdit
        saveStage={saveStage}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton goBack={goBack} maxWidth="600px">
      <HeadingCenter>{existingType ? "Edit" : "Add"} Event Type</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSubmitEventType)} noValidate>
          <FormGroup>
            {!DEFAULTS.includes(watchName.toLowerCase()) ? (
              <FormField>
                <InputText name="name" label="Provide a name" required />
              </FormField>
            ) : (
              <TypeLabel>{watchName.toUpperCase()}</TypeLabel>
            )}

            <FormField>
              <ColorPicker>
                <InputColor
                  name="color"
                  label="Provide a color"
                  hasHelp
                  help="Events of this type will appear on the calendar in the selected color."
                  required
                />
              </ColorPicker>
            </FormField>

            <FormField>
              <InputIcon
                name="icon"
                label="Icon"
                options={SCHEDULE_ICON_OPTIONS}
                defaultValue={watchIcon || defaultValues.icon}
                color={watchColor}
                required
              />
            </FormField>

            {watchName.toLowerCase() !== REMINDER && (
              <FormField>
                <InputCheck name="tracked">
                  <Text inline>
                    Do you want to track results of events created using this Event Type&nbsp;
                    <Help>
                      These events will show in the event record table and will require user input.
                    </Help>
                  </Text>
                </InputCheck>
              </FormField>
            )}
          </FormGroup>

          {watchTracked && !DEFAULTS.includes(watchName) && (
            <FormGroup>
              <FormField>
                <Label bold>Stages: (Default)</Label>
                <Stages allowNewStages={!existingType}>
                  {watchStages?.map((stage, index) => (
                    <Stage
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      control={control}
                      stage={stage}
                      index={index}
                      allowUpdates={existingType?.name !== "reminder"}
                      allowUpdateStageList={!existingType}
                      setTargetStage={setTargetStage}
                      setShowModalStage={setShowModalStage}
                    />
                  ))}
                  {!existingType && (
                    <AddStage type="button" onClick={() => addStage(DEFAULT_STAGE)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </AddStage>
                  )}
                </Stages>
              </FormField>
              <FormField>
                <InputCheck name="hasFields">
                  Would you like to add default fields? (Users will be prompted on event creation)
                </InputCheck>
              </FormField>
              {watchHasFields &&
                watchFields?.map((field, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <CustomField key={index}>
                    <FieldBuilder
                      name={`fields.${index}`}
                      field={field}
                      form={form}
                      units={units}
                    />
                    <Menu>
                      <Edit type="button" onClick={() => removeField(index)}>
                        -
                      </Edit>
                      <Edit type="button" onClick={() => addField(index + 1, initialFieldValues)}>
                        +
                      </Edit>
                    </Menu>
                  </CustomField>
                ))}
            </FormGroup>
          )}
          <ButtonFull type="submit">Save</ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEventType.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  existingType: PropTypes.objectOf(PropTypes.any),
  updateTemplates: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired
};

ModalEventType.defaultProps = {
  existingType: null
};

// Style Overrides
const TypeLabel = styled.label`
  font-weight: bold;
  margin-bottom: ${pad}px;
`;

const CustomField = styled.div`
  width: 100%;
  border-radius: ${radius};
  margin-top: ${pad}px;

  &:first-child {
    margin: 0;
  }
`;

const Menu = styled.div`
  display: inline-flex;
  margin: ${pad}px 0 0;
`;

const Edit = styled(Button)`
  &:first-child {
    margin-right: ${pad}px;
  }
`;

const ColorPicker = styled.div`
  position: relative;
  width: 200px;

  :first-child {
    margin-right: ${pad}px;
  }
`;

const Stages = styled.div`
  position: relative;
  width: 65%;
  max-width: 600px;
  padding: ${({allowNewStages}) =>
    allowNewStages ? `${pad}px ${pad}px ${pad * 5}px;` : `${pad}px`};
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
`;

const AddStage = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: ${pad}px;
`;

export default ModalEventType;
