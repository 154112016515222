import React, {useContext, useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faExclamation, faFileExport} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import Badge from "../../components/Badge.js";
import ModalExport from "../../components/ModalExport.js";
import ModalViewAllFacilities from "../general/ModalViewAllFacilities.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {colors, pad} from "../../style/components/variables.js";
import {
  Heading,
  Inline,
  Text,
  TableWrapper,
  TableHeaderWrap,
  TableFooter,
  Button,
  ListItem,
  List,
  TableMenu,
  Warning,
  Error,
  Small,
  Abbr
} from "../../style/components/general.js";

const TableFacilities = ({
  facilities,
  setFacilities,
  query,
  filters,
  orderBy,
  showArchived,
  currentPage,
  setCurrentPage,
  setTarget,
  setShowSettingsModal
}) => {
  const isMounted = useMountedState();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const [visible, setVisible] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);
  // Pagination
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(5);

  const {api} = useApi("facilities");

  const formatFacilities = useCallback(
    prevFacilities =>
      prevFacilities?.map(facility => {
        const {isDeleted, id, slug, name, type, details, checksheets, events} = facility;
        return {
          isDeleted,
          id: id,
          name: (
            <TableLink to={`/facilities/${slug}`} title={name}>
              {name}
            </TableLink>
          ),
          type: <List column>{type}</List>,
          jobs: details?.jobs?.length > 0 && (
            <List column>
              {details.jobs.map(job => (
                <ListItem key={job}>{job}</ListItem>
              ))}
            </List>
          ),
          checksheets: checksheets?.map(
            ({id: cId, name: cName, frequency, draftCount, overdueCount}) => (
              <NotifyList key={cId} column>
                <TableLink to={`/facilities/${slug}/tasks`} title={cName}>
                  {cName}
                  {frequency?.name && <Frequency>({frequency.name})</Frequency>}
                </TableLink>
                <NotifyListItem>
                  <Small>Not completed today</Small>
                </NotifyListItem>
                {draftCount > 0 && (
                  <NotifyListItem>
                    <Warning>{draftCount} incomplete record(s)</Warning>
                  </NotifyListItem>
                )}
                {overdueCount > 0 && (
                  <NotifyListItem>
                    <Error>{overdueCount} overdue record(s)</Error>
                  </NotifyListItem>
                )}
              </NotifyList>
            )
          ),
          events: events?.map(({id: eId, name: eName, available, draftCount, overdueCount}) => (
            <NotifyList key={eId} column>
              <TableLink to={`/facilities/${slug}/schedule`} title={eName}>
                {eName}
              </TableLink>
              {available && (
                <NotifyListItem>
                  <Small>Not completed today</Small>
                </NotifyListItem>
              )}
              {draftCount > 0 && (
                <NotifyListItem>
                  <Warning>{draftCount} incomplete record(s)</Warning>
                </NotifyListItem>
              )}
              {overdueCount > 0 && (
                <NotifyListItem>
                  <Error>{overdueCount} overdue record(s)</Error>
                </NotifyListItem>
              )}
            </NotifyList>
          )),
          settings: (roleCanAccessResource("facility", "delete") ||
            roleCanAccessResource("facility", "archive") ||
            roleCanAccessResource("facility", "create")) && (
            <Settings
              type="button"
              onClick={() => {
                setTarget(facility);
                setShowSettingsModal(true);
              }}>
              {!facility.hasNotifications && (
                <Badge offsetX="7px" offsetY="8px" color={colors.red} diameter="16px">
                  <StyledAbbr title="Notifications not configured">
                    <FontAwesomeIcon icon={faExclamation} />
                  </StyledAbbr>
                </Badge>
              )}
              <FontAwesomeIcon icon={faCog} className="primaryIcon">
                Settings
              </FontAwesomeIcon>
            </Settings>
          )
        };
      }),
    [roleCanAccessResource, setShowSettingsModal, setTarget]
  );

  const getFacilities = useCallback(() => {
    setLoading(true);

    const filter = {};

    const payload = {
      userPublicId: currentUser.publicId,
      page: currentPage,
      orderBy: orderBy,
      groupBy: "name",
      withChecksheets: true,
      withEvents: true,
      withDeleted: showArchived
    };

    if (limit !== "all") payload.limit = limit;

    if (query) filter.Search = query;
    if (filters?.types) filter.Type = filters.types;
    if (filters?.checksheets) filter.Checksheet = filters.checksheets;

    if (filter && Object.keys(filter).length > 0) payload.filter = JSON.stringify(filter);

    api.callGet(null, payload).then(({status, data}) => {
      if (status === 200) {
        const {facilities: facilityData, total: cTotal, page, pages} = data;
        const formatted = formatFacilities(facilityData);

        // Page Limit
        const option = value => ({
          label: value,
          value
        });

        let count = 10;
        const temp = [option(5)];
        while (count < cTotal + 10 && count <= 50) {
          temp.push(option(count));
          count += 10;
        }

        if (cTotal > temp[temp.length - 1].value) temp.push({label: "All", value: cTotal});

        setTotal(cTotal);
        setCurrentPage(page);
        setPageTotal(pages);
        setVisible(formatted || []);
        setFacilities(facilityData);
        setLimits(temp);
        setLoading(false);
      }
    });
  }, [
    api,
    currentPage,
    currentUser.publicId,
    filters,
    formatFacilities,
    limit,
    orderBy,
    query,
    setCurrentPage,
    setFacilities,
    showArchived
  ]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && facilities === null) getFacilities();
  }, [facilities, getFacilities, isMounted]);

  // Update facilities on query, sort, limit or delete
  useEffect(() => {
    if (isMounted()) setFacilities(null);
  }, [isMounted, query, orderBy, limit, showArchived, setFacilities]);

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <TableHeading>Facilities</TableHeading>
          <TableMenu>
            {roleCanAccessResource("facility", "export") && facilities && facilities.length > 0 && (
              <Button type="button" onClick={() => setShowModalExport(true)}>
                <FontAwesomeIcon icon={faFileExport} />
              </Button>
            )}
          </TableMenu>
        </TableHeaderWrap>
        <BaseTable
          headings={{
            settings: {header: " ", disabled: true},
            id: {header: "", disabled: true},
            name: {header: "Name", disabled: true},
            type: {header: "Type(s)", disabled: true},
            jobs: {header: "Job Number(s)", disabled: true},
            checksheets: {header: "Checksheets", disabled: true},
            events: {header: "Events", disabled: true}
          }}
          data={visible}
          updateData={getFacilities}
          orderBy={orderBy}
          groupBy="name"
          loading={loading}
          alignRows="top"
          cellOverflow
        />
        <TableFooter>
          {total > 0 && (
            <>
              <Inline>
                {total > 10 && limits.length > 0 && (
                  <PageLimit>
                    <Dropdown
                      options={limits}
                      selection={limit}
                      setSelection={selection => {
                        setLimit(selection);
                        setCurrentPage(1);
                      }}
                    />
                  </PageLimit>
                )}
                <Total>
                  {pageTotal > 1 && limits.length > 0 && `${limit} per page, `}{" "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                    {total} total
                  </ModalViewLink>
                </Total>
              </Inline>
              {pageTotal > 1 && (
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  pageTotal={pageTotal}
                  updateData={getFacilities}
                />
              )}
            </>
          )}
        </TableFooter>
      </TableWrapper>

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={exportParams =>
            api.callGet(null, {
              userPublicId: currentUser.publicId,
              ...exportParams
            })
          }
        />
      )}

      {showModalViewAll && (
        <ModalViewAllFacilities visible={showModalViewAll} setVisible={setShowModalViewAll} />
      )}
    </>
  );
};

TableFacilities.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.any),
  setFacilities: PropTypes.func.isRequired,
  query: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any),
  orderBy: PropTypes.string,
  showArchived: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setTarget: PropTypes.func.isRequired,
  setShowSettingsModal: PropTypes.func.isRequired
};

TableFacilities.defaultProps = {
  facilities: null,
  query: null,
  filters: null,
  orderBy: "asc",
  showArchived: false
};

// Style Overrides
const TableHeading = styled(Heading)`
  white-space: nowrap;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const NotifyList = styled(List)`
  list-style: circle;
`;

const NotifyListItem = styled(ListItem)`
  padding: ${pad / 2}px 0;
  margin-left: ${pad * 1.5}px;
`;

const TableLink = styled(Link)`
  font-weight: bold;
  align-self: flex-start;
  vertical-align: top;

  &:hover {
    color: ${({theme}) => theme.primary};
    transition: all ease 0.5s;
  }
`;

const Frequency = styled.span`
  text-transform: uppercase;
  padding: 0 ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
  ${voice.quiet};
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const Total = styled(Text)`
  ${voice.quiet};
`;

const StyledAbbr = styled(Abbr)`
  padding-left: 0.55px;
`;

const Settings = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${pad}px ${pad}px ${pad / 2}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    fill: ${({theme}) => theme.secondary};
  }
`;

export default TableFacilities;
