import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faEdit,
  faEye,
  faEyeSlash,
  faFileExport,
  faFileMedical,
  faKeyboard,
  faSearch,
  faUpload,
  faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {useToast} from "../../contexts/toast.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {hexToRgba, openLink, prettyDateInUserTimezone} from "../../utils/helpers.js";
import {isMobileSize} from "../../utils/responsive.js";

// Components
import ToggleSwitch from "../../components/ToggleSwitch.js";
import BaseTable from "../../components/BaseTable.js";
import Dropdown from "../../components/Dropdown.js";
import Pagination from "../../components/Pagination.js";
import ModalRowAdd from "./ModalRowAdd.js";
import ModalRowPreview from "./ModalRowPreview.js";
import ModalRowDelete from "./ModalRowArchive.js";
import ModalExport from "../../components/ModalExport.js";
import ModalFailedUpload from "../facility/ModalFailedUpload.js";

// Styles
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {pad, transition} from "../../style/components/variables.js";
import {
  Inline,
  Heading,
  TableWrapper,
  TableMenu,
  TableFooter,
  Search,
  SearchWrapper,
  SearchIcon,
  Button,
  Arrow,
  Text,
  TableEditMenu,
  TableEditMenuButton,
  Abbr,
  Input
} from "../../style/components/general.js";

const CustomTable = ({
  index,
  table,
  active,
  setActive,
  handleEditTable,
  handleViewAll,
  inGlobalTables
}) => {
  const {
    id: tableId,
    name,
    label,
    columns,
    fileOnly,
    tags,
    facility: {name: facilityName, slug: facilitySlug, id: facilityId, isDeleted, type}
  } = table;

  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {currentUser, roleCanAccessPage, roleCanAccessResource, atLeast} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  const maxFileSize = useMemo(() => {
    let {maxFileSize: current} = settings;
    if (!current) current = 5;
    else {
      current = parseInt(current, 10);
      if (!current || Number.isNaN(current)) current = 5;
      else if (current > 8) current = 8;
    }
    return current;
  }, [settings]);

  // Custom Table
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [headings, setHeadings] = useState({});

  // Base Table
  const [items, setItems] = useState([]);
  const [searchType, setSearchType] = useState("text");
  const [showOptions, setShowOptions] = useState(false);
  const [targetRow, setTargetRow] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(null);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalFailedUpload, setShowModalFailedUpload] = useState(false);
  const [query, setQuery] = useState(null);
  const [failedUploads, setFailedUploads] = useState(false);
  const [validUploads, setValidUploads] = useState(false);

  // Pagination
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);

  const showDeleted = useRef(false);

  // Previous Hooks
  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);
  const prevLimit = usePrevious(limit);

  const {api, loading} = useApi("custom-table-rows", {suppress: {longRequest: false}});
  const {api: apiFiles} = useApi("files", {suppress: {longRequest: false}});

  const formatTableData = useCallback(
    payload =>
      payload.map(item => {
        const newItem = {};

        const isSuper = !currentUser.role?.id && atLeast("super");
        const canAccess =
          isSuper ||
          !item.roles?.length ||
          item.roles.some(role => currentUser.role?.id === role.id);

        Object.keys(headings).forEach(heading => {
          if (heading === "preview" && roleCanAccessResource("custom_table_row", "view")) {
            if (!canAccess)
              newItem[heading] = (
                <NotAllowed
                  onClick={() => {
                    addToast("You do not have permission to view this row", "error");
                  }}>
                  <Abbr title="You do not have permission to view this row">View</Abbr>
                </NotAllowed>
              );
            else
              newItem[heading] = (
                <Button
                  onClick={() => {
                    setTargetRow(item);
                    setShowModalPreview(true);
                  }}>
                  View
                </Button>
              );
          } else if (heading === "createdAt" || heading === "updatedAt")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone);
          else if (heading === "date")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone, "MMM D YYYY");
          else newItem[heading] = item[heading];
          newItem.message = item.message;
          newItem.files = item.files;
          newItem.id = item.id;
          newItem.tags = item.tags;
          newItem.isDeleted = item.isDeleted;
          newItem.roles = item.roles;
          newItem.canAccess = canAccess;
        });
        return newItem;
      }),
    [headings, roleCanAccessResource, timezone, currentUser, atLeast, addToast]
  );

  const getTableData = useCallback(() => {
    setItems([]);
    setShowOptions(false);
    setTargetRow(null);

    const filter = {showDeleted: showDeleted.current};

    if (query) filter.Search = query;

    const payload = {
      tableId,
      page: current,
      orderBy: orderBy,
      groupBy,
      limit,
      filter: JSON.stringify(filter)
    };

    api.callGet(null, payload).then(({status, data}) => {
      if (status === 200 && data) {
        const formatted = formatTableData(data.notes);

        // Page Limit
        const {total: dataTotal} = data;

        let count = 10;
        const temp = [];
        while (count < dataTotal + 10 && count <= 50) {
          temp.push(count);
          count += 10;
        }
        setLimits(temp);

        setTableData(formatted);
        setTotal(dataTotal);
        setCurrent(data.page);
        setPageTotal(data.pages);
      }
    });
  }, [api, current, formatTableData, tableId, query, orderBy, groupBy, limit]);

  // Format Headings
  useEffect(() => {
    if (isMounted()) {
      const tempHeaders = {};
      columns.map(col => {
        if (col.name === "preview" || col.name === "updatedBy")
          tempHeaders[col.name] = {header: col.label, disabled: true};
        else tempHeaders[col.name] = {header: col.label, disabled: false};
      });
      setHeadings(tempHeaders);
    }
  }, [isMounted, api, columns]);

  // Open first table
  useEffect(() => {
    if (isMounted() && index === 1) setOpen(true);
  }, [isMounted, index]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && open && !tableData) getTableData();
  }, [isMounted, getTableData, open, tableData]);

  // Update rows on sort or limit
  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      prevLimit &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy || prevLimit !== limit)
    )
      getTableData();
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, prevLimit, orderBy, groupBy, limit]);

  // Search
  useEffect(() => {
    if (open && (query || query === "")) {
      getTableData();
    }
  }, [open, getTableData, query]);

  const handleSearch = e => {
    setCurrent(1);
    setQuery(e.target.value);
  };

  const megabytesToBytes = mb => {
    const multiplier = 1024 * 1024;
    return mb * multiplier;
  };

  const handleSave = useCallback(
    target => {
      const params = {
        userId: currentUser.publicId,
        tableId,
        facilityId,
        tags: tags.map(t => t.label)
      };

      const fileData = new FormData();

      const files = Object.values(target.files);

      const failed = [];
      const successful = [];

      for (let idx = 0; idx < files.length; idx++) {
        const file = files[idx];
        const {name: fileName, type: fileType, size} = file;

        if (size > megabytesToBytes(maxFileSize)) failed.push(file);
        else {
          fileData.append(`file-${idx}`, new File([file], fileName, {type: fileType}));
          successful.push(file);
        }
      }

      if (failed.length === 0)
        api
          .callPost(fileData, {
            params,
            headers: {"Content-Type": "multipart/form-data"}
          })
          .then(() => getTableData());
      else {
        setFailedUploads(failed);
        setValidUploads(successful);
        setShowModalFailedUpload(true);
      }
    },
    [api, currentUser, facilityId, getTableData, maxFileSize, tableId, tags]
  );

  const fileInputOnChange = useCallback(
    ({target}) => {
      if (target?.files && target.files.length > 0) handleSave(target);
    },
    [handleSave]
  );

  const openFile = fileId => {
    apiFiles.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
    });
  };

  // Drop Files
  const dropzone = useRef();
  const [showDropzone, setShowDropzone] = useState(false);

  const handleDragStart = e => {
    e.preventDefault();
    setShowDropzone(true);
  };

  const handleDragEnd = e => {
    e.preventDefault();
    setShowDropzone(false);
  };

  const handleDrop = useCallback(
    e => {
      e.preventDefault();
      setShowDropzone(false);

      if (!isDeleted) handleSave(e.dataTransfer);
    },
    [handleSave, isDeleted]
  );

  useEffect(() => {
    if (isMounted() && open && dropzone.current && !isDeleted) {
      dropzone.current.addEventListener("dragover", handleDragStart);
      dropzone.current.addEventListener("dragleave", handleDragEnd);
      dropzone.current.addEventListener("drop", handleDrop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, open]);

  const tableLabel = useMemo(() => {
    let l = "";
    if (inGlobalTables && facilityName) l += `${facilityName.toUpperCase()} ${type} `;
    l += label;
    return l;
  }, [facilityName, inGlobalTables, label, type]);

  return (
    <CustomTableWrapper key={name}>
      <TableMenuRow>
        <TableHeading>
          <TableLabel>
            <Abbr title={tableLabel}>{tableLabel}</Abbr>
          </TableLabel>
          {!inGlobalTables && roleCanAccessResource("custom_table", "update") && !isDeleted && (
            <EditButton type="button" onClick={() => handleEditTable(table)}>
              <Abbr title="Edit Table">
                <FontAwesomeIcon icon={faEdit} />
              </Abbr>
            </EditButton>
          )}
          {inGlobalTables && facilitySlug && roleCanAccessPage("records") && (
            <EditLink to={`/facilities/${facilitySlug}/tables`}>
              <Abbr title="View in Facility">
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Abbr>
            </EditLink>
          )}
        </TableHeading>
        <ToggleActive
          type="button"
          onClick={() => {
            setOpen(prev => !prev);
            setActive(index);
          }}>
          <Arrow rotate={!open ? "180deg" : "270deg"} />
        </ToggleActive>
      </TableMenuRow>
      {open && (
        <div>
          <TableMenuRow>
            <HeaderMenu>
              <MenuItems active={active}>
                <SearchWrapper>
                  {searchType === "text" && (
                    <SearchIcon>
                      <FontAwesomeIcon icon={faSearch} />
                    </SearchIcon>
                  )}
                  <Search
                    name="search"
                    type={searchType}
                    placeholder="Rows..."
                    onChange={handleSearch}
                  />
                  <Spaced>
                    <ToggleSwitch
                      iconLeft={faKeyboard}
                      iconRight={faCalendarDay}
                      textLeft="Text"
                      textRight="Date"
                      onChange={() => setSearchType(prev => (prev === "text" ? "date" : "text"))}
                      options={["text", "date"]}
                      defaultSelected="text"
                    />
                  </Spaced>
                </SearchWrapper>
                <CustomTableMenu>
                  {(roleCanAccessResource("custom_table_row", "update") ||
                    roleCanAccessResource("custom_table_row", "archive")) && (
                    <>
                      {tableData?.length > 0 && !isDeleted && (
                        <Button
                          type="button"
                          title={showOptions ? "Cancel" : "Options"}
                          onClick={() => setShowOptions(!showOptions)}>
                          {showOptions ? "Cancel" : "Options"}
                        </Button>
                      )}
                      {!isMobileSize() && (
                        <Button
                          type="button"
                          title={showDeleted.current ? "Show Archived" : "Hide Archived"}
                          onClick={() => {
                            showDeleted.current = !showDeleted.current;
                            getTableData();
                          }}>
                          <FontAwesomeIcon icon={showDeleted.current ? faEyeSlash : faEye} />
                          &nbsp;Archived
                        </Button>
                      )}
                    </>
                  )}

                  {roleCanAccessResource("custom_table_row", "export") && tableData?.length > 0 && (
                    <Button type="button" title="Export" onClick={() => setShowModalExport(true)}>
                      <FontAwesomeIcon icon={faFileExport} />
                    </Button>
                  )}
                  {roleCanAccessResource("custom_table_row", "create") && !isDeleted && (
                    <Button
                      type="button"
                      title={fileOnly ? "Upload" : "Add Row"}
                      onClick={() => {
                        if (!fileOnly) setShowModalAdd(true);
                        else document.getElementById(`single_upload_${tableId}`).click();
                      }}>
                      {fileOnly && (
                        <Input
                          id={`single_upload_${tableId}`}
                          type="file"
                          onChange={fileInputOnChange}
                          multiple
                          hidden
                        />
                      )}
                      <FontAwesomeIcon icon={fileOnly ? faUpload : faFileMedical} />
                    </Button>
                  )}
                </CustomTableMenu>
              </MenuItems>
            </HeaderMenu>
          </TableMenuRow>
          <RowWrapper ref={dropzone}>
            <Dropzone visible={!isDeleted && showDropzone}>
              <div>
                <FontAwesomeIcon icon={faUpload} />
                <Text>Upload Files</Text>
              </div>
            </Dropzone>
            <BaseTable
              headings={headings}
              data={tableData}
              loading={loading}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              items={items}
              setItems={setItems}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              editMenu={
                isDeleted ? null : (
                  <TableEditMenu>
                    {items && items.length === 0 && (
                      <Text inverted>Please select items below for options.</Text>
                    )}
                    {roleCanAccessResource("custom_table_row", "update") &&
                      !fileOnly &&
                      items &&
                      items.length === 1 && (
                        <TableEditMenuButton
                          type="button"
                          onClick={() => {
                            if (items && items.length > 0) {
                              setTargetRow(items[0]);
                              setShowModalAdd(true);
                            }
                          }}>
                          Edit
                        </TableEditMenuButton>
                      )}
                    {roleCanAccessResource("custom_table_row", "archive") &&
                      items &&
                      items.length >= 1 && (
                        <TableEditMenuButton type="button" onClick={() => setShowModalDelete(true)}>
                          Archive
                        </TableEditMenuButton>
                      )}
                  </TableEditMenu>
                )
              }
            />
          </RowWrapper>
          <TableFooter>
            <Inline>
              {total > 10 && limits.length > 0 && (
                <PageLimit>
                  <Dropdown
                    options={limits}
                    selection={limit}
                    setSelection={selection => {
                      setLimit(selection);
                      setCurrent(1);
                    }}
                  />
                </PageLimit>
              )}
              <Total>
                {pageTotal > 1 && limits.length > 0 && "per page, "}{" "}
                <ModalViewLink
                  disabled={!total || total <= 10}
                  onClick={!total || total <= 10 ? undefined : () => handleViewAll(table)}>
                  {total} total
                </ModalViewLink>
              </Total>
            </Inline>
            {pageTotal > 1 && (
              <Pagination
                current={current}
                setCurrent={setCurrent}
                pageTotal={pageTotal}
                updateData={getTableData}
              />
            )}
          </TableFooter>
        </div>
      )}

      {showModalAdd && (
        <ModalRowAdd
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          selected={targetRow}
          setSelected={setTargetRow}
          facilityId={facilityId}
          updateTable={getTableData}
          tags={table.tags}
          tableId={tableId}
        />
      )}

      {showModalPreview && targetRow && (
        <ModalRowPreview
          visible={showModalPreview}
          setVisible={setShowModalPreview}
          openFile={openFile}
          selected={targetRow}
          setSelected={setTargetRow}
        />
      )}

      {showModalDelete && (
        <ModalRowDelete
          loading={loading}
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          facilityId={facilityId}
          selected={items}
          updateTable={getTableData}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={exportParams =>
            api.callGet(null, {
              tableId: tableId,
              ...exportParams
            })
          }
        />
      )}

      {showModalFailedUpload && (
        <ModalFailedUpload
          visible={showModalFailedUpload}
          setVisible={state => {
            setShowModalFailedUpload(state);
            if (!state) {
              setValidUploads(null);
              setFailedUploads(null);
            }
          }}
          facilityId={facilityId}
          updateTable={getTableData}
          tags={table.tags}
          tableId={table.id}
          failed={failedUploads}
          successful={validUploads}
        />
      )}
    </CustomTableWrapper>
  );
};

CustomTable.propTypes = {
  index: PropTypes.number.isRequired,
  table: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    tags: PropTypes.arrayOf(PropTypes.any).isRequired,
    global: PropTypes.bool,
    fileOnly: PropTypes.bool,
    facility: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isDeleted: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  }).isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  handleEditTable: PropTypes.func,
  handleViewAll: PropTypes.func,
  inGlobalTables: PropTypes.bool
};

CustomTable.defaultProps = {
  handleEditTable: null,
  handleViewAll: null,
  inGlobalTables: false
};

// Style Overrides
const CustomTableWrapper = styled(TableWrapper)`
  min-width: 350px;
`;

const CustomTableMenu = styled(TableMenu)`
  gap: 7px;

  ${bp(1)} {
    gap: ${pad}px;
  }
`;

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const TableLabel = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableHeading = styled.div`
  display: inline-flex;
  overflow: hidden;
  max-width: calc(100% - 30px);
`;

const EditButton = styled.button`
  ${voice.normal};
  margin-left: ${pad}px;
  height: min-height;
  align-self: center;
  width: max-content;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const EditLink = styled(NavLink)`
  ${voice.normal};
  margin-left: ${pad}px;
  height: min-height;
  align-self: center;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const HeaderMenu = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(2)} {
    display: flex;
  }
`;

const MenuItems = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  opacity: ${props => props.active};
`;

const ToggleActive = styled(Button)`
  background: none;
`;

const Spaced = styled.div`
  margin-left: ${pad / 2}px;

  ${bp(1)} {
    margin-left: ${pad}px;
  }
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const RowWrapper = styled.div`
  position: relative;
`;

const Dropzone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => hexToRgba(props.theme.primary, 0.1)};
  z-index: ${z("above")};

  opacity: 0;
  visibility: hidden;
  transition: ${transition};

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `};

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${Text} {
    color: ${props => props.theme.primary};
    text-align: center;
    ${voice.strong};
  }

  svg {
    fill: ${props => props.theme.primary};
    margin: 0 auto;
    ${voice.strong};
  }
`;

const NotAllowed = styled(Button)`
  cursor: not-allowed;
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const Total = styled(Text)`
  ${voice.quiet};
`;

export default CustomTable;
