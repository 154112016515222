import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faEnvelope, faLink, faPhone} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import {
  InputTextArea,
  InputText,
  InputDate,
  InputError,
  InputFileGroup,
  InputCheck
} from "../../components/form/FormInputs.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {
  Form,
  FormGroup,
  FormField,
  ButtonFull,
  HeadingCenter,
  ButtonLoader,
  Inline,
  Abbr,
  Button
} from "../../style/components/general.js";

const defaultValues = {
  id: "",
  name: "",
  date: dayjs().format("YYYY-MM-DD"),
  message: ""
};

const schema = yup.object().shape({
  name: yup.string().required("Please provide label"),
  message: yup.string().required("Please provide text"),
  date: yup.date().required("Please provide a date"),
  tags: yup
    .array()
    .of(yup.object())
    .test({
      test: val => Array.isArray(val) && val.length > 0,
      message: "Please provide at least one tag."
    })
});

const ModalRowAdd = ({
  visible,
  setVisible,
  facilityId,
  selected,
  setSelected,
  updateTable,
  tags: defaultTags,
  tableId
}) => {
  const isMounted = useMountedState();

  const {currentUser, roles} = useContext(AuthContext);

  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState(null);
  const [files, setFiles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [restrictTo, setRestrictTo] = useState([]);
  const [error, setError] = useState(null);

  const {api, loading} = useApi("custom-table-rows");
  const {api: apiTags} = useApi("tags");

  const available = roles?.filter(
    curr => curr?.permissions?.resource?.byId?.custom_table_row?.view === "enabled"
  );

  const form = useForm({
    defaultValues: selected
      ? {
          ...defaultValues,
          ...selected,
          restrictRole: !!selected.roles?.length,
          files: selected.files.map(file => ({id: file.id, filename: file.label}))
        }
      : {
          ...defaultValues,
          tags: defaultTags
        },
    resolver: yupResolver(schema)
  });
  const {
    setValue,
    control,
    watch,
    clearErrors,
    formState: {errors},
    handleSubmit
  } = form;

  const {append: appendTag, remove: removeTag} = useFieldArray({control, name: "tags"});

  const selectedTags = watch("tags");
  const restrictRole = watch("restrictRole");

  // Load tags
  useEffect(() => {
    if (isMounted()) {
      apiTags.callGet().then(response => {
        if (response.status === 200)
          setAllTags(response.data.map(tag => ({name: tag.label, label: tag.label})));
      });

      if (selected) setRestrictTo(selected.roles);
    }
  }, [isMounted, apiTags, selected]);

  const searchTags = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allTags.filter(option => option.label.toLowerCase().includes(lower));
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setTags(tempResults);
    } else setTags(allTags);
  };

  // Reset tag error
  useEffect(() => {
    if (selectedTags && selectedTags.length > 0) clearErrors("tags");
  }, [clearErrors, selectedTags]);

  const handleSave = values => {
    const {name, date, message, tags: updatedTags, files: updatedFiles} = values;

    if (restrictRole && !restrictTo?.length) {
      setError("Please select roles.");
    }

    const params = {
      userId: currentUser.publicId,
      facilityId,
      tableId,
      name: name,
      date: date,
      message: message,
      tags: updatedTags.map(tag => tag.label),
      roles: restrictTo?.map(({id}) => id)
    };

    const fileData = new FormData();

    for (let i = 0; i < files.length; i++) {
      fileData.append(`file-${i}`, new File([files[i]], files[i].name, {type: files[i].type}));
    }

    if (selected) {
      const old = selectedTags.map(tag => tag.name);
      const diff = updatedTags.filter(tag => old.includes(tag.name)).length === 0;

      // Find Deleted Files
      const deletedFiles = selected.files.filter(
        oldFile => !updatedFiles.some(newFile => oldFile.label === newFile.filename)
      );
      params.delete = deletedFiles.map(file => file.id);

      api
        .callPut(selected.id, fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
        .then(() => {
          if (diff) window.location.reload();
          else {
            setSelected([]);
            updateTable();
            setVisible(false);
          }
        });
    } else {
      api
        .callPost(fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
        .then(() => {
          setVisible(false);
          updateTable();
        });
    }
  };

  // Reset on Component unmount
  useEffect(
    () => () => {
      if (!isMounted()) setSelected(null);
    },
    [isMounted, setSelected]
  );

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = available.filter(
        option =>
          option.label.toLowerCase().includes(lower) &&
          !restrictTo?.map(role => role.name).includes(option.name)
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setSearchResults(tempResults);
    } else
      setSearchResults(available.filter(role => !restrictTo?.map(r => r.name).includes(role.name)));
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>{selected ? "Edit Row" : "Add Row"}</ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)} noValidate>
          <FormGroup>
            {selected && (
              <FormField>
                <SearchSelect
                  label="Tags"
                  prompt="Select from available tags to define table data."
                  results={tags}
                  setResults={setTags}
                  search={searchTags}
                  add={result => {
                    if (result.name) appendTag(result);
                    else appendTag({name: result, label: result});
                  }}
                  allowNew
                />
                {selectedTags && selectedTags.length > 0 && (
                  <Selections>
                    {selectedTags.map(tag => (
                      <Selection key={tag.name} onClick={() => removeTag(tag.name)}>
                        {tag.label}&nbsp;
                        <FontAwesomeIcon icon={faClose} />
                      </Selection>
                    ))}
                  </Selections>
                )}
                <InputError errors={errors} name="tags" />
              </FormField>
            )}
            <FormField>
              <InputText name="name" placeholder="Label" />
            </FormField>
            <FormField>
              <InputDate name="date" />
            </FormField>
            <FormField>
              <Inline>
                <IconButton
                  type="button"
                  onClick={() =>
                    setValue("message", `${watch("message")}tel=1111111111;name=Phone Name;`)
                  }>
                  <Abbr title="Add Phone">
                    <FontAwesomeIcon icon={faPhone} />
                  </Abbr>
                </IconButton>
                <IconButton
                  type="button"
                  onClick={() =>
                    setValue(
                      "message",
                      `${watch("message")}mail=example@hero-services.com;name=Email Name;`
                    )
                  }>
                  <Abbr title="Add Mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Abbr>
                </IconButton>
                <IconButton
                  type="button"
                  onClick={() =>
                    setValue(
                      "message",
                      `${watch("message")}url=https://hero-services.com;name=Link Name;`
                    )
                  }>
                  <Abbr title="Add Link">
                    <FontAwesomeIcon icon={faLink} />
                  </Abbr>
                </IconButton>
              </Inline>
              <InputTextArea name="message" placeholder="Message" />
            </FormField>
            <FormField>
              <InputFileGroup name="files" setFiles={setFiles} />
            </FormField>
            {roles?.length > 0 && (
              <FormField>
                <InputCheck name="restrictRole">Restrict access by role?</InputCheck>
                {restrictRole && (
                  <Wrapper>
                    <SearchSelect
                      placeholder="Find role(s)..."
                      results={searchResults}
                      setResults={setSearchResults}
                      search={search}
                      add={({id, name, label}) => {
                        setRestrictTo(prev => {
                          if (prev && !prev.map(curr => curr.id).includes(id))
                            return [...prev, {id, name, label}];
                          return prev ?? [{id, name, label}];
                        });
                      }}
                      showAll
                    />
                    <StyledInline>
                      {restrictTo?.map(({name, label}) => (
                        <SelectedRole key={name}>
                          <Abbr title={label.toUpperCase()}>{label.toUpperCase()}</Abbr>
                          <IconButton
                            onClick={() => {
                              setRestrictTo(prev => {
                                let toRemove;
                                prev.map((role, index) => {
                                  if (role.name === name) toRemove = index;
                                });
                                const deleted = prev.splice(toRemove, 1);
                                return prev.filter(
                                  item =>
                                    !deleted
                                      ?.map(({name: nameInPrev}) => nameInPrev)
                                      .includes(item.name)
                                );
                              });
                            }}>
                            <FontAwesomeIcon icon={faClose} />
                          </IconButton>
                        </SelectedRole>
                      ))}
                      <InputError errors={{restrictTo: error}} name="restrictTo" />
                    </StyledInline>
                  </Wrapper>
                )}
              </FormField>
            )}
          </FormGroup>
          <ButtonFull type="submit" loading={loading ? 1 : 0}>
            Save{loading && <ButtonLoader />}
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalRowAdd.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  setSelected: PropTypes.func,
  facilityId: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.any),
  tableId: PropTypes.number
};

ModalRowAdd.defaultProps = {
  selected: null,
  setSelected: null,
  facilityId: null,
  tags: [],
  tableId: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Selections = styled.div`
  position: relative;
  ${flex("row", "wrap", "start", "start")};
`;

const Selection = styled.button`
  display: flex;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad / 2}px;
  width: fit-content;
  margin: ${pad}px ${pad / 2}px 0 0;
  ${voice.quiet};
`;

const Wrapper = styled.div`
  margin-top: ${pad}px;
  width: 100%;
`;

const SelectedRole = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  flex: 1;
  width: max-content;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  padding: ${pad / 2}px ${pad}px;

  ${Abbr} {
    ${voice.quiet};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: min-content;
  }
`;

const IconButton = styled(Button)`
  ${voice.small};
  background-color: transparent;
  width: min-content;
  padding: 0;
  margin-left: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const StyledInline = styled(Inline)`
  margin-top: ${pad}px;
`;

export default ModalRowAdd;
