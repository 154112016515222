import React, {useEffect, useState, useContext, useCallback, useRef, useMemo} from "react";
import {useParams} from "react-router";
import styled, {css} from "styled-components";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faMapMarkerAlt, faSearch} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../hooks/useMountedState.js";
import useApi from "../hooks/useApi.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {useSocket} from "../contexts/socket.js";

// Helpers
import {addGroup, addComponent, removeElement, updateElement} from "../utils/builder.js";
import MapProvider, {
  DEFAULT_MARKER,
  FACILITY_MARKER,
  formatAddressComponents,
  formatAddressForGeocode
} from "../utils/google/maps.js";

// Components
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Map from "../components/Map.js";
import ModalEditElement from "./facility-builder/ModalEditElement.js";
import RenderFacilityBuilder from "./facility-builder/RenderFacilityBuilder.js";
import ModalDeleteElement from "./facility-builder/ModalDeleteElement.js";
import ModalLocation from "./facility-builder/ModalLocation.js";
import ModalSetLocation from "./facility-builder/ModalSetLocation.js";
import Badge from "../components/Badge.js";
import MultiSelect from "../components/MultiSelect.js";
import PrimaryAddress from "./facility/PrimaryAddress.js";

// Style
import {bp} from "../style/components/breakpoints.js";
import {voice} from "../style/components/typography.js";
import {z} from "../style/components/mixins.js";
import {
  border,
  radius,
  pad,
  navWidth,
  navHeight,
  shadow,
  colors
} from "../style/components/variables.js";
import {
  Page,
  Loader,
  NotLoaded,
  SearchIcon,
  SearchWrapper,
  Search,
  Inline,
  StickyWrapper,
  Button,
  Error,
  Small,
  HeadingMedium
} from "../style/components/general.js";

// Socket Constants
import Room, {LIST_LOCKS, LOCK, RELEASE, REQUEST_LOCKS, ROOM_JOINED} from "./general/Room.js";

const NOTIFY_UPDATE = "notify_update_builder";
const LISTEN_NOTIFY_UPDATE = "notify:update_builder";

const maps = new MapProvider();

const FacilityBuilder = () => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {slug} = useParams();

  const {api: apiFacilities} = useApi("facilities");
  const {api: apiAddress, data: markers} = useApi("addresses");

  const {currentUser, atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {facility, setFacility} = useContext(FacilityNavContext);

  const [locks, setLocks] = useState(null);
  const [canRequestLocks, setCanRequestLocks] = useState(false);

  const [active, setActive] = useState(null);
  const [builder, setBuilder] = useState(null);
  const [builderQueryResult, setBuilderQueryResult] = useState(null);
  const [query, setQuery] = useState("");
  const [refreshResults, setRefreshResults] = useState(false);
  const [refreshMarkers, setRefreshMarkers] = useState(false);
  const [activeDroppableId, setActiveDroppableId] = useState("");
  const [view, setView] = useState(roleCanAccessResource("facility", "view") ? "builder" : "map");

  // Map
  const [locations, setLocations] = useState(null);
  const [activeMarker, setActiveMarker] = useState("");
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // Modals
  const [target, setTarget] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSetLocation, setShowSetLocation] = useState(false);

  const requestingLocks = useRef(false);

  const markerIconMap = useMemo(() => {
    const map = {};
    markers?.map(({id, ...rest}) => {
      map[id] = {...rest};
    });
    return map;
  }, [markers]);

  /**
   * Refresh Google Map Markers
   * @param {array} a Addresses
   * @param {object} b Facility Builder
   */
  const loadMap = useCallback(
    (a, b) => {
      let extracted = [
        ...a.map(address => ({label: address.name, ...address, marker: FACILITY_MARKER})),
        ...Object.values(b.byId || {})
          .filter(({hasAddress}) => hasAddress === true)
          .map(({id, name, label, address, help, markerId}) => ({
            id: id || name,
            label,
            ...address,
            details: help ? [...help] : [{}],
            draggable: true,
            marker:
              markerId && Object.keys(markerIconMap)?.length > 0 && markerId in markerIconMap
                ? {id: markerId, ...markerIconMap[markerId]}
                : DEFAULT_MARKER
          }))
      ];

      if (activeFilters?.length > 0)
        extracted = extracted.filter(({marker}) => activeFilters.includes(marker.id));

      setLocations(extracted);
      setRefreshMarkers(true);
    },
    [markerIconMap, activeFilters]
  );

  const loadFacility = useCallback(() => {
    apiFacilities.callGet(slug).then(({status, data}) => {
      if (status === 200) {
        setFacility(data);
        setBuilder(data.builder);
      }
    });
  }, [apiFacilities, slug, setFacility]);

  // Initial load
  useEffect(() => {
    if (isMounted() && (locations === null || facility === null)) {
      apiAddress.callGet(null, {markers: true});
      loadFacility();
    }
  }, [isMounted, locations, facility, loadFacility, apiAddress]);

  useEffect(() => {
    if (isMounted() && facility && builder)
      loadMap(
        builderQueryResult ? [] : facility.addresses,
        builderQueryResult ?? builder,
        !!builderQueryResult
      );
  }, [isMounted, loadMap, facility, builder, builderQueryResult]);

  useEffect(() => {
    if (view === "builder") setActiveMarker(null);
  }, [view]);

  const notifyUpdate = useCallback(() => loadFacility(), [loadFacility]);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  const roomJoined = useCallback(() => setCanRequestLocks(true), []);

  // Socket Management
  useEffect(() => {
    if (isMounted() && currentUser && !locks && canRequestLocks && !requestingLocks.current) {
      requestingLocks.current = true;
      socket.emit(REQUEST_LOCKS, {
        rooms: `${slug}-builder`,
        user: currentUser.publicId
      });
    }

    socket.on(LIST_LOCKS, listLocks);

    socket.on(ROOM_JOINED, roomJoined);

    socket.on(LISTEN_NOTIFY_UPDATE, notifyUpdate);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
      socket.off(LISTEN_NOTIFY_UPDATE, notifyUpdate);
      socket.off(ROOM_JOINED, roomJoined);
    };
  }, [
    socket,
    isMounted,
    listLocks,
    roomJoined,
    currentUser,
    locks,
    canRequestLocks,
    slug,
    notifyUpdate
  ]);

  const searchAndFilter = useCallback(() => {
    if ((activeFilters.length === 0 && !query) || !builder) {
      setBuilderQueryResult(null);
    } else {
      const {allIds, byId} = builder;

      const updatedAllIds = allIds.filter(
        name =>
          activeFilters?.length === 0 ||
          (!byId[name].marker && activeFilters.includes(DEFAULT_MARKER.id)) ||
          activeFilters.includes(byId[name].markerId)
      );

      const groups = Object.entries(byId).filter(
        ([name, obj]) =>
          (!query || obj.label.toLowerCase().includes(query.toLowerCase())) && allIds.includes(name)
      );

      const components = Object.entries(byId).filter(
        ([name, obj]) =>
          (!query || obj.label.toLowerCase().includes(query.toLowerCase())) &&
          !allIds.includes(name)
      );

      const parentNames = components
        .filter(([, obj]) => obj.parentName && obj.parentName !== "")
        .map(([, obj]) => obj.parentName);

      const updatedById = Object.fromEntries([...groups, ...components]);

      parentNames.map(name => {
        if (!(name in updatedById)) {
          updatedById[name] = byId[name];
          if (!updatedAllIds.includes(name)) updatedAllIds.push(name);
        }
      });

      updatedAllIds.sort((a, b) => allIds.indexOf(a) - allIds.indexOf(b));

      setBuilderQueryResult({allIds: updatedAllIds, byId: updatedById});
    }
  }, [activeFilters, builder, query]);

  useEffect(() => {
    if (refreshResults) setRefreshResults(false);
    searchAndFilter();
  }, [builder, query, refreshResults, searchAndFilter, activeFilters]);

  // Browser Resize Events
  const handleResize = useCallback(localView => {
    if (window.innerWidth >= 1024) setView(null);
    else if (localView === null) setView("map");
  }, []);

  const handleResizeTimeout = useCallback(
    (resized, localView) => {
      clearTimeout(resized);
      resized = setTimeout(() => handleResize(localView), 10);
    },
    [handleResize]
  );

  useEffect(() => {
    let resizedFn;
    const fnWrapper = () => handleResizeTimeout(resizedFn, view);
    if (isMounted() && locations?.length > 0) {
      window.removeEventListener("resize", fnWrapper);
      if (!view || window.innerWidth >= 1024) handleResize();

      window.addEventListener("resize", fnWrapper);
    }

    return () => {
      window.removeEventListener("resize", fnWrapper);
    };
  }, [isMounted, view, locations, handleResize, handleResizeTimeout]);

  // Updates
  const handleSave = updatedBuilder =>
    apiFacilities
      .callPut(facility.id, {
        builder: updatedBuilder,
        details: facility.details,
        userPublicId: currentUser.publicId
      })
      .then(() => {
        socket.emit(NOTIFY_UPDATE, `${slug}-builder`);
        // Reset
        setRefreshResults(true);
        loadMap(facility.addresses, updatedBuilder);
      });

  const handleAddComponent = component => {
    setQuery(null);
    setFilters([]);
    setActiveFilters([]);

    if (component.linkedGroup) component.parentName = component.linkedGroup;
    delete component.linkedGroup;
    const updatedBuilder = addComponent(builder, component);

    handleSave(updatedBuilder);
    setBuilder(updatedBuilder);
    setRefreshResults(true);
  };

  const handleAddGroup = group => {
    setQuery(null);
    setFilters([]);
    setActiveFilters([]);

    const updatedBuilder = addGroup(builder, group);
    handleSave(updatedBuilder);
    setBuilder(updatedBuilder);
  };

  const confirmDelete = element => {
    setShowDeleteModal(true);
    setTarget(element);
  };

  const persistDelete = () => {
    const updatedBuilder = removeElement(builder, target);
    setBuilder(updatedBuilder);
    handleSave(updatedBuilder);

    // Reset
    setShowDeleteModal(false);
  };

  const persistEdit = element => {
    socket.emit(RELEASE, `${slug}-builder`, target.name);

    setShowEditModal(false);

    const {address: newAddress, hasAddress} = element;
    let updatedBuilder;

    if (hasAddress && newAddress && !newAddress.line1) {
      const coords = maps.getLatLng(newAddress.lat, newAddress.lon);
      maps.geocode({latLng: coords}, results => {
        let locationFound = false;

        if (results?.length > 0)
          for (let i = 0; i < results.length; i++) {
            if (results[i].types[0] === "street_address") {
              const location = formatAddressComponents(results[i].address_components);
              if (location) {
                locationFound = true;
                ({builder: updatedBuilder} = updateElement(
                  builder,
                  {
                    ...element,
                    hasAddress: true,
                    address: {
                      id: element.name,
                      ...location,
                      lat: coords.lat(),
                      lon: coords.lng()
                    }
                  },
                  false
                ));
                break;
              }
            }
          }

        if (!locationFound) {
          ({builder: updatedBuilder} = updateElement(
            builder,
            {
              ...element,
              hasAddress: true,
              address: {
                id: element.name,
                lat: coords.lat(),
                lon: coords.lng()
              }
            },
            false
          ));
        }

        setBuilder(updatedBuilder);
        handleSave(updatedBuilder);
      });
    } else if (hasAddress && newAddress && (!newAddress.lat || !newAddress.lon)) {
      maps.geocode({address: formatAddressForGeocode(newAddress)}, results => {
        if (results && results.length > 0) {
          // Should provide a single match
          const location = formatAddressComponents(results[0].address_components);
          if (location) {
            ({builder: updatedBuilder} = updateElement(
              builder,
              {
                ...element,
                hasAddress: true,
                address: {
                  id: element.name,
                  ...newAddress,
                  lat: results[0].geometry.location.lat(),
                  lon: results[0].geometry.location.lng()
                }
              },
              false
            ));
          }
        }

        setBuilder(updatedBuilder);
        handleSave(updatedBuilder);
      });
    } else {
      ({builder: updatedBuilder} = updateElement(
        builder,
        {...element, address: null, markerId: null},
        false
      ));

      setBuilder(updatedBuilder);
      handleSave(updatedBuilder);
    }
  };

  const handleEditLocation = (coords, id) => {
    const element = builder.byId[id];
    if (element)
      maps.geocode({latLng: coords}, results => {
        for (let i = 0; i < results.length; i++) {
          if (results[i].types[0] === "street_address") {
            const location = formatAddressComponents(results[i].address_components);
            if (location) {
              const {builder: updatedBuilder} = updateElement(
                builder,
                {
                  ...element,
                  id: id,
                  hasAddress: true,
                  address: {...location, lat: coords.lat(), lon: coords.lng()}
                },
                false
              );

              handleSave(updatedBuilder);
              setTarget(null);
            }

            break;
          }
        }
      });
  };

  const persistLocation = coords => {
    const formatted = maps.getLatLng(coords.lat, coords.lon);
    setLocations(null);
    let updatedBuilder;
    maps.geocode({latLng: formatted}, results => {
      let locationFound = false;
      if (results?.length > 0)
        for (let i = 0; i < results.length; i++) {
          if (results[i].types[0] === "street_address") {
            const location = formatAddressComponents(results[i].address_components);
            if (location) {
              locationFound = true;
              ({builder: updatedBuilder} = updateElement(
                builder,
                {
                  ...target,
                  id: target.name,
                  hasAddress: true,
                  address: {...location, ...coords}
                },
                false
              ));
              break;
            }
          }
        }

      if (!locationFound)
        ({builder: updatedBuilder} = updateElement(
          builder,
          {
            ...target,
            id: target.name,
            hasAddress: true,
            address: {
              id: target.name,
              ...coords
            }
          },
          false
        ));

      handleSave(updatedBuilder);
      // Reset
      setRefreshResults(true);
      setTarget(null);
      loadMap(facility.addresses, updatedBuilder);
    });
  };

  const handleEdit = element => {
    socket.emit(LOCK, `${slug}-builder`, element.name);

    setTarget(element);
    setShowEditModal(true);
  };

  const handleSetLocation = element => {
    setTarget(element);
    setShowSetLocation(true);
  };

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type} = result;
    if (!destination) {
      return;
    }

    let updatedBuilder;
    const builderCopy = {...builder};

    if (type === "DEFAULT") {
      const allIds = [...builder.allIds];

      const [removed] = allIds.splice(source.index, 1);
      allIds.splice(destination.index, 0, removed);

      updatedBuilder = {...builderCopy, allIds};

      setBuilder(updatedBuilder);
    } else if (type === source.droppableId) {
      const byId = {...builder.byId};

      const parent = byId[type];
      const {children} = parent;

      const [removed] = children.splice(source.index, 1);
      children.splice(destination.index, 0, removed);

      updatedBuilder = {...builderCopy, byId: {...byId, [type]: {...parent, children}}};

      setBuilder(updatedBuilder);
    }

    handleSave(updatedBuilder);
  };

  const handleSearch = e => {
    const search = e.target.value;
    setQuery(search);
  };

  const filterMenu = useRef(null);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu?.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const handleApply = () => {
    setShowFilters(false);
    setActiveFilters(filters);
  };

  const handleClear = () => {
    setFilters([]);
    setActiveFilters([]);
    setShowFilters(false);
  };

  return (
    <BuilderPage hasMenu>
      <Room name="builder" active={active} setActive={setActive} />

      {facility?.name && (
        <FacilityPageHeader
          facility={facility}
          reloadData={() => setLocations(null)}
          path="/builder"
        />
      )}

      <PageMenu>
        <HeadingMedium>
          Facility&nbsp;
          {atLeast("creator") && roleCanAccessResource("facility", "create") ? "Builder" : "Map"}
        </HeadingMedium>
        {roleCanAccessResource("facility", "view") && (
          <ViewSwitch
            onClick={() => {
              setView(view === "builder" ? "map" : "builder");
            }}
            general={!atLeast("creator")}>
            {view === "builder" ? "View Map" : "View Builder"}
          </ViewSwitch>
        )}
      </PageMenu>

      <Content fullWidth>
        {roleCanAccessResource("facility", "view") && (
          <Builder data-testid="facility.preview" view={view}>
            <BuilderOptions>
              {roleCanAccessResource("facility_address", "view") && (
                <Button type="button" onClick={() => setShowLocationModal(true)}>
                  Location(s)
                </Button>
              )}
              <Inline>
                <SearchWrapper>
                  <SearchIcon>
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchIcon>
                  <Search
                    name="search"
                    type="text"
                    placeholder="Filter..."
                    onChange={e => handleSearch(e)}
                  />
                </SearchWrapper>
                {markers?.length > 0 && (
                  <Filters>
                    <Button type="button" onClick={() => setShowFilters(true)}>
                      <Badge count={activeFilters?.length} offset="14px" color={colors.heroGreen} />
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>
                    {showFilters && (
                      <OptionSub ref={filterMenu}>
                        <Small>Filter(s)</Small>
                        <MultiSelect
                          options={[
                            {...DEFAULT_MARKER, value: DEFAULT_MARKER.id},
                            ...markers.map(({id, label, icon, color}) => ({
                              value: `${id}`,
                              label,
                              icon,
                              color
                            }))
                          ]}
                          setSelection={setFilters}
                          defaultSelection={filters}
                        />
                        <Inline>
                          <ApplyFilter type="button" onClick={handleApply}>
                            Apply
                          </ApplyFilter>
                          <ApplyFilter type="button" onClick={handleClear}>
                            Clear
                          </ApplyFilter>
                        </Inline>
                      </OptionSub>
                    )}
                  </Filters>
                )}
              </Inline>
            </BuilderOptions>

            {(!locations || locations.length === 0) && (
              <WarningText>
                {!!query && query !== ""
                  ? "No locations found"
                  : "Update group locations to view in Facility Map."}
              </WarningText>
            )}

            {activeFilters?.length === 0 && facility?.primaryAddress && (
              <AddressWrapper>
                <PrimaryAddress details={{...facility.details, address: facility.primaryAddress}}>
                  <View
                    onClick={() => {
                      setActiveMarker(facility.primaryAddress.id);
                      if (view && setView) setView("map");
                    }}>
                    View on Map
                    <Icon icon={faMapMarkerAlt} color={`#${FACILITY_MARKER.color}`} />
                  </View>
                </PrimaryAddress>
              </AddressWrapper>
            )}

            {builder && locks ? (
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="facility.builder"
                  isDropDisabled={activeDroppableId !== "facility.builder"}>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <RenderFacilityBuilder
                        facility={facility}
                        builder={builderQueryResult || builder}
                        handleAddGroup={handleAddGroup}
                        handleAddComponent={handleAddComponent}
                        removeElement={confirmDelete}
                        editElement={handleEdit}
                        setElementLocation={handleSetLocation}
                        handleSave={handleSave}
                        activeDroppableId={activeDroppableId}
                        querying={!!query && query !== ""}
                        activeMarker={activeMarker}
                        setActiveMarker={setActiveMarker}
                        markerIconMap={markerIconMap}
                        active={active}
                        locks={locks}
                        view={view}
                        setView={setView}>
                        {provided.placeholder}
                      </RenderFacilityBuilder>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <NotLoaded>
                <Loader data-testid="loader.facilityBuilder" />
              </NotLoaded>
            )}
          </Builder>
        )}

        <MapWrapper view={view} empty={!locations || locations.length === 0}>
          {locations ? (
            <Map
              locations={locations}
              editLocation={handleEditLocation}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              filters={activeFilters}
              refreshMarkers={refreshMarkers}
              querying={!!query && query !== ""}
              setRefreshMarkers={setRefreshMarkers}
            />
          ) : (
            <Loader />
          )}
        </MapWrapper>
      </Content>

      {/* Modals */}
      {builder && (
        <>
          {showEditModal && target && (
            <ModalEditElement
              visible={showEditModal}
              setVisible={val => {
                socket.emit(RELEASE, `${slug}-builder`, target.name);
                setShowEditModal(val);
              }}
              targetElement={target}
              setTargetElement={setTarget}
              persistEdit={persistEdit}
              builder={builder}
              markerIconMap={markerIconMap}
            />
          )}

          {showDeleteModal && target && (
            <ModalDeleteElement
              builder={builder}
              visible={showDeleteModal}
              setVisible={setShowDeleteModal}
              facilityId={facility.id}
              persistDelete={persistDelete}
              targetElement={target}
            />
          )}

          {showLocationModal && (
            <ModalLocation
              visible={showLocationModal}
              setVisible={state => {
                if (!state) loadFacility();
                setShowLocationModal(state);
              }}
              facility={facility}
              loadMap={loadMap}
              setLocations={setLocations}
            />
          )}

          {showSetLocation && target && (
            <ModalSetLocation
              visible={showSetLocation}
              setVisible={setShowSetLocation}
              persistEdit={persistLocation}
              targetElement={target}
              setTargetElement={setTarget}
            />
          )}
        </>
      )}
    </BuilderPage>
  );
};

// Style Overrides
const BuilderPage = styled(Page)`
  align-items: flex-start;
  display: block;
  height: 100%;
  margin-bottom: ${pad * 2}px;

  ${bp(4)} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - ${navWidth}px);
  }
`;

const Content = styled(Inline)`
  gap: ${pad * 2}px;
  align-items: start;
`;

const PageMenu = styled(Inline)`
  width: 100%;
  padding: 0 0 ${pad}px 0;
  align-items: center;
  justify-content: space-between;
`;

const BuilderOptions = styled(Inline)`
  width: 100%;
  justify-content: space-between;
`;

const ViewSwitch = styled(Button)`
  ${bp(4)} {
    display: none;
    pointer-events: none;
  }
`;

const Builder = styled.div`
  position: relative;
  width: inherit;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad * 2}px;

  ${bp(4)} {
    margin: 0;
    display: block;
    width: 40%;
  }

  ${({view}) =>
    view === "builder"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const MapWrapper = styled(StickyWrapper)`
  width: 100%;
  height: calc(55vh - (${({empty}) => (empty ? "30px" : "0px")}));
  margin: 0 0 ${navHeight}px;

  ${bp(4)} {
    display: block;
    width: 60%;
    height: calc(75vh - (${({empty}) => (empty ? "30px" : "0px")}));
    top: calc(${navHeight}px + ${pad * 2}px);
  }

  ${({view}) =>
    view === "map"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const WarningText = styled(Error)`
  color: ${({theme}) => theme.secondary};
  margin-top: ${pad}px;
`;

const Filters = styled.div`
  position: relative;
`;

const OptionSub = styled.div`
  position: absolute;
  top: 100%;
  left: -115px;
  width: 230px;
  padding: ${pad}px;
  margin-top: ${pad}px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  color: ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  box-shadow: ${shadow};
  z-index: ${z("top")};

  ${bp(2)} {
    left: 0;
  }
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  width: fit-content;
  margin-right: ${pad / 2}px;
  padding: ${pad / 2}px;
  display: inline-flex;

  svg {
    fill: ${({theme}) => theme.tertiary};
    align-self: center;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const AddressWrapper = styled.div`
  margin: ${pad * 2}px 0 0;

  > div {
    margin: 0;
  }
`;

const View = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.secondary};

  ${({active, theme}) =>
    active &&
    css`
      color: ${theme.primary};
    `}

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  padding: ${pad}px 0 ${pad}px ${pad / 2}px;
  fill: ${({color, theme}) => color || theme.primary};
`;

export default FacilityBuilder;
