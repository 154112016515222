import React, {useContext, useState, useEffect, useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus, faFileExport, faSearch, faFilter} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useToast} from "../../contexts/toast.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";
import Badge from "../../components/Badge.js";
import ModalExport from "../../components/ModalExport.js";
import ModalFacilityUserAdd from "./ModalFacilityUserAdd.js";
import ModalFacilityUserEdit from "./ModalFacilityUserEdit.js";
import ModalFacilityUserDelete from "./ModalFacilityUserDelete.js";
import ModalUserFilter from "../general/ModalUserFilter.js";
import ModalViewAllFacilityUsers from "../general/ModalViewAllFacilityUsers.js";

// Style
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {colors, pad} from "../../style/components/variables.js";
import {
  Button,
  Heading,
  Inline,
  TableWrapper,
  TableHeaderWrap,
  TableMenu,
  TableEditMenu,
  TableEditMenuButton,
  TableFooter,
  Text,
  Search,
  SearchWrapper,
  SearchIcon,
  Abbr,
  Small
} from "../../style/components/general.js";

const formatUsers = formattedUsers =>
  formattedUsers.map(user => {
    const {
      publicId,
      role,
      department,
      firstName,
      lastName,
      isInvited,
      isAccepted,
      isActive,
      isDeleted,
      email,
      primary,
      phones,
      details
    } = user;

    return {
      publicId: publicId,
      userRoleId: role ? role.label : null,
      userDepartmentId: department,
      isDeleted,
      firstName: (
        <span>
          {firstName} {lastName}&nbsp;
          {isDeleted && (
            <ErrorSmall>
              <Abbr title="User is deleted">deleted!</Abbr>
            </ErrorSmall>
          )}
          {isInvited && !isAccepted && !isDeleted && (
            <Small>
              <Abbr title="User has not accepted invitation">invited</Abbr>
            </Small>
          )}
          {isInvited && isAccepted && !isActive && !isDeleted && (
            <Small>
              <Abbr title="User is disabled">disabled!</Abbr>
            </Small>
          )}
          {!isInvited && !isActive && !isDeleted && (
            <Small>
              <Abbr title="User has not been invited">contact</Abbr>
            </Small>
          )}
        </span>
      ),
      email: <a href={`mailto:${email}`}>{email}</a>,
      phone: primary && <PhoneNumbers primary={primary} phones={phones} />,
      company: details && details.company ? details.company : ""
    };
  });

const TableFacilityUsers = ({facilityId, setRefreshFacility}) => {
  const isMounted = useMountedState();

  const {atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);
  const {addToast} = useToast();

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState();
  const [original, setOriginal] = useState([]);
  // Edit
  const [edit, setEdit] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  // Search
  const [query, setQuery] = useState("");
  // Filters
  const [activeFilters, setActiveFilters] = useState();
  const [showHidden, setShowHidden] = useState(false);
  // Pagination
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);
  // Modals
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);

  const {api: apiFacilityUsers} = useApi("facility-users");
  const {api: apiUsers} = useApi("users", {suppress: {success: true, error: true}});
  const {api: apiSend} = useApi("send-invite");

  const isActive = useMemo(() => facility && !facility.isDeleted, [facility]);

  const getFacilityUsers = useCallback(() => {
    setLoadingUsers(true);

    const filter = {
      ExcludeCreator: true
    };

    if (query) filter.Search = query;
    if (activeFilters?.departments) filter.Departments = activeFilters.departments;
    if (activeFilters?.roles) filter.Roles = activeFilters.roles;
    if (showHidden) {
      filter.showHidden = true;
      if (activeFilters?.state) filter.State = activeFilters.state;
    }
    apiFacilityUsers
      .callGet(null, {
        facilityId: facilityId,
        page: current,
        orderBy,
        groupBy,
        limit,
        filter: JSON.stringify(filter),
        userManagement: true
      })
      .then(({status, data}) => {
        if (status && data) {
          setCurrent(data.page);
          setPageTotal(data.pages);
          setOriginal(data.users);
          const formatted = formatUsers(data.users);
          setUsers(formatted);

          // Page Limit
          const {total: dataTotal} = data;
          setTotal(dataTotal);
          let count = 10;
          const temp = [];
          while (count < dataTotal + 10 && count <= 50) {
            temp.push(count);
            count += 10;
          }

          setLimits(temp);
          setShowOptions(false);
          setLoadingUsers(false);
        }
      });
  }, [
    apiFacilityUsers,
    facilityId,
    current,
    orderBy,
    groupBy,
    limit,
    query,
    activeFilters,
    showHidden
  ]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && facilityId && !users) getFacilityUsers();
  }, [isMounted, getFacilityUsers, facilityId, users]);

  // Update users on search, filter, sort or limit
  useEffect(() => {
    if (isMounted()) {
      setUsers(undefined);
      setCurrent(1);
    }
  }, [isMounted, query, activeFilters, showHidden, orderBy, groupBy, limit]);

  useEffect(() => {
    if (edit.length === 1) {
      original.map(user => {
        if (user.publicId === edit[0].publicId) setSelectedUser(user);
      });
    }
  }, [edit, original, setSelectedUser]);

  const handleSearch = e => {
    setCurrent(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrent(1);
    getFacilityUsers();
  };

  const restore = () => {
    const [user] = edit;
    const payload = {restore: true};
    apiUsers.callPut(user.publicId, payload).then(({status}) => {
      if (status === 200) {
        addToast("User restored.", "success");
        setUsers(null);
      } else addToast("Failed to restore user", "error");
    });
  };

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {atLeast("admin") && users?.length > 0 && isActive && (
                  <Option
                    type="button"
                    onClick={() => {
                      if (showOptions) setEdit([]);
                      setShowOptions(!showOptions);
                    }}>
                    {showOptions ? "Cancel" : "Options"}
                  </Option>
                )}
                {atLeast("admin") && (
                  <Option
                    type="button"
                    onClick={() => {
                      setShowModalFilters(!showModalFilters);
                    }}>
                    <Badge
                      count={
                        (activeFilters?.departments ? activeFilters.departments.length : 0) +
                        (activeFilters?.roles ? activeFilters.roles.length : 0) +
                        (showHidden && activeFilters?.state?.length
                          ? activeFilters.state.length
                          : 0) +
                        (showHidden && !activeFilters?.state?.length ? 1 : 0)
                      }
                      offset="14px"
                      color={colors.heroGreen}
                    />
                    <FontAwesomeIcon icon={faFilter} />
                  </Option>
                )}
                {roleCanAccessResource("user", "export") && users && users.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      setShowModalExport(true);
                    }}>
                    <abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </abbr>
                  </Option>
                )}
                {roleCanAccessResource("user", "create") && isActive && (
                  <Option type="button" onClick={() => setShowModalAdd(true)}>
                    <abbr title="Add User">
                      <FontAwesomeIcon icon={faUserPlus} />
                    </abbr>
                  </Option>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableHeaderWrap>

        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            userRoleId: {header: "Role", disabled: false},
            userDepartmentId: {header: "Department", disabled: false},
            firstName: {header: "Name", disabled: false},
            email: {header: "Email", disabled: false},
            phone: {header: "Phone", disabled: false},
            company: {header: "Company", disabled: false}
          }}
          data={users || null}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={edit}
          setItems={setEdit}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          loading={loadingUsers}
          editMenu={
            !isActive ? null : (
              <TableEditMenu>
                {edit.length === 0 && <Text inverted>Please select users below for options.</Text>}
                {edit.length === 1 && !edit[0].isDeleted && (
                  <>
                    <TableEditMenuButton
                      type="button"
                      onClick={() => {
                        apiSend
                          .callPatch(null, {publicId: edit[0].publicId})
                          .then(() => setShowOptions(false));
                      }}>
                      Send/Resend Invite
                    </TableEditMenuButton>
                    {roleCanAccessResource("user", "update") && (
                      <TableEditMenuButton type="button" onClick={() => setShowModalEdit(true)}>
                        Edit
                      </TableEditMenuButton>
                    )}
                  </>
                )}
                {edit.length === 1 &&
                  edit[0].isDeleted &&
                  roleCanAccessResource("user", "delete") && (
                    <TableEditMenuButton type="button" onClick={() => restore()}>
                      Restore
                    </TableEditMenuButton>
                  )}
                {edit.length >= 1 &&
                  !edit[0].isDeleted &&
                  roleCanAccessResource("user", "delete") && (
                    <TableEditMenuButton type="button" onClick={() => setShowModalDelete(true)}>
                      Delete
                    </TableEditMenuButton>
                  )}
              </TableEditMenu>
            )
          }
        />
        <TableFooter>
          <Inline>
            {total > 10 && limits.length > 0 && (
              <PageLimit>
                <Dropdown
                  options={limits}
                  selection={limit}
                  setSelection={selection => {
                    setLimit(selection);
                    setCurrent(1);
                  }}
                />
              </PageLimit>
            )}
            <Total>
              {pageTotal > 1 && limits.length > 0 && "per page, "}&nbsp;
              <ModalViewLink
                disabled={!total || total <= 10}
                onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                {total} total
              </ModalViewLink>
            </Total>
          </Inline>
          <Pagination
            current={current}
            setCurrent={setCurrent}
            pageTotal={pageTotal}
            updateData={getFacilityUsers}
            loading={loadingUsers}
          />
        </TableFooter>
      </TableWrapper>

      {showModalFilters && (
        <ModalUserFilter
          visible={showModalFilters}
          setVisible={setShowModalFilters}
          filters={activeFilters}
          setFilters={setActiveFilters}
          showHidden={showHidden}
          setShowHidden={setShowHidden}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={exportParams =>
            apiFacilityUsers.callGet(null, {
              ...exportParams,
              facilityId: facilityId,
              filter: JSON.stringify({ExcludeCreator: true})
            })
          }
        />
      )}

      {showModalAdd && (
        <ModalFacilityUserAdd
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          facilityId={facilityId}
          updateTable={getFacilityUsers}
        />
      )}

      {showModalEdit && (
        <ModalFacilityUserEdit
          selected={selectedUser}
          visible={showModalEdit}
          setVisible={setShowModalEdit}
          updateTable={getFacilityUsers}
        />
      )}

      {showModalDelete && (
        <ModalFacilityUserDelete
          selected={edit}
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          updateTable={getFacilityUsers}
          setRefreshFacility={setRefreshFacility}
        />
      )}

      {showModalViewAll && (
        <ModalViewAllFacilityUsers
          visible={showModalViewAll}
          setVisible={setShowModalViewAll}
          reload={handleReload}
          setRefreshFacility={setRefreshFacility}
        />
      )}
    </>
  );
};

TableFacilityUsers.propTypes = {
  facilityId: PropTypes.number.isRequired,
  setRefreshFacility: PropTypes.func.isRequired
};

// Style Overrides
const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const ErrorSmall = styled(Small)`
  color: ${({theme}) => theme.error};
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const Total = styled(Text)`
  ${voice.quiet};
`;

export default TableFacilityUsers;
