import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Utils
import useMountedState from "../hooks/useMountedState.js";

// Style
import {flex} from "../style/components/mixins.js";
import {triangle} from "../style/components/shapes.js";
import {voice} from "../style/components/typography.js";
import {Abbr, Inline, Label} from "../style/components/general.js";
import {pad, border, radius, transition} from "../style/components/variables.js";

const AccordionWrapped = ({
  children,
  label,
  labelNode,
  handleOpen,
  toggleOpen,
  testId,
  disabled,
  isGroup,
  isEvent,
  name,
  showHelp,
  disallowOpen,
  invertColors,
  menu
}) => {
  const isMounted = useMountedState();

  const [open, setOpen] = useState(toggleOpen ?? false);

  useEffect(() => {
    if (isMounted() && toggleOpen !== null && open !== toggleOpen) setOpen(toggleOpen);
  }, [isMounted, toggleOpen, open]);

  return (
    <Wrapper
      disabled={disabled ? 1 : 0}
      isGroup={isGroup ? 1 : 0}
      isEvent={isEvent ? 1 : 0}
      invertColors={invertColors ? 1 : 0}
      id={name ? `accordion-${name}` : undefined}>
      <Menu
        invertColors={invertColors}
        data-testid={testId || "accordion"}
        disallowOpen={disallowOpen}
        onClick={e => {
          if (!disabled && !disallowOpen) {
            setOpen(show => !show);
            if (handleOpen) handleOpen(e);
          }
        }}>
        <LabelWrapper hasMenu={menu === null}>
          {labelNode || (
            <AccordionLabel
              isGroup={isGroup ? 1 : 0}
              isEvent={isEvent ? 1 : 0}
              invertColors={invertColors}
              bold>
              <Abbr title={label}>{label}</Abbr>
            </AccordionLabel>
          )}
          {showHelp && open && (
            <Help
              type="button"
              onClick={e => {
                e.stopPropagation();
                showHelp();
              }}
              data-testid={`${name}-help`}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Help>
          )}
        </LabelWrapper>
        <MenuWrapper hasMenu={menu === null}>
          {menu}
          {!disallowOpen && (
            <Arrow
              openDrawer={open}
              isGroup={isGroup ? 1 : 0}
              isEvent={isEvent ? 1 : 0}
              invertColors={invertColors}
            />
          )}
        </MenuWrapper>
      </Menu>
      <Hide hide={open || disallowOpen ? 0 : 1} invertColors={invertColors}>
        {children}
      </Hide>
    </Wrapper>
  );
};

AccordionWrapped.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleOpen: PropTypes.func,
  toggleOpen: PropTypes.bool,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  isGroup: PropTypes.bool,
  isEvent: PropTypes.bool,
  name: PropTypes.string,
  showHelp: PropTypes.func,
  disallowOpen: PropTypes.bool,
  labelNode: PropTypes.node,
  invertColors: PropTypes.bool,
  menu: PropTypes.node
};

AccordionWrapped.defaultProps = {
  label: "",
  toggleOpen: null,
  handleOpen: null,
  testId: null,
  disabled: false,
  isGroup: false,
  isEvent: false,
  name: null,
  showHelp: null,
  disallowOpen: false,
  menu: null,
  labelNode: null,
  invertColors: false
};

// Style Overrides
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${pad}px;
  border-radius: ${radius};

  &:last-child {
    margin-bottom: 0;
  }

  ${({disabled}) =>
    disabled
      ? css`
          fieldset {
            cursor: not-allowed;
          }
          opacity: 0.2;
        `
      : ""}

  ${({isGroup, isEvent, theme}) =>
    isGroup
      ? css`
          border: ${border} solid ${!isEvent ? theme.group : theme.secondary};
        `
      : ""}

  ${({invertColors, theme}) =>
    invertColors
      ? css`
          border: ${border} solid ${theme.secondary};
          background-color: ${theme.secondary};

          input {
            background-color: ${theme.tertiary};
          }
        `
      : ""}
`;

const Menu = styled.fieldset`
  ${flex("row", "nowrap", "space-between", "center")};
  width: 100%;
  min-width: 0; // css default for fieldset with 100%
  padding: ${pad / 2}px ${pad}px;
  background-color: ${({invertColors, theme}) => (invertColors ? theme.secondary : theme.tertiary)};
  border-radius: ${radius};

  ${({disallowOpen}) =>
    !disallowOpen
      ? css`
          cursor: pointer;
        `
      : ""}
`;

const LabelWrapper = styled(Inline)`
  width: 70%;

  ${({hasMenu}) =>
    hasMenu &&
    css`
      width: 95%;
    `}
`;

const MenuWrapper = styled(Inline)`
  width: 30%;
  justify-content: end;

  ${({hasMenu}) =>
    hasMenu &&
    css`
      width: 5%;
    `}
`;

const AccordionLabel = styled(Label)`
  font-weight: bold;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.secondary};

  ${({isGroup, isEvent, theme}) =>
    isGroup
      ? css`
          ${voice.medium};
          text-transform: uppercase;
          color: ${!isEvent ? theme.group : theme.secondary};
          margin-bottom: ${pad / 2}px;
        `
      : ""}

  ${({invertColors, theme}) =>
    invertColors
      ? css`
          color: ${theme.tertiary};
        `
      : ""};
`;

const Arrow = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  transform: rotate(0deg);
  transition: ${transition};

  ${({openDrawer}) =>
    openDrawer &&
    css`
      transform: rotate(90deg);
    `}

  ${({isGroup, isEvent, invertColors, theme}) => {
    if (isGroup) return triangle(pad / 2, !isEvent ? theme.group : theme.secondary, "right");
    return triangle(pad / 2, invertColors ? theme.tertiary : theme.secondary, "right");
  }}
`;

const Hide = styled.div`
  ${({hide}) =>
    hide
      ? css`
          visibility: hidden;
          max-height: 0;
          overflow: hidden;
        `
      : ""}

  ${({invertColors, theme}) =>
    invertColors &&
    css`
      background-color: ${theme.secondary};
    `}
`;

const Help = styled.button`
  width: auto;
  padding: ${pad / 2}px ${pad / 2}px ${pad / 2}px 0;
  fill: ${({theme}) => theme.group};
`;

export default AccordionWrapped;
