import React, {useContext, useState, useEffect, useRef, useCallback} from "react";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";

// Utils
import {SettingsContext} from "../contexts/settings.js";
import {AuthContext} from "../contexts/auth.js";
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";
import {prettyDateWithDayInUserTimezone, toTitleCase} from "../utils/helpers.js";

// Components
import BaseTable from "../components/BaseTable.js";
import Modal from "../components/Modal.js";
import Dropdown from "../components/Dropdown.js";
import Pagination from "../components/Pagination.js";
import MultiSelect from "../components/MultiSelect.js";
import Badge from "../components/Badge.js";
import {InputText, InputSelect, InputTextArea} from "../components/form/FormInputs.js";

// Style
import {voice} from "../style/components/typography.js";
import {border, colors, navHeight, pad, radius, shadow} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {
  Form,
  FormGroup,
  Title,
  ButtonFull,
  FormField,
  Pill,
  Heading,
  Text,
  Button,
  ButtonLoader,
  Inline,
  TableFooter,
  Small,
  SearchWrapper,
  SearchIcon,
  Search,
  LabelBold
} from "../style/components/general.js";

const defaultPages = [
  "User Management",
  "Global Schedule",
  "Global Custom Tables",
  "Component Templates",
  "Facilities Dashboard",
  "Facility Dashboard",
  "Facility Map/Builder",
  "Checksheet Builder",
  "Checksheet Editor",
  "Schedule",
  "Tasks"
];

const defaultValues = {
  type: "",
  subject: ""
};

const schema = {
  subject: yup.string().required("Subject is required."),
  type: yup.string().required("Type is required.")
};

const Support = () => {
  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);
  const {currentUser, atLeast, roleCanAccessPage, getAccessablePages, roleCanAccessResource} =
    useContext(AuthContext);

  const {api, data} = useApi("ticket");

  const [pages, setPages] = useState(defaultPages);
  const [loading, setLoading] = useState(false);
  const [existing, setExisting] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");

  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  // Pagination
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("key");
  const [limit, setLimit] = useState(5);
  const [limits, setLimits] = useState([]);

  const validationSchema = useRef(schema);

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape(validationSchema.current ? {...validationSchema.current} : {...schema})
    )
  });
  const {watch, handleSubmit, reset} = form;
  const watchType = watch("type");

  const getExisting = useCallback(() => {
    const filter = {
      Type: [],
      Status: []
    };

    if (query) filter.Search = query;
    if (showDeleted) filter.showDeleted = true;

    if (activeFilters)
      activeFilters.map(curr => {
        if (curr === "open" || curr === "closed") filter.Status.push(curr);
        else filter.Type.push(curr);
      });

    api
      .callGet(null, {
        page: current,
        orderBy,
        groupBy,
        limit,
        filter: JSON.stringify(filter),
        userManagement: true
      })
      .then(({status, data: response}) => {
        if (status === 200) setExisting(response.tickets);
      });
  }, [api, current, groupBy, limit, orderBy, query, activeFilters, showDeleted]);

  useEffect(() => {
    if (isMounted() && existing === null) getExisting();

    if (isMounted() && currentUser.role) {
      const {allIds, byId} = currentUser.role.permissions.page;
      const tempOptions = getAccessablePages(allIds, byId);
      setPages(
        tempOptions
          .map(page => {
            const {parent} = byId[page];
            const parentName = parent ? `${byId[parent].name} ` : "";
            return `${parentName}${byId[page].name}`;
          })
          .sort()
      );
    }
  }, [isMounted, api, getExisting, existing, currentUser, getAccessablePages, setPages]);

  // Update users on sort or limit
  useEffect(() => {
    if (isMounted() && existing) getExisting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, groupBy, limit, query, activeFilters, showDeleted]);

  const handleSupportRequest = ({type, subject, ...rest}) => {
    setLoading(true);
    const params = {type, subject: subject, lines: {...rest}};
    api
      .callPost({createdBy: currentUser.publicId, ...params})
      .then(({status}) => {
        if (status === 201) {
          setExisting(null);
          reset({...defaultValues});
        }
      })
      .finally(() => setLoading(false));
  };

  const requestAllowed = (updatedAt, createdAt) => {
    if (updatedAt !== null) return dayjs().diff(dayjs(updatedAt), "days") >= 5;
    return dayjs().diff(dayjs(createdAt), "days") >= 5;
  };

  const requestReminder = id => api.callPut(id, {requestUpdate: true}).then(setExisting(null));

  const renderFields = fields => {
    validationSchema.current = schema;
    return fields.map(({name, validate, field}) => {
      validationSchema.current[name] = validate;
      return <FormField key={name}>{field}</FormField>;
    });
  };

  const filterMenu = useRef(null);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu &&
      filterMenu.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const handleApply = () => {
    setShowFilters(false);
    // trigger reload
    setActiveFilters(filters);
  };

  const handleClear = () => {
    setFilters([]);
    setShowFilters(false);
    setShowDeleted(false);
    // trigger reload
    setActiveFilters(null);
  };

  useEffect(() => {
    if (isMounted() && !loading && data && data.tickets) {
      setPageTotal(data.pages);
      setExisting(data.tickets);

      // Page Limit
      const {total: dataTotal} = data;

      setTotal(dataTotal);
      let count = 10;
      const temp = [];
      while (count < total + 10 && count <= 50) {
        temp.push(count);
        count += 10;
      }
      setLimits(temp);
    }
  }, [isMounted, data, loading, total]);

  const handleSearch = e => {
    setCurrent(1);
    setQuery(e.target.value);
  };

  const closeTicket = id =>
    api.callPut(id, {updatedBy: currentUser.publicId, close: true}).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        setSelected(null);
        getExisting();
      }
    });

  const deleteTicket = id =>
    api.callDelete(id).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        setSelected(null);
        getExisting();
      }
    });

  const defaultOptions = {
    bug: {
      label: "Bug Report",
      subject: "ex: URGENT! Unable to Submit a Checksheet",
      fields: [
        {
          name: "where",
          validate: yup.string().required("Page location is required."),
          field: (
            <InputSelect
              name="where"
              label="Where did you experience this bug?"
              placeholder="Page..."
              options={pages}
              required
            />
          )
        },
        {
          name: "what",
          validate: yup.string().required("Description is required."),
          field: (
            <InputTextArea
              name="what"
              label="What happened?"
              placeholder='ex: When I attempted to submit a checksheet a "Network Error" error was displayed.'
              required
            />
          )
        },
        {
          name: "expectation",
          validate: yup.string().required("Expectation is required."),
          field: (
            <InputTextArea
              name="expectation"
              label="What was expected?"
              placeholder="ex: I expected the checksheet to be submitted successfully."
              required
            />
          )
        }
      ]
    },
    feature: {
      label: "Feature Request",
      subject: "ex: Data Export",
      fields: [
        {
          name: "problem",
          validate: yup.string().required("Problem is required."),
          field: (
            <InputTextArea
              name="problem"
              label="What is the problem you are trying to solve?"
              placeholder="ex: I need to pull data from Hero Builder™ in order to use it in Excel."
              required
            />
          )
        },
        {
          name: "description",
          validate: yup.string().required("Description is required."),
          field: (
            <InputTextArea
              name="description"
              label="Feature Description"
              placeholder="ex: Allow Data to be exported from the records table in a specific order with filters (Preferably as an Excel sheet)."
              required
            />
          )
        }
      ]
    },
    general: {
      label: "General Inquiry",
      subject: "ex: Onboarding a new Facility",
      fields: [
        {
          name: "description",
          validate: yup.string().required("Description is required."),
          field: (
            <InputTextArea
              name="description"
              label="Description"
              placeholder="ex: I would like to discuss onboarding a new facility into Hero Builder™."
              required
            />
          )
        }
      ]
    },
    technical: {
      label: "Technical Support",
      subject: "ex: Need to discuss an Unacceptable Parameter",
      fields: [
        {
          name: "description",
          validate: yup.string().required("Description is required."),
          field: (
            <InputTextArea
              name="description"
              label="Description"
              placeholder="ex: I would like to schedule a meeting to discuss an unacceptable parameter within the system."
              required
            />
          )
        }
      ]
    }
  };

  return (
    <SupportPage>
      <PageWrapper>
        <FormWrapper>
          <Title>Support Ticket</Title>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(handleSupportRequest)} noValidate>
              <FormGroup>
                <FormField>
                  <InputSelect
                    name="type"
                    label="Which scenario best describes your support request?"
                    placeholder="Inquiry Type..."
                    options={Object.keys(defaultOptions).map(option => ({
                      label: defaultOptions[option].label,
                      value: option
                    }))}
                  />
                </FormField>
              </FormGroup>
              {watchType && (
                <>
                  <FormGroup>
                    <FormField>
                      <InputText
                        name="subject"
                        label="Subject"
                        placeholder={watchType ? defaultOptions[watchType].subject : "Subject..."}
                        required
                      />
                    </FormField>
                    {renderFields(defaultOptions[watchType].fields)}
                  </FormGroup>
                  <ButtonFull type="submit" loading={loading ? 1 : 0}>
                    {loading && <ButtonLoader />}
                    Send
                  </ButtonFull>
                </>
              )}
            </Form>
          </FormProvider>
        </FormWrapper>
        {roleCanAccessPage("existing_tickets") && roleCanAccessResource("ticket", "view") && (
          <Existing>
            <Tickets>
              <div>
                <TableTitle>Existing Tickets</TableTitle>
                <TableMenu fullWidth>
                  <SearchWrapper>
                    <SearchIcon>
                      <FontAwesomeIcon icon={faSearch} />
                    </SearchIcon>
                    <Search
                      name="search"
                      type="text"
                      placeholder="Search..."
                      onChange={e => handleSearch(e)}
                    />
                  </SearchWrapper>
                  <div>
                    <Button type="button" key="Filter" onClick={() => setShowFilters(!showFilters)}>
                      <Badge
                        count={activeFilters ? activeFilters.length : 0}
                        offset="14px"
                        color={colors.heroGreen}
                      />
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>
                    {showFilters && (
                      <OptionSub ref={filterMenu}>
                        <Small>Filter(s)</Small>
                        <MultiSelect
                          options={[
                            ...Object.keys(defaultOptions).map(option => ({
                              label: defaultOptions[option].label,
                              value: option
                            })),
                            {value: "open", label: "Open"},
                            {value: "closed", label: "Closed"}
                          ]}
                          defaultSelection={filters}
                          setSelection={setFilters}
                        />
                        <Inline>
                          <ApplyFilter type="button" onClick={handleApply}>
                            Apply
                          </ApplyFilter>
                          <ApplyFilter type="button" onClick={handleClear}>
                            Clear
                          </ApplyFilter>
                          {atLeast("creator") && (
                            <ApplyFilter
                              type="button"
                              title="Show/Hide Deleted"
                              onClick={() => {
                                setShowFilters(false);
                                setShowDeleted(prev => !prev);
                              }}>
                              <FontAwesomeIcon icon={showDeleted ? faEyeSlash : faEye} />
                              &nbsp;Deleted
                            </ApplyFilter>
                          )}
                        </Inline>
                      </OptionSub>
                    )}
                  </div>
                </TableMenu>
              </div>
              <BaseTable
                headings={{
                  view: {header: "Ticket", disabled: true},
                  id: {header: "ID", disabled: false},
                  open: {header: "Status", disabled: false},
                  createdAt: {header: "Submitted", disabled: false},
                  updatedAt: {header: "Closed", disabled: false}
                }}
                data={
                  existing
                    ? existing.map(ticket => {
                        const {key, open, createdAt, updatedAt} = ticket;
                        return {
                          view: (
                            <Button
                              type="button"
                              onClick={() => {
                                setVisible(true);
                                setSelected(ticket);
                              }}
                              data-testid="tableRecord.preview">
                              View
                            </Button>
                          ),
                          id: key.split(" ")[1],
                          open: (
                            <Pill color={!open ? colors.gray : null}>
                              {open ? "Open" : "Closed"}
                            </Pill>
                          ),
                          createdAt: prettyDateWithDayInUserTimezone(
                            createdAt,
                            settings.timezone,
                            "ddd, MMM D YYYY"
                          ),
                          updatedAt: prettyDateWithDayInUserTimezone(
                            updatedAt,
                            settings.timezone,
                            "ddd, MMM D YYYY"
                          )
                        };
                      })
                    : null
                }
                updateData={getExisting}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                groupBy={groupBy}
                setGroupBy={setGroupBy}
                loading={loading}
                dataMissing="No existing tickets found"
              />
              <TableFooter>
                <Inline>
                  {total > 10 && limits.length > 0 && (
                    <PageLimit>
                      <Dropdown
                        options={limits}
                        selection={limit}
                        setSelection={selection => {
                          setLimit(selection);
                          setCurrent(1);
                        }}
                      />
                    </PageLimit>
                  )}
                  <Small>
                    {pageTotal > 1 && limits.length > 0 && "per page,"} {total}&nbsp;total
                  </Small>
                </Inline>
                {pageTotal > 1 && (
                  <Pagination
                    current={current}
                    setCurrent={setCurrent}
                    pageTotal={pageTotal}
                    updateData={getExisting}
                    loading={loading}
                  />
                )}
              </TableFooter>
            </Tickets>
          </Existing>
        )}

        {visible && (
          <Modal visible={visible} setVisible={setVisible}>
            <Pill color={!selected.open ? colors.gray : null}>
              {selected.open ? "OPEN" : "CLOSED"}
            </Pill>
            <ModalHeading>
              {selected.key}: {defaultOptions[selected.type].label}
            </ModalHeading>
            <Heading>{selected.name}</Heading>
            <Text>
              <strong>Reported By: </strong>
              {selected.createdBy.firstName} {selected.createdBy.lastName}
              &nbsp;on&nbsp;
              {prettyDateWithDayInUserTimezone(
                selected.createdAt,
                settings.timezone,
                "ddd, MMM D YYYY"
              )}
            </Text>
            <br />
            {JSON.parse(selected.description).map(line => {
              const pair = line.split(":");
              return (
                <Line key={pair[0]}>
                  <LabelBold key={pair[0]}>{toTitleCase(pair[0])}</LabelBold>
                  <Text>{pair[1]}</Text>
                </Line>
              );
            })}
            <br />
            {!selected.open && (
              <Text>
                <strong>Closed By: </strong>
                {selected.updatedBy.firstName} {selected.updatedBy.lastName}
                &nbsp;on&nbsp;
                {prettyDateWithDayInUserTimezone(
                  selected.updatedAt,
                  settings.timezone,
                  "ddd, MMM D YYYY"
                )}
              </Text>
            )}
            <TicketOptions>
              {roleCanAccessResource("ticket", "update") &&
                requestAllowed(selected.updatedAt, selected.createdAt) &&
                selected.open && (
                  <Button
                    type="button"
                    title="Close Ticket"
                    onClick={() => requestReminder(selected.id)}>
                    Request Update
                  </Button>
                )}
              {atLeast("creator") && roleCanAccessResource("ticket", "update") && selected.open && (
                <Button type="button" title="Close Ticket" onClick={() => closeTicket(selected.id)}>
                  Close Ticket
                </Button>
              )}
              {atLeast("super") && roleCanAccessResource("ticket", "delete") && (
                <Delete
                  type="button"
                  title="Close Ticket"
                  onClick={() => deleteTicket(selected.id)}>
                  Delete
                </Delete>
              )}
            </TicketOptions>
          </Modal>
        )}
      </PageWrapper>
    </SupportPage>
  );
};

// Style Overrides
const SupportPage = styled.section`
  position: relative;
  width: 100vw;
  min-height: 100vh;
`;

const PageWrapper = styled.section`
  ${flex("row", "wrap", "start", "start")};
  width: 100%;
  padding: ${navHeight + pad * 2}px ${pad * 2}px ${navHeight}px;
`;

const FormWrapper = styled.section`
  ${flex("row", "wrap", "start", "start")};
  width: 100%;
  padding-right: ${pad * 2}px;

  ${bp(3)} {
    width: 40%;
  }
`;

const Existing = styled.section`
  width: 100%;

  ${bp(3)} {
    width: 60%;
  }
`;

const Tickets = styled.div`
  position: relative;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad}px;
`;

const TableTitle = styled(Heading)`
  padding: ${pad}px ${pad}px 0;
  color: ${props => props.theme.secondary};
  width: fill-available;
`;

const TableMenu = styled(Inline)`
  justify-content: space-between;
  padding: ${pad}px;

  ${Title} {
    color: ${props => props.theme.secondary};
  }
`;

const OptionSub = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 205px;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${props => props.theme.tertiary};
  margin-top: ${pad}px;
  z-index: ${z("top")};
  box-shadow: ${shadow};

  color: ${props => props.theme.secondary};

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  width: fit-content;
  height: min-content;
  white-space: nowrap;
  padding: ${pad / 2}px;
  margin-left: 0 !important;
  margin-right: ${pad / 2}px;

  svg {
    fill: ${props => props.theme.tertiary};
    align-self: center;
  }
`;

const PageLimit = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${pad}px;

  select {
    padding: ${pad / 4}px;
  }
`;

const TicketOptions = styled(Inline)`
  padding-top: ${pad * 2}px;
  gap: ${pad}px;

  ${Button} {
    ${voice.quiet};
    padding: ${pad / 2} ${pad}px;
  }
`;

const ModalHeading = styled.h3`
  ${voice.medium};
  font-weight: bold;
  color: ${props => props.theme.primary};
  margin-top: ${pad}px;
`;

const Line = styled.div`
  padding-bottom: ${pad}px;
`;

const Delete = styled(Button)`
  background: ${props => props.theme.error};
`;

export default Support;
