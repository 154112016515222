import React, {useState, useEffect, useContext, useCallback, useMemo} from "react";
import {useParams} from "react-router-dom";
import styled, {css} from "styled-components";
import dayjs from "dayjs";

// Contexts
import {useSocket} from "../contexts/socket.js";
import {AuthContext} from "../contexts/auth.js";
import {SettingsContext} from "../contexts/settings.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {CalendarContext} from "../contexts/calendar.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {isMobileSize} from "../utils/responsive.js";
import {generateVirtualEvents} from "./schedule/helpers.js";

// Components
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Menu from "./schedule/Menu.js";
import Calendar from "./schedule/Calendar.js";
import TableEvent from "./schedule/TableEvent.js";
import TableEventRecord from "./schedule/TableEventRecord.js";
import Modal from "../components/Modal.js";
import ModalEventOptions from "./schedule/ModalEventOptions.js";
import ModalEvent from "./schedule/ModalEvent.js";
import ModalEvents from "./schedule/ModalEvents.js";
import ModalSettings from "./schedule/ModalSettings.js";
import ModalBulkApply from "./schedule/ModalBulkApply.js";
import ModalFilter from "./schedule/ModalFilter.js";
import ModalBulkComplete from "./schedule/ModalBulkComplete.js";

// Style
import {bp} from "../style/components/breakpoints.js";
import {flex} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {border, pad, radius, transition} from "../style/components/variables.js";
import {Button, Page, Text, HeadingMedium, Title} from "../style/components/general.js";

// Socket Constants
import Room, {RELOAD_EVENT, UPDATE_EVENT} from "./general/Room.js";

const now = new Date();
now.setHours(0, 0, 0, 0);
const today = dayjs(now).format("YYYY-MM-DD");

const Schedule = () => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {settings} = useContext(SettingsContext);

  const {slug} = useParams();
  const isGlobal = typeof slug !== "string";

  const {roleCanAccessResource} = useContext(AuthContext);
  const {calendar, getRecords, updateRecord} = useContext(CalendarContext);
  const {facility, setFacility} = useContext(FacilityNavContext) || {};

  const {api: apiFacilities} = useApi("facilities");
  const {api: apiEventTypes} = useApi("event-types");
  const {api: apiTemplates, data: allTemplates} = useApi("event-templates");
  const {api: apiEvents} = useApi("events");
  const {api: apiFrequencies, data: frequencies} = useApi("frequencies");
  const {api: apiUnits} = useApi("units");
  const {api: apiAddress, data: markers} = useApi("addresses");

  const [showHeader, setShowHeader] = useState(false);
  const [tab, setTab] = useState("calendar");
  const [eventTypes, setEventTypes] = useState(null);
  const [events, setEvents] = useState(null);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [active, setActive] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [units, setUnits] = useState([]);
  const [range, setRange] = useState("month");
  const [showEventForm, setShowEventForm] = useState(false);
  const [eventsModified, setEventsModified] = useState({});
  const [bulkTarget, setBulkTarget] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  // Filters
  const [filters, setFilters] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  // Modals
  const [showModalEventOptions, setShowModalEventOptions] = useState(false);
  const [showModalBulkApply, setShowModalBulkApply] = useState(false);
  const [showModalEvent, setShowModalEvent] = useState(false);
  const [showModalBulkComplete, setShowModalBulkComplete] = useState(false);
  const [showModalSettings, setShowModalSettings] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalModified, setShowModalModified] = useState(false);
  // Calendar
  const [calendarLoading, setCalendarLoading] = useState(true);
  const [view, setView] = useState(localStorage.getItem("schedule_view") || "calendar");

  const markerIconMap = useMemo(() => {
    const map = {};
    markers?.map(({id, ...rest}) => {
      map[id] = {...rest};
    });
    return map;
  }, [markers]);

  const getEvents = useCallback(() => {
    setCalendarLoading(true);
    const params = {showArchived};
    if (facility?.id) params.facilityId = facility.id;
    apiEvents.callGet(null, params).then(({status, data}) => {
      if (status === 200 && data) {
        if (!calendar || data?.length === 0) setEvents(null);

        const lastWeek = calendar[calendar.length - 1];
        const lastDate = lastWeek[lastWeek.length - 1];

        const endDateBound = lastDate?.date;
        const startDateBound = new Date(endDateBound);
        startDateBound.setDate(startDateBound.getDate() - (7 * calendar.length - 1));
        const virtual = data
          ? generateVirtualEvents(data, settings.timezone, startDateBound, endDateBound)
          : null;
        setEvents(virtual);
        getRecords();
      }
    });
  }, [apiEvents, facility, calendar, settings, getRecords, showArchived]);

  // Initial Load - set facility
  useEffect(() => {
    if (isMounted() && !isGlobal && facility === null)
      apiFacilities.callGet(slug).then(({status, data}) => {
        if (status === 200) setFacility(data);
      });
  }, [isMounted, isGlobal, facility, apiFacilities, slug, setFacility]);

  // Initial Load - get events on Facility Schedule
  useEffect(() => {
    if (isMounted() && !isGlobal && facility !== null && calendar) getEvents();
  }, [isMounted, isGlobal, facility, calendar, getEvents]);

  // Initial Load - get events on Global Schedule
  useEffect(() => {
    if (isMounted() && isGlobal) getEvents();
  }, [isMounted, isGlobal, getEvents]);

  // Initial load - other resources
  useEffect(() => {
    if (isMounted()) {
      setTimeout(() => setShowHeader(true), 1000);

      apiTemplates.callGet();

      apiFrequencies.callGet();

      apiUnits.callGet().then(({status, data}) => {
        if (status === 200) setUnits(data);
      });

      apiAddress.callGet(null, {markers: true});
    }
  }, [isMounted, apiTemplates, apiFrequencies, apiUnits, apiAddress]);

  useEffect(() => {
    if (isMounted() && eventTypes === null)
      apiEventTypes.callGet().then(({status, data}) => {
        if (status === 200) setEventTypes(data);
      });
  }, [isMounted, eventTypes, apiEventTypes]);

  // Handle loading state
  useEffect(() => {
    if (isMounted() && eventTypes && events) setCalendarLoading(false);
  }, [isMounted, eventTypes, events]);

  // Socket Management
  useEffect(() => {
    const showLoadButton = payload => {
      const READABLE = {put: "modified", delete: "deleted"};

      if (payload?.eventId && payload?.action && ["put", "delete"].includes(payload.action)) {
        const {eventId, action} = payload;
        setEventsModified(prev => ({...prev, [eventId]: READABLE[action]}));
      }

      setHasChanges(true);
    };

    socket.on(RELOAD_EVENT, showLoadButton);
    socket.on(UPDATE_EVENT, updateRecord);

    return () => {
      // unbind all event handlers used in this component
      socket.off(RELOAD_EVENT, showLoadButton);
      socket.off(UPDATE_EVENT, updateRecord);
    };
  }, [socket, setHasChanges, updateRecord]);

  // setCurrentEvent data when creating event
  const addEvent = date => {
    if (date || allTemplates?.length === 0 || isMobileSize()) {
      setCurrentDate(date);
      setIsEditing(false);
      setShowEventForm(true);
      setShowModalEvent(true);
    } else setShowModalEventOptions(true);
  };

  const editEvent = () => {
    setIsEditing(true);
    setShowEventForm(true);
  };

  const cancelEdit = () => {
    setShowEventForm(false);
    setIsEditing(false);
  };

  const restoreEvent = () => {
    if (currentEvent)
      apiEvents.callPut(currentEvent.id, {archive: false}).then(({status}) => {
        if (status === 200) {
          setShowModalEvent(false);
          getEvents();
        }
      });
  };

  const viewEvent = useCallback(event => {
    setCurrentDate(event.dateDue);
    setCurrentEvent(event);
    setShowModalEvent(true);
    setShowEventForm(false);
  }, []);

  const closeEvent = useCallback(() => {
    setCurrentDate(null);
    setCurrentEvent(null);
    setShowModalEvent(false);
    setShowEventForm(false);
  }, []);

  // setCurrentEvent data when clicking on individual event
  const handleClickEvent = (selectedEvent, date, eventsOnDay) => {
    if (selectedEvent.id in eventsModified) setShowModalModified(true);
    else {
      setIsEditing(false);
      setShowModalEvent(true);
      setShowEventForm(false);
    }

    setCurrentDate(date);
    setCurrentEvent(selectedEvent);
    setCurrentEvents(eventsOnDay);
  };

  const handleDoubleClickEvent = dateStr => {
    setShowEventForm(true);
    addEvent(dateStr);
  };

  const handleBulkComplete = targets => {
    const targetEvents = targets || currentEvents;
    setBulkTarget(targetEvents);
    setShowModalBulkComplete(true);
    setShowModalEvent(false);
    setShowAll(false);
    setShowEventForm(false);
  };

  return (
    <Page hasMenu={!isGlobal}>
      <Room name="schedule" active={active} setActive={setActive} />

      <PageHeader>
        <HeaderWrapper show={showHeader}>
          {facility?.name && (
            <FacilityPageHeader
              facility={facility}
              reloadData={() => setFacility(null)}
              path="/schedule"
            />
          )}

          {!facility?.name && <Header>GLOBAL SCHEDULE</Header>}
        </HeaderWrapper>

        {roleCanAccessResource("event_record", "view") && (
          <Tabs>
            <Tab type="button" onClick={() => setTab("calendar")} selected={tab === "calendar"}>
              Calendar
            </Tab>
            <Tab type="button" onClick={() => setTab("events")} selected={tab === "events"}>
              Events
            </Tab>
          </Tabs>
        )}
      </PageHeader>

      {tab === "calendar" && (
        <Container>
          <Menu
            view={view}
            setView={setView}
            filters={filters}
            setFilters={setFilters}
            addEvent={addEvent}
            setShowModalExport={setShowModalExport}
            setShowModalFilter={setShowModalFilter}
            hasChanges={tab !== "events" && hasChanges}
            setHasChanges={() => {
              setHasChanges(false);
              getEvents();
            }}
            setShowModalSettings={setShowModalSettings}
          />
          {view === "calendar" && (
            <Calendar
              events={events?.onDay}
              today={today}
              filters={filters}
              addEvent={addEvent}
              currentEvent={currentEvent}
              setCurrentEvent={setCurrentEvent}
              handleClickEvent={handleClickEvent}
              handleDoubleClickEvent={handleDoubleClickEvent}
              showAll={(current, date) => {
                setCurrentDate(date);
                setShowAll(true);
                setCurrentEvents(current);
              }}
              range={range}
              setRange={setRange}
              markerIconMap={markerIconMap}
              isGlobal={isGlobal}
              loading={calendarLoading}
            />
          )}
          {view === "list" && (
            <TableEventRecord
              facility={facility}
              eventTypes={eventTypes}
              events={events}
              filters={filters}
              setFilters={setFilters}
              loading={calendarLoading}
              setShowEventForm={handleClickEvent}
              refresh={getEvents}
              showModalExport={showModalExport}
              setShowModalExport={setShowModalExport}
              markerIconMap={markerIconMap}
            />
          )}
        </Container>
      )}

      {tab === "events" && eventTypes && (
        <EventWrapper>
          <TableEvent
            facility={facility}
            viewEvent={viewEvent}
            filters={filters}
            setFilters={setFilters}
            addEvent={addEvent}
            setShowModalFilter={setShowModalFilter}
            setShowEventModal={state => {
              setCurrentDate(today);
              setShowEventForm(state);
              setShowModalEvent(state);
            }}
            markerIconMap={markerIconMap}
          />
        </EventWrapper>
      )}

      {/* Modals */}
      {showModalSettings && eventTypes && (
        <ModalSettings
          visible={showModalSettings}
          setVisible={setShowModalSettings}
          eventTypes={eventTypes}
          setEventTypes={setEventTypes}
          units={units}
          setUnits={setUnits}
          view={view}
          setView={setView}
        />
      )}

      {showModalEventOptions && (
        <ModalEventOptions
          visible={showModalEventOptions}
          setVisible={setShowModalEventOptions}
          handleSingle={() => {
            setShowModalEventOptions(false);
            setShowModalEvent(true);
            setShowEventForm(true);
          }}
          handleBulk={() => {
            setShowModalEventOptions(false);
            setShowModalBulkApply(true);
          }}
        />
      )}

      {showModalBulkApply && (
        <ModalBulkApply
          visible={showModalBulkApply}
          setVisible={setShowModalBulkApply}
          isGlobal={isGlobal}
          getEvents={getEvents}
          frequencies={frequencies}
          eventTypes={eventTypes}
          allTemplates={allTemplates}
          facility={facility}
        />
      )}

      {showAll && (
        <ModalEvents
          visible={showAll}
          setVisible={setShowAll}
          isGlobal={isGlobal}
          events={currentEvents}
          eventTypes={eventTypes}
          date={currentDate}
          handleClickEvent={handleClickEvent}
          handleBulkComplete={isGlobal ? handleBulkComplete : undefined}
          markerIconMap={markerIconMap}
        />
      )}

      {showModalEvent && markerIconMap && (
        <ModalEvent
          visible={showModalEvent}
          setVisible={closeEvent}
          isGlobal={isGlobal}
          isEditing={isEditing}
          facility={facility || currentEvent?.facility}
          event={currentEvent}
          eventTypes={eventTypes}
          date={currentDate ?? today}
          editEvent={editEvent}
          restoreEvent={restoreEvent}
          cancelEdit={cancelEdit}
          handleBulkComplete={isGlobal && handleBulkComplete}
          showEventForm={showEventForm}
          getEvents={getEvents}
          units={units}
          setUnits={setUnits}
          frequencies={frequencies}
          markerIconMap={markerIconMap}
        />
      )}

      {showModalBulkComplete && bulkTarget && (
        <ModalBulkComplete
          visible={showModalBulkComplete}
          setVisible={setShowModalBulkComplete}
          date={currentDate}
          events={bulkTarget}
          eventTypes={eventTypes}
          getEvents={getEvents}
          markerIconMap={markerIconMap}
        />
      )}

      {showModalFilter && (
        <ModalFilter
          visible={showModalFilter}
          setVisible={setShowModalFilter}
          facility={facility}
          events={events}
          eventTypes={eventTypes}
          filters={filters}
          setFilters={setFilters}
          showArchived={showArchived}
          setShowArchived={setShowArchived}
        />
      )}

      {showModalModified && currentEvent && (
        <Modal visible={showModalModified} setVisible={setShowModalModified}>
          <HeadingMedium>Missing Changes</HeadingMedium>
          <Text>
            This event has been {eventsModified[currentEvent.id]} since you last viewed it.
          </Text>
          <br />
          <Button
            type="button"
            onClick={() => {
              setEventsModified(null);
              setShowModalModified(false);
              setHasChanges(false);
              getEvents();
            }}>
            Update
          </Button>
        </Modal>
      )}
    </Page>
  );
};

// Style Overrides
const PageHeader = styled.div`
  ${flex("row", "wrap", "space-between", "center")};
  margin-bottom: ${pad}px;

  ${bp(1)} {
    flex-wrap: nowrap;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  opacity: ${({show}) => (show ? 1 : 0)};
  max-width: 85%;
  align-items: end;
`;

const Header = styled(Title)`
  position: relative;

  ${bp(3)} {
    white-space: nowrap;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: ${pad}px;
  align-self: end;
`;

const Tab = styled(Button)`
  ${voice.quiet};
  border: ${border} solid ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.secondary};
  background-color: transparent;
  transition: ${transition};
  max-width: 100%;
  width: 100%;

  ${bp(3)} {
    ${voice.normal};
    max-width: max-content;
  }

  &:hover {
    background-color: ${({theme}) => theme.secondary};
    color: ${({theme}) => theme.tertiary};
  }

  ${({selected, theme}) =>
    selected &&
    css`
      background-color: ${theme.secondary};
      color: ${theme.tertiary};
    `}
`;

const EventWrapper = styled.div`
  margin: ${pad}px 0;
`;

const Container = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${pad * 2}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
`;

export default Schedule;
