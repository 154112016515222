import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  Heading,
  Loader,
  RelativeWrapper,
  Text
} from "../../style/components/general.js";

// Socket Constants
import {
  CHECK_ON_TEST,
  FACILITY_ON_TEST,
  SET_FACILITY_ON_TEST,
  SET_FACILITY_OFF_TEST
} from "./Room.js";

const ModalOnTest = ({visible, setVisible, facility}) => {
  const isMounted = useMountedState();

  const {currentUser} = useContext(AuthContext);

  const socket = useSocket();

  const [onTest, setOnTest] = useState(false);

  const {api, data, loading} = useApi("notifications");

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility.id)
      api.callGet(null, {facilityId: facility.id, notificationName: "OnTest"});
  }, [isMounted, api, facility]);

  const facilityOnTest = useCallback(
    ({facility: current, setBy}) => {
      if (setBy && current && current === facility.slug) setOnTest(setBy);
    },
    [facility.slug]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && currentUser && currentUser.publicId)
      socket.emit(CHECK_ON_TEST, facility.slug);

    socket.on(FACILITY_ON_TEST, facilityOnTest);

    return () => {
      // unbind all event handlers used in this component
      socket.off(FACILITY_ON_TEST, facilityOnTest);
    };
  }, [socket, isMounted, currentUser, facility, facilityOnTest]);

  const toggle = () => {
    socket.emit(
      onTest ? SET_FACILITY_OFF_TEST : SET_FACILITY_ON_TEST,
      facility.slug,
      currentUser.publicId
    );
    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalHeading>Facility Testing</ModalHeading>
      <Description>
        Set the <span>{facility.name}</span> on test, this will notify selected users to ignore any
        alarms from the facility until test is switched off.
      </Description>

      {/* Only Show if configured */}
      {data && (
        <RelativeWrapper>
          {!loading ? (
            <Notify>
              <Text bold>Configured to Notify:</Text>
              <Email>
                {data.map(email => (
                  <Abbr title={email} key={email}>
                    {email}
                  </Abbr>
                ))}
              </Email>
            </Notify>
          ) : (
            <Loader />
          )}
        </RelativeWrapper>
      )}

      <Button type="button" onClick={toggle}>
        Set Facility {onTest ? "OFF" : "ON"} Test
      </Button>
    </Modal>
  );
};

ModalOnTest.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const ModalHeading = styled(Heading)`
  margin-bottom: ${pad}px;
`;

const Description = styled(Text)`
  margin-bottom: ${pad}px;

  span {
    font-weight: bold;
    color: ${props => props.theme.primary};
  }
`;

const Notify = styled.div`
  margin-bottom: ${pad}px;
`;

const Email = styled.span`
  line-height: ${pad * 2.5}px;
  margin-left: ${pad}px;
  display: block;
  height: min-content;

  abbr {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default ModalOnTest;
