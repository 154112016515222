import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faHistory} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {isMobile} from "../../utils/responsive.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";

// Components
import InputTextArea from "./InputTextArea.js";
import InputError from "./InputError.js";
import InputCheck from "./InputCheck.js";
import InputSelect from "./InputSelect.js";
import Help from "../Help.js";

// Style
import {pad, shadow} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {
  Abbr,
  Button,
  CloseButton,
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Pill,
  Text
} from "../../style/components/general.js";

const InputRange = ({
  testId,
  name,
  label,
  degree,
  precision,
  prompt,
  placeholder,
  disabled,
  units,
  aMin,
  aMax,
  rangeMin,
  rangeMax,
  hasARange,
  rangeException,
  hasSetPoint,
  setLow,
  setHigh,
  overrideWidth,
  innerRef,
  previousRead,
  qualifier,
  allowInfinite,
  condition,
  tag,
  required
}) => {
  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);

  const [mobile, setMobile] = useState(false);
  const [prevVisible, setPrevVisible] = useState(false);

  const {
    watch,
    register,
    setValue,
    formState: {errors}
  } = useFormContext();

  const value = watch(name);
  const qualifierEnabled = watch(`${name}_qualifierEnabled`);
  const infinityEnabled = watch(`${name}_infinite`);

  useEffect(() => {
    if (isMounted()) setMobile(isMobile());
  }, [isMounted]);

  const evaluateRange = (low, high) =>
    Number.parseFloat(value) < Number.parseFloat(low) ||
    Number.parseFloat(value) > Number.parseFloat(high);

  const getStep = () => 1 / 10 ** precision;

  return (
    <>
      <FormFieldWrapper data-testid={testId}>
        <Inline>
          {label && (
            <Label htmlFor={name} bold inline>
              {label.toUpperCase()}
              {required && <span>*</span>}
              {tag && (
                <Pill quiet>
                  <Abbr title={tag}>{tag}</Abbr>
                </Pill>
              )}
              {condition && (
                <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>
              )}
            </Label>
          )}

          {previousRead && previousRead.value && (
            <DetailIcon type="button" onClick={() => setPrevVisible(!prevVisible)} mobile={mobile}>
              <Detail visible={prevVisible}>
                Previous Read:&nbsp;{previousRead.value}&nbsp;{units}&nbsp;on&nbsp;
                {prettyDateInUserTimezone(previousRead.date, settings.timezone, "MMM D YYYY")}
              </Detail>
              <FontAwesomeIcon icon={faHistory} />
            </DetailIcon>
          )}
        </Inline>

        {prompt && <Prompt>{prompt}</Prompt>}

        <Inline>
          {qualifier && <InputCheck hidden name={`${name}_qualifierEnabled`} />}
          {qualifier && !qualifierEnabled && (
            <StyledButton type="button" onClick={() => setValue(`${name}_qualifierEnabled`, true)}>
              Qualifier
            </StyledButton>
          )}
          {qualifier && qualifierEnabled && (
            <>
              <StyledCloseButton
                data-testid="close-button"
                type="button"
                onClick={() => {
                  setValue(`${name}_qualifier`, null);
                  setValue(`${name}_qualifierEnabled`, false);
                }}
              />
              <SelectWrapper>
                <InputSelect
                  name={`${name}_qualifier`}
                  placeholder="Select..."
                  options={["<", ">", "≤", "≥", "ND", "DNQ", "EST"]}
                />
              </SelectWrapper>
            </>
          )}
          <InputWrapper>
            <StyledInput
              id={name}
              data-testid={`${testId}-input`}
              ref={innerRef}
              type="range"
              placeholder={placeholder}
              disabled={disabled || infinityEnabled}
              min={rangeMin}
              max={rangeMax}
              step={degree === "Number" ? 1 : getStep()}
              size="10"
              overrideWidth={overrideWidth}
              onWheel={e => e.target.blur()} // disable scroll increment
              {...register(name, {required: required})}
            />

            <StyledText>
              {!infinityEnabled ? value : "∞"} {units}
            </StyledText>
          </InputWrapper>
          {hasSetPoint && (
            <Info>
              Set&nbsp;Point:&nbsp;{setLow}&nbsp;-&nbsp;{setHigh}
            </Info>
          )}
        </Inline>

        {allowInfinite && <InputCheck name={`${name}_infinite`}>Value is Infinite</InputCheck>}

        <InputError errors={errors} name={name} />
      </FormFieldWrapper>
      {hasARange && evaluateRange(aMin, aMax) && (
        <InputTextArea
          name={`${name}_comment`}
          label={rangeException || defaultUnacceptableParameterPrompt}
          required
          maxLength={1000}
        />
      )}
    </>
  );
};

InputRange.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  degree: PropTypes.string,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  units: PropTypes.string,
  aMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasARange: PropTypes.bool,
  rangeMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rangeMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeException: PropTypes.string,
  hasSetPoint: PropTypes.bool,
  setLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]),
  overrideWidth: PropTypes.string,
  previousRead: PropTypes.objectOf(PropTypes.any),
  qualifier: PropTypes.bool,
  allowInfinite: PropTypes.bool,
  disableInverse: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputRange.defaultProps = {
  testId: "input-range",
  label: null,
  prompt: null,
  precision: 2,
  degree: "Decimal",
  placeholder: null,
  required: true,
  disabled: false,
  innerRef: null,
  units: "",
  aMin: null,
  aMax: null,
  hasARange: false,
  min: null,
  max: null,
  rangeException: "",
  hasSetPoint: false,
  setLow: null,
  setHigh: null,
  overrideWidth: null,
  previousRead: null,
  qualifier: false,
  allowInfinite: false,
  disableInverse: false,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const Prompt = styled(Text)`
  margin-top: 2px;
  margin-bottom: ${pad / 2}px;
`;

const Info = styled.span`
  color: ${({theme}) => theme.secondary};
  margin-left: ${pad}px;
  ${voice.quiet};

  ${bp(3)} {
    ${voice.normal};
  }
`;

const InputWrapper = styled.div`
  ${flex("row", "nowrap", "start", "center")}
  gap: ${pad}px;
  position: relative;
`;

const Detail = styled.span`
  ${voice.quiet};
  position: absolute;
  left: 0;
  bottom: 25px;
  color: ${({theme}) => theme.primary};
  background: ${({theme}) => theme.secondary};
  border-radius: 5px;
  padding: ${pad / 4}px ${pad}px;
  transition: all ease 0.2s;
  box-shadow: ${shadow};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;

  ${({visible}) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const DetailIcon = styled.button`
  ${voice.quiet};
  margin-left: ${pad / 2}px;
  width: min-content;
  color: ${({theme}) => theme.primary};
  background-color: transparent;

  svg {
    fill: ${({theme}) => theme.primary};
  }

  ${({mobile}) =>
    !mobile &&
    css`
      &:hover {
        ${Detail} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}
`;

const StyledInput = styled(Input)`
  width: 100px;
  min-width: 150px;
  padding: 0;

  ${({overrideWidth}) =>
    overrideWidth &&
    css`
      min-width: ${overrideWidth};
      width: ${overrideWidth};
    `}
`;

const StyledCloseButton = styled(CloseButton)`
  margin-right: ${pad}px;
  margin-left: 0;
`;

const StyledButton = styled(Button)`
  margin-right: ${pad}px;
  padding: ${pad}px;
  ${voice.quiet};
`;

const SelectWrapper = styled.div`
  width: 100px;
  margin-right: ${pad}px;
  margin-top: -${pad}px;
`;

const StyledText = styled(Text)`
  ${voice.small}
`;

export default InputRange;
